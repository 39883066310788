<template>
    <div class="graphicComet_main">
        <div class="graphicComet">
            <ul class="graphicComet_lines">
                <li><span>100</span></li>
                <li><span>80</span></li>
                <li><span>60</span></li>
                <li><span>40</span></li>
                <li><span>20</span></li>
                <li><span>0</span></li>
            </ul>
            <ul class="graphicComet_comet">
                <li
                    v-for="(value, i) in values"
                    :key="'commetGraph_' + name + '_' + i"
                    :style="{
                        left: `calc(${calcPosition(i + 1)}% + 12px)`,
                        height: `calc(${value.value}% - 16px)`,
                    }"
                >
                    <div
                        class="graphicComet_comet_ball"
                        :class="'ballColor-' + colors[i]"
                    ></div>
                    <div
                        class="graphicComet_comet_line"
                        :class="'lineColor-' + colors[i]"
                    ></div>
                </li>
            </ul>
        </div>
        <p class="graphicComet_name">{{ name }}</p>
    </div>
</template>

<script>
export default {
  props: ['name', 'values'],
  data() {
    return {
      colors: ['white', 'purple', 'green'],
    };
  },
  methods: {
    calcPosition(value) {
      return (100 / (this.values.length + 1)) * value;
    },
  },
};
</script>

<style lang="scss" scoped>
* {
    margin: 0;
    padding: 0;
    text-decoration: none;
    list-style: none;
}

.graphicComet {
    position: relative;
    height: 100%;
    &_main {
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    &_lines {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        li {
            font-size: 12px;
            color: #95949b;
            display: flex;
            align-items: center;
            justify-content: space-between;
            text-align: right;
            &:last-child {
                margin-bottom: 0;
            }
            span {
                text-align: right;
                width: 21px;
            }
            &::after {
                content: "";
                display: block;
                width: 100%;
                height: 1px;
                background: #393b40;
                margin-left: 20px;
            }
        }
    }
    &_comet {
        //height: calc(100% - 0px);
        li {
            position: absolute;
            left: 76px;
            bottom: 12px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        &_ball {
            width: 8px;
            height: 8px;
            min-height: 8px;
            border-radius: 50%;
        }
        &_line {
            width: 2px;
            height: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
        .ballColor {
            &-white {
                background: #fff;
                //box-shadow: 0px 0.5px 1px #ffffff,
                //    0px 4px 4px rgba(#ffffff, 0.7),
                //    0px 1px 2.5px rgba(#ffffff, 0.55),
                //    0px 3px 10px rgba(#ffffff, 0.8),
                //    0px 7px 16px rgba(#ffffff, 0.8);
            }
            &-purple {
                background: #7d35ff;
                //box-shadow: 0px 0.5px 1px #7d35ff,
                //    0px 4px 4px rgba(#7d35ff, 0.7),
                //    0px 1px 2.5px rgba(#7d35ff, 0.55),
                //    0px 3px 10px rgba(#7d35ff, 0.8),
                //    0px 7px 16px rgba(#7d35ff, 0.8);
            }
            &-green {
                background: #00ff19;
                box-shadow: 0px 0.5px 1px #00ff19,
                    0px 4px 4px rgba(#00ff19, 0.7),
                    0px 1px 2.5px rgba(#00ff19, 0.55),
                    0px 3px 10px rgba(#00ff19, 0.8),
                    0px 7px 16px rgba(#00ff19, 0.8);
            }
        }
        .lineColor {
            &-white {
                background: linear-gradient(
                    180deg,
                    #dddddd 0%,
                    rgba(221, 221, 221, 0) 100%
                );
            }
            &-purple {
                background: linear-gradient(
                    180deg,
                    #7d35ff 0%,
                    rgba(125, 53, 255, 0) 100%
                );
            }
            &-green {
                background: linear-gradient(
                    180deg,
                    #00ff19 0%,
                    rgba(15, 198, 33, 0) 100%
                );
            }
        }
    }
    &_name {
        font-size: 14px;
        color: #fff;
        text-align: center;
        margin-top: 19px;
        width: calc(100% - 24px);
        margin-left: 30px;
    }
}
</style>
