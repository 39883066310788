<template>
    <transition>
        <section
            class="box"
            :class="{
                fullWidth: isFullWidth(!isCard()),
                flat: isFlat(),
                light: isLight(),
                card: isCard(),
            }"
        >
            <slot> </slot>
        </section>
    </transition>
</template>

<script>
export default {
  name: 'Box',
  data() {
    return {
      size: ['card'],
    };
  },
  props: ['variant', 'fullWidth', 'width'],
  methods: {
    isFullWidth(modifier = true) {
      return !!(this.fullWidth || this.fullWidth === '') && modifier;
    },
    isFlat() {
      return !!(this.variant === 'flat');
    },
    isLight() {
      return !!(this.variant === 'light');
    },
    isCard() {
      return !!(this.width === 'card');
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-radius: 20px;
    background: linear-gradient(120.23deg, #272a2f 10.38%, #1e2127 56.66%);
    transition: ease 0.5s;
    width: 650px;

    &.fullWidth {
        width: 100%;
    }

    &.card {
        width: 320px;
    }

    &.light {
        background: linear-gradient(120.23deg, #2e3239 10.38%, #292e37 95.02%);
    }
}
</style>
