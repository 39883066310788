<template>
    <div class="app">
        <Menu active="retailer" />
        <div class="app_content">
            <Container>
                <Column>
                    <Block>
                        <template v-slot:header>
                            <div class="row">
                                <p class="font-title">Manage Accounts</p>
                                <div class="row retailer_search">
                                    <InputText
                                        placeholder="Retailer Name"
                                        class="block_input"
                                        icon="/imgs/icons/search.svg"
                                        v-model="inputFilterUser"
                                    />
                                    <ButtonIcon
                                        icon="/imgs/icons/outline_add.svg"
                                        :rounded="true"
                                        padding="8px 8px 8px 9px"
                                        @click="pushRetailer"
                                    />
                                </div>
                            </div>
                        </template>
                        <LoadingElement v-if="loading" />
                        <ul class="list_retailer">
                            <li
                                v-for="(retailer, i) in filterRetailersByName()"
                                :key="'line_'+i"
                            >
                                <div class="row retailer-item">
                                    <div class="retailer-info-content">
                                        <div class="row retailer-info-group">
                                            <div>
                                                <p class="font-field">Retailer Name</p>
                                                <p
                                                    v-if="!retailer.edit"
                                                    class="font-common"
                                                >{{retailer.name}}</p>
                                                <InputText
                                                    v-if="retailer.edit"
                                                    placeholder=""
                                                    class="input"
                                                    type="text"
                                                    style="width: 120px"
                                                    v-model="retailer.name"
                                                />
                                            </div>
                                            <div>
                                                <p class="font-field">Extension</p>
                                                <p
                                                    v-if="!retailer.edit"
                                                    class="font-common"
                                                >{{extensions[retailer.extension]}}</p>
                                                <Dropdown
                                                    :list="extensions"
                                                    :modelValue="retailer.extension"
                                                    v-if="retailer.edit"
                                                    @update="setExtension"
                                                />
                                            </div>
                                            <div>
                                                <p class="font-field">Size (H x W px)</p>
                                                <p
                                                    v-if="!retailer.edit"
                                                    class="font-common"
                                                >{{retailer.size.width}}x{{retailer.size.height}}</p>
                                                <div
                                                    class="row gap-5"
                                                    v-if="retailer.edit"
                                                >
                                                    <InputText
                                                        placeholder=""
                                                        class="input"
                                                        type="text"
                                                        v-model="retailer.size.width"
                                                    />
                                                    <p class="font-common">X</p>
                                                    <InputText
                                                        placeholder=""
                                                        class="input"
                                                        type="text"
                                                        v-model="retailer.size.height"
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <p class="font-field">DPI</p>
                                                <p
                                                    v-if="!retailer.edit"
                                                    class="font-common"
                                                >{{retailer.dpi}}</p>
                                                <InputText
                                                    v-if="retailer.edit"
                                                    placeholder=""
                                                    class="input"
                                                    type="text"
                                                    v-model="retailer.dpi"
                                                />
                                            </div>
                                            <div>
                                                <p class="font-field">Color Depth</p>
                                                <p
                                                    v-if="!retailer.edit"
                                                    class="font-common"
                                                >{{retailer.colorDepth}}</p>
                                                <InputText
                                                    v-if="retailer.edit"
                                                    placeholder=""
                                                    class="input"
                                                    type="text"
                                                    v-model="retailer.colorDepth"
                                                />
                                            </div>
                                            <div>
                                                <p class="font-field">File Size (MB)</p>
                                                <p
                                                    v-if="!retailer.edit"
                                                    class="font-common"
                                                >{{retailer.fileSize}}</p>
                                                <InputText
                                                    v-if="retailer.edit"
                                                    placeholder=""
                                                    class="input"
                                                    type="text"
                                                    v-model="retailer.fileSize"
                                                />
                                            </div>
                                            <div>
                                                <p class="font-field">Images per Row</p>
                                                <p
                                                    v-if="!retailer.edit"
                                                    class="font-common"
                                                >{{imagesPerRow[retailer.imagesPerRow]}}</p>
                                                <Dropdown
                                                    :list="imagesPerRow"
                                                    :modelValue="retailer.imagesPerRow"
                                                    v-if="retailer.edit"
                                                    @update="setImagesPerRow"
                                                />
                                            </div>
                                            <div v-if="!retailer.edit">
                                                <p class="font-field">File Name</p>
                                                <p class="font-common">{{retailer.fileName}}</p>
                                            </div>
                                        </div>
                                        <div
                                            class="row input-full line-filename"
                                            v-if="retailer.edit"
                                        >
                                            <div class="input-full">
                                                <p class="font-field">File Name</p>
                                                <InputText
                                                    placeholder=""
                                                    class="input input-full"
                                                    type="text"
                                                    v-model="retailer.fileName"
                                                />
                                                <ul class="list-filename">
                                                    <li class="fit-width">
                                                        <Button
                                                            class="button-filename"
                                                            :text="'<note>'"
                                                            icon="/imgs/icons/plus.svg"
                                                            :inverted="true"
                                                            :smallspace="true"
                                                            background="#1e2127"
                                                            @click="addParamName('<note>')"
                                                        />
                                                    </li>
                                                    <li class="fit-width">
                                                        <Button
                                                            class="button-filename"
                                                            :text="'<frame_num2>'"
                                                            icon="/imgs/icons/plus.svg"
                                                            :inverted="true"
                                                            :smallspace="true"
                                                            background="#1e2127"
                                                            @click="addParamName('<frame_num2>')"
                                                        />
                                                    </li>
                                                    <li class="fit-width">
                                                        <Button
                                                            class="button-filename"
                                                            :text="'<frame_num3>'"
                                                            icon="/imgs/icons/plus.svg"
                                                            :inverted="true"
                                                            :smallspace="true"
                                                            background="#1e2127"
                                                            @click="addParamName('<frame_num3>')"
                                                        />
                                                    </li>
                                                    <li class="fit-width">
                                                        <Button
                                                            class="button-filename"
                                                            :text="'<frame_num4>'"
                                                            icon="/imgs/icons/plus.svg"
                                                            :inverted="true"
                                                            :smallspace="true"
                                                            background="#1e2127"
                                                            @click="addParamName('<frame_num4>')"
                                                        />
                                                    </li>
                                                    <li class="fit-width">
                                                        <Button
                                                            class="button-filename"
                                                            :text="'<ext>'"
                                                            icon="/imgs/icons/plus.svg"
                                                            :inverted="true"
                                                            :smallspace="true"
                                                            background="#1e2127"
                                                            @click="addParamName('<ext>')"
                                                        />
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="right-buttons">
                                        <ButtonMenu
                                            v-if="!retailer.edit"
                                            text="Edit"
                                            :noicon="true"
                                            class="button-save"
                                            background="#1e2127"
                                            color="#ffffff"
                                            @click="setEdit(i)"
                                        />
                                        <ButtonMenu
                                            v-if="retailer.edit"
                                            text="Cancel"
                                            :noicon="true"
                                            class="button-save"
                                            background="#1e2127"
                                            color="#ffffff"
                                            @click="setEdit(i)"
                                        />
                                        <ButtonMenu
                                            text="Delete Retailer"
                                            :noicon="true"
                                            class="button-save"
                                            color="#ffffff"
                                            v-if="retailer.edit"
                                            type="danger"
                                            @click="buttonDeleteRetailer(i)"
                                        />
                                    </div>
                                </div>
                                <ButtonBorderText
                                    v-if="retailer.edit"
                                    noicon="true"
                                    text="Save"
                                    @click="emitButtonSave(i)"
                                />
                            </li>
                        </ul>
                    </Block>
                </Column>
            </Container>
        </div>
    </div>
    <PopupAlert
        v-if="popupAlert.show"
        :title="popupAlert.title"
        :subTitle="popupAlert.subTitle"
        :confirm="popupAlert.confirm"
        :cancel="popupAlert.cancel"
        @confirm="emitConfirm"
        @cancel="emitCancel"
    />
</template>

<script>
import Menu from "@/views/common/Menu_Admin.vue";
import Container from "../../components/sections/Container.vue";
import Column from "../../components/sections/Column.vue";
import Block from "../../components/sections/Block.vue";
import InputText from "@/components/inputs/InputText.vue";
import ButtonIcon from "@/components/buttons/Button-icon.vue";
import Dropdown from "@/components/dropdowns/Dropdown-rounded.vue";
import ButtonMenu from "@/components/buttons/Button-icon-text.vue";
import Button from "@/components/buttons/Button-icon-text.vue";
import ButtonBorderText from "@/components/buttons/Button-text-border.vue";
import http from "@/http";
import PopupAlert from "../common/PopupAlert.vue";
import LoadingElement from "@/components/LoadingElement.vue";

export default {
    components: {
        Menu,
        Container,
        Column,
        Block,
        InputText,
        ButtonIcon,
        Dropdown,
        ButtonMenu,
        Button,
        ButtonBorderText,
        PopupAlert,
        LoadingElement,
    },
    data() {
        return {
            edit: true,
            retailers: [],
            extensions: [".png", ".jpg"],
            imagesPerRow: [12, 24, 48],
            inputFilterUser: "",
            popupAlert: {
                show: false,
                title: "Automation System",
                subTitle: "Saving...",
                confirm: false,
                cancel: false,
                type: "save",
            },
            deleteRetailerIndex: null,
            loading: true,
        };
    },
    methods: {
        getRetailers() {
            http.get("/gets/retailer/list").then((response) => {
                response.data.data.forEach((item) => {
                    let data = {
                        name: item.retailer,
                        extension: this.getIndexExtension("." + item.file_type),
                        size: {
                            height: item.height,
                            width: item.width,
                        },
                        dpi: item.dpi,
                        colorDepth: item.color_depth,
                        fileSize: item.file_size,
                        imagesPerRow: this.getIndexQtyFrames(
                            item.images_per_row
                        ),
                        fileName: item.file_name,
                        id: item.id,
                    };
                    this.pushRetailer(data, false);
                    this.loading = false;
                });
            });
        },
        putRetailer(index) {
            let retailer = this.retailers[index];
            this.popupAlert.show = true;
            this.popupAlert.title = "Edit Retailer";
            this.popupAlert.subTitle = "Saving...";
            this.popupAlert.confirm = false;
            this.popupAlert.cancel = false;
            debugger;
            let data = {
                retailer: retailer.name,
                file_type: this.extensions[retailer.extension].replace(".", ""),
                height: retailer.size.height,
                width: retailer.size.width,
                dpi: retailer.dpi,
                color_depth: retailer.colorDepth,
                file_size: retailer.fileSize,
                images_per_row: this.imagesPerRow[retailer.imagesPerRow],
                file_name: retailer.fileName,
                id: retailer.id,
            };
            http.put("/puts/retailer/edit", data)
                .then((response) => {
                    this.popupAlert.subTitle = "Saved!";
                    this.popupAlert.confirm = true;
                    this.popupAlert.cancel = false;
                    this.setEdit(index);
                })
                .catch((error) => {
                    this.popupAlert.subTitle =
                        "Error: " + error.response.data.message;
                    this.popupAlert.confirm = false;
                    this.popupAlert.cancel = true;
                });
        },
        postRetailer(index) {
            let retailer = this.retailers[index];
            this.popupAlert.show = true;
            this.popupAlert.title = "Add Retailer";
            this.popupAlert.subTitle = "Saving...";
            this.popupAlert.confirm = false;
            this.popupAlert.cancel = false;
            let data = {
                retailer: retailer.name,
                file_type: this.extensions[retailer.extension].replace(".", ""),
                height: retailer.size.height,
                width: retailer.size.width,
                dpi: retailer.dpi,
                color_depth: retailer.colorDepth,
                file_size: retailer.fileSize,
                images_per_row: this.imagesPerRow[retailer.imagesPerRow],
                file_name: retailer.fileName,
            };
            http.post("/posts/retailer/add", data)
                .then((response) => {
                    debugger;
                    this.retailers[index].id = response.data.data.id;
                    this.popupAlert.subTitle = "Saved!";
                    this.popupAlert.confirm = true;
                    this.popupAlert.cancel = false;
                    this.setEdit(index);
                })
                .catch((error) => {
                    let errorMessage = error.response.data.message;
                    debugger;
                    if (error.response.data.errors) {
                        let errors = error.response.data.errors;
                        Object.keys(errors).forEach((key) => {
                            errorMessage += "\n" + errors[key].join("\n");
                        });
                    }
                    this.popupAlert.subTitle = "Error: " + errorMessage;
                    this.popupAlert.confirm = false;
                    this.popupAlert.cancel = true;
                });
        },
        deleteRetailer(index) {
            let retailer = this.retailers[index];
            this.popupAlert.show = true;
            this.popupAlert.title = "Delete Retailer";
            this.popupAlert.subTitle = "Deleting Retailer...";
            this.popupAlert.confirm = false;
            this.popupAlert.cancel = false;
            this.popupAlert.type = "load";
            http.delete("/deletes/retailer/delete", {
                data: {
                    id: retailer.id,
                },
            })
                .then((response) => {
                    this.popupAlert.subTitle = "Retailer Deleted!";
                    this.popupAlert.confirm = true;
                    this.popupAlert.cancel = false;
                    this.deleteRetailerList(index);
                    this.deleteRetailerIndex = null;
                })
                .catch((error) => {
                    this.popupAlert.subTitle =
                        "Error: " + error.response.data.message;
                    this.popupAlert.confirm = false;
                    this.popupAlert.cancel = true;
                    this.deleteRetailerIndex = null;
                });
        },
        confirmDeleteRetailer(index) {
            this.popupAlert.show = true;
            this.popupAlert.title = "Delete Retailer";
            this.popupAlert.subTitle =
                "Are you sure you want to delete this retailer?";
            this.popupAlert.confirm = true;
            this.popupAlert.cancel = true;
            this.popupAlert.type = "delete";
            this.deleteRetailerIndex = index;
        },
        deleteRetailerList(index) {
            this.retailers.splice(index, 1);
        },
        getIndexQtyFrames(qty) {
            let index = this.imagesPerRow.indexOf(qty);
            return index == -1 ? null : index;
        },
        getIndexExtension(extension) {
            let index = this.extensions.indexOf(extension);
            return index == -1 ? null : index;
        },
        pushRetailer(data = {}, edit = true) {
            let defaultData = {
                name: "",
                extension: 0,
                size: {
                    height: "",
                    width: "",
                },
                dpi: "",
                colorDepth: "",
                fileSize: "",
                imagesPerRow: 2,
                fileName: "",
                edit: false,
            };
            this.retailers.push({ ...defaultData, ...data });
            if (edit) this.setEdit(this.retailers.length - 1);
        },
        getEditableIndex() {
            return this.retailers.findIndex((item) => item.edit);
        },
        setEdit(index) {
            let value = this.retailers[index].edit;
            this.retailers.forEach((item) => {
                item.edit = false;
            });
            this.retailers[index].edit = !value;
        },
        setExtension(value) {
            let index = this.getEditableIndex();
            if (index == -1) return;
            console.log(value);
            this.retailers[index].extension = value;
        },
        setImagesPerRow(value) {
            let index = this.getEditableIndex();
            if (index == -1) return;
            this.retailers[index].imagesPerRow = value;
        },
        addParamName(value) {
            let index = this.getEditableIndex();
            if (index == -1) return;
            this.retailers[index].fileName += value;
        },
        emitConfirm() {
            if (this.popupAlert.type == "delete") {
                this.deleteRetailer(this.deleteRetailerIndex);
            } else this.popupAlert.show = false;
        },
        emitCancel() {
            this.popupAlert.show = false;
        },
        emitButtonSave(index) {
            if (this.retailers[index].id) {
                this.putRetailer(index);
            } else {
                this.postRetailer(index);
            }
        },
        buttonDeleteRetailer(index) {
            let retailer = this.retailers[index];
            if (retailer.id) this.confirmDeleteRetailer(index);
            else this.deleteRetailerList(index);
        },
        filterRetailersByName() {
            let retailers = this.retailers;
            if (!this.inputFilterUser.trim()) return retailers;
            retailers = retailers.filter(
                (item) =>
                    item.name
                        .toLowerCase()
                        .includes(this.inputFilterUser.trim().toLowerCase()) ||
                    item.id == undefined
            );
            return retailers;
        },
    },
    mounted() {
        this.getRetailers();
    },
};
</script>

<style lang="scss" scoped>
.app {
    display: flex;
    height: 100vh;
}
.app_content {
    width: 100%;
    background: #141517;
    padding: 20px;
    overflow-y: auto;
}

.font-title {
    color: #ffffff;
}

.row {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.retailer_search {
    gap: 10px;
}
p {
    color: #ffffff;
}

.list_retailer {
    > li + li {
        margin-top: 20px;
        padding-top: 20px;
        border-top: 1px solid #3d424e;
    }
}

.retailer-item {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
    margin-bottom: 20px;
    .retailer-info-content {
        flex: 1;
    }
}

.retailer-info {
    &-content {
        gap: 20px;
    }
    &-group {
        gap: 20px;
        display: flex;
        flex-wrap: wrap;
        //justify-content: flex-start;
    }
}

.input {
    width: 80px;
}

.input-full {
    width: 100%;
}

.button-filename {
    min-width: auto;
}

.line-filename {
    margin-top: 20px;
}

.list-filename {
    margin-top: 10px;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    width: 100%;
}

.gap-5 {
    gap: 5px;
}

.right-buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
</style>
