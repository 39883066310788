import axios from 'axios';
import store from './store';

const http = axios.create({
  baseURL: process.env.VUE_APP_URL_API
});

http.interceptors.request.use((config) => {
  if (store.state.user.token)
    config.headers.Authorization = `Bearer ${store.state.user.token}`;
  return config;
});

export default http;
