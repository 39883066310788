<template>
    <div class="app">
        <Menu active="dashboard" />
        <div class="app_content">
            <div class="menu_superior"></div>
            <div class="content" v-if="permissions['dashboard-access']">
                <div class="content_left">
                    <h2 class="titleSection">Rounds Review</h2>
                    <div class="content_left_container">
                        <ButtonBorderIcon
                            class="button_left"
                            icon="/imgs/icons/chevrons-left.svg"
                            v-if="roundsReviewAux.scrollX > 0"
                            @click="scrollElement('scrollRoundsReview', 'left')"
                        />
                        <ul
                            class="cardBloco"
                            @scroll="verifyScrollRounds"
                            id="scrollRoundsReview"
                        >
                            <li
                                v-for="rReview in roundsReview"
                                :key="'viewCard_' + rReview.sv"
                            >
                                <RoundReviewCard
                                    :name="rReview.name"
                                    :sv="rReview.sv"
                                    :round="rReview.round"
                                    :rounds="rReview.rounds"
                                    :models="rReview.models"
                                    :upcoming="rReview.upcoming"
                                    :start="rReview.start"
                                    :end="rReview.end"
                                    :status="rReview.status"
                                    :link="rReview.link_review"
                                    :canApprove="permissions['approve-services']"
                                />
                            </li>
                        </ul>
                        <ButtonBorderIcon
                            class="button_right"
                            icon="/imgs/icons/chevrons-right.svg"
                            v-if="roundsReviewAux.scrollX < 2"
                            @click="
                                scrollElement('scrollRoundsReview', 'right')
                            "
                        />
                    </div>
                    <h2 class="titleSection">Tasks by States</h2>
                    <div
                        class="
                            content_left_container
                            cardBloco
                            tasksByState_main
                        "
                    >
                        <ButtonBorderIcon
                            class="button_left"
                            icon="/imgs/icons/chevrons-left.svg"
                            v-if="
                                tasksByStateAux.scrollX > 0 &&
                                tasksByState.length > 3
                            "
                            @click="scrollElement('scrollTasksState', 'left')"
                        />
                        <div class="tasksByState">
                            <div class="tasksByState_header">
                                <ul class="tasksByState_legends">
                                    <li class="tasksByState_legends_item">
                                        <div
                                            class="
                                                tasksByState_legends_item_ball
                                            "
                                        >
                                            <div
                                                class="
                                                    tasksByState_legends_item_ball_innerBall
                                                    ball-white
                                                "
                                            ></div>
                                        </div>
                                        <p
                                            class="
                                                tasksByState_legends_item_title
                                            "
                                        >
                                            Not Started
                                        </p>
                                    </li>
                                    <li class="tasksByState_legends_item">
                                        <div
                                            class="
                                                tasksByState_legends_item_ball
                                            "
                                        >
                                            <div
                                                class="
                                                    tasksByState_legends_item_ball_innerBall
                                                    ball-purple
                                                "
                                            ></div>
                                        </div>
                                        <p
                                            class="
                                                tasksByState_legends_item_title
                                            "
                                        >
                                            In Progress
                                        </p>
                                    </li>
                                    <li class="tasksByState_legends_item">
                                        <div
                                            class="
                                                tasksByState_legends_item_ball
                                            "
                                        >
                                            <div
                                                class="
                                                    tasksByState_legends_item_ball_innerBall
                                                    ball-green
                                                "
                                            ></div>
                                        </div>
                                        <p
                                            class="
                                                tasksByState_legends_item_title
                                            "
                                        >
                                            Done
                                        </p>
                                    </li>
                                </ul>
                            </div>
                            <div class="tasksByState_graphics">
                                <ul
                                    @scroll="verifyScrollTasks"
                                    id="scrollTasksState"
                                >
                                    <li
                                        v-for="el in tasksByState"
                                        :key="'elGraphicsCommet_' + el.name"
                                    >
                                        <GraphicComet
                                            :name="el.name"
                                            :values="el.values"
                                        />
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <ButtonBorderIcon
                            class="button_right"
                            icon="/imgs/icons/chevrons-right.svg"
                            v-if="
                                tasksByStateAux.scrollX < 2 &&
                                tasksByState.length > 3
                            "
                            @click="scrollElement('scrollTasksState', 'right')"
                        />
                    </div>
                </div>
                <div class="content_right">
                    <h2
                        class="titleSection"
                        v-if="
                            permissions['reader-info-access'] ||
                            permissions['editor-info-access']
                        "
                    >
                        Last Registered {{ lastRegistered_filters.sku }}
                    </h2>
                    <div
                        v-if="
                            permissions['reader-info-access'] ||
                            permissions['editor-info-access']
                        "
                        class="
                            content_right_container
                            cardBloco
                            lastRegistered_main
                        "
                    >
                        <div class="lastRegistered">
                            <div class="lastRegistered_filters">
                                <InputLine
                                    icon="/imgs/icons/search.svg"
                                    placeholder="SKU"
                                    v-model="lastRegistered_filters.sku"
                                    class="lastRegistered_filters_filter"
                                />
                                <InputLine
                                    icon="/imgs/icons/search.svg"
                                    placeholder="ID Model"
                                    v-model="lastRegistered_filters.id"
                                    class="lastRegistered_filters_filter"
                                />
                            </div>
                            <ul class="lastRegistered_list">
                                <li
                                    v-for="item in filterLastRegistered"
                                    :key="'linePhoto_' + item.id"
                                >
                                    <LinePhoto
                                        :photo="item.photo"
                                        :itens="[
                                            { key: 'SKU: ', value: item.sku },
                                            { key: 'ID: #', value: item.id },
                                        ]"
                                        :link="item.link"
                                    />
                                </li>
                            </ul>
                        </div>
                        <router-link to="/catalog?section=allproducts">
                            <ButtonBorder text="View All" bigFont="true" />
                        </router-link>
                    </div>
                    <div class="content_right_container cardBloco serviceHome">
                        <div class="serviceHome_header">
                            <Dropdown
                                :list="
                                    formatList(servicesHome.services, 'name')
                                "
                                :modelValue="servicesHome.serviceActive"
                                @update="updateServiceHome"
                            />
                            <ul class="tasksByState_legends">
                                <li class="tasksByState_legends_item">
                                    <div class="tasksByState_legends_item_ball">
                                        <div
                                            class="
                                                tasksByState_legends_item_ball_innerBall
                                                ball-pink
                                            "
                                        ></div>
                                    </div>
                                    <p class="tasksByState_legends_item_title">
                                        Modeling and Texture
                                    </p>
                                </li>
                                <li class="tasksByState_legends_item">
                                    <div class="tasksByState_legends_item_ball">
                                        <div
                                            class="
                                                tasksByState_legends_item_ball_innerBall
                                                ball-blue
                                            "
                                        ></div>
                                    </div>
                                    <p class="tasksByState_legends_item_title">
                                        Silo Image
                                    </p>
                                </li>
                                <li class="tasksByState_legends_item">
                                    <div class="tasksByState_legends_item_ball">
                                        <div
                                            class="
                                                tasksByState_legends_item_ball_innerBall
                                                ball-orange
                                            "
                                        ></div>
                                    </div>
                                    <p class="tasksByState_legends_item_title">
                                        Lifestyle
                                    </p>
                                </li>
                            </ul>
                        </div>
                        <div class="serviceHome_graph">
                            <GraphicBar
                                :values="
                                    formatList(
                                        servicesHome.services[
                                            servicesHome.serviceActive
                                        ]
                                            ? servicesHome.services[
                                                  servicesHome.serviceActive
                                              ].values
                                            : [],
                                        'value'
                                    )
                                "
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import Menu from './common/Menu.vue';
import RoundReviewCard from '@/components/home/RoundReviewCard.vue';
import ButtonBorder from '@/components/buttons/Button-text-border.vue';
import ButtonBorderIcon from '@/components/buttons/Button-icon-border.vue';
import GraphicComet from '@/components/graphics/GraphicCometVertical.vue';
import InputLine from '@/components/inputs/Input-icon-line.vue';
import LinePhoto from '@/components/listItem/Line-photo-3infos-arrow.vue';
import Dropdown from '@/components/dropdowns/Dropdown.vue';
import GraphicBar from '@/components/graphics/GraphicBarHorizontal.vue';
import http from '@/http';
import store from '../store';

const exRoundsReview = [];

for (let i = 0; i < 15; i += 1) {
  exRoundsReview.push({
    name: `Batch${Math.floor(Math.random() * 1000)}`,
    sv: `SV${Math.floor(Math.random() * 1000)}BATCH${Math.floor(Math.random() * 1000)}`,
    round: Math.floor(Math.random() * 7),
    rounds: [],
    status: Math.floor(Math.random() * 5),
    models: Math.floor(Math.random() * 1000),
    upcoming: 'First Round Jun 10th 2022',
    start: 'May 13th 2022',
    end: 'May 13th 2022',
  });
  const { status } = exRoundsReview[i];
  const roundAux = status === 2 ? exRoundsReview[i].round - 1 : exRoundsReview[i].round;
  for (let j = 0; j < roundAux; j += 1) {
    exRoundsReview[i].rounds.push({
      status: 3,
    });
  }
  exRoundsReview[i].rounds.push({
    status: status === 3 ? 4 : status,
  });
  for (let j = roundAux + 1; j < 7; j += 1) {
    exRoundsReview[i].rounds.push({
      status: 0,
    });
  }
}

export default {
  components: {
    Menu,
    RoundReviewCard,
    ButtonBorder,
    ButtonBorderIcon,
    GraphicComet,
    InputLine,
    LinePhoto,
    Dropdown,
    GraphicBar,
  },
  data() {
    return {
      idClient: 1,
      lastRegistered: [],
      roundsReview: [],
      tasksByState: [],
      servicesHome: {
        services: [],
        serviceActive: 0,
      },
      lastRegistered_filters: {
        sku: '',
        id: '',
      },
      roundsReviewAux: {
        scrollX: 0,
      },
      tasksByStateAux: {
        scrollX: 0,
      },
      permissions: {
        'dashboard-access': false,
        'reader-info-access': false,
        'editor-info-access': false,
        'approve-services': false,
      },
    };
  },
  methods: {
    verifyScrollRounds(event) {
      const scrollX = event.target.scrollLeft;
      const sizeScroll = event.target.scrollWidth - event.target.offsetWidth;
      if (scrollX === 0) this.roundsReviewAux.scrollX = 0;
      else if (scrollX === sizeScroll) this.roundsReviewAux.scrollX = 2;
      else this.roundsReviewAux.scrollX = 1;
    },
    verifyScrollTasks(event) {
      const scrollX = event.target.scrollLeft;
      const sizeScroll = event.target.scrollWidth - event.target.offsetWidth;
      if (scrollX === 0) this.tasksByStateAux.scrollX = 0;
      else if (scrollX === sizeScroll) this.tasksByStateAux.scrollX = 2;
      else this.tasksByStateAux.scrollX = 1;
    },
    scrollElement(id, direction) {
      const element = document.getElementById(id);
      if (direction === 'left') {
        element.scrollBy({
          left: -250,
          behavior: 'smooth',
        });
      } else {
        element.scrollBy({
          left: 250,
          behavior: 'smooth',
        });
      }
    },
    formatList(list, key) {
      const aux = [];
      for (let i = 0; i < list.length; i += 1) {
        aux.push(list[i][key]);
      }
      return aux;
    },
    updateServiceHome(index) {
      this.servicesHome.serviceActive = index;
    },
    getDataLastRegistered() {
      http
        .get(`/gets/dashboard/products/last-registered/show?id_customer=${this.idClient}`)
        .then((r) => {
          if (r.data.success) {
            r.data.products.forEach((p) => {
              this.lastRegistered.push({
                photo: p.images[0] ? `${process.env.VUE_APP_AWS_BUCKET_IMAGES}${p.images[0]}` : '',
                name: p.name,
                sku: p.sku ? p.sku : '',
                id: p.id_product,
                link: `/product/${p.id_product}`,
              });
            });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getDataTasksByStates() {
      http
        .get(`/gets/dashboard/products/tasks-by-states/show?id_customer=${this.idClient}`)
        .then((r) => {
          if (r.data.success) {
            r.data.tasks_by_states.forEach((t, i) => {
              if (i >= 0) {
                this.tasksByState.push({
                  name: t.title,
                  values: [
                    {
                      value: t.not_started,
                      color: 'white',
                    },
                    {
                      value: t.in_progress,
                      color: 'purple',
                    },
                    {
                      value: t.done,
                      color: 'green',
                    },
                  ],
                });
              }
            });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getDataServicesProgress() {
      http
        .get(`/gets/dashboard/products/progress-bar-service/show?id_customer=${this.idClient}`)
        .then((r) => {
          if (r.data.success) {
            r.data.progress_bar_service.forEach((s) => {
              this.servicesHome.services.push({
                name: s.title,
                values: [
                  {
                    value: s.modeling_and_texturing,
                    color: 'pink',
                  },
                  {
                    value: s.silo_image,
                    color: 'blue',
                  },
                  {
                    value: s.lifestyle,
                    color: 'orange',
                  },
                ],
              });
            });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getDataRoundsReview() {
      http
        .get(`/gets/dashboard/products/rounds-review/show?id_customer=${this.idClient}`)
        .then((r) => {
          if (r.data.success) {
            r.data.rounds_review.forEach((s) => {
              console.log(r.data);
              const i = this.roundsReview.push({
                name: s.title,
                sv: s.subtitle,
                round: s.round_current,
                rounds: [],
                models: s.total_models,
                upcoming: s.upcoming_dates,
                status: s.status_service,
                start: s.start_date,
                end: s.end_date,
                link_review: s.link_review ? s.link_review : '',
              });
              const roundAux = s.status_service === 2 ? s.round_current - 1 : s.round_current;
              for (let j = 0; j < roundAux; j += 1) {
                this.roundsReview[i - 1].rounds.push({
                  status: s.status_service === 3 ? 4 : s.status_service,
                });
              }
              for (let j = roundAux + 1; j < 7; j += 1) {
                this.roundsReview[i - 1].rounds.push({
                  status: 0,
                });
              }
            });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async setupAccess() {
      const slugs = [];
      Object.keys(this.permissions).forEach((key) => {
        slugs.push(key);
      });

      const access = await this.$userAccess.to(slugs);
      Object.keys(this.permissions).forEach((key) => {
        this.permissions[key] = access[key];
      });
      if (!this.permissions['dashboard-access']) {
        if (this.permissions['reader-info-access'] || this.permissions['reader-info-edit']) {
          this.$router.push({ name: 'Catalog' });
        } else {
          this.$router.push({ name: 'User' });
        }
      }
    },
  },
  computed: {
    filterLastRegistered() {
      let filtered = this.lastRegistered.filter((item) => {
        const sku = item.sku.trim().toLowerCase();
        return sku.includes(this.lastRegistered_filters.sku.trim().toLowerCase());
      });
      filtered = filtered.filter((item) => {
        const { id } = item;
        return id.toString().includes(this.lastRegistered_filters.id.trim());
      });
      return filtered;
    },
  },
  async created() {
    this.idClient = store.state.customer.id;
    this.setupAccess();
    this.getDataLastRegistered();
    this.getDataTasksByStates();
    this.getDataServicesProgress();
    this.getDataRoundsReview();
  },
};
</script>

<style lang="scss" scoped>
* {
    margin: 0;
    padding: 0;
    text-decoration: none;
    list-style: none;
}
.app {
    display: flex;
    width: 100vw;
    height: 100vh;
    //overflow-x: hidden;
    &_content {
        background: #141517;
        //width: 100%;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        width: calc(100% - 200px);
        overflow-x: hidden;

        @media screen and (max-width: 1680px) {
            width: calc(100%);
            &::-webkit-scrollbar {
                display: none;
            }
        }
    }
}
.menu_superior {
    height: 85px;
    min-height: 85px;
    background: #141517;
}
.content {
    padding: 25px 65px 50px 65px;
    height: calc(100% - 85px);
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    @media screen and (max-width: 1680px) {
        flex-wrap: wrap;
    }
    &_left {
        //width: 54.637%;
        width: calc(100% - 720px - 35px);
        max-width: calc(100% - 720px - 35px);
        margin-right: 35px;
        display: flex;
        flex-direction: column;
        height: 100%;
        min-width: 580px;
        @media screen and (max-width: 1680px) {
            width: calc(100%);
            max-width: none;
            height: fit-content;
            margin-bottom: 40px;
            margin-right: 0;
        }

        &_container {
            position: relative;

            & + h2 {
                margin-top: 35px;
            }
            .button {
                position: absolute;
                bottom: 0;
                height: 100%;
                border: none;
                z-index: 1;
                display: flex;
                align-items: center;
                &_left {
                    border-radius: 10px 0 0 10px;
                    border-right: 1px solid #2a2e34;
                    left: 0;
                }
                &_right {
                    border-radius: 0 10px 10px 0;
                    right: 0;
                    border-left: 1px solid #2a2e34;
                }
            }
        }
    }
    &_right {
        //width: calc(45.363% - 35px);
        width: 720px;
        min-width: 720px;
        max-width: 720px;
        height: calc(100% - 85px);
        &_container {
            & + & {
                margin-top: 35px;
            }
        }
        @media screen and (max-width: 1680px) {
            width: calc(100%);
            max-width: none;
            padding-bottom: 40px;
            height: fit-content;
        }
    }
}

.titleSection {
    font-size: 20px;
    font-weight: bold;
    color: #fff;
    margin-bottom: 15px;
}

.cardBloco {
    background: #1e2127;
    padding: 35px;
    border-radius: 10px;
    width: 100%;
    display: flex;
    overflow-x: scroll;
    &::-webkit-scrollbar {
        display: none;
    }
    > li {
        margin-right: 20px;
    }
}

.tasksByState {
    width: 100%;
    display: flex;
    flex-direction: column;
    &_main {
        height: 100%;
        min-height: 335px;
        overflow-x: visible;
    }
    .titleSection {
        margin-bottom: 0;
    }
    &_header {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-bottom: 43px;
    }
    &_legends {
        display: flex;
        align-items: center;
        > li {
            margin-right: 20px;
            width: 50%;
            display: flex;
            align-items: center;
            &:last-child {
                margin-right: 0;
            }
            .tasksByState_legends_item_ball {
                width: 14px;
                height: 14px;
                border-radius: 50%;
                background: #3d424e;
                display: flex;
                align-items: center;
                justify-content: center;
                &_innerBall {
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    background: #fff;
                }
                .ball {
                    &-white {
                        background: #fff;
                    }
                    &-purple {
                        background: #7d35ff;
                    }
                    &-green {
                        background: #00ff19;
                    }
                    &-pink {
                        background: #e10f58;
                    }
                    &-blue {
                        background: #0264d8;
                    }
                    &-orange {
                        background: #e5531a;
                    }
                }
            }
            .tasksByState_legends_item_title {
                margin-left: 10px;
                font-size: 12px;
                color: #fff;
                white-space: nowrap;
            }
        }
    }
    &_graphics {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        > ul {
            display: flex;
            align-items: center;
            //justify-content: space-between;
            width: 100%;
            height: 100%;
            overflow-x: scroll;
            overflow-y: hidden;
            &::-webkit-scrollbar {
                display: none;
            }
            > li {
                margin-right: 43px;
                width: calc(100% - 10px);
                height: 100%;
                min-width: 210px;
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}

.lastRegistered {
    width: 100%;
    display: flex;
    flex-direction: column;
    &_main {
        max-height: calc(100% - 210px);
        position: relative;
        overflow: inherit;
        padding-top: 25px;
        min-height: 474px;
        @media screen and (max-width: 1680px) {
            height: 720px;
        }
        .button {
            position: absolute;
            bottom: 0;
            border: none;
            width: 100%;
            left: 0;
            border-top: 1px solid #2a2e34;
            z-index: 1;
            padding: 20px;
            border-radius: 0 0 10px 10px;
        }
        //&::after {
        //    content: "";
        //    position: absolute;
        //    bottom: 50px;
        //    left: 0;
        //    width: 100%;
        //    height: 35px;
        //    background: linear-gradient(
        //        180deg,
        //        rgba(30, 33, 39, 0) 0%,
        //        rgba(30, 33, 39, 0.8) 100%
        //    );
        //    border-radius: 0px;
        //}
    }
    &_filters {
        display: flex;
        margin-bottom: 20px;
        &_filter {
            margin-right: 20px;
            &:last-child {
                margin-right: 0;
            }
        }
    }
    &_list {
        overflow-y: scroll;
        height: 100%;
        &::-webkit-scrollbar {
            display: none;
        }
        li {
            margin-bottom: 10px;
            &:last-child {
                margin-bottom: 35px;
            }
        }
    }
}

.serviceHome {
    overflow: inherit;
    display: block;
    &_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 26px;
        flex: 1;
    }
}
</style>
