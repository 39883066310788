<template>
  <div class="login">
    <img class="login__background" :src="require('../assets/backgrounds/bkl' + number_img + '.jpg')" />
    <div class="wrapper">
      <img class="logo" src="../assets/ilustraviz_logo.png" />
      <Box
        v-if="!loading"
        class="box-login"
      >
        <!-- content-register-company -->
        <div
          v-if="content == 0"
          class="box-login__form"
        >
          <Typography class="box-login__title" is="h2" variant="title">
            Register Company
          </Typography>
          <Typography class="box-login__label" is="label" variant="subtitle">
            Name
            <TextInput
              v-model="company.name"
            />
          </Typography>
          <Typography class="box-login__label" is="label" variant="subtitle">
            Country
            <TextInput
              v-model="company.country"
            />
          </Typography>
        </div>
        <div
          v-if="content == 1"
          class="box-login__form"
        >
          <Typography class="box-login__title" is="h2" variant="title">
            Register Company
            <div
              @click="Back_content()"
            >
              <img src="../assets/svg/arrow-left.svg">
            </div>
          </Typography>
          <Typography class="box-login__label" is="label" variant="subtitle">
            Street
            <TextInput
              v-model="company.street"
            />
          </Typography>
          <Typography class="box-login__label" is="label" variant="subtitle">
            City
            <TextInput
              v-model="company.city"
            />
          </Typography>
        </div>
        <div
          v-if="content == 2"
          class="box-login__form"
        >
          <Typography class="box-login__title" is="h2" variant="title">
            Register Company
            <div
              @click="Back_content()"
            >
              <img src="../assets/svg/arrow-left.svg">
            </div>
          </Typography>
          <Typography class="box-login__label" is="label" variant="subtitle">
            Region
            <TextInput
              v-model="company.region"
            />
          </Typography>
          <Typography class="box-login__label" is="label" variant="subtitle">
            Zipcode
            <TextInput
              v-model="company.zipcode"
            />
          </Typography>
        </div>
        <div
          v-if="content == 3"
          class="box-login__form"
        >
          <Typography class="box-login__title" is="h2" variant="title">
            Register Company
            <div
              @click="Back_content()"
            >
              <img src="../assets/svg/arrow-left.svg">
            </div>
          </Typography>
          <Typography class="box-login__label" is="label" variant="subtitle">
            Sector
            <TextInput
              v-model="company.sector"
            />
          </Typography>
          <Typography class="box-login__label" is="label" variant="subtitle">
            Website
            <TextInput
              v-model="company.website"
            />
          </Typography>
        </div>
        <div
          v-if="content == 4"
          class="box-login__form"
        >
          <Typography class="box-login__title" is="h2" variant="title">
            Register Company
            <div
              @click="Back_content()"
            >
              <img src="../assets/svg/arrow-left.svg">
            </div>
          </Typography>
          <Typography class="box-login__label" is="label" variant="subtitle">
            Description
            <TextInput
              v-model="company.description"
            />
          </Typography>
          <Typography class="box-login__label" is="label" variant="subtitle">
            Profile Logo
            <TextInput
              :config="{ inputType: 'file' }"
              @change="GetProfileImage($event, 'company')"
              style="position: relative;"
            />
            <label 
              style="position: absolute; left: 15px; top: 38px;"
            >
              {{company.profile_logo.name}}
            </label>
          </Typography>
        </div>
        <!-- content-description-next-stage -->
        <div
          v-if="content == 5"
          class="box-login__form"
          style="height: 100%;"
        >
          <Typography 
            class="box-login__title" 
            is="h2" 
            variant="title"
            style="margin-bottom: 50px;"
          >
            Register Company
            <div
              @click="Back_content()"
            >
              <img src="../assets/svg/arrow-left.svg">
            </div>
          </Typography>
          <Typography class="box-login__label" is="label" variant="subtitle">
            In the next stage you will register the manager company.
          </Typography>
          <Typography class="box-login__label" is="label" variant="subtitle">
            The manager is responsible by access of Furniture Visual.
          </Typography>
        </div>
        <!-- content-register-manager -->
        <div 
          v-if="content == 6"
          class="box-login__form"
        >
          <Typography class="box-login__title" is="h2" variant="title">
            Register Manager
          </Typography>
          <Typography class="box-login__label" is="label" variant="subtitle">
            Name
            <TextInput
              v-model="user_manager.name"
            />
          </Typography>
          <Typography class="box-login__label" is="label" variant="subtitle">
            Last name
            <TextInput
              v-model="user_manager.last_name"
            />
          </Typography>
        </div>
        <div
          v-if="content == 7"
          class="box-login__form"
        >
          <Typography class="box-login__title" is="h2" variant="title">
            Register Manager
            <div
              @click="Back_content()"
            >
              <img src="../assets/svg/arrow-left.svg">
            </div>
          </Typography>
          <Typography class="box-login__label" is="label" variant="subtitle">
            E-mail
            <TextInput
              :config="{ inputType: 'email' }"
              v-model="user_manager.email"
            />
          </Typography>
          <Typography class="box-login__label" is="label" variant="subtitle">
            Phone Number
            <TextInput
              v-model="user_manager.phone_number"
            />
          </Typography>
        </div>
        <div
          v-if="content == 8"
          class="box-login__form"
        >
          <Typography class="box-login__title" is="h2" variant="title">
            Register Manager
            <div
              @click="Back_content()"
            >
              <img src="../assets/svg/arrow-left.svg">
            </div>
          </Typography>
          <Typography class="box-login__label" is="label" variant="subtitle">
            Office
            <TextInput
              v-model="user_manager.office"
            />
          </Typography>
          <Typography class="box-login__label" is="label" variant="subtitle">
            Profile Picture
            <TextInput
              :config="{ inputType: 'file' }"
              @change="GetProfileImage($event, 'manager')"
              style="position: relative;"
            />
            <label 
              style="position: absolute; left: 15px; top: 38px;"
            >
              {{user_manager.picture_profile.name}}
            </label>
          </Typography>
        </div>
        <div
          v-if="content == 9"
          class="box-login__form"
        >
          <Typography class="box-login__title" is="h2" variant="title">
              Register Manager
              <div
                @click="Back_content()"
              >
                <img src="../assets/svg/arrow-left.svg">
              </div>
          </Typography>
          <Typography class="box-login__label" is="label" variant="subtitle">
            Password
            <TextInput
              v-model = "user_manager.password"
              :config = "{ inputType: 'password', showPassword: showPassword }"
              @showHidePassword = "showPassword = !showPassword"
            />
          </Typography>
          <Typography class="box-login__label" is="label" variant="subtitle">
            Confirm Password
            <TextInput
              v-model="user_manager.password_confirm"
            />
          </Typography>
        </div>
        <!-- Button-next-content -->
        <SectionButtons>
          <button
            v-if="content <= 8"
            class="box-login__button"
            @click="Next_content()"
          >
            Next
          </button>
          <button
            v-else
            class="box-login__button"
            @click="Register()"
          >
            Register
          </button>
        </SectionButtons>
      </Box>
      <Box v-else class="box-login">
        <img class="box-login__loader" src="../assets/svg/loader.svg" />
      </Box>
    </div>
  </div>
</template>

<script>
import http from '../http';
import store from '../store';
import TextInput from '@/components/TextInput.vue';
import SectionButtons from '@/components/SectionButtons.vue';
import Typography from '@/components/styleguide/Typography.vue';
import Box from '@/components/styleguide/Box.vue';

export default {
  name: 'RegisterManeger',
  components: {
    TextInput,
    Typography,
    Box,
    SectionButtons,
  },
  data() {
    return {
      loading: false,
      showPassword: false,
      company: {
        id_customer: "",
        name: "luis",     //requerid
        country: "brazil",  //requerid
        street: "rua olimpia",   //requerid
        city: "são paulo",     //requerid
        region: "são paulo",   //requerid
        zipcode: "123456",  //requerid
        sector: "clear",   //requerid
        website: "site.com",  
        description: "clear sale",
        profile_logo: {
          name: "",
          file: ""
        }
      },
      user_manager: {
        name: "Luis",           //requerid
        last_name: "Soares",      //requerid
        email: "LuisSoares@gmail.com",          //requerid
        phone_number: "+1504556222", 
        office: "manager",         //requerid
        picture_profile: {
          name: "",
          file: {}
        },
        password: "12345678910111213141516",         //requerid
        password_confirm: "12345678910111213141516"  //requerid
      },
      number_img: Math.floor(Math.random() * 10) + 1,
      content: 0
    };
  },
  methods: {
    changeImg() {
      setTimeout(() => {
        this.number_img = Math.floor(Math.random() * 10) + 1;
        this.changeImg();
      }, 10000);
    },
    Next_content() {
        let errors = [];

        /*switch(this.content) {
          case 0 :
            if(this.company.name == "") {
              errors.push("The name fild is empty, please fill in");
            }
            if(this.company.country == "") {
              errors.push("The country fild is empty, please fill in");
            }
          break;
          case 1 :
            if(this.company.street == "") {
              errors.push("The street fild is empty, please fill in");
            }
            if(this.company.city == "") {
              errors.push("The city fild is empty, please fill in");
            }
          break;
          case 2 :
            if(this.company.region == "") {
              errors.push("The region fild is empty, please fill in");
            }
            if(this.company.zipcode == "") {
              errors.push("The zipcode fild is empty, please fill in");
            }
          break;
          case 3 :
            if(this.company.sector == "") {
              errors.push("The sector fild is empty, please fill in");
            }
          break;
          case 6 : 
            if(this.user_manager.name == "") {
              errors.push("The name fild is empty, please fill in");
            }
            if(this.user_manager.last_name == "") {
              errors.push("The last name fild is empty, please fill in");
            }
          break;
          case 7 :
            if(this.user_manager.email == "") {
              errors.push("The email fild is empty, please fill in");
            }
          break;
          case 8 :
            if(this.user_manager.office == "") {
              errors.push("The office fild is empty, please fill in");
            }
          break;
          case 9 :
            if(this.user_manager.password == "") {
              errors.push("The password fild is empty, please fill in");
            }
            if(this.user_manager.password_confirm == "") {
              errors.push("The password confirm fild is empty, please fill in");
            }
          break;
        }*/

        if(errors.length <= 0) {
          this.content++;
        }
        else {
          alert(errors[0]);
        }
    },
    Back_content() {
      if(this.content >= 0) {
        this.content--;
      }
    },
    GetProfileImage(e, type) {
      let file = e.target.files[0];

      switch (type) {
        case "manager" :
          this.user_manager.picture_profile.name = file.name;
          this.user_manager.picture_profile.file = file;

          console.log(this.user_manager.picture_profile);
        break;
        case "company" :
          this.company.profile_logo.name = file.name;
          this.company.profile_logo.file = file;
        
          console.log(this.company.profile_logo);
        break;
      }
    },
    async Register() {
      let result_company,
          result_manager_company,
          formData = new FormData();

      this.loading = !this.loading;

      formData.append('name_company', this.company.name.toLowerCase());
      formData.append('country', this.company.country.toLowerCase());
      formData.append('street', this.company.street.toLowerCase());
      formData.append('city', this.company.city.toLowerCase());
      formData.append('region', this.company.region.toLowerCase());
      formData.append('zipcode', this.company.zipcode.toLowerCase());
      formData.append('sector', this.company.sector.toLowerCase());

      if(this.company.website) {
        formData.append('site', this.company.website.toLowerCase());
      }
      if(this.company.description) {
        formData.append('description', this.company.description.toLowerCase());
      }
      if(this.company.profile_logo.name) {
        formData.append('profile_logo', this.company.profile_logo.file);
      }

      if(!this.company.id_customer) {
        //Create a new company;
        await http.post("/company/register", formData)
        .then(r => {
          result_company = r.data;
        })
        .catch(e => {
          console.error(e);
          alert("There was error, please try again later.");
        });
      }
      else {
        result_company = {
          success: true,
          id_customer: this.company.id_customer
        };
      }

      if(result_company.success || this.company.id_customer) {
        formData = new FormData();

        this.company.id_customer = result_company.id_customer;

        formData.append('id_customer', result_company.id_customer);
        formData.append('name', this.user_manager.name.toLowerCase());
        formData.append('last_name', this.user_manager.last_name.toLowerCase());
        formData.append('email', this.user_manager.email);
        formData.append('office', this.user_manager.office.toLowerCase());
        formData.append('password', this.user_manager.password);
        formData.append('password_confirmation', this.user_manager.password_confirm);

        if(this.user_manager.phone_number) {
          formData.append('phone_number', this.user_manager.phone_number);
        }
        if(this.user_manager.picture_profile.name) {
          formData.append('picture_profile', this.user_manager.picture_profile.file);
        }
        
        //Create a new manager company;
        await http.post(`/company/manager/register`, formData)
        .then(r => {
          result_manager_company = r.data;
        })
        .catch(e => {
          console.error(e);
          alert('There are a error, please try again later.');
        });

        if(result_manager_company.success) {
          alert("Create accontu with success!! You will be redirected to the login page");
          window.location.href = `${window.location.origin}/login`;
          this.loading = !this.loading;
        }
        else {
          this.loading = !this.loading;
          alert(result_manager_company.message);
          console.error(result_manager_company);
        }
      }
      else {
        this.loading = !this.loading;
        alert('There are a error, please try again later.');
      }
    }
  },
  mounted() {
    this.changeImg();
  }
};
</script>

<style lang="scss" scoped>
@import "@/layout/Mixins.scss";

.login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  position: relative;
  background: linear-gradient(90deg, #141517 40%, rgba(20, 21, 23, 0) 100%);

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}

.wrapper {
  margin-left: 110px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 450px;
  row-gap: 20px;
}

.logo {
  width: 300px;
}

.box-login {
  width: 100%;
  max-width: 450px;
  height: 320px;
  max-height: 320px;

  &__form {
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
  }

  &__title {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    >div {
      display: flex;
      justify-content: center;
      cursor: pointer;
      border-radius: 50%;
      margin-right: 10px;
      width: 25px;
      height: 25px;
      transition: 0.3s ease;
      &:hover {
        background: $color_blue_1;
        box-shadow: 0px 7px 5.32px rgba(0, 109, 255, 0.06), 0px 16px 17.87px rgba(0, 109, 255, 0.08),
          0px 8px 33.42px rgba(0, 109, 255, 0.12), 0px 33px 80px rgba(0, 109, 255, 0.16);
      }
    }
  }

  &--error {
    transition: 0.1s ease;
    border: 3px solid #ed1802;
    box-shadow: 0px 7px 5.32px rgba(255, 24, 0, 0.06), 0px 16px 17.87px rgba(255, 24, 0, 0.08),
      0px 8px 33.42px rgba(255, 24, 0, 0.12), 0px 33px 80px rgba(255, 24, 0, 0.16);
  }

  &__label {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    position: relative;
  }

  &__button {
    @include column-title(white);
    display: block;
    width: 100%;
    transition: 0.3s ease;
    border-radius: 0px 0px 20px 20px;
    min-height: 70px;

    &:hover {
      background: $color_blue_1;
      box-shadow: 0px 7px 5.32px rgba(0, 109, 255, 0.06), 0px 16px 17.87px rgba(0, 109, 255, 0.08),
        0px 8px 33.42px rgba(0, 109, 255, 0.12), 0px 33px 80px rgba(0, 109, 255, 0.16);
    }

    &--error {
      &:hover {
        background: #ed1802;
        box-shadow: 0px 7px 5.32px rgba(255, 24, 0, 0.06), 0px 16px 17.87px rgba(255, 24, 0, 0.08),
          0px 8px 33.42px rgba(255, 24, 0, 0.12), 0px 33px 80px rgba(255, 24, 0, 0.16);
      }
    }
  }

  &__loader {
    width: 70px;
    animation: loading 3s linear infinite;
  }
}

@keyframes loading {
  100% {
    transform: rotate(360deg);
  }
}
</style>
