<template>
    <div class="container">
        <slot></slot>
    </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.container {
    width: 100%;
    height: 100%;
    padding: 10px;
    background: #1e2127;
    border-radius: 10px;
    display: flex;
    gap: 10px;
}
</style>
