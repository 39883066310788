<template>
    <div
        class="photoItem"
        :class="{ active: active, deactived: deactived, hasHover: !noHover }"
    >
        <div
            class="photoItem_photo"
            :style="{ background: background ? background : 'none' }"
        >
            <router-link :to="link" v-if="link" class="linkFull" />
            <img
                :src="photo"
                v-if="photo"
                alt=""
                :crossorigin="crossorigin"
                :loading="lazyload ? 'lazy' : 'eager'"
                fetchpriority="low"
            />
            <img
                :src="photo"
                v-for="(photo, i) in photos"
                alt=""
                :crossorigin="crossorigin"
                :key="'photo_' + photo"
                class="imgList"
                :style="{ opacity: opacities[i] }"
                :loading="lazyload ? 'lazy' : 'eager'"
            />
        </div>
        <div
            class="photoItem_description"
            :class="{ alignCenter: alignCenter }"
            v-if="infos"
        >
            <p
                class="photoItem_description_text"
                v-for="p in infos"
                :key="'info_' + p"
            >
                {{ p }}
            </p>
        </div>
        <div
            class="photoItem_itemAction action"
            @click="actionButton"
            action="true"
            v-if="hasAction"
        >
            <img
                :src="
                    require('@/assets' +
                        (actionIcon ? actionIcon : '/imgs/icons/search.svg'))
                "
                alt=""
                class="action"
                action="true"
            />
        </div>
        <div
            class="actionZoom actionZoom-active"
            v-if="actionActive && hasAction"
            @click="closeAction($event)"
            @wheel="zoomImage($event)"
            close="true"
            action="true"
        >
            <div class="actionZoom_backgound" close="true" action="true">
                <div class="actionZoom_image" close="true" action="true">
                    <img
                        :src="photo"
                        alt=""
                        action="true"
                        :style="`transform: scale(${zoomImageValue})
                        translate(${moveImageValue.x}px, ${moveImageValue.y}px)`"
                        @mousemove="moveImage($event)"
                        @mousedown="clickImage($event, true)"
                        @mouseup="clickImage($event, false)"
                        @mouseleave="clickImage($event, false)"
                    />
                </div>
            </div>
        </div>
        <div class="actionZoom_controlers" v-if="actionActive" action="true">
            <div
                class="actionZoom_controlers_close"
                @click="actionActive = false"
                action="true"
            >
                <img
                    :src="require('@/assets/imgs/icons/x.svg')"
                    alt=""
                    action="true"
                />
            </div>
        </div>
        <div
            class="photoItem_flag"
            v-if="flag"
            :style="{
                background: flagColor,
            }"
        >
            {{ flag }}
        </div>
    </div>
</template>

<script>
export default {
  props: [
    'photo',
    'photos',
    'opacities',
    'background',
    'infos',
    'active',
    'deactived',
    'alignCenter',
    'hasAction',
    'fullAction',
    'actionEmit',
    'actionIcon',
    'flag',
    'flagColor',
    'crossorigin',
    'lazyload',
    'link',
    'noHover',
  ],
  data() {
    return {
      actionActive: false,
      zoomImageValue: 1,
      moveImageValue: {
        x: 0,
        y: 0,
      },
      lastPointMouse: {
        x: 0,
        y: 0,
      },
      speedZoom: 0.1,
      enableDrag: false,
    };
  },
  methods: {
    actionButton() {
      if (this.actionEmit) this.$emit('action');
      else this.actionActive = !this.actionActive;
    },
    closeAction(event) {
      if (!this.enableDrag) {
        if (event.target.getAttribute('close') === 'true') {
          this.actionActive = false;
          this.zoomImageValue = 1;
          this.moveImageValue = {
            x: 0,
            y: 0,
          };
        }
      }
    },
    zoomImage(event) {
      if (event.deltaY > 0) {
        if (this.zoomImageValue - this.speedZoom > 0.1) this.zoomImageValue -= this.speedZoom;
      } else {
        this.zoomImageValue += this.speedZoom;
      }
    },
    moveImage(event) {
      if (this.enableDrag) {
        this.moveImageValue.x += (this.lastPointMouse.x - event.clientX) * -1;
        this.moveImageValue.y += (this.lastPointMouse.y - event.clientY) * -1;
      }
      this.lastPointMouse.x = event.clientX;
      this.lastPointMouse.y = event.clientY;
    },
    clickImage(event, enable) {
      event.preventDefault();
      this.enableDrag = enable;
    },
  },
};
</script>

<style lang="scss" scoped>
.photoItem {
    border-radius: 5px;
    overflow: hidden;
    width: 110px;
    height: 110px;
    position: relative;
    transition: 0.5s ease;
    border: 1px solid rgba($color: #0264d8, $alpha: 0);
    * {
        transition: 0.5s ease;
    }
    &_photo {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        .imgList {
            position: absolute;
            top: 0;
            left: 0;
        }
    }
    &_description {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background: rgba($color: #141517, $alpha: 0.8);
        color: #fff;
        padding: 7px 6px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        &_text {
            font-size: 11px;
            margin: 0;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-align: left;
            white-space: normal;
            text-overflow: ellipsis;
            line-break: anywhere;
        }
    }
    &_itemAction {
        position: absolute;
        top: 5px;
        right: 5px;
        z-index: 1;
        opacity: 0;
        transition: 0.5s ease;
    }
    &_flag {
        position: absolute;
        top: 0px;
        left: 5px;
        z-index: 1;
        opacity: 1;
        transition: 0.5s ease;
        //background: #0264d8;
        color: #fff;
        font-size: 12px;
        font-weight: 600;
        padding: 5px;
        border-radius: 0px 0px 2px 2px;
    }

    .alignCenter {
        justify-content: center;
    }
    &:after {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        transition: 0.5s ease;
    }
}
.active {
    border: 1px solid #0264d8;
    &::after {
        background: rgba($color: #0264d8, $alpha: 0.5);
    }
}
.hasHover {
    &:hover {
        cursor: pointer;
        border: 1px solid #0264d8;
        &::after {
            background: rgba($color: #0264d8, $alpha: 0.5);
        }
        .photoItem_itemAction {
            opacity: 1;
        }
    }
}

.actionZoom {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($color: #141517, $alpha: 0.5);
    z-index: 5;
    transition: 0.5s ease;
    display: none;
    align-items: center;
    justify-content: center;
    cursor: default;
    &-active {
        display: flex;
    }
    &-active .actionZoom_backgound {
        transform: scale(1.1);
    }
    &-active .actionZoom_controlers {
        opacity: 1;
    }
    &_image {
        max-width: 80vw;
        max-height: 80vh;
        > img {
            object-fit: none;
            transition: none;
        }
    }
    &_controlers {
        &_close {
            position: fixed;
            top: 15px;
            right: 15px;
            cursor: pointer;
            z-index: 6;
            background: #3d424e;
            padding: 7px;
            border-radius: 50%;
            width: 34px;
            height: 34px;
            > img {
                width: 20px;
                height: 20px;
            }
            &:hover {
                background: #0264d8;
                box-shadow: 0px 7px 5.32px rgba(0, 109, 255, 0.06),
                    0px 16px 17.87px rgba(0, 109, 255, 0.08),
                    0px 8px 33.42px rgba(0, 109, 255, 0.12),
                    0px 33px 80px rgba(0, 109, 255, 0.16);
            }
        }
    }
}

.deactived {
    opacity: 0.3;
    cursor: not-allowed;
    &:hover {
        border: 1px solid rgba($color: #0264d8, $alpha: 0);
        cursor: default;
        background: none !important;
        &::after {
            background: none;
        }
    }
}
.linkFull {
    width: 100%;
    height: 100%;
    z-index: 1;
    position: absolute;
}
</style>
