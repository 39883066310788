<template>
    <div
        class="card"
        v-tilt="{
            speed: 500,
            perspective: 500,
            max: 10,
            glare: true,
            scale: 1,
            'max-glare': 0.2,
            'glare-prerender': true,
        }"
    >
        <div class="card__image">
            <div class="badge"></div>
            <img class="icon" :src="url" :alt="alt" />
        </div>
        <h2 class="card__title">
            {{ name }}
        </h2>
        <p class="card__text">
            {{ alt }}
        </p>
        <div class="js-tilt-glare">
            <div class="js-tilt-glare-inner"></div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'achievementCard',
  props: {
    url: String,
    alt: String,
    name: String,
  },
};
</script>

<style lang="scss" scoped>
@import "@/layout/Mixins.scss";

.card {
    width: 236px;
    height: 322px;
    border-radius: 8px;
    padding: 10px;
    background: linear-gradient(
        180deg,
        #4235e4 0%,
        #3d2ee8 0.01%,
        #06005b 100%
    );
    display: flex;
    flex-direction: column;
    align-items: center;
    transform-style: preserve-3d;

    &:hover {
        z-index: 3;
    }

    &__title {
        @include main-title(#fff);
        text-align: center;
        transform: translateZ(40px) perspective(2000px);
        transform-style: preserve-3d;
        width: 200px;
    }
    &__text {
        @include info-text(#fff);
        text-align: center;
        transform: translateZ(30px) perspective(2000px);
        transform-style: preserve-3d;
        width: 200px;
    }

    &__image {
        transform: perspective(2000px) translateZ(20px);
        transform-style: preserve-3d;
        position: relative;

        .badge {
            $size: 216px;
            background: url("https://ilustranext.com/img/ui/svg/achievements/badge.svg");
            width: $size;
            height: $size;
            background-position: center center;
            background-repeat: no-repeat;
        }

        .icon {
            width: 40px;
            height: 40px;
            object-fit: cover;
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -43%) translateZ(20px)
                perspective(2000px);
            transform-style: preserve-3d;
        }
    }
}

.js-tilt-glare {
    border-radius: inherit;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    overflow: hidden;
    pointer-events: none;

    &-inner {
        position: absolute;
        top: 50%;
        left: 50%;
        pointer-events: none;
        background-image: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0) 0%,
            rgb(255, 255, 255) 100%
        );
        transform: rotate(180deg) translate(-50%, -50%);
        transform-origin: 0% 0%;
        opacity: 0;
        width: 600px;
        height: 600px;
        z-index: 2;
    }
}
</style>>
