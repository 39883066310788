<template>
    <div class="column">
        <slot></slot>
    </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.column {
    width: 100%;
    height: 100%;
    padding: 10px;
    background: #272a30;
    border-radius: 10px;
    gap: 10px;
    overflow-y: auto;
    &::-webkit-scrollbar {
        display: none;
    }
}
</style>
