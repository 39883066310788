<template>
    <div class="appProduct">
        <Menu />
        <div class="appProduct_content">
            <div class="menu_superior"></div>
            <div class="contentProduct">
                <div class="row">
                    <p class="productScreen_sku">
                        {{ collection ? collection + " Collection" : "" }}
                        {{ sku ? "SKU: " + sku : "" }} #{{ id }}
                    </p>
                </div>
                <div class="productScreen">
                    <div class="productScreen_left">
                        <!-- Info preview -->
                        <div
                            class="productScreen_mainPhoto"
                            v-if="
                                selection == 'info' ||
                                selection == '3dfiles' ||
                                (selection == 'video' &&
                                    videoSelected.video == '') ||
                                (selection == 'viewer3d' &&
                                    viewerSelected.url == '') ||
                                siloSelected.images.length == 0
                            "
                        >
                            <div
                                class="photoArrow left"
                                @click="nextPhoto(-1)"
                                v-if="
                                    photos.length > 1 &&
                                    (selection != 'info' || !galeryImagesOpened)
                                "
                            >
                                <img :src="
                                        require('@/assets/imgs/icons/chevron-left.svg')
                                    " />
                            </div>
                            <div
                                class="photoArrow right"
                                @click="nextPhoto(1)"
                                v-if="
                                    photos.length > 1 &&
                                    (selection != 'info' || !galeryImagesOpened)
                                "
                            >
                                <img :src="
                                        require('@/assets/imgs/icons/chevron-right.svg')
                                    " />
                            </div>
                            <img
                                :src="
                                    showImageSize
                                        ? imageSize.replace(
                                              '.png',
                                              '_thumb.png'
                                          )
                                        : photos[photoSelected]
                                "
                                v-if="!editInfo || (editInfo && photos.length)"
                                style="background-color: #ffffff"
                            />
                            <div
                                class="
                                    productScreen_mainPhoto
                                    productScreen_dragDrop
                                "
                                v-else
                            >
                                <p class="text">
                                    Upload product images - Required*
                                </p>
                                <p class="text-small">Drag and Drop</p>
                                <div>
                                    <img :src="
                                            require('@/assets/imgs/icons/tabler_drag-drop.svg')
                                        " />
                                </div>
                            </div>
                            <div
                                class="productScreen_leftTop"
                                v-if="imageSize != ''"
                            >
                                <ButtonIcon
                                    text="Measures Image"
                                    :noicon="true"
                                    class=""
                                    :active="showImageSize"
                                    @click="showImageSize = !showImageSize"
                                />
                            </div>
                            <div
                                class="galeryImagesInfo"
                                :class="{
                                    closed: !galeryImagesOpened,
                                }"
                                v-if="selection == 'info' && photos.length"
                            >
                                <div class="galeryImagesInfo_row">
                                    <div>
                                        <img
                                            :class="{
                                                rotated: galeryImagesOpened,
                                            }"
                                            class="openCloseGalery"
                                            :src="
                                                require('@/assets/imgs/icons/comp-card-btn.svg')
                                            "
                                            @click="
                                                galeryImagesOpened =
                                                    !galeryImagesOpened
                                            "
                                        />
                                    </div>
                                </div>
                                <div class="galeryImagesInfo_galery">
                                    <div @click="
                                            scrollElement(
                                                'galeryImages',
                                                'left'
                                            )
                                        ">
                                        <img
                                            :src="
                                                require('@/assets/imgs/icons/comp-card-btn.svg')
                                            "
                                            class="rotated_left arrow"
                                        />
                                    </div>
                                    <ul
                                        class="galeryImagesInfo_galery_list"
                                        id="galeryImages"
                                    >
                                        <li
                                            v-for="(photo, i) in photos"
                                            :key="'photo_' + i"
                                        >
                                            <ButtonItemServicePhoto
                                                :photo="photos[i]"
                                                :active="i == photoSelected"
                                                :alignCenter="true"
                                                @action="deleteImage(i)"
                                                @click="setPhotoSelected(i)"
                                                :hasAction="editInfo"
                                                :actionEmit="true"
                                                actionIcon="/imgs/icons/trash.svg"
                                            />
                                        </li>
                                    </ul>
                                    <div @click="
                                            scrollElement(
                                                'galeryImages',
                                                'right'
                                            )
                                        ">
                                        <img
                                            :src="
                                                require('@/assets/imgs/icons/comp-card-btn.svg')
                                            "
                                            class="rotated_right arrow"
                                        />
                                    </div>
                                </div>
                            </div>
                            <ButtonIcon
                                v-if="showImageSize"
                                text="Download"
                                icon="/imgs/icons/download.svg"
                                class="downloadButtonImage"
                                :inverted="true"
                                @click="downloadImageMeasures"
                            />
                            <input
                                type="file"
                                accept="image/*"
                                multiple
                                class="inputAll"
                                @change="eventUploadImage($event)"
                                v-if="editInfo"
                            />
                        </div>
                        <!-- Vignette preview -->
                        <div
                            class="
                                productScreen_mainPhoto
                                productScreen_mainPhoto-silo
                            "
                            v-if="
                                selection == 'vignette' &&
                                siloSelected.images.length > 0
                            "
                        >
                            <div
                                class="photoArrow left"
                                @click="nextPhoto(-1)"
                                v-if="siloSelected.images.length > 1"
                            >
                                <img :src="
                                        require('@/assets/imgs/icons/chevron-left.svg')
                                    " />
                            </div>
                            <div
                                class="photoArrow right"
                                @click="nextPhoto(1)"
                                v-if="siloSelected.images.length > 1"
                            >
                                <img :src="
                                        require('@/assets/imgs/icons/chevron-right.svg')
                                    " />
                            </div>
                            <div class="productScreen_mainPhoto_group">
                                <img
                                    id="siloDownloadWindow_beauty2"
                                    :src="
                                        siloSelected.background.replace(
                                            '.jpg',
                                            '_thumb.jpg'
                                        )
                                    "
                                    :style="{
                                        transform: `scale(${convertValueBgScale(
                                            convertScale(
                                                -1,
                                                1,
                                                siloSelected.bg_scale
                                            )
                                        )}%) translate(${convertValueBgOffset(
                                            siloSelected.bg_offset_x
                                        )}%, ${convertValueBgOffset(
                                            siloSelected.bg_offset_y
                                        )}%)`,
                                    }"
                                    crossorigin="anonymous"
                                />
                                <img
                                    v-if="siloSelected.hasReflection"
                                    :src="
                                        siloSelected.images[photoSelected]
                                            .reflection
                                    "
                                    crossorigin="anonymous"
                                    alt="reflection2"
                                    :style="{
                                        opacity: siloSelected.reflections / 100,
                                        transform: `translate(${convertValueImageOffset(
                                            siloSelected.image_offset_x
                                        )}%, ${convertValueImageOffset(
                                            siloSelected.image_offset_y
                                        )}%) scale(${convertValueImageScale(
                                            convertScale(
                                                -1,
                                                1,
                                                siloSelected.image_scale
                                            )
                                        )}%)`,
                                    }"
                                />
                                <img
                                    v-if="siloSelected.hasShadow"
                                    :src="
                                        siloSelected.images[photoSelected]
                                            .shadow
                                    "
                                    crossorigin="anonymous"
                                    alt=""
                                    :style="{
                                        opacity: siloSelected.dropShadow / 100,
                                        transform: `translate(${convertValueImageOffset(
                                            siloSelected.image_offset_x
                                        )}%, ${convertValueImageOffset(
                                            siloSelected.image_offset_y
                                        )}%) scale(${convertValueImageScale(
                                            convertScale(
                                                -1,
                                                1,
                                                siloSelected.image_scale
                                            )
                                        )}%)`,
                                    }"
                                />
                                <img
                                    id="siloDownloadWindow_beauty2"
                                    :src="
                                        siloSelected.images[photoSelected]
                                            .beauty
                                    "
                                    class="saturate"
                                    crossorigin="anonymous"
                                    :style="{
                                        filter: `saturate(${siloSelected.saturation}) brightness(${siloSelected.brightness})`,
                                        transform: `translate(${convertValueImageOffset(
                                            siloSelected.image_offset_x
                                        )}%, ${convertValueImageOffset(
                                            siloSelected.image_offset_y
                                        )}%) scale(${convertValueImageScale(
                                            convertScale(
                                                -1,
                                                1,
                                                siloSelected.image_scale
                                            )
                                        )}%)`,
                                    }"
                                />
                            </div>
                            <ButtonIcon
                                text="Download"
                                icon="/imgs/icons/download.svg"
                                class="downloadButtonImage"
                                :inverted="true"
                                @click="downloadSilo"
                            />
                        </div>
                        <!-- Silo preview -->
                        <div
                            class="
                                productScreen_mainPhoto
                                productScreen_mainPhoto-silo
                            "
                            v-if="
                                selection == 'silo' &&
                                siloSelected.images.length > 0
                            "
                        >
                            <div
                                class="photoArrow left"
                                @click="nextPhoto(-1)"
                                v-if="siloSelected.images.length > 1"
                            >
                                <img :src="
                                        require('@/assets/imgs/icons/chevron-left.svg')
                                    " />
                            </div>
                            <div
                                class="photoArrow right"
                                @click="nextPhoto(1)"
                                v-if="siloSelected.images.length > 1"
                            >
                                <img :src="
                                        require('@/assets/imgs/icons/chevron-right.svg')
                                    " />
                            </div>
                            <div class="productScreen_mainPhoto_group">
                                <div
                                    class="siloBrackground"
                                    :class="{
                                        noBackground:
                                            siloSelected.background == 'none',
                                    }"
                                    :style="{
                                        background: siloSelected.background,
                                    }"
                                ></div>
                                <img
                                    v-if="siloSelected.hasReflection"
                                    :src="
                                        siloSelected.images[photoSelected]
                                            .reflection
                                    "
                                    crossorigin="anonymous"
                                    alt="reflection2"
                                    :style="{
                                        opacity: siloSelected.reflections / 100,
                                        transform: `translate(${convertValueImageOffset(
                                            siloSelected.image_offset_x
                                        )}%, ${convertValueImageOffset(
                                            siloSelected.image_offset_y
                                        )}%) scale(${convertValueImageScale(
                                            convertScale(
                                                -1,
                                                1,
                                                siloSelected.image_scale
                                            )
                                        )}%)`,
                                    }"
                                    fetchpriority="high"
                                />
                                <img
                                    v-if="siloSelected.hasShadow"
                                    :src="
                                        siloSelected.images[photoSelected]
                                            .shadow
                                    "
                                    crossorigin="anonymous"
                                    alt=""
                                    :style="{
                                        opacity: siloSelected.dropShadow / 100,
                                        transform: `translate(${convertValueImageOffset(
                                            siloSelected.image_offset_x
                                        )}%, ${convertValueImageOffset(
                                            siloSelected.image_offset_y
                                        )}%) scale(${convertValueImageScale(
                                            convertScale(
                                                -1,
                                                1,
                                                siloSelected.image_scale
                                            )
                                        )}%)`,
                                    }"
                                    fetchpriority="high"
                                />
                                <img
                                    id="siloDownloadWindow_beauty2"
                                    :src="
                                        siloSelected.images[photoSelected]
                                            .beauty
                                    "
                                    class="saturate"
                                    crossorigin="anonymous"
                                    fetchpriority="high"
                                    :style="{
                                        filter: `saturate(${siloSelected.saturation}) brightness(${siloSelected.brightness})`,
                                        transform: `translate(${convertValueImageOffset(
                                            siloSelected.image_offset_x
                                        )}%, ${convertValueImageOffset(
                                            siloSelected.image_offset_y
                                        )}%) scale(${convertValueImageScale(
                                            convertScale(
                                                -1,
                                                1,
                                                siloSelected.image_scale
                                            )
                                        )}%)`,
                                    }"
                                />
                            </div>
                            <ButtonIcon
                                text="Download"
                                icon="/imgs/icons/download.svg"
                                class="downloadButtonImage"
                                :inverted="true"
                                @click="downloadSilo"
                            />
                        </div>
                        <!-- 360 preview -->
                        <div
                            class="
                                productScreen_mainPhoto
                                productScreen_mainPhoto-silo
                            "
                            v-if="
                                selection == '360' &&
                                siloSelected.images.length > 0
                            "
                            @mousemove="nextPhotoMouseMove"
                            @mousedown="toggleDownMoveMouse(true)"
                            @mouseup="toggleDownMoveMouse(false)"
                            @mouseleave="toggleDownMoveMouse(false)"
                        >
                            <div
                                class="dragdrop"
                                :style="{
                                    opacity: lasttPositionMouse.firstUse
                                        ? 0
                                        : 1,
                                }"
                            >
                                <div class="dragdrop_icon">
                                    <img :src="
                                            require('@/assets/imgs/icons/hand.svg')
                                        " />
                                </div>
                                <p>Click and hold to rotate</p>
                            </div>
                            <div
                                class="photoArrow left"
                                @click="nextPhoto(-1)"
                                v-if="siloSelected.images.length > 1"
                            >
                                <img :src="
                                        require('@/assets/imgs/icons/chevron-left.svg')
                                    " />
                            </div>
                            <div
                                class="photoArrow right"
                                @click="nextPhoto(1)"
                                v-if="siloSelected.images.length > 1"
                            >
                                <img :src="
                                        require('@/assets/imgs/icons/chevron-right.svg')
                                    " />
                            </div>
                            <div class="productScreen_mainPhoto_group">
                                <div
                                    class="siloBrackground"
                                    :class="{
                                        noBackground:
                                            siloSelected.background == 'none',
                                    }"
                                    :style="{
                                        background: siloSelected.background,
                                    }"
                                ></div>
                                <img
                                    v-if="siloSelected.hasReflection"
                                    :src="
                                        siloSelected.images[photoSelected]
                                            .reflection
                                    "
                                    crossorigin="anonymous"
                                    alt="reflection2"
                                    :style="{
                                        opacity: siloSelected.reflections / 100,
                                        transform: `translate(${convertValueImageOffset(
                                            siloSelected.image_offset_x
                                        )}%, ${convertValueImageOffset(
                                            siloSelected.image_offset_y
                                        )}%) scale(${convertValueImageScale(
                                            convertScale(
                                                -1,
                                                1,
                                                siloSelected.image_scale
                                            )
                                        )}%)`,
                                    }"
                                    draggable="false"
                                />
                                <img
                                    v-if="siloSelected.hasShadow"
                                    :src="
                                        siloSelected.images[photoSelected]
                                            .shadow
                                    "
                                    crossorigin="anonymous"
                                    alt=""
                                    :style="{
                                        opacity: siloSelected.dropShadow / 100,
                                        transform: `translate(${convertValueImageOffset(
                                            siloSelected.image_offset_x
                                        )}%, ${convertValueImageOffset(
                                            siloSelected.image_offset_y
                                        )}%) scale(${convertValueImageScale(
                                            convertScale(
                                                -1,
                                                1,
                                                siloSelected.image_scale
                                            )
                                        )}%)`,
                                    }"
                                    draggable="false"
                                />
                                <img
                                    id="siloDownloadWindow_beauty2"
                                    :src="
                                        siloSelected.images[photoSelected]
                                            .beauty
                                    "
                                    class="saturate"
                                    crossorigin="anonymous"
                                    draggable="false"
                                    :style="{
                                        filter: `saturate(${siloSelected.saturation}) brightness(${siloSelected.brightness})`,
                                        transform: `translate(${convertValueImageOffset(
                                            siloSelected.image_offset_x
                                        )}%, ${convertValueImageOffset(
                                            siloSelected.image_offset_y
                                        )}%) scale(${convertValueImageScale(
                                            convertScale(
                                                -1,
                                                1,
                                                siloSelected.image_scale
                                            )
                                        )}%)`,
                                    }"
                                />
                            </div>
                            <ButtonIcon
                                text="Download"
                                icon="/imgs/icons/download.svg"
                                class="downloadButtonImage"
                                :inverted="true"
                                @click="downloadSilo"
                            />
                        </div>
                        <!-- video preview -->
                        <div
                            class="
                                productScreen_mainPhoto
                                productScreen_mainPhoto-video
                            "
                            v-if="selection == 'video' && videoSelected.video != ''"
                        >
                            <video
                                :src="videoSelected.video"
                                controls
                                crossorigin="anonymous"
                            ></video>
                            <ButtonIcon
                                text="Download"
                                icon="/imgs/icons/download.svg"
                                class="downloadButtonImage"
                                :inverted="true"
                                @click="downloadVideo"
                            />
                        </div>
                        <!-- 3dviewer preview -->
                        <div
                            class="
                                productScreen_mainPhoto
                                productScreen_mainPhoto-video
                            "
                            v-if="
                                selection == 'viewer3d' &&
                                viewerSelected.url != ''
                            "
                        >
                            <iframe
                                :src="viewerSelected.url"
                                style="border: 0px #ffffff none"
                                name="viewer"
                                scrolling="no"
                                frameborder="1"
                                marginheight="0px"
                                marginwidth="0px"
                                height="100%"
                                width="100%"
                                allowfullscreen
                            ></iframe>
                        </div>
                        <!-- end preview -->
                        <div class="productScreen_servicesList">
                            <ButtonBorderIcon
                                class="button_left"
                                icon="/imgs/icons/chevrons-left.svg"
                                @click="scrollElement('scrollService', 'left')"
                                v-if="scrollServiceAux.scrollX > 0"
                            />
                            <ul
                                @scroll="verifyScrollServices"
                                id="scrollService"
                            >
                                <li
                                    class="productScreen_servicesList_item"
                                    v-if="canCaccessReadOrWrite('info-access')"
                                >
                                    <ButtonItemService
                                        title="Info"
                                        subtitle="Add Informations"
                                        icon="/imgs/icons/alert.svg"
                                        :active="selection == 'info'"
                                        @click="setSection('info')"
                                    />
                                </li>
                                <li
                                    class="productScreen_servicesList_item"
                                    v-if="
                                        canCaccessReadOrWrite('vignette-access')
                                    "
                                >
                                    <ButtonItemServicePhoto
                                        :photo="photos[0]"
                                        :infos="['Vignette']"
                                        :active="selection == 'vignette'"
                                        :alignCenter="true"
                                        @click="setSection('vignette')"
                                    />
                                </li>
                                <li
                                    class="productScreen_servicesList_item"
                                    v-if="canCaccessReadOrWrite('silo-access')"
                                >
                                    <ButtonItemServicePhoto
                                        :photo="photos[0]"
                                        :infos="['Silo Image']"
                                        :active="selection == 'silo'"
                                        :alignCenter="true"
                                        @click="setSection('silo')"
                                    />
                                </li>
                                <li
                                    class="productScreen_servicesList_item"
                                    v-if="canCaccessReadOrWrite('360-access')"
                                >
                                    <ButtonItemService
                                        title="360˚"
                                        icon="/imgs/icons/360.svg"
                                        :active="selection == '360'"
                                        @click="setSection('360')"
                                    />
                                </li>
                                <li
                                    class="productScreen_servicesList_item"
                                    v-if="canCaccessReadOrWrite('video-access')"
                                >
                                    <ButtonItemService
                                        title="Video"
                                        icon="/imgs/icons/youtube.svg"
                                        :active="selection == 'video'"
                                        @click="setSection('video')"
                                    />
                                    <!-- @click="selection = 'video'" this line make up -->
                                </li>
                                <li
                                    class="productScreen_servicesList_item"
                                    v-if="
                                        canCaccessReadOrWrite(
                                            '3d-viewer-access'
                                        )
                                    "
                                >
                                    <ButtonItemService
                                        title="Viewer 3D"
                                        icon="/imgs/icons/viewer-3d.svg"
                                        :active="selection == 'viewer3d'"
                                        @click="setSection('viewer3d')"
                                    />
                                    <!-- @click="selection = 'viewer3d'" this line make up -->
                                </li>
                                <li
                                    class="productScreen_servicesList_item"
                                    v-if="
                                        canCaccessReadOrWrite('3d-files-access')
                                    "
                                >
                                    <ButtonItemService
                                        title="3D Files"
                                        icon="/imgs/icons/3DFiles.svg"
                                        :active="selection == '3dfiles'"
                                        @click="setSection('3dfiles')"
                                    />
                                </li>
                            </ul>
                            <ButtonBorderIcon
                                class="button_right"
                                icon="/imgs/icons/chevrons-right.svg"
                                @click="scrollElement('scrollService', 'right')"
                                v-if="
                                    scrollServiceAux.scrollX < 2 &&
                                    scrollServiceAux.scrollX > -1
                                "
                            />
                        </div>
                    </div>
                    <div class="productScreen_right scrollElement">
                        <ProductInfo
                            v-if="
                                selection == 'info' &&
                                canCaccessReadOrWrite('info-access')
                            "
                            :id="id"
                            :id_customer="customer"
                            @updateImages="eventUpdateDbImages"
                            @editStatus="eventSetEditInfo"
                            @addUploadImages="processImageUploadInput"
                            ref="productInfo"
                        />
                        <ProductSilo
                            v-if="
                                selection == 'silo' &&
                                canCaccessReadOrWrite('silo-access')
                            "
                            @update="setSiloImage"
                            @updateImageUpload="pushImageUpload"
                            @downloadAutomationBatch="downloadAutomationBatch"
                            :id="id"
                            :customer="customer"
                            :uploadImages="imagesUploaded"
                            ,
                            :photo="photos[0] ? photos[0] : ''"
                        />
                        <ProductVignette
                            v-if="
                                selection == 'vignette' &&
                                canCaccessReadOrWrite('vignette-access')
                            "
                            @update="setSiloImage"
                            @updateImageUpload="pushImageUploadVignette"
                            @downloadAutomationBatch="downloadAutomationBatch"
                            :id="id"
                            :customer="customer"
                            :uploadImages="imagesUploadedVignette"
                            :photo="photos[0] ? photos[0] : ''"
                        />
                        <Product360
                            v-if="
                                selection == '360' &&
                                canCaccessReadOrWrite('360-access')
                            "
                            @update="setSiloImage"
                            @updateImageUpload="pushImageUpload360"
                            @downloadAutomationBatch="downloadAutomationBatch"
                            :id="id"
                            :customer="customer"
                            :uploadImages="imagesUploaded360"
                            :photo="photos[0] ? photos[0] : ''"
                        />
                        <Product3DFiles
                            v-if="
                                selection == '3dfiles' &&
                                canCaccessReadOrWrite('3d-files-access')
                            "
                            @update="setSiloImage"
                            @updateImageUpload="pushImageUpload360"
                            @downloadAutomationBatch="downloadAutomationBatch"
                            :id="id"
                            :customer="customer"
                            :uploadImages="imagesUploaded360"
                            :photo="photos[0] ? photos[0] : ''"
                        />
                        <ProductVideo
                            v-if="
                                selection == 'video' &&
                                canCaccessReadOrWrite('video-access')
                            "
                            @update="setVideo"
                            @updateImageUpload="pushImageUpload360"
                            @downloadAutomationBatch="downloadAutomationBatch"
                            :id="id"
                            :customer="customer"
                            :photo="photos[0] ? photos[0] : ''"
                        />
                        <Product3DViewer
                            v-if="
                                selection == 'viewer3d' &&
                                canCaccessReadOrWrite('3d-viewer-access')
                            "
                            @update="setViewer"
                            :id="id"
                            :customer="customer"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div v-if="isAutomated() && siloSelected.images.length > 0">
            <div
                id="siloDownloadWindow"
                class="printScreenSilo"
            >
                <div
                    class="siloBrackground"
                    :style="{ background: siloSelected.background }"
                    v-if="this.selection == 'silo' || this.selection == '360'"
                ></div>
                <img
                    v-if="this.selection == 'vignette'"
                    :src="siloSelected.background"
                    crossorigin="anonymous"
                    :style="{
                        transform: `scale(${convertValueBgScale(
                            convertScale(-1, 1, siloSelected.bg_scale)
                        )}%) translate(${convertValueBgOffset(
                            siloSelected.bg_offset_x
                        )}%, ${convertValueBgOffset(
                            siloSelected.bg_offset_y
                        )}%)`,
                    }"
                />
                <img
                    v-if="siloSelected.hasReflection"
                    :src="
                        siloSelected.images[photoSelected].reflection.replace(
                            '_thumb',
                            ''
                        )
                    "
                    alt="reflection"
                    :style="{
                        opacity: siloSelected.reflections / 100,
                        transform: `translate(${convertValueImageOffset(
                            siloSelected.image_offset_x
                        )}%, ${convertValueImageOffset(
                            siloSelected.image_offset_y
                        )}%) scale(${convertValueImageScale(
                            convertScale(-1, 1, siloSelected.image_scale)
                        )}%)`,
                    }"
                    crossorigin="anonymous"
                    @load="finishLoadFull.reflection = true"
                />
                <img
                    v-if="siloSelected.hasShadow"
                    :src="
                        siloSelected.images[photoSelected].shadow.replace(
                            '_thumb',
                            ''
                        )
                    "
                    alt=""
                    :style="{
                        opacity: siloSelected.dropShadow / 100,
                        transform: `translate(${convertValueImageOffset(
                            siloSelected.image_offset_x
                        )}%, ${convertValueImageOffset(
                            siloSelected.image_offset_y
                        )}%) scale(${convertValueImageScale(
                            convertScale(-1, 1, siloSelected.image_scale)
                        )}%)`,
                    }"
                    crossorigin="anonymous"
                    @load="finishLoadFull.shadow = true"
                />
                <img
                    :src="beautyPrint"
                    v-if="beautyPrint"
                    :style="{
                        transform: `translate(${convertValueImageOffset(
                            siloSelected.image_offset_x
                        )}%, ${convertValueImageOffset(
                            siloSelected.image_offset_y
                        )}%) scale(${convertValueImageScale(
                            convertScale(-1, 1, siloSelected.image_scale)
                        )}%)`,
                    }"
                />
                <img
                    v-if="!beautyPrint"
                    id="siloDownloadWindow_beauty"
                    :src="
                        siloSelected.images[photoSelected].beauty.replace(
                            '_thumb',
                            ''
                        )
                    "
                    :style="{
                        filter: `saturate(${siloSelected.saturation}) brightness(${siloSelected.brightness})`,
                    }"
                    class="saturate"
                    crossorigin="anonymous"
                    @load="finishLoadFull.beauty = true"
                />
            </div>
        </div>
    </div>
    <PopupAlert
        v-if="popupAlert.show"
        :title="popupAlert.title"
        :subTitle="popupAlert.subTitle"
        :confirm="popupAlert.confirm"
        :cancel="popupAlert.cancel"
        @confirm="alertEmitConfirm"
        @cancel="alertEmitCancel"
    />
    <PopupVideo
        v-if="tutorial.show"
        :video="tutorial.video"
        @confirm="watchedTutorialVideo"
    />
</template>

<script>
import html2canvas from "html2canvas";
import screenshot from "image-screenshot";
import axios from "axios";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import Menu from "./common/Menu.vue";
import ProductInfo from "./product/ProductInfo.vue";
import ProductSilo from "./product/ProductSilo.vue";
import Product360 from "./product/Product360.vue";
import ProductVignette from "./product/ProductVignette.vue";
import Product3DFiles from "./product/Product3DFiles.vue";
import ProductVideo from "./product/ProductVideo.vue";
import Product3DViewer from "./product/Product3DViewer.vue";
import ButtonIcon from "@/components/buttons/Button-icon-text.vue";
import ButtonItemService from "@/components/listItem/Icon-title-subtitle.vue";
import ButtonItemServicePhoto from "@/components/listItem/Square-photo-description-event.vue";
import ButtonBorderIcon from "@/components/buttons/Button-icon-border.vue";
import PopupAlert from "./common/PopupAlert.vue";
import PopupVideo from "./common/PopupVideo.vue";
import http from "@/http";
import store from "@/store";
import sleep from "@/utils/sleep";
import loadingController from "@/utils/loadingController";
import preloadImages from "@/utils/preloadImages";

const today = new Date();

const lController = new loadingController();
const imgVersion = `${today.getFullYear()}${today.getMonth()}${today.getDate()}${today.getHours()}`;
console.log("imgVersion", imgVersion);

export default {
    components: {
        Menu,
        ButtonIcon,
        ButtonItemService,
        ButtonItemServicePhoto,
        ButtonBorderIcon,
        ProductInfo,
        ProductSilo,
        Product360,
        ProductVignette,
        Product3DFiles,
        PopupAlert,
        ProductVideo,
        Product3DViewer,
        PopupVideo,
    },
    data() {
        return {
            lasttPositionMouse: {
                down: false,
                firstUse: false,
                x: 0,
                y: 0,
            },
            name: "",
            collection: "",
            sku: "",
            id: "",
            imageSize: "",
            showImageSize: false,
            photos: [],
            photoSelected: 0,
            selection: "info",
            customer: 1,
            beautyPrint: "",
            siloDownloadZip: [],
            scrollServiceAux: {
                scrollX: 0,
            },
            infos: [],
            editInfo: false,
            toUpdate: {
                images: {
                    add: [],
                    remove: [],
                },
            },
            imagesUploaded: [],
            imagesUploadedVignette: [],
            imagesUploaded360: [],
            finishLoadFull: {
                beauty: false,
                reflection: false,
                shadow: false,
            },
            galeryImagesOpened: true,
            siloSelected: {
                images: [
                    {
                        beauty: "/beauty.png",
                        shadow: "/shadow.png",
                        reflection: "/reflections.png",
                    },
                ],
                background: "#ffec5c",
                hasShadow: true,
                dropShadow: 55,
                hasReflection: true,
                reflections: 80,
                bg_scale: 0,
                bg_offset_x: 0,
                bg_offset_y: 0,
                image_scale: 0,
                image_offset_x: 0,
                image_offset_y: 0,
            },
            imgVersion,
            videoSelected: {
                video: "",
            },
            viewerSelected: {
                url: "",
            },
            abortDownloadAutomation: false,
            popupAlert: {
                show: false,
                title: "Automation System",
                subTitle: "Saving...",
                confirm: false,
                cancel: false,
                type: "save",
            },
            permissions: {
                "reader-info-access": false,
                "editor-info-access": false,
                "reader-vignette-access": false,
                "editor-vignette-access": false,
                "reader-silo-access": false,
                "editor-silo-access": false,
                "reader-360-access": false,
                "editor-360-access": false,
                "reader-video-access": false,
                "editor-video-access": false,
                "reader-3d-viewer-access": false,
                "editor-3d-viewer-access": false,
                "reader-3d-files-access": false,
                "editor-3d-files-access": false,
            },
            tutorial: {
                show: false,
                video: "",
            },
        };
    },
    methods: {
        isAutomated() {
            const automated = ["silo", "vignette", "360"];
            return automated.includes(this.selection);
        },
        getDataProduct() {
            // $route.params.id
            http.get(
                `/gets/product?id_product=${this.$route.params.id}&id_customer=${this.customer}`
            )
                .then((r) => {
                    if (r.data.success) {
                        this.name = r.data.product.name;
                        // this.collection = r.data.product.collection;
                        this.sku = r.data.product.sku;
                        this.id = r.data.product.id_product;
                        if (r.data.product.sizeImageProduct)
                            this.imageSize = `${process.env.VUE_APP_AWS_BUCKET_IMAGES}${r.data.product.sizeImageProduct}`;
                        r.data.product.images.forEach((photo) =>
                            this.photos.push(
                                `${process.env.VUE_APP_AWS_BUCKET_IMAGES}${photo}`
                            )
                        );
                        r.data.product.categories.forEach((category) => {
                            if (category.subcategory) {
                                // this.$refs.productInfo.addCategory(
                                //  {
                                //    id: category.id_subcategory,
                                //    name: category.subcategory,
                                //  },
                                //  false,
                                // );
                            }
                        });
                        r.data.product.images_uploaded.forEach((image) => {
                            this.imagesUploaded.push({
                                photo: `${process.env.VUE_APP_AWS_BUCKET_IMAGES}/${image}`,
                            });
                        });
                        document.title = `FV - SKU: ${
                            this.sku ? this.sku : this.name
                        }`;
                        this.getDataInfos();
                        this.getUploadsVignette();
                        this.getUploads360();
                    }
                    console.log("getDataProduct");
                    this.completeTaskLoad();
                })
                .catch((r) => {
                    console.log("Error:");
                    /* eslint-disable*/
                    //debugger;
                    /* eslint-enable */
                    console.log(r.response);
                    // debugger;
                    this.$router.push("/catalog");
                });
        },
        getUploadsVignette() {
            // $route.params.id
            http.get(
                `/gets/product/images_uploaded/vignette?id_product=${this.$route.params.id}&id_customer=${this.customer}`
            )
                .then((r) => {
                    if (r.data.success) {
                        r.data.images_uploaded.forEach((image) => {
                            this.imagesUploadedVignette.push({
                                photo: `${process.env.VUE_APP_AWS_BUCKET_IMAGES}/${image}`,
                            });
                        });
                        // this.getDataInfos();
                    }
                    console.log("getUploadsVignette");
                    this.completeTaskLoad();
                })
                .catch((r) => {
                    console.log("Error:");
                    this.completeTaskLoad();
                    /* eslint-disable*/
                    debugger;
                    /* eslint-enable */
                    console.log(r.response);
                    // this.$router.push('/catalog');
                });
        },
        getUploads360() {
            // $route.params.id
            http.get(
                `/gets/product/images_uploaded/360?id_product=${this.$route.params.id}&id_customer=${this.customer}`
            )
                .then((r) => {
                    console.log(r.data);
                    if (r.data.success) {
                        r.data.images_uploaded.forEach((image) => {
                            this.imagesUploaded360.push({
                                id: image.id,
                                photo: [],
                            });
                            image.images.forEach((img) => {
                                this.imagesUploaded360[
                                    this.imagesUploaded360.length - 1
                                ].photo.push(
                                    `${process.env.VUE_APP_AWS_BUCKET_IMAGES}${img}`
                                );
                            });
                        });
                        // this.getDataInfos();
                    }
                    console.log("getUploads360");
                    this.completeTaskLoad();
                })
                .catch((r) => {
                    console.log("Error:");
                    this.completeTaskLoad();
                    /* eslint-disable*/
                    debugger;
                    /* eslint-enable */
                    console.log(r.response);
                    // this.$router.push('/catalog');
                });
        },
        getDataInfos() {
            http.get(
                `/gets/product/description/all/show?id_product=${this.id}&id_customer=${this.customer}`
            )
                .then((r) => {
                    if (r.data.success) {
                        r.data.descriptions.forEach((description) => {
                            this.infos.push({
                                title: description.title,
                                value: description.notes,
                                height: 0,
                                open: false,
                                id: description.id,
                            });
                        });
                    } else {
                        console.log(r);
                    }
                    console.log("getDataInfos");
                    this.completeTaskLoad();
                })
                .catch((e) => {
                    this.completeTaskLoad();
                    console.log(e);
                });
        },
        getDataTutorialVideo() {
            http.get("/user/tutorial/watched?slug=product-info")
                .then((r) => {
                    if (r.data.success) {
                        this.tutorial.show = !r.data.data.watched;
                        this.tutorial.video = r.data.data.tutorial.url;
                    } else {
                        console.log(r);
                    }
                })
                .catch((e) => {
                    console.log(e);
                    debugger;
                });
        },
        watchedTutorialVideo() {
            this.tutorial.show = false;
            http.post("/user/tutorial/watched", { slug: "product-info" }).then(
                (r) => {
                    if (r.data.success) {
                    } else {
                        console.log(r);
                    }
                }
            );
        },
        verifyScrollServices() {
            const element = document.getElementById("scrollService");
            const scrollX = element.scrollLeft;
            const sizeScroll = element.scrollWidth - element.offsetWidth;
            if (scrollX === 0 && scrollX === sizeScroll)
                this.scrollServiceAux.scrollX = -1;
            else if (scrollX === 0) this.scrollServiceAux.scrollX = 0;
            else if (scrollX === sizeScroll) this.scrollServiceAux.scrollX = 2;
            else this.scrollServiceAux.scrollX = 1;
        },
        /* eslint-disable no-param-reassign */
        scrollElement(id, direction) {
            const element = document.getElementById(id);
            if (direction === "left") {
                element.scrollBy({
                    left: -100,
                    behavior: "smooth",
                });
            } else {
                element.scrollBy({
                    left: 100,
                    behavior: "smooth",
                });
            }
        },
        async urlToFile(url, filename, mimeType) {
            const res = await fetch(url);
            const buf = await res.arrayBuffer();

            return new File([buf], filename, { type: mimeType });
        },
        downloadURI(uri, name) {
            const link = document.createElement("a");
            link.download = name;
            link.href = uri;
            link.target = "_blank";
            console.log(this.urlToFile(uri, name, "image/png"));
            // link.click();

            // after creating link you should delete dynamic link
            // clearDynamicLink(link);
        },
        async printToFile(div, download = true) {
            while (this.beautyPrint === "") {
                console.log("Waiting for beautyPrint");
                await sleep(1000);
            }
            await html2canvas(div, {
                backgroundColor: "rgba(0,0,0,0)",

                allowTaint: true,
                useCORS: true,
            }).then((canvas) => {
                // create your own dialog with warning before saving file
                // beforeDownloadReadMessage();
                // Then download file
                // this.downloadURI(`data:${myImage}`, 'yourImage.png');
                if (download) {
                    const myImage = canvas.toDataURL("image/png");
                    const link = document.createElement("a");
                    link.download = `${this.sku}_${this.photoSelected}.png`;
                    link.href = `data:${myImage}`;
                    link.target = "_blank";

                    link.click();
                } else {
                    canvas.toBlob((blob) => {
                        const file = new File(
                            [blob],
                            `${this.sku}_${this.photoSelected}.png`,
                            {
                                type: "image/png",
                                lastModified: Date.now(),
                            }
                        );
                        this.siloDownloadZip.push(file);
                    });
                }
                this.beautyPrint = "";
            });
        },
        async downloadAutomationBatch() {
            this.popupAlert.title = "Automation System";
            this.popupAlert.subTitle = "Preparing to building...";
            this.popupAlert.type = "abort_download_automation";
            this.popupAlert.show = true;
            this.popupAlert.confirm = false;
            this.popupAlert.cancel = true;
            this.photoSelected = 0;
            this.siloDownloadZip = [];
            this.abortDownloadAutomation = false;
            let dots = 0;
            if (!this.siloSelected.hasShadow) this.finishLoadFull.shadow = true;
            if (!this.siloSelected.hasReflection)
                this.finishLoadFull.reflection = true;
            if (this.photoSelected !== 0) this.finishLoadFull.beauty = false;
            for (let i = 0; i < this.siloSelected.images.length; i += 1) {
                preloadImages([
                    this.siloSelected.images[i].beauty.replace("_thumb", ""),
                ]);
                if (this.abortDownloadAutomation) {
                    this.popupAlert.show = false;
                    this.popupAlert.confirm = false;
                    this.popupAlert.cancel = false;
                    this.abortDownloadAutomation = false;
                    this.siloDownloadZip = [];
                    return;
                }
                this.photoSelected = i;
                this.beautyPrint = "";
                await sleep(2000);
                do {
                    await sleep(1000);
                    dots += 1;
                    if (dots > 3) dots = 0;
                    this.popupAlert.subTitle = `Building Image ${i + 1} of ${
                        this.siloSelected.images.length
                    }${".".repeat(dots)}`;
                } while (
                    !this.finishLoadFull.beauty ||
                    !this.finishLoadFull.shadow ||
                    !this.finishLoadFull.reflection
                );

                await this.downloadSilo(true, false);
                while (this.siloDownloadZip.length <= i) {
                    await sleep(1000);
                }
                this.finishLoadFull.beauty = false;
                this.finishLoadFull.shadow = !this.siloSelected.hasShadow;
                this.finishLoadFull.reflection =
                    !this.siloSelected.hasReflection;
            }
            this.popupAlert.subTitle = "Creating a zip file...";
            const zip = new JSZip();
            const img = zip.folder("images");
            for (let i = 0; i < this.siloDownloadZip.length; i += 1) {
                img.file(`${this.sku}_${i}.png`, this.siloDownloadZip[i], {
                    base64: true,
                });
            }
            zip.generateAsync({ type: "blob" }).then((content) => {
                const today = new Date();
                saveAs(
                    content,
                    `${this.sku}_${today.getFullYear()}${
                        today.getMonth() + 1
                    }${today.getDate()}.zip`
                );
                this.popupAlert.show = false;
            });
            console.log(this.siloDownloadZip);
        },
        async uploadCustomImage(url, filename) {
            const file = await this.urlToFile(url, filename, "image/png");
            console.log(file);

            const formData = new FormData();
            formData.append("file_image", file);
            formData.append("id_product", this.id);
            formData.append("id_customer", this.customer);
            await http
                .post("/posts/product/images-uploaded/store", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((r) => {
                    console.log(r);
                })
                .catch((r) => {
                    console.log("Error:");
                    console.log(r);
                    /* eslint-disable */
                    debugger;
                    /* eslint-enable */
                });
        },
        /* eslint-enable no-param-reassign */
        setSiloImage(silo) {
            // console.log('Hi!');
            // console.log(silo);
            this.siloSelected.images = silo.images;
            // this.siloSelected.images.forEach((image) => {
            //  image.beauty += '?type=siloimage';
            // });
            this.siloSelected.background = silo.background;
            this.siloSelected.hasShadow = silo.hasShadow;
            this.siloSelected.dropShadow = silo.dropShadow;
            this.siloSelected.hasReflection = silo.hasReflection;
            this.siloSelected.reflections = silo.reflection;
            this.siloSelected.saturation = silo.saturation;
            this.siloSelected.brightness = silo.brightness;
            this.siloSelected.bg_scale = silo.bg_scale ? silo.bg_scale : 0;
            this.siloSelected.bg_offset_x = silo.bg_offset_x
                ? silo.bg_offset_x
                : 0;
            this.siloSelected.bg_offset_y = silo.bg_offset_y
                ? silo.bg_offset_y
                : 0;
            this.siloSelected.image_scale = silo.image_scale
                ? silo.image_scale
                : 0;
            this.siloSelected.image_offset_x = silo.image_offset_x
                ? silo.image_offset_x
                : 0;
            this.siloSelected.image_offset_y = silo.image_offset_y
                ? silo.image_offset_y
                : 0;
            this.siloSelected.id = silo.id ? silo.id : 0;
        },
        convertScale(min, max, value) {
            const normalized = (value - min) / (max - min);
            return normalized;
        },
        convertValueBgScale(value) {
            const min = 100;
            const max = 200;
            const scaleValue = min + value * (max - min);
            return scaleValue;
        },
        convertValueImageScale(value) {
            const min = 75;
            const max = 175;
            const scaleValue = min + value * (max - min);
            return scaleValue;
        },
        convertValueBgOffset(value) {
            let multiply =
                this.convertValueBgScale(
                    this.convertScale(-1, 1, this.siloSelected.bg_scale)
                ) - 100;
            multiply /= 4;
            return value * multiply;
        },
        convertValueImageOffset(value) {
            return value * 100 * 0.5;
        },
        setVideo(video) {
            this.videoSelected.video = video;
        },
        setViewer(url) {
            this.viewerSelected.url = url;
        },
        downloadVideo() {
            // dpwnload video here
            axios({
                url: this.videoSelected.video,
                method: "GET",
                responseType: "blob",
            }).then((response) => {
                const urlObject = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                const link = document.createElement("a");
                link.href = urlObject;
                link.setAttribute(
                    "download",
                    `${this.sku ? this.sku : this.id}.mp4`
                );
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            });
        },
        addinfo() {
            this.infos.push({
                title: "",
                value: "",
                height: 0,
                open: true,
                edit: true,
                id: 0,
            });
        },
        deleteInfo(index) {
            this.infos.splice(index, 1);
        },
        setSizeDownloadWindow() {
            const targetDiv = document.getElementById("siloDownloadWindow");
            const zoomLevel = window.devicePixelRatio || 1; // Obtém o nível de zoom

            // Calcula o tamanho da div ajustado pelo zoom
            const adjustedWidth = 3840 / zoomLevel;
            targetDiv.style.width = `${adjustedWidth}px`;
            targetDiv.style.height = `${adjustedWidth}px`;
        },
        async downloadSilo(repeat = true, download = true) {
            const img = document.getElementById("siloDownloadWindow_beauty");
            this.setSizeDownloadWindow();

            console.log(this.finishLoadFull.beauty);
            while (this.finishLoadFull.beauty === false) {
                console.log("Waiting for beautyPrint");
                await sleep(1000);
            }

            screenshot(img).then((url) => {
                if (repeat) {
                    setTimeout(() => {
                        this.downloadSilo(false, download);
                    }, 1000);
                } else {
                    this.beautyPrint = url;
                    setTimeout(() => {
                        this.printToFile(
                            document.getElementById("siloDownloadWindow"),
                            download
                        );
                    }, 1000);
                }
            });
            // this.printToFile(document.getElementById('siloDownloadWindow'));
        },
        async postQueueDownload() {
            if (!this.siloSelected.id) {
                this.popupAlert.title = "Error";
                this.popupAlert.subTitle = "Please select or create a preset.";
                this.popupAlert.confirm = true;
                this.popupAlert.cancel = false;
                this.popupAlert.type = "info";
                this.popupAlert.show = true;
                return;
            }

            this.popupAlert.title = "Requesting Download Automation";
            this.popupAlert.subTitle = "Please wait.";
            this.popupAlert.confirm = false;
            this.popupAlert.cancel = false;
            this.popupAlert.show = true;
            await http
                .post("/posts/product/automation/enqueue/download", {
                    id_product: this.id,
                    id_customer: this.customer,
                    id_product_config: this.siloSelected.id,
                })
                .then((r) => {
                    console.log(r);
                    this.popupAlert.subTitle =
                        "Download Automation Requested, you'll receive an email when it's ready.";
                    this.popupAlert.confirm = true;
                    this.popupAlert.cancel = false;
                    this.popupAlert.type = "info";
                })
                .catch((r) => {
                    console.log("Error:");
                    console.log(r);
                    /* eslint-disable */
                    debugger;
                    /* eslint-enable */
                    this.popupAlert.subTitle =
                        "Error requesting download automation.";
                    this.popupAlert.confirm = true;
                    this.popupAlert.cancel = false;
                    this.popupAlert.type = "info";
                });
        },
        nextPhoto(direction) {
            this.photoSelected += direction;
            if (this.photoSelected < 0) {
                if (this.selection === "info")
                    this.photoSelected = this.photos.length - 1;
                else if (this.selection === "silo")
                    this.photoSelected = this.siloSelected.images.length - 1;
                else if (this.selection === "vignette")
                    this.photoSelected = this.siloSelected.images.length - 1;
                else if (this.selection === "360")
                    this.photoSelected = this.siloSelected.images.length - 1;
            } else if (
                this.selection === "info" &&
                this.photoSelected === this.photos.length
            )
                this.photoSelected = 0;
            else if (
                this.selection === "silo" &&
                this.photoSelected === this.siloSelected.images.length
            )
                this.photoSelected = 0;
            else if (
                this.selection === "vignette" &&
                this.photoSelected >= this.siloSelected.images.length
            )
                this.photoSelected = 0;
            else if (
                this.selection === "360" &&
                this.photoSelected >= this.siloSelected.images.length
            )
                this.photoSelected = 0;
            this.finishLoadFull.beauty = false;
            this.finishLoadFull.shadow = false;
            this.finishLoadFull.reflection = false;
        },
        setPhotoSelected(index) {
            this.photoSelected = index;
        },
        nextPhotoMouseMove(event) {
            if (this.lasttPositionMouse.x > 0) {
                if (this.lasttPositionMouse.down) {
                    let value = event.clientX - this.lasttPositionMouse.x;
                    if (value < 3 && value > -3) return;
                    value /= 2;
                    value = Math.round(value);
                    if (value < -10) value = -10;
                    else if (value > 10) value = 10;
                    this.nextPhoto(value);
                }
                this.lasttPositionMouse.x = event.clientX;
            } else {
                this.lasttPositionMouse.x = event.clientX;
            }
            // this.siloSelected.mouseX = event.clientX;
        },
        toggleDownMoveMouse(value) {
            this.lasttPositionMouse.down = value;
            if (value) this.lasttPositionMouse.firstUse = true;
        },
        setSection(sectiion) {
            this.photoSelected = 0;
            this.selection = sectiion;
            this.editInfo = false;
        },
        pushImageUpload(url) {
            this.imagesUploaded.push({
                photo: url,
            });
        },
        pushImageUploadVignette(url) {
            this.imagesUploadedVignette.push({
                photo: url,
            });
        },
        pushImageUpload360(url) {
            this.imagesUploaded360.push({
                photo: [url],
            });
        },
        alertEmitConfirm() {
            this.popupAlert.show = false;
        },
        alertEmitCancel() {
            if (this.popupAlert.type === "abort_download_automation") {
                this.popupAlert.title = "Aborting Download Automation";
                this.popupAlert.subTitle = "Please wait.";
                this.popupAlert.cancel = false;
                this.abortDownloadAutomation = true;
            } else {
                console.log(this.popupAlert.type);
            }
        },
        async completeTaskLoad() {
            lController.completeTask();
            this.popupAlert.subTitle = `Please wait. completed ${lController.getProgress()}%`;
            if (lController.getStatus() === "ended") {
                await sleep(1000);
                this.popupAlert.show = false;
            }
        },
        downloadImageMeasures() {
            axios({
                url: this.imageSize,
                method: "GET",
                responseType: "blob",
            }).then((response) => {
                const urlObject = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                const link = document.createElement("a");
                link.href = urlObject;
                link.setAttribute(
                    "download",
                    `${this.sku ? this.sku : this.id}.png`
                );
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            });
        },
        deleteImage(index) {
            const image =
                this.photos[index].indexOf("data:") == 0
                    ? this.photos[index]
                    : this.photos[index].split("/").pop();
            console.log(image);
            this.photos.splice(index, 1);
            if (this.photoSelected >= this.photos.length)
                this.photoSelected = 0;
            const fileToRemove = this.toUpdate.images.add.find(
                (f) => f.name === image
            );
            if (fileToRemove) {
                this.toUpdate.images.add.splice(
                    this.toUpdate.images.add.indexOf(fileToRemove),
                    1
                );
            } else {
                this.toUpdate.images.remove.push(image);
            }
        },
        eventUploadImage(event) {
            const files = [];
            const other = [];
            const supportedFiles = ["jpg", "jpeg", "png", "webp"];
            const otherSupportedFiles = [
                "txt",
                "pdf",
                "doc",
                "docx",
                "xls",
                "xlsx",
                "ppt",
                "pptx",
                "csv",
            ];
            for (let i = 0; i < event.target.files.length; i += 1) {
                let extensionFile = event.target.files[i].name.split(".");
                extensionFile =
                    extensionFile[extensionFile.length - 1].toLowerCase();
                if (supportedFiles.includes(extensionFile)) {
                    files.push(event.target.files[i]);
                } else if (otherSupportedFiles.includes(extensionFile)) {
                    other.push(event.target.files[i]);
                }
            }
            this.processImageUploadInput(files);
            this.$refs.productInfo.processFileUploadInput(other);
            event.target.value = "";
        },
        processImageUploadInput(files) {
            const supportedFiles = ["jpg", "jpeg", "png", "webp"];
            for (let i = 0; i < files.length; i += 1) {
                let extensionFile = files[i].name.split(".");
                extensionFile =
                    extensionFile[extensionFile.length - 1].toLowerCase();
                if (supportedFiles.includes(extensionFile)) {
                    const file = files[i];
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        this.photos.push(e.target.result);
                        this.toUpdate.images.add.push({
                            file,
                            name: e.target.result,
                        });
                        this.imageSelected =
                            this.products[this.prodSelected].images.length - 1;
                    };
                    reader.readAsDataURL(file);
                }
            }
        },
        async storeImageProduct(image) {
            this.popupAlert.title = "Updating product";
            this.popupAlert.subTitle = "Adding image to product";
            this.popupAlert.confirm = false;
            this.popupAlert.cancel = false;
            this.popupAlert.type = "";
            this.popupAlert.show = true;
            // send image to server
            const formData = new FormData();
            formData.append("id_product", this.id);
            formData.append("image", image);
            await http
                .post("/posts/product/resource/store", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((r) => {
                    console.log(r.data);
                })
                .catch((e) => {
                    console.log(e);
                    debugger;
                });
        },
        async deleteImageProduct(image) {
            this.popupAlert.title = "Updating product";
            this.popupAlert.subTitle = "Removing image from product";
            this.popupAlert.confirm = false;
            this.popupAlert.cancel = false;
            this.popupAlert.type = "";
            this.popupAlert.show = true;
            await http
                .delete(
                    `/deletes/product/resource/delete?id_product=${this.id}&image=${image}`
                )
                .then((r) => {
                    console.log(r.data);
                })
                .catch((e) => {
                    console.log(e);
                    debugger;
                });
        },
        async eventUpdateDbImages() {
            for (let i = 0; i < this.toUpdate.images.add.length; i++) {
                await this.storeImageProduct(this.toUpdate.images.add[i].file);
            }
            for (let i = 0; i < this.toUpdate.images.remove.length; i++) {
                await this.deleteImageProduct(this.toUpdate.images.remove[i]);
            }
            this.popupAlert.show = true;
            this.popupAlert.title = "Update Product";
            this.popupAlert.subTitle = "Product updated successfully!";
            this.popupAlert.confirm = true;
            this.popupAlert.cancel = false;
            this.popupAlert.type = "success";
            this.edit = false;
        },
        resetUpdates() {
            this.toUpdate = {
                images: {
                    add: [],
                    remove: [],
                },
            };
        },
        eventSetEditInfo(value) {
            this.editInfo = value;
        },
        async setupAccess() {
            const slugs = [];
            Object.keys(this.permissions).forEach((key) => {
                slugs.push(key);
            });

            const access = await this.$userAccess.to(slugs);
            Object.keys(this.permissions).forEach((key) => {
                this.permissions[key] = access[key];
            });
            // if all access are false, redirect to dashboard
            if (!Object.values(this.permissions).includes(true)) {
                this.$router.push({ name: "Dashboard" });
            }
        },
        canCaccessReadOrWrite(slugFunc) {
            return (
                this.permissions[`editor-${slugFunc}`] ||
                this.permissions[`reader-${slugFunc}`]
            );
        },
    },
    mounted() {
        this.popupAlert.title = "Loading Product";
        this.popupAlert.subTitle = "Please wait. completed 0%";
        this.popupAlert.show = true;
        lController.reset();
        lController.start(4);
        this.getDataProduct();
        this.getDataTutorialVideo();
        this.verifyScrollServices();
        // this.getDataInfos();
        window.addEventListener("resize", this.verifyScrollServices);
        // this.printToFile(document.getElementById('siloDownloadWindow'));
    },
    created() {
        this.setupAccess();
        this.id = this.$route.params.id;
        this.customer = store.state.customer.id;
    },
};

/* eslint-disable */

/* eslint-enable */
</script>

<style lang="scss">
* {
    margin: 0;
    padding: 0;
    text-decoration: none;
    list-style: none;
}
.appProduct {
    display: flex;
    width: 100vw;
    height: 100vh;
    //overflow-x: hidden;
    &_content {
        background: #141517;
        //width: 100%;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        width: calc(100% - 0px);
        overflow-x: hidden;
    }
}
.menu_superior {
    height: 85px;
    min-height: 85px;
    background: #141517;
}
.contentProduct {
    padding: 20px;
    padding-top: 0;
    height: calc(100vh - 125px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}
.productScreen {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    background: #1e2127;
    border-radius: 10px;
    padding: 20px;

    @media screen and (max-width: 1300px) {
        flex-direction: column;
        align-items: center;
        //justify-content: center;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            display: none;
        }
    }

    &_left {
        display: flex;
        flex-direction: column;
        width: calc(100% - 550px);
        @media screen and (max-width: 1300px) {
            width: 100%;
            margin-bottom: 20px;
        }
        //width: 700px;
    }
    &_right {
        padding: 20px;
        padding-bottom: 0;
        display: flex;
        position: relative;
        flex-direction: column;
        background: #272a30;
        border-radius: 10px;
        width: 100%;
        min-width: 500px;
        max-width: 796px;
        margin-left: 20px;
        @media screen and (max-width: 1300px) {
            width: 100%;
            margin-left: 0px;
            min-width: none;
            max-width: none;
        }

        &.scrollElement {
            @media screen and (max-width: 1300px) {
                overflow-y: initial;
            }
        }
    }
    &_mainPhoto {
        border-radius: 10px;
        margin-bottom: 15px;
        height: calc(100% - 125px);
        position: relative;
        @media screen and (max-width: 1300px) {
            height: calc(100vh);
            max-height: calc(100vh - 400px);
        }
        &_group {
            height: 100%;
            width: auto;
            aspect-ratio: 1 / 1;
            margin: 0 auto;
            position: relative;
            overflow: hidden;
            > img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                @media screen and (max-width: 1300px) {
                    object-fit: contain;
                }
                position: absolute;
                top: 0;
                left: 0;
            }
        }
        > img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            background: #141517;
            @media screen and (max-width: 1300px) {
                object-fit: contain;
            }
        }
        &-silo {
            position: relative;
            overflow: hidden;
            //background: white;
            > img {
                position: absolute;
                top: 0;
                left: 0;
                background: none;
            }
        }
        &-video {
            position: relative;
            overflow: hidden;
            //background: white;
            display: flex;
            align-items: center;
            justify-content: center;
            > video {
                width: 100%;
            }
        }
        .downloadButtonImage {
            position: absolute;
            top: 10px;
            right: 10px;
            background: #3d424e;
            min-width: auto;
        }
    }
    &_dragDrop {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background: #141517 !important;
    }

    &_servicesList {
        padding-top: 10px;
        padding-bottom: 10px;
        background: #272a30;
        border-radius: 10px;
        width: 100%;
        position: relative;
        .button {
            position: absolute;
            bottom: 0;
            height: 100%;
            border: none;
            z-index: 1;
            display: flex;
            align-items: center;
            background: #272a30;
            &:hover {
                background: #0264d8;
            }
            &_left {
                left: 0;
                border-radius: 10px 0 0 10px;
                border-right: 1px solid #2e3137;
            }
            &_right {
                border-radius: 0 10px 10px 0;
                right: 0;
                border-left: 1px solid #2e3137;
            }
        }
        > ul {
            display: flex;
            overflow-x: scroll;
            padding-right: 10px;
            &::-webkit-scrollbar {
                display: none;
            }
        }
        &_item {
            width: 90px;
            min-width: 90px;
            height: 90px;
            min-height: 90px;

            margin-left: 10px;
            .photoItem {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
    &_sku {
        font-size: 15px;
        color: #95949b;
        margin-bottom: 10px;
    }
    &_name {
        font-size: 40px;
        color: #fff;
        font-weight: bold;
        margin-bottom: 20px;
    }
    &_productinfo {
        display: flex;
        flex-direction: column;
        width: 100%;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            display: none;
        }
        @media screen and (max-width: 1300px) {
            //max-height: 500px;
        }
        &_item {
            width: 100%;
            min-width: 100%;
            background: #2e3238;
            border-radius: 5px;
            margin-bottom: 10px;
            &:last-child {
                margin-bottom: 0;
            }
            &_header {
                padding: 3px 20px;
                padding-right: 3px;
                border-bottom: 1px solid rgba($color: #3d424e, $alpha: 0);
                display: flex;
                align-items: center;
                justify-content: space-between;
                transition: 0.5s ease;
                &_title {
                    font-size: 15px;
                    color: #ffffff;
                    font-weight: bold;
                    input {
                        font-size: 15px;
                        color: #ffffff;
                        font-weight: bold;
                        background: none;
                        border: none;
                        transition: 0.5s ease;
                        &:focus-visible {
                            outline: none;
                        }
                    }
                }
                &_icons {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    > li {
                        width: 34px;
                        height: 34px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-right: 3px;
                        transition: 0.5s ease;
                        &:last-child {
                            margin-right: 0;
                        }
                        > img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
                .icon_arrow {
                    transform: rotate(180deg);
                }
            }
            &_value {
                height: 0;
                overflow: hidden;
                transition: 0.5s ease;
                padding-left: 20px;
                padding-right: 20px;
            }
        }
        .open {
            .productScreen_productinfo_item_header {
                border-bottom: 1px solid #3d424e;
            }
            .productScreen_productinfo_item_value {
                padding-bottom: 20px;
                padding-top: 16px;
                height: auto;
            }
            .icon_arrow {
                transform: rotate(0deg);
            }
        }
        &_buttonAddMoreDescription {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            background: #1e2127;
            border-radius: 5px;
            margin-top: 10px;
            padding: 3px;
            cursor: pointer;
            transition: 0.5s ease;
            &:hover {
                background: #0264d8;
            }
            > img {
                width: 34px;
                height: 34px;
            }
        }
    }
    &_section {
        border-bottom: 1px solid #3d424e;
        padding-bottom: 20px;
        padding-top: 20px;
        &:first-child {
            padding-top: 0;
        }
        &:last-child {
            border-bottom: none;
        }
        .buttonSection {
            border: 1px solid #56585c;
            border-radius: 10px;
        }
        &_line {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 15px;
        }
        &_grid {
            display: grid;
            grid-template-columns: calc(50% - 5px) calc(50% - 5px);
            grid-gap: 10px;
        }
    }
    &_titleSection {
        font-size: 20px;
        color: #fff;
        font-weight: bold;
        margin-bottom: 0px;

        &.marginTop {
            margin-top: 20px;
        }
    }
    &_buttonIcon {
        width: 34px;
        height: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.5s ease;
        > img {
            width: 100%;
            height: 100%;
        }
    }

    &_leftTop {
        position: absolute;
        top: 10px;
        left: 10px;
        display: flex;

        .button {
            background: #3d424e;
            min-width: auto;
            margin-right: 10px;
            height: 40px;
        }
    }

    .photoArrow {
        position: absolute;
        top: calc(50% - 17px);
        background: #3d424e;
        border-radius: 50%;
        width: 34px;
        height: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.5s ease;
        cursor: pointer;
        z-index: 2;
        > img {
            width: 20px;
            height: 20px;
        }
        &:hover {
            background: #0092ff;
        }

        &.left {
            left: 10px;
        }
        &.right {
            right: 10px;
        }
    }
    &_mainPhoto {
        background: #272a30;
    }
}

.scrollElement {
    overflow-y: scroll;
    &::-webkit-scrollbar {
        display: none;
    }
}

.textarea {
    width: 100%;
    min-height: 50px;
    //height: 300px;
    border: none;
    resize: none;
    outline: none;
    background: none;
    color: #fff;
    font-size: 12px;
    transition: 0.5s ease;
    &::placeholder {
        color: #95949b;
    }
}

.siloBrackground {
    //position: absolute;
    //width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    aspect-ratio: 1 / 1;
    margin: 0 auto;
    &.noBackground {
        background: url("/background-colors-none.png") !important;
    }
}
.saturate {
    //filter: saturate(5);
}
.printScreenSilo {
    position: fixed;
    width: 3840px;
    height: 3840px;
    top: calc(100vh * 2);
    left: 0;
    z-index: 1;
    overflow: hidden;
    > * {
        position: absolute;
        top: 0;
        left: 0;
    }
}

.button_save {
    position: sticky;
    bottom: 0px;
    margin-left: -20px;
    width: calc(100% + 40px);
    @media screen and (max-width: 1300px) {
        bottom: -20px;
    }
}
.noBorderBotton {
    border-bottom: none;
}

.dragdrop {
    padding: 10px;
    background: rgba(#3d424e, $alpha: 0.7);
    width: 102px;
    position: absolute;
    bottom: 20px;
    left: 20px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transition: opacity 1s ease;
    &_icon {
        width: 22px;
        margin-bottom: 5px;
    }
    > p {
        font-family: "Fira Sans";
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        color: #ffffff;
    }
}

.vc-chrome-alpha-wrap {
    display: none !important;
}
.vc-chrome-sliders {
    align-items: center;
    display: flex;
}
.vc-chrome-hue-wrap {
    flex: 1;
    margin-bottom: 0 !important;
}
.vc-hue--horizontal {
    border-radius: 5px !important;
}
.vc-chrome-body {
    background: #2e3238 !important;
}
.vc-input__input {
    color: #fff !important;
}
.vc-chrome-toggle-icon {
    * {
        fill: #fff;
    }
}
.vc-chrome-toggle-icon-highlight {
    display: none !important;
}
.vc-chrome-field {
    &:nth-child(4) {
        display: none;
    }
}
.vc-chrome {
    border-radius: 5px;
    overflow: hidden;
}

.simpleSectionnText {
    font-family: "Fira Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #ffffff;
}

.sectionBlock {
    background: #2e3238;
    border-radius: 5px;
    margin-top: 10px;
    position: relative;
    &_header {
        padding: 11px 20px;
        p {
            color: #fff;
            font-size: 15px;
            font-weight: bold;
            margin: 0;
        }
        border-bottom: 1px solid #373c45;
    }
    &_content {
        padding: 14px 20px;
    }

    .row {
        display: flex;
        align-items: center;
        > * + * {
            margin-left: 12px;
        }
    }
    .listImages {
        &-90 {
            .photoItem {
                width: 90px;
                height: 90px;
            }
        }
    }
}

.galeryImagesInfo {
    position: absolute;
    bottom: 10px;
    background: rgba(#141517, $alpha: 0.8);
    padding-left: 15px;
    padding-right: 15px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100% - 20px);
    border-radius: 10px;
    z-index: 1;
    &_row {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &_galery {
        justify-content: space-between;
        display: flex;
        align-items: center;
        &_list {
            flex: 1;
            display: flex;
            align-items: center;
            overflow-x: auto;
            margin-bottom: 10px;

            &::-webkit-scrollbar {
                display: none;
            }

            > li {
                margin-right: 10px;
                &:last-child {
                    margin-right: 0;
                }
            }

            .photoItem {
                width: 90px;
                height: 90px;
            }
        }
    }
    .arrow {
        cursor: pointer;
    }
    .rotated {
        transform: rotate(180deg);

        &_left {
            transform: rotate(-90deg);
        }
        &_right {
            transform: rotate(90deg);
        }
    }
    .openCloseGalery {
        cursor: pointer;
    }

    &.closed {
        background: none;
        .galeryImagesInfo {
            &_galery {
                display: none;
            }
        }
        .openCloseGalery {
            background: rgba(#141517, $alpha: 0.8);
            border-radius: 10px;
        }
    }
}
</style>
