import { createStore } from 'vuex';

export default createStore({
  state: {
    user: {
      id: '',
      name: '',
      email: '',
      phone: '',
      token: '',
      photo: '',
      termofuse: '0',
      type: 'client',
    },
    customer: {
      id: '',
      name: '',
      photo: '',
    },
  },
  mutations: {},
  actions: {},
  modules: {},
});
