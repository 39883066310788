<template>
    <button
        class="button"
        :class="{
            active: active,
            smallspace: smallspace,
            rounded: rounded,
        }"
        :style="{ background: background ? background : '' }"
    >
        <div
            class="button_content"
            :style="{ padding: padding ? padding : '10px' }"
        >
            <div
                class="button_image"
                :class="{ notext: !text }"
                :style="{
                    opacity: opacity ? opacity : '',
                     width: size_icon ? size_icon : '20px',
                    height: size_icon ? size_icon : '20px',
                }"
            >
                <img
                    :src="require('@/assets' + icon)"
                    alt=""
                    :style="{
                        width: size_icon ? size_icon : '20px',
                        height: size_icon ? size_icon : '20px',
                    }"
                />
            </div>
        </div>
    </button>
</template>

<script>
export default {
    props: [
        "icon",
        "active",
        "smallspace",
        "background",
        "rounded",
        "padding",
        "opacity",
        "size_icon",
    ],
    data() {
        return {
            publicPath: process.env.BASE_URL,
        };
    },
};
</script>

<style lang="scss" scoped>
* {
    margin: 0;
    padding: 0;
    text-decoration: none;
    list-style: none;
}
.button {
    background: none;
    display: flex;
    align-items: stretch;
    border: none;
    border-radius: 5px;
    transition: 0.5s ease;
    cursor: pointer;
    justify-content: center;

    &_content {
        padding: 10px 10px;
        display: flex;
        align-items: center;
        transform: scale(0.82);
    }

    &_image {
        width: 20px;
        height: 20px;
        opacity: 0.5;
        transition: 0.5s ease;
        img {
            width: 100%;
            height: 100%;
        }
    }

    &:hover {
        background: #0092ff !important;
        .button {
            &_image {
                opacity: 1;
            }
            &_text {
                color: #fff;
            }
        }
    }

    @at-root {
        .active #{&} {
            &_image {
                opacity: 1;
            }
        }
        .active {
            background: #0092ff !important;
        }
    }
}

.smallspace {
    padding: 5px 10px;
    .button_image {
        margin-right: 5px;
    }
    &.inverted {
        .button_image {
            margin-left: 5px;
        }
    }
}
.rounded {
    border-radius: 50%;
}
</style>
