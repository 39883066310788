<template>
    <div class="appProduct">
        <Menu active="add products" />
        <div class="app_content">
            <div class="menuCatalogo">
                <div>
                    <h2 class="menuCatalogo_title font-title">
                        New Products list
                    </h2>
                    <ul class="menuCatalogo_itens">
                        <li class="menuCatalogo_item">
                            <Button
                                v-if="
                                    !onlyOneProduct ||
                                    (products.length < 1 && onlyOneProduct)
                                "
                                class="buttonAdd"
                                icon="/imgs/icons/plus.svg"
                                background="#141517"
                                @click="addProduct()"
                            />
                        </li>

                        <li
                            class="menuCatalogo_item"
                            v-for="(prod, i) in products"
                            :key="'prod_button' + i"
                        >
                            <Button
                                :noicon="true"
                                :text="formatNameProdButton(i)"
                                align="left"
                                :flag="prod.automation ? 'A' : ''"
                                :active="i == prodSelected"
                                @click="selectProduct(i)"
                                :previneOverflowText="true"
                                class="buttonProd"
                                :class="{ buttonError: prod.error }"
                            />
                            <ButtonIcon
                                class="buttonIcon"
                                icon="/imgs/icons/trash.svg"
                                @click="showAlertRemove(i)"
                            />
                        </li>
                        <li class="menuCatalogo_item" v-if="products.length">
                            <Button
                                v-if="
                                    !onlyOneProduct ||
                                    (products.length < 1 && onlyOneProduct)
                                "
                                class="buttonAdd"
                                icon="/imgs/icons/plus.svg"
                                background="#141517"
                                @click="addProduct()"
                            />
                        </li>
                    </ul>
                </div>
                <div class="menuCatalogo_bottom">
                    <Button
                        :noicon="true"
                        background="#141517"
                        text="SEND"
                        @click="eventButtonSend"
                    />
                </div>
            </div>
            <div class="content">
                <div class="menu_superior"></div>
                <div class="appInternal">
                    <div class="startContent" v-if="products.length == 0">
                        <p class="text">Add a New product</p>
                        <Button
                            icon="/imgs/icons/plus.svg"
                            background="#141517"
                            @click="addProduct()"
                        />
                    </div>
                    <div class="productScreen" v-else>
                        <div class="productScreen_left">
                            <!-- Photo -->
                            <div
                                class="productScreen_mainPhoto"
                                v-if="filterImagesUploaded.length"
                            >
                                <div
                                    class="photoArrow left"
                                    v-if="filterImagesUploaded.length > 1"
                                    @click="selectImage(imageSelected - 1)"
                                >
                                    <img
                                        :src="
                                            require('@/assets/imgs/icons/chevron-left.svg')
                                        "
                                    />
                                </div>
                                <div
                                    class="photoArrow right"
                                    v-if="filterImagesUploaded.length > 1"
                                    @click="selectImage(imageSelected + 1)"
                                >
                                    <img
                                        :src="
                                            require('@/assets/imgs/icons/chevron-right.svg')
                                        "
                                    />
                                </div>
                                <img
                                    :src="
                                        filterImagesUploaded[imageSelected]
                                            .image
                                    "
                                    alt=""
                                />
                            </div>
                            <div
                                class="
                                    productScreen_mainPhoto
                                    productScreen_dragDrop
                                "
                                v-else
                            >
                                <p class="text">
                                    Upload product images - Required*
                                </p>
                                <p class="text-small">Drag and Drop</p>
                                <div>
                                    <img
                                        :src="
                                            require('@/assets/imgs/icons/tabler_drag-drop.svg')
                                        "
                                    />
                                </div>
                            </div>
                            <input
                                type="file"
                                accept="image/*"
                                multiple
                                class="inputAll"
                                @change="eventUploadFile($event)"
                            />
                            <!-- End Photo -->
                            <div class="productScreen_servicesList">
                                <ButtonBorderIcon
                                    class="button_left"
                                    icon="/imgs/icons/chevrons-left.svg"
                                    @click="
                                        scrollElement('scrollService', 'left')
                                    "
                                    v-if="scrollServiceAux.scrollX > 0"
                                />
                                <ul
                                    @scroll="verifyScrollServices"
                                    id="scrollService"
                                >
                                    <li
                                        class="productScreen_servicesList_item"
                                        v-for="(
                                            image, i
                                        ) in filterImagesUploaded"
                                        :key="'image_' + i"
                                    >
                                        <ButtonPhoto
                                            :photo="image.image"
                                            :alignCenter="true"
                                            :active="imageSelected == i"
                                            @click="selectImage(i)"
                                            :actionIcon="'/imgs/icons/trash.svg'"
                                            :hasAction="true"
                                            :actionEmit="true"
                                            @action="removeImageFile(image)"
                                        />
                                    </li>
                                </ul>
                                <ButtonBorderIcon
                                    class="button_right"
                                    icon="/imgs/icons/chevrons-right.svg"
                                    @click="
                                        scrollElement('scrollService', 'right')
                                    "
                                    v-if="
                                        scrollServiceAux.scrollX < 2 &&
                                        scrollServiceAux.scrollX > -1
                                    "
                                />
                            </div>
                        </div>
                        <div class="productScreen_right scrollElement">
                            <Block
                                title="Product Identification - Name or SKU Required"
                            >
                                <div class="row productIdentification_line">
                                    <div class="row">
                                        <p class="simpleSectionnText">Name:</p>
                                        <InputText
                                            v-model="
                                                products[prodSelected].name
                                            "
                                        />
                                    </div>
                                    <div class="row">
                                        <p class="simpleSectionnText">SKU:</p>
                                        <InputText
                                            v-model="products[prodSelected].sku"
                                        />
                                    </div>
                                    <div class="row">
                                        <p class="simpleSectionnText">
                                            Collection:
                                        </p>
                                        <InputText
                                            v-model="
                                                products[prodSelected]
                                                    .collection
                                            "
                                        />
                                    </div>
                                </div>
                            </Block>
                            <Block
                                title="Automation System"
                                v-if="
                                    permissions['send-products-for-automation']
                                "
                            >
                                <div class="row">
                                    <p class="simpleSectionnText">
                                        Send to Automation:
                                    </p>
                                    <Toggle
                                        :value="
                                            products[prodSelected].automation
                                        "
                                        @update="setToggle"
                                    />
                                </div>
                            </Block>
                            <Block
                                :title="'Upload technical files and reference images'"
                            >
                                <div class="dragDropFiles">
                                    <div
                                        class="dragDropFiles_listFiles"
                                        v-if="filterFilesUploaded.length"
                                    >
                                        <ul>
                                            <li
                                                class="
                                                    dragDropFiles_listFiles_item
                                                "
                                                v-for="(
                                                    file, i
                                                ) in filterFilesUploaded"
                                                :key="'files_' + i"
                                            >
                                                <Button
                                                    class="
                                                        dragDropFiles_listFiles_item_file
                                                    "
                                                    :noicon="true"
                                                    :text="file.name"
                                                    align="left"
                                                    background="#3d424e"
                                                    :disabled="true"
                                                    :previneOverflowText="true"
                                                />
                                                <ButtonIcon
                                                    class="buttonIcon"
                                                    icon="/imgs/icons/trash.svg"
                                                    background="#3d424e"
                                                    @click="
                                                        removeImageFile(file)
                                                    "
                                                />
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="dragDropFiles_info">
                                        <p class="text">Upload Files</p>
                                        <p class="text-small">Drag and Drop</p>
                                        <div>
                                            <img
                                                :src="
                                                    require('@/assets/imgs/icons/tabler_drag-drop.svg')
                                                "
                                            />
                                        </div>
                                    </div>
                                    <input
                                        type="file"
                                        multiple
                                        class="inputAll"
                                        @change="eventUploadFile($event)"
                                    />
                                </div>
                            </Block>
                            <Block
                                :title="
                                    'Technical Specifications' +
                                    (products[prodSelected].automation
                                        ? ' - Units Required'
                                        : '')
                                "
                            >
                                <div class="row technical_content technical">
                                    <div class="row technical_content">
                                        <div class="technical_imageMeasure">
                                            <img
                                                :src="
                                                    require('@/assets/imgs/measureImage.svg')
                                                "
                                                alt=""
                                            />
                                        </div>
                                        <ul class="technical_measures">
                                            <li
                                                class="
                                                    row
                                                    technical_measures_measure
                                                "
                                            >
                                                <p class="simpleSectionnText">
                                                    Units:
                                                </p>
                                                <Dropdown
                                                    class="
                                                        technical_measures_input
                                                    "
                                                    :list="measures"
                                                    :modelValue="
                                                        measureSelected
                                                    "
                                                    @update="setMeasureSelected"
                                                    :fullHeight="true"
                                                />
                                            </li>
                                            <li
                                                class="
                                                    row
                                                    technical_measures_measure
                                                "
                                            >
                                                <p class="simpleSectionnText">
                                                    Height:
                                                </p>
                                                <InputText
                                                    class="
                                                        technical_measures_input
                                                    "
                                                    placeholder="15” 3’/10"
                                                    v-model="
                                                        products[prodSelected]
                                                            .height
                                                    "
                                                />
                                            </li>
                                            <li
                                                class="
                                                    row
                                                    technical_measures_measure
                                                "
                                            >
                                                <p class="simpleSectionnText">
                                                    Width:
                                                </p>
                                                <InputText
                                                    class="
                                                        technical_measures_input
                                                    "
                                                    placeholder="15” 3’/10"
                                                    v-model="
                                                        products[prodSelected]
                                                            .width
                                                    "
                                                />
                                            </li>
                                            <li
                                                class="
                                                    row
                                                    technical_measures_measure
                                                "
                                            >
                                                <p class="simpleSectionnText">
                                                    Depth:
                                                </p>
                                                <InputText
                                                    class="
                                                        technical_measures_input
                                                    "
                                                    placeholder="15” 3’/10"
                                                    v-model="
                                                        products[prodSelected]
                                                            .depth
                                                    "
                                                />
                                            </li>
                                        </ul>
                                    </div>
                                    <div
                                        class="
                                            technical_content technical_infos
                                        "
                                    >
                                        <div class="row">
                                            <p class="simpleSectionnText">
                                                Finish:
                                            </p>
                                            <InputText
                                                placeholder="Matte Black, Satin"
                                                v-model="
                                                    products[prodSelected]
                                                        .finish
                                                "
                                            />
                                        </div>
                                        <div>
                                            <p
                                                class="
                                                    simpleSectionnText
                                                    technical_infos_labelInformations
                                                "
                                            >
                                                More informations:
                                            </p>
                                            <InputTextArea
                                                placeholder="Some more details to inform about technical aspects of the product"
                                                v-model="
                                                    products[prodSelected]
                                                        .moreInformations
                                                "
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Block>
                            <Block title="Categories">
                                <p
                                    class="simpleSectionnText"
                                    v-if="
                                        products[prodSelected].categories
                                            .length == 0
                                    "
                                >
                                    No categories selected
                                </p>
                                <ul class="listTag listTag-edit">
                                    <li
                                        v-for="(cat, i) in products[
                                            prodSelected
                                        ].categories"
                                        :key="'catprod_' + i"
                                    >
                                        <Button
                                            :text="cat.name"
                                            icon="/imgs/icons/trash.svg"
                                            :inverted="true"
                                            :smallspace="true"
                                            @click="removeCategory(i)"
                                        />
                                    </li>
                                </ul>
                                <div
                                    class="
                                        productScreen_productinfo_item_header_title
                                        divCategories
                                    "
                                >
                                    <p>Add Categories</p>
                                </div>
                                <ul class="listTag">
                                    <li
                                        v-for="(cat, i) in filterAddCategories"
                                        :key="'catadd_' + i"
                                    >
                                        <Button
                                            :text="cat.name"
                                            icon="/imgs/icons/plus.svg"
                                            :inverted="true"
                                            :smallspace="true"
                                            @click="addCategory(cat)"
                                        />
                                    </li>
                                </ul>
                            </Block>
                            <Block title="Tags">
                                <p
                                    class="simpleSectionnText"
                                    v-if="
                                        products[prodSelected].tags.length == 0
                                    "
                                >
                                    No tags selected
                                </p>
                                <ul class="listTag listTag-edit">
                                    <li
                                        v-for="(tag, i) in products[
                                            prodSelected
                                        ].tags"
                                        :key="'tagprod_' + i"
                                    >
                                        <Button
                                            :text="tag.name"
                                            icon="/imgs/icons/trash.svg"
                                            :inverted="true"
                                            :smallspace="true"
                                            @click="removeTag(i)"
                                        />
                                    </li>
                                </ul>
                                <div
                                    class="
                                        productScreen_productinfo_item_header_title
                                        divCategories
                                    "
                                >
                                    <p>Select Tags</p>
                                    <div class="addTags">
                                        <p class="simpleSectionnText">
                                            Add Custom Tag:
                                        </p>

                                        <InputText
                                            icon="/imgs/icons/plus.svg"
                                            v-model="customTagInput"
                                            @keyup.enter="addCustomTag"
                                            @submit="addCustomTag"
                                        />
                                    </div>
                                </div>
                                <ul class="listTag">
                                    <li
                                        v-for="(tag, i) in filterAddTags"
                                        :key="'tagadd_' + i"
                                    >
                                        <Button
                                            :text="tag.name"
                                            icon="/imgs/icons/plus.svg"
                                            :inverted="true"
                                            :smallspace="true"
                                            @click="addTag(tag)"
                                        />
                                    </li>
                                </ul>
                            </Block>
                            <Block
                                :customHeader="true"
                                v-for="(desc, i) in products[prodSelected]
                                    .descriptions"
                                :key="'desc_' + i"
                            >
                                <template v-slot:header>
                                    <div class="row header_descriptions">
                                        <InputText
                                            placeholder="Matte Black, Satin"
                                            v-model="desc.name"
                                            class="descriptions_input"
                                        />
                                        <ButtonIcon
                                            class="buttonIcon"
                                            icon="/imgs/icons/trash.svg"
                                            background="none"
                                            @click="removeDescription(i)"
                                        />
                                    </div>
                                </template>
                                <InputTextArea
                                    placeholder=""
                                    v-model="desc.description"
                                />
                            </Block>
                            <Button
                                class="buttonAdd buttonAdd-screenRight"
                                icon="/imgs/icons/plus.svg"
                                background="#141517"
                                @click="addDescription"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <PopupAlert
        v-if="popupAlert.show"
        :title="popupAlert.title"
        :subTitle="popupAlert.subTitle"
        :confirm="popupAlert.confirm"
        :cancel="popupAlert.cancel"
        @confirm="alertEmitConfirm"
        @cancel="alertEmitCancel"
        :error="popupAlert.type === 'error'"
    />
    <PopupVideo
        v-if="tutorial.show"
        :video="tutorial.video"
        @confirm="watchedTutorialVideo"
    />
</template>

<script>
import http from '@/http';
import Menu from './common/Menu.vue';
import Button from '@/components/buttons/Button-icon-text.vue';
import ButtonIcon from '@/components/buttons/Button-icon.vue';
import ButtonPhoto from '@/components/listItem/Square-photo-description-event.vue';
import ButtonBorderIcon from '@/components/buttons/Button-icon-border.vue';
import Toggle from '@/components/inputs/Toggle.vue';
import InputText from '@/components/inputs/InputText.vue';
import InputTextArea from '@/components/inputs/InputTextarea.vue';
import Block from '@/components/sections/Block.vue';
import PopupAlert from './common/PopupAlert.vue';
import Dropdown from '@/components/dropdowns/Dropdown-rounded.vue';
import store from '@/store';
import PopupVideo from './common/PopupVideo.vue';

export default {
  components: {
    Menu,
    Button,
    ButtonIcon,
    ButtonPhoto,
    ButtonBorderIcon,
    Toggle,
    Block,
    InputText,
    InputTextArea,
    PopupAlert,
    Dropdown,
    PopupVideo,
  },
  data() {
    return {
      id_customer: 0,
      allCategories: [],
      allTags: [],
      products: [],
      prodSelected: 0,
      imageSelected: 0,
      customTagInput: '',
      auxDelete: -1,
      scrollServiceAux: {
        scrollX: 0,
      },
      onlyOneProduct: false,
      popupAlert: {
        show: false,
        title: '',
        subTitle: '',
        confirm: false,
        cancel: false,
        type: '',
      },
      measures: ['in', 'ft', '', 'mm', 'cm', 'm'],
      permissions: {
        'register-products': false,
        'send-products-for-automation': false,
      },
      tutorial: {
        show: false,
        video: '',
      },
    };
  },
  methods: {
    getDataCategories() {
      http
        .get('/gets/product/categories/show')
        .then((r) => {
          if (r.data.success) {
            r.data.categories.forEach((cat) => {
              cat.subcategories.forEach((subcat) => {
                this.allCategories.push({
                  name: subcat.subcategory,
                  id: subcat.id,
                });
              });
            });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getDataProducts() {
      http
        .get(`/gets/product/show?id_customer=${this.id_customer}`)
        .then((r) => {
          if (r.data.success) {
            if (r.data.products.length < 2) this.onlyOneProduct = true;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getDataTags() {
      http
        .get(`/gets/tags/show?id_customer=${this.id_customer}`)
        .then((r) => {
          if (r.data.success) {
            console.log(r.data);
            r.data.tags.forEach((tag) => {
              this.allTags.push({
                name: tag.name,
                id: tag.id,
              });
            });
          } else {
            console.log(r.data);
          }
        })
        .catch((e) => {
          debugger;
          console.log(e);
        });
    },
    getDataTutorialVideo() {
      http
        .get('/user/tutorial/watched?slug=register-products')
        .then((r) => {
          if (r.data.success) {
            this.tutorial.show = !r.data.data.watched;
            this.tutorial.video = r.data.data.tutorial.url;
          } else {
            console.log(r);
          }
        })
        .catch((e) => {
          console.log(e);
          debugger;
        });
    },
    watchedTutorialVideo() {
      this.tutorial.show = false;
      http.post('/user/tutorial/watched', { slug: 'register-products' }).then((r) => {
        if (r.data.success) {
        } else {
          console.log(r);
        }
      });
    },
    addProduct() {
      const index = this.products.push({
        id: 0,
        finishedStore: false,
        name: '',
        error: false,
        sku: '',
        collection: '',
        automation: false,
        height: '',
        width: '',
        depth: '',
        finish: '',
        moreInformations: '',
        categories: [],
        tags: [],
        measure: 'in',
        descriptions: [
          {
            name: 'Description',
            description: '',
          },
        ],
        images: [],
        files: [],
      });
      this.selectProduct(index - 1);
    },
    async storeProduct(product) {
      this.popupAlert.title = 'Creating product';
      this.popupAlert.subTitle = `Creating product ${product.sku ? product.sku : product.name}`;
      this.popupAlert.confirm = false;
      this.popupAlert.cancel = false;
      this.popupAlert.type = '';
      this.popupAlert.show = true;
      await http
        .post('/posts/product/store', {
          id_customer: this.id_customer,
          name: product.name,
          sku: product.sku,
          collection: product.collection,
          automation_system: product.automation ? 1 : 0,
          height: product.height,
          width: product.width,
          depth: product.depth,
          finish: product.finish,
          note: product.moreInformations,
          measure: product.measure,
        })
        .then((r) => {
          if (r.data.success) {
            debugger;
            product.id = r.data.product.id;
          } else {
            this.popupAlert.title = 'Error creating product';
            this.popupAlert.subTitle = `Error creating product ${
              product.sku ? product.sku : product.name
            } ${r.data.message}`;
            this.popupAlert.confirm = true;
            this.popupAlert.cancel = false;
            this.popupAlert.type = 'error';
            this.popupAlert.show = true;
          }
        })
        .catch((e) => {
          this.popupAlert.title = 'Error creating product';
          this.popupAlert.subTitle = `Error creating product ${
            product.sku ? product.sku : product.name
          } ${e.data.message}`;
          this.popupAlert.confirm = true;
          this.popupAlert.cancel = false;
          this.popupAlert.type = 'error';
          this.popupAlert.show = true;
          console.log(e);
        });
    },
    addCategory(cat) {
      this.products[this.prodSelected].categories.push(cat);
    },
    addTag(tag) {
      if (this.products[this.prodSelected].tags.find((t) => t.id === tag.id)) return;
      this.products[this.prodSelected].tags.push(tag);
    },
    addDescription() {
      this.products[this.prodSelected].descriptions.push({
        name: '',
        description: '',
      });
    },
    async createTag(tag) {
      this.popupAlert.title = 'Creating tag';
      this.popupAlert.subTitle = `Creating tag ${tag.name}`;
      this.popupAlert.confirm = false;
      this.popupAlert.cancel = false;
      this.popupAlert.type = '';
      this.popupAlert.show = true;
      await http
        .post('/posts/tag/store', {
          id_customer: this.id_customer,
          name: tag.name,
        })
        .then((r) => {
          console.log(r);
          if (r.data.success) {
            tag.id = r.data.tag.id;
            this.allTags.push(tag);
            this.addTag(tag);
            this.popupAlert.show = false;
          } else {
            this.popupAlert.title = 'Error';
            this.popupAlert.subTitle = `Error create tag ${tag.name}: ${r.data.message}`;
            this.popupAlert.confirm = true;
            this.popupAlert.cancel = false;
            this.popupAlert.type = 'error';
            this.popupAlert.show = true;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async storeTagProduct(product, tag) {
      this.popupAlert.title = 'Adding tag to product';
      this.popupAlert.subTitle = `Adding tag ${tag.name} to product ${
        product.sku ? product.sku : product.name
      }`;
      this.popupAlert.confirm = false;
      this.popupAlert.cancel = false;
      this.popupAlert.type = '';
      this.popupAlert.show = true;
      await http
        .post('/posts/product/tag/store', {
          id_product: product.id,
          id_tag: tag.id,
        })
        .then((r) => {
          if (r.data.success) {
            console.log(r.data);
          } else {
            console.log(r.data);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async storeCategoryProduct(product, category) {
      this.popupAlert.title = 'Adding category to product';
      this.popupAlert.subTitle = `Adding category ${category.name} to product ${
        product.sku ? product.sku : product.name
      }`;
      this.popupAlert.confirm = false;
      this.popupAlert.cancel = false;
      this.popupAlert.type = '';
      this.popupAlert.show = true;
      await http
        .post('/posts/product/subcategories/store', {
          id_product: product.id,
          id_customer: this.id_customer,
          id_product_subcategory: category.id,
        })
        .then((r) => {
          console.log(r.data);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async storeImageProduct(product, image) {
      this.popupAlert.title = 'Adding image to product';
      this.popupAlert.subTitle = `Adding image to product ${
        product.sku ? product.sku : product.name
      }`;
      this.popupAlert.confirm = false;
      this.popupAlert.cancel = false;
      this.popupAlert.type = '';
      this.popupAlert.show = true;
      // send image to server
      const formData = new FormData();
      formData.append('id_product', product.id);
      formData.append('image', image);
      await http
        .post('/posts/product/resource/store', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((r) => {
          console.log(r.data);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async storeFileProduct(product, file) {
      this.popupAlert.title = 'Adding file to product';
      this.popupAlert.subTitle = `Adding file to product ${
        product.sku ? product.sku : product.name
      }`;
      this.popupAlert.confirm = false;
      this.popupAlert.cancel = false;
      this.popupAlert.type = '';
      this.popupAlert.show = true;
      // send file to server
      const formData = new FormData();
      formData.append('id_product', product.id);
      formData.append('file', file);
      await http
        .post('/posts/product/asset/store', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((r) => {
          console.log(r.data);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async storeDescriptionProduct(product, description) {
      this.popupAlert.title = 'Adding description to product';
      this.popupAlert.subTitle = `Adding description ${description.name} to product ${
        product.sku ? product.sku : product.name
      }`;
      this.popupAlert.confirm = false;
      this.popupAlert.cancel = false;
      this.popupAlert.type = '';
      this.popupAlert.show = true;
      await http
        .post('/posts/product/description/store', {
          id_product: product.id,
          id_customer: this.id_customer,
          title: description.name,
          notes: description.description,
        })
        .then((r) => {
          console.log(r.data);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async addCustomTag() {
      const value = this.customTagInput.split(',');
      for (let i = 0; i < value.length; i++) {
        const tagName = value[i].trim();
        let tag;
        if (tagName === '') continue;
        tag = this.allTags.find((tag) => tag.name.toLowerCase() === tagName.toLowerCase());
        if (tag) {
          this.addTag(tag);
        } else {
          const tag = {
            name: tagName,
            id: 0,
          };
          await this.createTag(tag);
        }
      }
      this.customTagInput = '';
    },
    removeCategory(index) {
      this.products[this.prodSelected].categories.splice(index, 1);
    },
    removeTag(index) {
      this.products[this.prodSelected].tags.splice(index, 1);
    },
    removeDescription(index) {
      this.products[this.prodSelected].descriptions.splice(index, 1);
    },
    showAlertRemove(i) {
      this.popupAlert.title = 'Remove Product';
      this.popupAlert.subTitle = 'Are you sure you want to remove this product?';
      this.popupAlert.confirm = true;
      this.popupAlert.cancel = true;
      this.popupAlert.type = 'delete';
      this.popupAlert.show = true;
      this.auxDelete = i;
    },
    removeProduct(index) {
      this.products.splice(index, 1);
      this.prodSelected = 0;
    },
    selectProduct(index) {
      this.prodSelected = index;
      this.imageSelected = 0;
    },
    selectImage(index) {
      if (index < 0) index = this.filterImagesUploaded.length - 1;
      else if (index >= this.filterImagesUploaded.length) index = 0;
      this.imageSelected = index;
      console.log(this.imageSelected);
    },
    verifyScrollServices() {
      const element = document.getElementById('scrollService');
      if (!element) return;
      const scrollX = element.scrollLeft;
      const sizeScroll = element.scrollWidth - element.offsetWidth;
      if (scrollX === 0 && scrollX === sizeScroll) this.scrollServiceAux.scrollX = -1;
      else if (scrollX === 0) this.scrollServiceAux.scrollX = 0;
      else if (scrollX === sizeScroll) this.scrollServiceAux.scrollX = 2;
      else this.scrollServiceAux.scrollX = 1;
    },
    scrollElement(id, direction) {
      const element = document.getElementById(id);
      if (direction === 'left') {
        element.scrollBy({
          left: -100,
          behavior: 'smooth',
        });
      } else {
        element.scrollBy({
          left: 100,
          behavior: 'smooth',
        });
      }
    },
    setToggle(value) {
      this.products[this.prodSelected].automation = value;
    },
    removeFileProduct(file) {
      // const fileName = this.products[this.prodSelected].files[index].name;
      // const i = this.products[this.prodSelected].images.findIndex((p) => p.name === fileName);
      this.products[this.prodSelected].files.splice(index, 1);
      // if (i > -1) this.removeImage(i);
    },
    formatNameProdButton(index) {
      const prod = this.products[index];
      if (prod.sku.trim()) return prod.sku.trim();
      if (prod.name.trim()) return prod.name.trim();
      return `Product ${index + 1}`;
    },
    eventUploadImage(event) {
      const files = [];
      const supportedFiles = ['jpg', 'jpeg', 'png', 'webp'];
      for (let i = 0; i < event.target.files.length; i += 1) {
        let extensionFile = event.target.files[i].name.split('.');
        extensionFile = extensionFile[extensionFile.length - 1].toLowerCase();
        if (supportedFiles.includes(extensionFile)) {
          files.push(event.target.files[i]);
        }
      }
      this.processImageUploadInput(files);
      // remove file input
      event.target.value = '';
    },
    eventUploadFile(event) {
      const files = [];
      const supportedFiles = [
        'txt',
        'pdf',
        'doc',
        'docx',
        'xls',
        'xlsx',
        'ppt',
        'pptx',
        'png',
        'jpg',
        'jpeg',
        'csv',
        'dwg',
        'webp',
      ];
      for (let i = 0; i < event.target.files.length; i += 1) {
        let extensionFile = event.target.files[i].name.split('.');
        extensionFile = extensionFile[extensionFile.length - 1].toLowerCase();
        if (supportedFiles.includes(extensionFile)) {
          files.push(event.target.files[i]);
        }
      }
      this.processFileUploadInput(files);
      // this.processImageUploadInput(files);
      // remove file input
      event.target.value = '';
    },
    processImageUploadInput(files) {
      const supportedFiles = ['jpg', 'jpeg', 'png', 'webp'];
      for (let i = 0; i < files.length; i += 1) {
        let extensionFile = files[i].name.split('.');
        extensionFile = extensionFile[extensionFile.length - 1].toLowerCase();
        if (supportedFiles.includes(extensionFile)) {
          const file = files[i];
          const reader = new FileReader();
          reader.onload = (e) => {
            this.products[this.prodSelected].images.push({
              image: e.target.result,
              file,
              name: file.name,
            });
            this.imageSelected = this.products[this.prodSelected].images.length - 1;
          };
          reader.readAsDataURL(file);
        }
      }
    },
    processFileUploadInput(files) {
      const supportedFiles = [
        'txt',
        'pdf',
        'doc',
        'docx',
        'xls',
        'xlsx',
        'ppt',
        'pptx',
        'png',
        'jpg',
        'jpeg',
        'csv',
        'dwg',
        'webp',
      ];
      const extensionFileImage = ['png', 'jpg', 'jpeg', 'webp'];
      for (let i = 0; i < files.length; i += 1) {
        let extensionFile = files[i].name.split('.');
        extensionFile = extensionFile[extensionFile.length - 1].toLowerCase();
        if (supportedFiles.includes(extensionFile)) {
          const file = files[i];
          let indexProduct = this.products[this.prodSelected].files.push({
            name: file.name,
            file,
            image: false,
          });
          indexProduct -= 1;
          if (extensionFileImage.includes(extensionFile)) {
            const reader = new FileReader();
            reader.onload = (e) => {
              this.products[this.prodSelected].files[indexProduct].image = e.target.result;
            };
            reader.readAsDataURL(file);
          }
        }
      }
    },
    async eventButtonSend() {
      this.products.forEach((prod) => {
        prod.error = false;
      });
      const result = this.checkSendProducts();
      if (!result.status) {
        this.popupAlert.title = 'Error';
        this.popupAlert.subTitle = result.message;
        this.popupAlert.confirm = true;
        this.popupAlert.cancel = false;
        this.popupAlert.type = 'error';
        this.popupAlert.show = true;
        if (result.idProduct !== undefined) {
          this.products[result.idProduct].error = true;
        }
        return;
      }
      for (let i = 0; i < this.products.length; i++) {
        this.products[i].error = false;
        if (this.products[i].id === 0) await this.storeProduct(this.products[i]);
      }
      const productsWithId = this.products.filter((prod) => prod.id !== 0 && !prod.finishedStore);
      for (let i = 0; i < productsWithId.length; i++) {
        for (let j = 0; j < productsWithId[i].tags.length; j++) {
          await this.storeTagProduct(productsWithId[i], productsWithId[i].tags[j]);
        }
      }
      for (let i = 0; i < productsWithId.length; i++) {
        for (let j = 0; j < productsWithId[i].categories.length; j++) {
          await this.storeCategoryProduct(productsWithId[i], productsWithId[i].categories[j]);
        }
      }
      for (let i = 0; i < productsWithId.length; i++) {
        const images = productsWithId[i].files.filter((file) => file.image);
        for (let j = 0; j < images.length; j++) {
          await this.storeImageProduct(productsWithId[i], images[j].file);
        }
      }
      for (let i = 0; i < productsWithId.length; i++) {
        const files = productsWithId[i].files.filter((file) => !file.image);
        for (let j = 0; j < files.length; j++) {
          await this.storeFileProduct(productsWithId[i], files[j].file);
        }
      }
      for (let i = 0; i < productsWithId.length; i++) {
        for (let j = 0; j < productsWithId[i].descriptions.length; j++) {
          await this.storeDescriptionProduct(productsWithId[i], productsWithId[i].descriptions[j]);
        }
      }

      if (this.products.length == productsWithId.length) {
        this.popupAlert.title = 'Success';
        this.popupAlert.subTitle = 'Products sent successfully';
        this.popupAlert.confirm = true;
        this.popupAlert.cancel = false;
        this.popupAlert.type = 'successAll';
        this.popupAlert.show = true;
      } else {
        this.popupAlert.title = 'Success';
        this.popupAlert.subTitle = `Products sent successfully, but ${
          this.products.length - productsWithId.length
        } products could not be sent`;
        this.popupAlert.confirm = true;
        this.popupAlert.cancel = false;
        this.popupAlert.type = 'success';
        this.popupAlert.show = true;
      }
    },
    setMeasureSelected(value) {
      this.products[this.prodSelected].measure = this.measures[value];
    },
    checkSendProducts() {
      if (this.products.length == 0) {
        return {
          status: false,
          message: 'You need to add at least one product',
        };
      }
      for (let i = 0; i < this.products.length; i += 1) {
        const prod = this.products[i];
        if (!prod.name.trim() && !prod.sku.trim()) {
          return {
            status: false,
            message: 'You need to add a name or sku to the product',
            idProduct: i,
          };
        }
      }
      for (let i = 0; i < this.products.length; i += 1) {
        const prod = this.products[i];
        if (prod.automation) {
          if (!prod.width.trim() || !prod.height.trim() || !prod.depth.trim()) {
            return {
              status: false,
              message: `You need to add the dimensions to the product ${
                i + 1
              }: ${this.formatNameProdButton(i)}`,
              idProduct: i,
            };
          }
        }
      }
      for (let i = 0; i < this.products.length; i += 1) {
        const prod = this.products[i];
        if (!prod.files.find((f) => f.image)) {
          return {
            status: false,
            message: `You need to add at least one image to the product ${
              i + 1
            }: ${this.formatNameProdButton(i)}`,
            idProduct: i,
          };
        }
      }
      for (let i = 0; i < this.products.length; i += 1) {
        const prod = this.products[i];
        if (prod.automation && !prod.files.length) {
          return {
            status: false,
            message: `You need to add at least one file to the product ${
              i + 1
            }: ${this.formatNameProdButton(i)}`,
            idProduct: i,
          };
        }
      }
      return {
        status: true,
      };
    },
    alertEmitConfirm() {
      if (this.popupAlert.type === 'successAll') {
        this.$router.push({ path: '/catalog' });
      }
      if (this.popupAlert.type === 'error') {
        this.popupAlert.show = false;
      } else if (this.popupAlert.type === 'delete') {
        this.removeProduct(this.auxDelete);
        this.popupAlert.show = false;
      } else {
        this.popupAlert.show = false;
      }
    },
    alertEmitCancel() {
      this.popupAlert.show = false;
    },
    removeImage(index) {
      const { name } = this.products[this.prodSelected].images[index];
      const iFile = this.products[this.prodSelected].files.findIndex((file) => file.name === name);
      this.products[this.prodSelected].images.splice(index, 1);
      this.imageSelected = 0;
      if (iFile > -1) {
        this.removeFileProduct(iFile);
      }
    },
    removeImageFile(file) {
      const indexOf = this.products[this.prodSelected].files.findIndex((f) => f === file);
      this.products[this.prodSelected].files.splice(indexOf, 1);
      if (filteredImages.length > 0) {
        this.imageSelected = 0;
      }
    },
    async setupAccess() {
      const slugs = [];
      Object.keys(this.permissions).forEach((key) => {
        slugs.push(key);
      });

      const access = await this.$userAccess.to(slugs);
      Object.keys(this.permissions).forEach((key) => {
        this.permissions[key] = access[key];
      });
      if (!this.permissions['register-products']) {
        this.$router.push({ name: 'Dashboard' });
      }
      console.log(this.permissions);
    },
  },
  computed: {
    filterAddCategories() {
      const filteredCategories = this.allCategories.filter(
        (cat) => !this.products[this.prodSelected].categories.find((my) => my.id === cat.id),
      );
      filteredCategories.sort((a, b) => a.name.localeCompare(b.name));
      return filteredCategories;
    },
    filterImagesUploaded() {
      const filteredImages = this.products[this.prodSelected].files.filter((file) => file.image);
      return filteredImages;
    },
    filterFilesUploaded() {
      const filteredImages = this.products[this.prodSelected].files.filter((file) => !file.image);
      return filteredImages;
    },
    filterAddTags() {
      const filteredTags = this.allTags.filter(
        (tag) => !this.products[this.prodSelected].tags.find((my) => my.id === tag.id),
      );
      filteredTags.sort((a, b) => a.name.localeCompare(b.name));
      return filteredTags;
    },
    measureSelected() {
      return this.measures.indexOf(this.products[this.prodSelected].measure);
    },
  },
  created() {
    this.setupAccess();
    this.id_customer = store.state.customer.id;
  },
  mounted() {
    this.verifyScrollServices();
    this.getDataCategories();
    this.getDataProducts();
    this.getDataTags();
    // this.getDataInfos();
    window.addEventListener('resize', this.verifyScrollServices);
    this.getDataTutorialVideo();
  },
};
</script>

<style lang="scss" scoped>
* {
    margin: 0;
    padding: 0;
    text-decoration: none;
    list-style: none;
}

.app {
    display: flex;
    width: 100vw;
    height: 100vh;

    //overflow-x: hidden;
    &_content {
        background: #141517;
        //width: 100%;
        display: flex;
        align-items: stretch;
        width: calc(100% - 0px);
        overflow-x: hidden;

        .content {
            width: inherit;

            .appInternal {
                padding-top: 20px;
                padding-bottom: 18px;
                padding-left: 18px;
                padding-right: 18px;
                height: calc(100% - 85px);
                display: flex;
                justify-content: space-between;
            }
        }
    }
}

.menuCatalogo {
    background: #1e2127;
    padding: 35px 10px;
    width: fit-content;
    height: 100%;
    transition: 0.5s ease;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    max-width: 180px;

    * {
        transition: 0.5s ease;
    }

    &_title {
        font-size: 15px;
        font-weight: bold;
        color: #fff;
        padding-bottom: 10px;
        border-bottom: 1px solid #3d424e;
        padding-left: 10px;
    }

    &_itens {
        margin-top: 10px;

        + .menuCatalogo_title {
            margin-top: 20px;
        }
    }

    &_item {
        margin-top: 5px;
        display: flex;
        align-items: stretch;

        .button {
            min-width: auto;
        }

        .buttonProd {
            flex: 1;
        }
        .buttonIcon {
            margin-left: 10px;
        }

        .buttonAdd {
            width: 100%;
        }

        .buttonError {
            border: 1px solid #ed1802;
        }

        &_header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 9px;
            padding-right: 3px;
            cursor: pointer;

            &_title {
                font-size: 13px;
                color: #fff;
            }

            &_icon {
                width: 20px;
                height: 20px;
                margin-left: 10px;
                display: flex;
                align-items: center;
                position: relative;

                > img {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .has-itens {
            &::before {
                content: "";
                left: -10px;
                background: #0264d8;
                min-width: 5px;
                height: 5px;
                position: absolute;
                border-radius: 50%;
            }
        }

        &_content {
            display: flex;
            flex-direction: column;
            padding: 0 5px;
            background: #272a30;
            border-radius: 0 0 4px 4px;

            ul {
                opacity: 0;
            }

            &_item {
                height: 0px;
                overflow: hidden;
                font-size: 13px;
                color: #fff;
                transition: 0.5s ease;
            }
        }

        &-open {
            background: #2e3238;
            border-radius: 4px;

            .menuCatalogo {
                &_item {
                    &_header {
                        &_icon {
                            > img {
                                transform: rotate(180deg);
                            }
                        }
                    }

                    &_content {
                        ul {
                            opacity: 1;
                        }

                        &_item {
                            height: 35px;
                            margin-top: 5px;

                            &:last-child {
                                margin-bottom: 5px;
                            }
                        }
                    }
                }
            }
        }

        .button {
            //max-width: 160px;
        }

        .button_text {
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-align: left;
            white-space: normal;
        }

        &:last-child {
            margin-bottom: 5px;
        }
    }

    &_bottom {
        border-top: 1px solid #3d424e;
        padding-top: 10px;

        .button {
            width: 100%;
            border: 1px solid #ffe433;

            &:hover {
                background: #ffe433 !important;
            }
        }
    }
}

.startContent {
    background: #1e2127;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.text {
    font-family: "Fira Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    color: #fff;
    margin-bottom: 10px;

    &-small {
        font-family: "Fira Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        color: #fff;
    }
}

.productScreen {
    //display: grid;
    //grid-template-columns: 1fr 660px;
    gap: 20px;
    max-width: calc(100vw - 400px);

    @media (max-width: 1300px) {
        display: flex;
        max-width: calc(100vw - 390px);
    }
}

.productScreen_left {
    overflow: hidden;
    position: relative;
    @media (max-width: 1300px) {
        width: auto;
        overflow: initial;
        width: 100%;
    }
}

.productScreen_right {
    min-width: auto;
    padding-bottom: 20px;
    margin-left: 0;
}

.row {
    display: flex;
    align-items: center;

    > * + * {
        margin-left: 10px;
    }
}

.productIdentification {
    &_line {
        > div + div {
            margin-left: 50px;
        }
    }
}

.dragDropFiles {
    background: #141517;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 20px;
    position: relative;

    > * {
        width: 50%;
    }

    &_listFiles {
        z-index: 1;

        &_item {
            display: flex;

            &_file {
                width: 100%;
            }

            > * + * {
                margin-left: 10px;
            }

            & + & {
                margin-top: 10px;
            }
        }
    }

    &_info {
        display: flex;
        flex-direction: column;
        align-items: center;

        .text-small {
            margin-bottom: 10px;
        }
    }
}

.technical {
    justify-content: space-between;

    > * {
        flex: 1;
    }

    > * + * {
        margin-left: 30px;
    }

    &_imageMeasure {
        width: 170px;
        min-width: 170px;
        height: 170px;

        > img {
            width: 100%;
            height: 100%;
        }
    }

    &_content {
        display: flex;
        align-items: stretch;
    }

    &_measures {
        display: flex;
        flex-direction: column;
        align-items: space-between;
        justify-content: space-between;
        margin-left: 30px;

        &_measure {
            justify-content: space-between;
        }

        &_input {
            width: 110px;
        }
    }

    &_infos {
        display: flex;
        flex-direction: column;
        align-items: space-between;
        justify-content: space-between;

        &_labelInformations {
            margin-bottom: 10px;
        }
    }
}

.listTag {
    margin-left: -5px;
    margin-right: -5px;

    .button {
        cursor: pointer;
    }
}

.divCategories {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 0;
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    > * {
        flex: 1;
    }

    .addTags {
        display: flex;
        align-items: center;

        p {
            margin-right: 10px;
            white-space: nowrap;
        }
    }
}

.buttonAdd-screenRight {
    margin-top: 10px;
}

.descriptions {
    &_input {
        max-width: 250px;
    }
}

.productScreen_servicesList {
    min-height: 110px;
}

.inputAll {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
}
.productScreen_left {
    max-width: none;
}

.header {
    &_descriptions {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .buttonIcon {
            &:hover {
                background: none !important;
            }
        }
    }
}
</style>
