import { createRouter, createWebHistory } from 'vue-router';
import User from '../views/User.vue';
import Login from '../views/Login.vue';
import RegisterManeger from '../views/RegisterManeger.vue'
import ResetPasswordManager from '../views/ResetPasswordManager.vue'
import store from '../store';
import Dashboard from '../views/Dashboard.vue';
import CatalogTemplate from '../views/CatalogTemplate.vue';
import CatalogProduct from '../views/Catalog.vue';
import AddTemplate from '../views/template/Add.vue';
import Template from '../views/template/View.vue';
import AddProduct from '../views/AddProduct.vue';
import Product from '../views/Product.vue';
import ConfigUser from '../views/ConfigUser.vue';
import RegisterCustomer from '../views/RegisterCustomer.vue';
import TermOfUse from '../views/TermOfUse.vue';
import Admin_Retailer from '../views/admin/Retailer.vue';
import VueCookies from 'vue-cookies'
import http from '../http';

const routes = [
  {
    path: '/:id',
    name: 'UserOld',
    component: User,
    meta: {
      title: 'Ilustranext | Achievements',
      requiresAuth: true,
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      title: 'FV | Login',
    },
  },
  {
    path: '/reset-password',
    name: 'ResetPasswordManager',
    component: ResetPasswordManager,
    meta: {
      title: 'FV | reset-password',
    },
  },
  {
    path: '/register-manager',
    name: 'RegisterManager',
    component: RegisterManeger,
    meta: {
      title: 'FV | register-manager',
    },
  },
  {
    path: '',
    component: Login,
    meta: {
      title: 'FV',
      requiresAuth: true,
    },
  },
  {
    path: '/dashboard', name: 'Dashboard', component: Dashboard, meta: { title: 'FV - Dashboard', requiresAuth: true, },
  },
  {
    path: '/catalog', name: 'Catalog', component: CatalogProduct, meta: { title: 'FV - Catalog', requiresAuth: true, },
  },
  {
    path: '/admin/template/add',
    name: 'Add Template',
    component: AddTemplate,
    meta: {
      title: 'FV - Catalog',
      requiresAuth: true,
      type_only: 'ilustraviz',
    },
  },
  {
    path: '/catalog_template',
    name: 'Template Catalog User',
    component: CatalogTemplate,
    meta: {
      title: 'FV - Catalog',
      requiresAuth: true,
    },
  },
  {
    path: '/admin/catalog_template',
    name: 'Template Catalog',
    component: CatalogTemplate,
    meta: {
      title: 'FV - Catalog',
      requiresAuth: true,
      type_only: 'ilustraviz',
    },
  },
  {
    path: '/product/add', name: 'Add Product', component: AddProduct, meta: { title: 'FV - Add Product', requiresAuth: true, },
  },
  {
    path: '/product/:id', name: 'Product', component: Product, meta: { title: 'FV - Product', requiresAuth: true, },
  },
  {
    path: '/template/:id', name: 'Template', component: Template, meta: { title: 'FV - Template', requiresAuth: true, },
  },
  {
    path: '/config/user', name: 'User', component: ConfigUser, meta: { title: 'FV - Config User', requiresAuth: true, },
  },
  {
    path: '/register/customer',
    name: 'RegisterCustomer',
    component: RegisterCustomer,
    meta: {
      title: 'FV - Register Customer ',
      requiresAuth: false,
    }
  },
  {
    path: '/term-of-use',
    name: 'TermOfUse',
    component: TermOfUse,
    meta: {
      title: 'FV - Term Of Use ',
      requiresAuth: false,
    }
  },
  {
    path: '/admin',
    name: 'Admin',
    component: Admin_Retailer,
    meta: {
      title: 'FV - Retailer ',
      requiresAuth: true,
      type_only: 'ilustraviz',
    }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const nearestWithTitle = to.matched.slice().reverse().find((r) => r.meta && r.meta.title);
  const nearestWithMeta = to.matched.slice().reverse().find((r) => r.meta && r.meta.metaTags);
  const previousNearestMeta = from.matched.slice().reverse().find((r) => r.meta && r.meta.metaTags);

  if (to.meta.requiresAuth && !store.state.user.token) {
    if ($cookies.isKey('user_token')) {
      store.state.user.token = $cookies.get('user_token');
      await http.get('/api/user', {
        headers: {
          Authorization: `Bearer ${store.state.user.token}`,
        }
      }).then((response) => {
        if (to.meta.type_only && response.data.type != to.meta.type_only) {
          next({
            name: 'Dashboard',
          });
        }
        let companyId = localStorage.getItem('customer');
        let company = response.data.companies.find((company) => company.id == companyId);
        store.state.user.id = response.data.id;
        store.state.user.name = response.data.name;
        store.state.user.photo = response.data.urlImage;
        store.state.user.email = response.data.email;
        store.state.user.phone = response.data.phone;
        store.state.user.termofuse = response.data.version_terms_use;
        if (company) {
          store.state.customer.id = company.id;
          store.state.customer.name = company.name;
          store.state.customer.photo = company.urlImage;

          if (to.path.includes('/admin')) {
            next({
              name: 'Dashboard',
            });
          }
        }
        else if (companyId == -1){
          store.state.customer.id = -1;
          store.state.customer.name = 'Ilustraviz';
          store.state.customer.photo = response.data.urlImage;
          //redirect to /admin if page not contains /admin
          if (!to.path.includes('/admin')) {
            next({
              name: 'Admin',
            });
          }
        }
        else {
          store.state.customer.id = response.data.companies[0].id;
          store.state.customer.name = response.data.companies[0].name;
          store.state.customer.photo = response.data.companies[0].urlImage;
        }
        if (response.data.type == 'ilustraviz') {
          store.state.user.type = 'ilustraviz';
        }
      }).catch((error) => {
        debugger;
        $cookies.remove('user_token'),
          next({
            name: 'Login',
          });
      });
    }
    else {

      next({
        name: 'Login',
      });
    }
  }
  else {
    if (nearestWithTitle) {
      document.title = nearestWithTitle.meta.title;
    }
    else if (previousNearestMeta) {
      document.title = previousNearestMeta.meta.title;
    }

    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map((el) => el.parentNode.removeChild(el));

    if (!nearestWithMeta) return next();

    nearestWithMeta.meta.metaTags.map((tagDef) => {
      const tag = document.createElement('meta');

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      tag.setAttribute('data-vue-router-controlled', '');

      return tag;
    }).forEach((tag) => document.head.appendChild(tag));
  }

  return next();
});

export default router;
