<template>
    <div class="popupAlert">
        <div
            class="popupAlert_content"
            :class="{ fullBorder: !confirm && !cancel }"
        >
            <div class="popupAlert_content_text">
                <p class="popupAlert_content_text_title">{{ title }}</p>
                <p class="popupAlert_content_text_subTitle">
                    {{ subTitle }}
                </p>
                <p class="labelInput">Subject:</p>
                <input type="text" v-model="subject" class="text-likeInput" />
                <p class="labelInput">Description:</p>
                <textarea v-model="description" class="text-likeInput" />
                <p class="labelInput">Attachment:</p>
                <label :for="'input_' + myId">
                    <input
                        type="file"
                        accept="image/*"
                        :id="'input_' + myId"
                        @change="updateImageDisplay($event)"
                        class="popupAlert_inputAll"
                    />
                    <ul class="popupAlert_previewUploads">
                        <li>
                            <ButtonItemService
                                :title="'Upload'"
                                icon="/imgs/icons/icon_upload.svg"
                                :backgroundLight="true"
                            />
                        </li>
                        <li
                            v-for="(img, i) in imgs"
                            :key="'imgs_' + myId + '_' + i"
                        >
                            <img :src="img" alt="" />
                        </li>
                        <li
                            v-for="(file, i) in otherFiles"
                            :key="'files_' + myId + '_' + i"
                        >
                            <ButtonItemService
                                :title="file"
                                :backgroundLight="true"
                            />
                        </li>
                    </ul>
                </label>
            </div>
            <div class="popupAlert_content_buttons" v-if="confirm || cancel">
                <button
                    class="popupAlert_content_buttons_button cancel"
                    v-if="cancel"
                    @click="cancelEvent"
                    :class="{ fullButton: !confirm }"
                >
                    <img :src="require('@/assets/imgs/icons/x.svg')" alt="" />
                </button>
                <button
                    class="popupAlert_content_buttons_button confirm"
                    v-if="confirm"
                    @click="confirmEvent"
                    :class="{ fullButton: !cancel }"
                >
                    <img
                        :src="require('@/assets/imgs/icons/check.svg')"
                        alt=""
                    />
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import ButtonItemService from '@/components/listItem/Icon-title-subtitle.vue';

export default {
  components: {
    ButtonItemService,
  },
  props: ['title', 'subTitle', 'confirm', 'cancel'],
  data() {
    return {
      myId: 0,
      imgs: [],
      otherFiles: [],
      subject: '',
      description: '',
    };
  },
  methods: {
    confirmEvent() {
      this.$emit(
        'confirm',
        document.getElementById(`input_${this.myId}`).files,
        this.subject,
        this.description,
      );
    },
    cancelEvent() {
      this.imgs = [];
      this.otherFiles = [];
      this.$emit('cancel');
    },
    updateImageDisplay(event) {
      console.log(event.target.files);
      this.imgs = [];
      for (let i = 0; i < event.target.files.length; i += 1) {
        let extensionFile = event.target.files[i].name.split('.');
        extensionFile = extensionFile[extensionFile.length - 1].toLowerCase();
        if (extensionFile === 'jpg' || extensionFile === 'jpeg' || extensionFile === 'png') {
          const file = event.target.files[i];
          const reader = new FileReader();
          reader.onload = (e) => {
            this.imgs.push(e.target.result);
          };
          reader.readAsDataURL(file);
        } else {
          this.otherFiles.push(extensionFile);
        }
      }
    },
  },
  created() {
    this.myd = Math.floor(Math.random() * 1000000000);
  },
};
</script>

<style lang='scss' scoped>
.popupAlert {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px;

    &_content {
        min-width: 650px;
        min-height: 300px;
        display: flex;
        position: relative;
        //max-width: 90%;

        &_text {
            padding: 30px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            background: #141517;
            border-radius: 15px 0 0 15px;
            width: -webkit-fill-available;
            position: relative;
            //height: 100%;

            &_title {
                font-size: 24px;
                font-weight: bold;
                color: #fff;
                margin-bottom: 20px;
            }
            &_subTitle {
                font-size: 14px;
                font-weight: lighter;
                color: #fff;
            }
        }
        &_buttons {
            display: flex;
            flex-direction: column;
            width: 70px;
            background: linear-gradient(90deg, #141517 0%, #252525 100%);
            border-radius: 0 15px 15px 0;

            &_button {
                width: 100%;
                height: 50%;
                border: none;
                cursor: pointer;
                transition: 0.5s ease;
                > img {
                    width: 20px;
                    height: 20px;
                }

                &:first-child {
                    border-radius: 0 15px 0 0;
                }
                &:last-child {
                    border-radius: 0 0 15px 0;
                }
            }
        }
    }
    .confirm {
        background-color: rgba($color: #0fc621, $alpha: 0);
        &:hover {
            background-color: rgba($color: #0fc621, $alpha: 1);
        }
    }
    .cancel {
        background-color: rgba($color: #ed1802, $alpha: 0);
        &:hover {
            background-color: rgba($color: #ed1802, $alpha: 1);
        }
    }

    .fullButton {
        height: 100%;
        border-radius: 0 15px 15px 0;
    }
    .fullBorder {
        border-radius: 15px 15px 15px 15px;
    }
    &_inputAll {
        //position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: 0;
    }
    &_previewUploads {
        display: flex;
        flex-wrap: wrap;
        li {
            width: 100px;
            height: 100px;
            //margin: 10px;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}
.labelInput {
    font-family: "Fira Sans";
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    color: #fff;
    margin-bottom: 10px;
}

.text-likeInput {
    background: #3d424e;
    border: none;
    border-radius: 5px;
    font-size: 15px;
    color: #ffffff;
    padding: 6px;
    margin: 0;
    white-space: nowrap;
    overflow-x: scroll;
    width: 100%;
    margin-bottom: 14px;

    &::-webkit-scrollbar {
        display: none;
    }
}
label {
    position: relative;
    input {
        position: absolute;
        width: 100%;
        height: 100%;
    }
}
</style>
