<template>
    <div class="app">
        <Menu
            v-if="idCustomer > 0"
            active="template catalog"
        />
        <MenuAdmin
            v-if="idCustomer == -1"
            active="template catalog"
        />
        <div class="app_content">
            <Container>
                <Column class="column_buttons">
                    <Block :title="'Ambients Type'">
                        <div class="list_buttons">
                            <Loading-element v-if="ambients.length == 0" />
                            <ButtonMenu
                                v-for="(item, i) in ambients"
                                :key="'item_' + i"
                                :text="item.name"
                                :noicon="true"
                                :active="filter.ambient == item.id"
                                class="button-save"
                                background="#1e2127"
                                color="#ffffff"
                                align="left"
                                @click="updateFilterAmbient(item.id)"
                            />
                        </div>
                    </Block>
                </Column>
                <Column>
                    <Block>
                        <template v-slot:header>
                            <div class="catalog_top">
                                <div class="catalog_filters">
                                    <Input
                                        icon="/imgs/icons/search.svg"
                                        placeholder="Search..."
                                        class="borderColor"
                                        v-model="filter.search"
                                    />

                                    <DropdownCalendar
                                        @update="updateCalendarValue"
                                        class="borderColor borderInput"
                                        direction="right"
                                    />
                                    <Dropdown
                                        :list="[
                                        'Private',
                                        'Public',
                                        'Public & Private',
                                    ]"
                                        :modelValue="filter.public"
                                        @update="updateFilterPublic"
                                        class="borderColor borderInput"
                                    />
                                </div>
                                <div class="catalog_scale">
                                    <p class="font-field">Thumb Size:</p>
                                    <ButtonIcon
                                        icon="/imgs/icons/thumbnail_size-small.svg"
                                        padding="0px"
                                        class="button_scale"
                                        @click="size = 'small'"
                                        :active="size == 'small'"
                                    />
                                    <ButtonIcon
                                        icon="/imgs/icons/thumbnail_size-medium.svg"
                                        padding="0px"
                                        class="button_scale"
                                        @click="size = 'medium'"
                                        :active="size == 'medium'"
                                    />
                                    <ButtonIcon
                                        icon="/imgs/icons/thumbnail_size-big.svg"
                                        padding="0px"
                                        class="button_scale"
                                        @click="size = 'big'"
                                        :active="size == 'big'"
                                    />
                                </div>
                            </div>
                        </template>

                        <LoadingElement v-if="templates.length == 0" />
                        <div
                            class="catalog_list"
                            :style="{
                                gridTemplateColumns: get_size_grid(),
                            }"
                            v-if="templates.length"
                        >
                            <ItemCustom
                                :photo="formatThumb(item.image_name)"
                                :infos="[item.name, '#' + item.id]"
                                :active="false"
                                background="#ffffff"
                                :lazyload="true"
                                flagColor="#7d35ff"
                                v-for="(item) in filteredTemplates"
                                :key="'template_' + item.id"
                                :link="'/template/' + item.id"
                                :style="{
                                        width: '100%',
                                        height: 'auto',
                                        aspectRatio: '1 / 1',
                                    }"
                            >
                                <template v-slot:top_line>
                                    <div class="inlineButtons">
                                        <ButtonIcon
                                            icon="/imgs/icons/edit_noBorder.svg"
                                            padding="4px"
                                            size_icon="14px"
                                            class="button_scale"
                                            @click="go_to_template(item.id)"
                                            background="#3d424e"
                                        />
                                        <ButtonIcon
                                            v-if="permissions['delete-template-access']"
                                            icon="/imgs/icons/trash.svg"
                                            padding="4px"
                                            size_icon="14px"
                                            class="button_scale"
                                            @click="prepareDeleteProduct(item.id)"
                                            background="#3d424e"
                                        />
                                        <ButtonIcon
                                            icon="/imgs/icons/search.svg"
                                            padding="4px"
                                            size_icon="14px"
                                            class="button_scale"
                                            @click="openZoomImage(formatZoom(item.image_name))"
                                            background="#3d424e"
                                        />
                                    </div>
                                </template>
                            </ItemCustom>
                        </div>

                    </Block>
                </Column>
            </Container>
        </div>
    </div>
    <ZoomImage
        v-if="zoomImage.show"
        :url="zoomImage.image"
        @close="closeZoomImage"
    />
    <PopupAlert
        v-if="popupAlert.show"
        :title="popupAlert.title"
        :subTitle="popupAlert.subTitle"
        :confirm="popupAlert.confirm"
        :cancel="popupAlert.cancel"
        @confirm="emitConfirm"
        @cancel="emitCancel"
    />
</template>

<script>
import Menu from "./common/Menu.vue";
import MenuAdmin from "@/views/common/Menu_Admin.vue";
import Container from "../components/sections/Container.vue";
import Column from "../components/sections/Column.vue";
import Block from "../components/sections/Block.vue";
import ButtonMenu from "@/components/buttons/Button-icon-text.vue";
import ButtonIcon from "@/components/buttons/Button-icon.vue";
import Input from "@/components/inputs/Input-icon-line.vue";
import Dropdown from "@/components/dropdowns/Dropdown.vue";
import DropdownCalendar from "@/components/dropdowns/Dropdown-calendar.vue";
import ItemCustom from "@/components/listItem/Square-photo-description-custom-event.vue";
import store from "@/store";
import sleep from "@/utils/sleep";
import http from "@/http";
import LoadingElement from "@/components/LoadingElement.vue";
import PopupAlert from "./common/PopupAlert.vue";
import ZoomImage from "@/components/popups/ZoomImage.vue";

export default {
    components: {
        Menu,
        MenuAdmin,
        Container,
        Column,
        Block,
        ButtonMenu,
        ButtonIcon,
        Input,
        Dropdown,
        DropdownCalendar,
        ItemCustom,
        LoadingElement,
        PopupAlert,
        ZoomImage,
    },
    data() {
        return {
            idCustomer: 1,
            size: "medium",
            ambients: [],
            templates: [],
            toDeleteId: null,
            filter: {
                search: "",
                public: 2,
                data: {
                    start: "",
                    end: "",
                },
                ambient: null,
            },
            popupAlert: {
                show: false,
                title: "New Service",
                subTitle: "Creating a new service...",
                confirm: false,
                cancel: false,
                type: "loading",
            },
            zoomImage: {
                show: false,
                image: "",
            },
            permissions: {
                "reader-template-access": false,
                "delete-template-access": false,
            },
        };
    },
    methods: {
        formatThumb(file) {
            //replace .jpg or another extension to _thumb.jpg
            file = file.replace(/\.[^/.]+$/, "_thumb$&");
            file = file.replace(".png", ".jpg");
            return `${process.env.VUE_APP_AWS_BUCKET_IMAGES}${file}`;
        },
        formatZoom(image) {
            //replace .jpg or another extension to _thumb.jpg
            return `${process.env.VUE_APP_AWS_BUCKET_IMAGES}${image}`;
        },
        async getTemplates() {
            let clientFilter = "";
            if (this.idCustomer > -1) {
                clientFilter = `?id_customer=${this.idCustomer}`;
            }
            http.get(`/template/get_all${clientFilter}`)
                .then((response) => {
                    this.templates = response.data.templates;
                })
                .catch((error) => {
                    console.log(error);
                });
            console.log(this.templates);
        },
        async getAmbients() {
            http.get("/template/ambient/get_all")
                .then((response) => {
                    this.ambients = response.data.ambients;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        async deleteTemplate() {
            this.popupAlert = {
                show: true,
                title: "Delete Template",
                subTitle: "Deleting a template...",
                confirm: false,
                cancel: false,
                type: "delete",
            };
            http.delete(`/template/delete?id=${this.toDeleteId}`)
                .then((response) => {
                    this.templates = this.templates.filter(
                        (template) => template.id != this.toDeleteId
                    );
                    this.toDeleteId = null;
                    this.popupAlert = {
                        show: true,
                        title: "Delete Template",
                        subTitle: "Template deleted successfully",
                        confirm: true,
                        cancel: false,
                        type: "success",
                    };
                })
                .catch((error) => {
                    console.log(error);
                    this.popupAlert = {
                        show: true,
                        title: "Delete Template",
                        subTitle: "Error deleting template:" + error.message,
                        confirm: true,
                        cancel: false,
                        type: "error",
                    };
                });
        },
        updateCalendarValue(value) {
            this.filter.data.start = value[0];
            this.filter.data.end = value[1];
        },
        updateFilterPublic(value) {
            this.filter.public = value;
        },
        updateFilterAmbient(value) {
            this.filter.ambient = this.filter.ambient == value ? null : value;
        },
        prepareDeleteProduct(id) {
            this.popupAlert = {
                show: true,
                title: "Delete Product",
                subTitle: "Are you sure you want to delete this product?",
                confirm: true,
                cancel: true,
                type: "delete_pre",
            };
            this.toDeleteId = id;
        },
        emitConfirm() {
            if (this.popupAlert.type == "delete_pre") {
                this.deleteTemplate();
            } else {
                this.popupAlert.show = false;
            }
        },
        emitCancel() {
            if (this.popupAlert.type == "delete_pre") {
                this.toDeleteId = null;
            }
            this.popupAlert.show = false;
        },
        async setupAccess() {
            const slugs = [];
            Object.keys(this.permissions).forEach((key) => {
                slugs.push(key);
            });

            const access = await this.$userAccess.to(slugs);
            Object.keys(this.permissions).forEach((key) => {
                this.permissions[key] = access[key];
            });
            if (!this.permissions["reader-template-access"]) {
                this.$router.push({ name: "Dashboard" });
            }
        },
        get_size_grid() {
            switch (this.size) {
                case "small":
                    return "repeat(auto-fill, 110px)";
                case "medium":
                    return "repeat(auto-fill, 172px)";
                case "big":
                    return "1fr 1fr 1fr";
                default:
                    return "repeat(auto-fill, 110px)";
            }
        },
        go_to_template(id) {
            this.$router.push({ name: "Template", params: { id: id } });
        },
        openZoomImage(image) {
            this.zoomImage.image = image;
            this.zoomImage.show = true;
        },
        closeZoomImage() {
            this.zoomImage.show = false;
        },
    },
    computed: {
        filteredTemplates() {
            let filtered = this.templates.filter((template) => {
                let search = this.filter.search.toLowerCase().trim();
                if (search == "") {
                    return true;
                }
                if (template.id.toString().includes(search)) {
                    return true;
                }
                if (template.name.toLowerCase().includes(search)) {
                    return true;
                }
                if (
                    template.tags.find((tag) =>
                        tag.name.toLowerCase().includes(search)
                    )
                ) {
                    return true;
                }
                return false;
            });

            filtered = filtered.filter((template) => {
                if (this.filter.public == 2) {
                    return true;
                }
                return template.public == this.filter.public;
            });

            if (this.filter.data.start != "") {
                let start = new Date();
                start.setFullYear(this.filter.data.start.year);
                start.setMonth(this.filter.data.start.month - 1);
                start.setDate(this.filter.data.start.day);
                start.setHours(0, 0, 0, 0);
                let end = new Date();
                if (this.filter.data.end.day) {
                    end.setFullYear(this.filter.data.end.year);
                    end.setMonth(this.filter.data.end.month - 1);
                    end.setDate(this.filter.data.end.day);
                    end.setHours(23, 59, 59, 999);
                } else {
                    end = new Date(start);
                    end.setHours(23, 59, 59, 999);
                }

                filtered = filtered.filter((template) => {
                    let date = new Date(template.created_at);
                    return date >= start && date <= end;
                });
            }
            if (this.filter.ambient) {
                filtered = filtered.filter((template) => {
                    return template.ambients.find(
                        (ambient) => ambient.id == this.filter.ambient
                    );
                });
            }
            return filtered;
        },
    },
    created() {
        this.idCustomer = store.state.customer.id;
        this.getTemplates();
        this.getAmbients();
        this.setupAccess();
    },
};
</script>

<style lang="scss" scoped>
.app {
    display: flex;
    height: 100vh;
}
.app_content {
    width: 100%;
    background: #141517;
    padding: 20px;
    overflow-y: auto;
}

.list_buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.column_buttons {
    max-width: 200px;

    .button {
        min-width: inherit;
    }
}

.catalog_filters {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    > * {
        &:last-child {
            margin-right: 0;
        }
    }

    .font-field {
        color: #fff;
    }
}

.catalog_scale {
    display: flex;
    align-items: center;
    p {
        color: #fff;
        margin-right: 10px;
    }
    > * {
        margin-right: 10px;
        &:last-child {
            margin-right: 0;
        }
    }
}

.catalog_top {
    display: flex;
    justify-content: space-between;
}

.borderColor {
    border-color: #5b5e62;
}

.borderInput {
    border: 1px solid #5b5e62;
    border-radius: 10px;
}
.catalog_list {
    display: grid;
    grid-template-columns: repeat(auto-fill, 110px);
    grid-gap: 15px;
    justify-content: space-between;
    overflow-y: scroll;
    height: fit-content;
    padding-bottom: 26px;
    &::-webkit-scrollbar {
        display: none;
    }
    > li {
        aspect-ratio: 1 / 1;
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.column {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
    > * {
        flex: 1;
    }
}

.block {
    &:last-child {
        margin-bottom: 0;
    }
}

.inlineButtons {
    display: flex;
    gap: 5px;
}

.button_scale {
    width: 30px;
    height: 30px;
}
</style>
