<template>
    <div class="menu">
        <div class="menu_top">
            <div class="user">
                <div class="photo">
                    <img
                        v-if="userPhoto || photo"
                        :src="userPhoto ? userPhoto : photo"
                    />
                    <div
                        v-else
                        class="photo_name"
                    >
                        <p>{{ initials(name) }}</p>
                    </div>
                </div>
                <p class="user_name">{{ userName ? userName : name }}</p>
                <p
                    class="user_company"
                    v-if="companies.length < 2"
                >
                    {{ company }}
                </p>
                <Dropdown
                    v-if="companies.length > 1"
                    :list="getNameCompanies()"
                    :modelValue="getSelectedCompany()"
                    class="select_company"
                    :textAlign="'center'"
                    :maxHeight="'calc(100vh - 500px)'"
                    @update="changeCompany"
                />
            </div>
            <nav class="menu_list">
                <ul>
                    <li
                        class="menu_list_item"
                        v-for="item in itens"
                        :key="item.name"
                    >
                        <ButtonMenu
                            v-if="
                                item.keyPermission
                                    ? permissions[item.keyPermission]
                                    : true
                            "
                            :text="item.name"
                            :icon="item.icon"
                            :active="active == item.name.toLowerCase()"
                            @click="toPage(item.url)"
                        />
                    </li>
                </ul>
            </nav>
        </div>
        <div class="menu_bottom">
            <div class="menu_list_item">
                <ButtonMenu
                    text="Terms of Use"
                    icon="/imgs/icons/settings.svg"
                    @click="toPage('/term-of-use', true)"
                    :active="active == 'none'"
                />
            </div>
            <div class="menu_list_item">
                <ButtonMenu
                    text="Settings"
                    icon="/imgs/icons/settings.svg"
                    @click="toPage('/config/user')"
                    :active="active == 'settings'"
                />
            </div>
            <div class="menu_list_item">
                <ButtonMenu
                    text="Report Bug"
                    icon="/imgs/icons/tool.svg"
                    @click="openReportBug()"
                />
            </div>
            <div class="menu_list_item">
                <ButtonMenu
                    text="Logout"
                    icon="/imgs/icons/log-out.svg"
                    @click="logout()"
                />
            </div>
        </div>
    </div>
    <PopupAlert
        v-if="popupAlert.show"
        :title="popupAlert.title"
        :subTitle="popupAlert.subTitle"
        :confirm="popupAlert.confirm"
        :cancel="popupAlert.cancel"
        @confirm="emitConfirm"
    />
    <PopupAlert
        v-if="popupTermsUse.show"
        :title="popupTermsUse.title"
        :subTitle="popupTermsUse.subTitle"
        :confirm="popupTermsUse.confirm"
        @confirm="sendAcceptTermUse"
    >
        <div class="termsPopup">
            <div class="row">
                <Toggle
                    :value="popupTermsUse.confirm"
                    @update="toggleTermUse"
                />
                <p>
                    Agree to
                    <a
                        href="/term-of-use"
                        target="_blank"
                    >Terms and Conditions</a>
                </p>
            </div>
        </div>
    </PopupAlert>
    <PopupReportBug
        v-if="popupAlertBug.show"
        :title="popupAlertBug.title"
        :subTitle="popupAlertBug.subTitle"
        :confirm="popupAlertBug.confirm"
        :cancel="popupAlertBug.cancel"
        @confirm="alertEmitConfirm"
        @cancel="alertEmitCancel"
    />
</template>

<script>
import ButtonMenu from "@/components/buttons/Button-icon-text.vue";
import store from "@/store";
import PopupReportBug from "@/views/common/PopupReportBug.vue";
import PopupAlert from "./PopupAlert.vue";
import http from "@/http";
import Dropdown from "@/components/dropdowns/Dropdown.vue";
import Toggle from "@/components/inputs/Toggle.vue";

export default {
    props: ["active", "userName", "userPhoto"],
    components: {
        ButtonMenu,
        PopupReportBug,
        PopupAlert,
        Dropdown,
        Toggle,
    },
    data() {
        return {
            name: "John Doe",
            company: "Company Inc.",
            companies: [],
            photo: false,
            itens: [
                {
                    icon: "/imgs/icons/shopping-bag.svg",
                    name: "Retailer",
                    url: "/admin",
                    keyPermission: "dashboard-access",
                },
                {
                    icon: "/imgs/icons/file-plus.svg",
                    name: "Add Templates",
                    url: "/admin/template/add",
                    keyPermission: "dashboard-access",
                },
                {
                    icon: "/imgs/icons/layers.svg",
                    name: "Template Catalog",
                    url: "/admin/catalog_template",
                    keyPermission: "dashboard-access",
                },
            ],
            popupAlert: {
                show: false,
                title: "Automation System",
                subTitle: "Saving...",
                confirm: false,
                cancel: false,
                type: "save",
            },
            popupTermsUse: {
                show: false,
                title: "Terms of Use",
                subTitle:
                    "Our terms of use have changed, please read and accept to continue.",
                confirm: false,
                cancel: false,
                type: "term",
                version: "1",
            },
            popupAlertBug: {
                show: false,
                title: "Bug Report",
                subTitle: "",
                confirm: false,
                cancel: false,
                type: "save",
            },
            permissions: {
                "dashboard-access": false,
                "editor-info-access": false,
                "register-products": false,
            },
        };
    },
    methods: {
        toPage(url, external = false) {
            if (external) window.open(url, "_blank");
            else this.$router.push(url);
        },
        logout() {
            this.$cookies.remove("user_token");
            store.state.user.token = "";
            this.$router.push("/");
        },
        openReportBug() {
            this.popupAlertBug.show = true;
            this.popupAlertBug.confirm = true;
            this.popupAlertBug.cancel = true;
        },
        emitConfirm() {
            this.popupAlert.show = false;
            this.popupAlert.confirm = false;
            this.popupAlert.cancel = false;
        },
        alertEmitCancel() {
            this.popupAlertBug.show = false;
        },
        alertEmitConfirm(file, subject, description) {
            this.popupAlertBug.show = false;
            this.popupAlert.show = true;
            this.popupAlert.confirm = false;
            this.popupAlert.cancel = false;
            this.popupAlert.subTitle = "Sending...";
            this.popupAlert.title = "Bug Report";

            const formData = new FormData();
            formData.append("id_user", store.state.user.id);
            formData.append("id_customer", store.state.customer.id);
            formData.append("subject", subject);
            formData.append("description", description);
            formData.append("url", window.location.href);
            if (file.length > 0) formData.append("file", file[0]);
            http.post("/api/report-bug", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
                .then((r) => {
                    if (r.data.success) {
                        this.popupAlert.subTitle =
                            "Bug report sent successfully!";
                    } else {
                        this.popupAlert.subTitle = `Error sending bug report: ${r.data.message}`;
                    }
                    this.popupAlert.confirm = true;
                })
                .catch((r) => {
                    this.popupAlert.subTitle = "Error sending bug report!";
                    this.popupAlert.confirm = true;
                    debugger;
                });
        },
        getUserData() {
            http.get("/api/user")
                .then((r) => {
                    if (r.data.companies) {
                        if (r.data.type == "ilustraviz") {
                            r.data.companies = [
                                {
                                    id: -1,
                                    name: "Ilustraviz",
                                },
                                ...r.data.companies,
                            ];
                        }
                        this.companies = r.data.companies;
                        this.$emit("getCompanies", r.data.companies);
                    }
                })
                .catch((r) => {
                    debugger;
                });
        },
        getTermVersioData() {
            http.get("/api/term-of-use/version")
                .then((r) => {
                    if (r.data.version) {
                        this.popupTermsUse.version = r.data.version;
                        if (r.data.version != store.state.user.termofuse) {
                            this.popupTermsUse.show = true;
                        }
                    }
                })
                .catch((r) => {
                    debugger;
                });
        },
        sendAcceptTermUse() {
            this.popupTermsUse.show = false;
            http.post("/user/terms/update", {
                version: this.popupTermsUse.version,
            })
                .then((r) => {
                    if (r.data.success) {
                        store.state.user.termofuse = this.popupTermsUse.version;
                    }
                })
                .catch((r) => {
                    debugger;
                });
        },
        getNameCompanies() {
            const names = [];
            this.companies.forEach((company) => names.push(company.name));
            return names;
        },
        getSelectedCompany() {
            let index = 0;
            this.companies.forEach((company, i) => {
                if (company.name === this.company) {
                    index = i;
                }
            });
            console.log(index);
            return index;
        },
        changeCompany(index) {
            localStorage.setItem("customer", this.companies[index].id);
            if (this.companies[index].id == -1) {
                window.location = "/admin";
            } else {
                window.location = "/dashboard";
            }
        },
        async setupAccess() {
            const slugs = [];
            Object.keys(this.permissions).forEach((key) => {
                slugs.push(key);
            });

            const access = await this.$userAccess.to(slugs);
            Object.keys(this.permissions).forEach((key) => {
                this.permissions[key] = access[key];
            });
        },
        toggleTermUse(value) {
            this.popupTermsUse.confirm = value;
        },
        imageExists(image_url) {
            const http = new XMLHttpRequest();

            http.open("HEAD", image_url, false);
            http.send();

            return http.status != 404 && http.status != 403;
        },
        initials(name) {
            const nameSplit = name.split(" ");
            let initials = "";
            if (nameSplit.length > 1) {
                initials = nameSplit[0].charAt(0) + nameSplit[1].charAt(0);
            } else {
                initials = nameSplit[0].charAt(0);
            }
            return initials;
        },
    },
    mounted() {
        this.getUserData();
        this.getTermVersioData();
        const name = store.state.user.name.split(" ");
        this.name = name[0];
        if (name.length > 1) {
            this.name += ` ${name[name.length - 1]}`;
        }
        this.company = store.state.customer.name;
        if (store.state.user.photo) {
            this.photo = `${process.env.VUE_APP_AWS_BUCKET_IMAGES}${store.state.user.photo}`;
        } else {
            this.photo = `${process.env.VUE_APP_AWS_BUCKET_IMAGES}/users/customers/${store.state.customer.id}.png`;
            if (!this.imageExists(this.photo)) this.photo = false;
        }
    },
    created() {
        this.setupAccess();
    },
};
</script>

<style lang="scss" scoped>
* {
    margin: 0;
    padding: 0;
    text-decoration: none;
    list-style: none;
}
.menu {
    display: flex;
    height: 100vh;
    background: #272a30;
    flex-direction: column;
    justify-content: space-between;
    padding: 5px;
    padding-top: 20px;
    position: sticky;
    top: 0;
    width: 180px;
    max-width: 180px;
    min-width: 180px;
    z-index: 10;
    &_top {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &_bottom {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &_list {
        margin-top: 10px;
        &_item {
            margin-bottom: 10px;
        }
    }
}
.photo {
    width: 72px;
    height: 72px;
    border-radius: 50%;
    overflow: hidden;
    border: 3px solid #3d424e;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }

    &_name {
        width: 100%;
        height: 100%;
        background: #7d35ff;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        font-weight: bold;
        color: #fff;
    }
}

.user {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid #3d424e;

    &_name {
        font-size: 18px;
        font-weight: bold;
        color: #fff;
        margin-top: 16px;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        white-space: normal;
        text-overflow: ellipsis;
        line-break: anywhere;
    }
    &_company {
        font-size: 12px;
        color: #95949b;
        margin-top: 4px;
        margin-bottom: 20px;
    }
}
.select_company {
    padding-top: 5px;
    padding-bottom: 10px;
}

.termsPopup {
    margin-top: 20px;
    .row {
        display: flex;
        align-items: center;
        gap: 20px;
    }
    gap: 10px;
    font-family: Fira Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: #ffffff;

    a {
        color: #0092ff;
    }
}
</style>
