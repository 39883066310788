<template>
    <button class="button" :class="active ? 'active' : ''">
        <p
            class="button_text font-button"
            :class="{ previneOverflowText: previneOverflowText }"
        >
            {{ text }}
        </p>
    </button>
</template>

<script>
export default {
  props: ['text', 'active', 'previneOverflowText'],
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
};
</script>

<style lang="scss" scoped>
* {
    margin: 0;
    padding: 0;
    text-decoration: none;
    list-style: none;
}
.button {
    padding: 10px 13px;
    background: none;
    display: flex;
    align-items: center;
    border: none;
    border-radius: 5px;
    transition: 0.5s ease;
    cursor: pointer;
    min-width: 160px;

    &_image {
        margin-right: 10px;
        width: 20px;
        height: 20px;
        opacity: 0.5;
        transition: 0.5s ease;
        img {
            width: 100%;
            height: 100%;
        }
    }
    &_text {
        color: #95949b;
        transition: 0.5s ease;
    }

    &:hover {
        background: #0264d8;
        .button {
            &_image {
                opacity: 1;
            }
            &_text {
                color: #fff;
            }
        }
    }

    @at-root {
        .active #{&} {
            &_image {
                opacity: 1;
            }
            &_text {
                color: #fff;
            }
        }
        .active {
            background: #0264d8;
        }
    }
}
.previneOverflowText {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-align: left;
    white-space: normal;
    text-overflow: ellipsis;
    line-break: anywhere;
}
</style>
