<template>
    <div class="inputContainer">
        <input
            :type="type ? type : 'text'"
            class="inputText"
            :class="{ hasIcon: icon }"
            :value="modelValue"
            @input="updateValue"
            :placeholder="placeholder"
        />
        <button class="inputIcon" v-if="icon" @click="buttonEvent">
            <div class="inputIcon_icon">
                <img :src="require('@/assets' + icon)" alt="" />
            </div>
        </button>
    </div>
</template>

<script>
export default {
  props: ['icon', 'modelValue', 'placeholder', 'type'],
  data() {
    return {};
  },
  methods: {
    updateValue(event) {
      this.$emit('update:modelValue', event.target.value);
    },
    buttonEvent() {
      this.$emit('submit', event.target.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.inputText {
    padding: 7px 10px;
    background: #3d424e;
    border: 1px solid #666972;
    border-radius: 50px;
    color: #fff;
    width: 100%;
    font-size: 12px;
    font-family: "Fira Sans";
    font-style: normal;
    font-weight: 300;
    &::placeholder {
        color: #adadad;
    }
}
.hasIcon {
    border-radius: 50px 0px 0px 50px;
    border-right: none;
}
.inputIcon {
    border: 1px solid #666972;
    background: #3d424e;
    border-radius: 0px 50px 50px 0px;
    border-left: none;
    padding: 2px;
    max-height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;

    &_icon {
        width: 26px;
        height: 26px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        padding-right: 5px;
        padding-left: 5px;
        transition: 0.5s ease;
        opacity: 0.5;

        &:hover {
            opacity: 1;
            background: #0092ff;
        }
    }
}
.inputContainer {
    width: 100%;
    display: flex;
    align-items: stretch;
}
</style>
