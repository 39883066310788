function downloadFile(path, fileName) {
	fetch(path)
		.then((response) => response.blob())
		.then((blob) => {
			// Crie um URL temporário para o blob
			const url = window.URL.createObjectURL(blob);

			// Crie um link com o atributo "download"
			const link = document.createElement('a');
			link.href = url;
			link.download = fileName;

			// Adicione o link ao corpo da página
			document.body.appendChild(link);

			// Clique no link para fazer o download
			link.click();

			// Remova o link e o URL temporário da página depois do download
			document.body.removeChild(link);
			window.URL.revokeObjectURL(url);
		})
		.catch((error) => console.error(error));
}

export default downloadFile;
