<template>
    <div class="graphicBarHorizontal">
        <ul>
            <li
                class="graphicBarHorizontal_bar"
                v-for="(value, i) in values"
                :key="'graphicBarHorizontal_' + i"
            >
                <span
                    :class="'barColor-' + colors[i]"
                    :style="{ width: value + '%' }"
                ></span>
            </li>
        </ul>
        <ul class="graphicBarHorizontal_percents">
            <li>0</li>
            <li>20</li>
            <li>40</li>
            <li>60</li>
            <li>80</li>
            <li>100</li>
        </ul>
    </div>
</template>

<script>
export default {
  props: ['values'],
  data() {
    return {
      colors: ['pink', 'blue', 'orange'],
    };
  },
};
</script>

<style lang="scss" scoped>
* {
    margin: 0;
    padding: 0;
    text-decoration: none;
    list-style: none;
}
.graphicBarHorizontal {
    width: 100%;
    &_bar {
        width: 100%;
        height: 18px;
        background: #272a30;
        margin-bottom: 10px;
        border-radius: 3px;
        position: relative;
        &:last-child {
            margin-bottom: 0;
        }
        span {
            height: 100%;
            border-radius: 5px;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            transition: 1s ease;
        }
        .barColor {
            &-pink {
                background: #e10f58;
            }
            &-blue {
                background: #0264d8;
            }
            &-orange {
                background: #e5531a;
            }
        }
    }
    &_percents {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        font-size: 12px;
        color: #95949b;
    }
}
</style>
