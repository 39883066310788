<template>
    <button
        class="button"
        :class="{
            active: active,
            inverted: inverted,
            textCenter: (noicon || !text) && !align,
            smallspace: smallspace,
            notext: !text,
            hasHover: !disabled,
            [type]: type,
        }"
        :style="{
            background: background ? background : '',
            borderRadius: borderRadius ? borderRadius : '',
            height: height ? height : '',
        }"
    >
        <p class="button_flag" v-if="flag">{{ flag }}</p>
        <div class="button_content">
            <div class="button_image" :class="{ notext: !text }" v-if="!noicon">
                <img :src="require('@/assets' + icon)" alt="" />
            </div>
            <p
                class="button_text font-button"
                :class="{ previneOverflowText: previneOverflowText }"
                :style="{
                    color: color ? color : '',
                }"
                v-if="text"
            >
                {{ text }}
            </p>
        </div>
    </button>
</template>

<script>
export default {
  props: [
    'text',
    'icon',
    'active',
    'disabled',
    'inverted',
    'noicon',
    'smallspace',
    'background',
    'align',
    'flag',
    'previneOverflowText',
    'type',
    'borderRadius',
    'color',
    'height',
  ],
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
};
</script>

<style lang="scss" scoped>
* {
    margin: 0;
    padding: 0;
    text-decoration: none;
    list-style: none;
}
.button {
    background: none;
    display: flex;
    align-items: stretch;
    border: none;
    border-radius: 5px;
    transition: 0.5s ease;
    cursor: default;

    min-width: 160px;

    &.danger {
        background: #ed1802;
        .button {
            &_text {
                color: #fff;
            }
        }

        &-border {
            border: 3px solid #ed1802;
            .button {
                &_text {
                    color: #fff;
                }
            }
            &:hover {
                .button {
                    &_text {
                        color: #fff;
                    }
                }
            }
        }
    }

    &_content {
        padding: 10px 13px;
        display: flex;
        align-items: center;
    }

    &_image {
        margin-right: 10px;
        width: 20px;
        height: 20px;
        opacity: 0.5;
        transition: 0.5s ease;
        img {
            width: 100%;
            height: 100%;
        }
    }
    &_text {
        color: #95949b;
        transition: 0.5s ease;
        white-space: nowrap;
    }

    &_flag {
        font-size: 13px;
        font-weight: bold;
        color: #fff;
        padding: 5px 10px;
        background: #7d35ff;
        display: flex;
        align-items: center;
        border-radius: 5px 0 0 5px;
    }

    &.notext {
        .button_image {
            margin: 0;
        }
    }

    @at-root {
        .active #{&} {
            &_image {
                opacity: 1;
            }
            &_text {
                color: #fff;
            }
        }
        .active {
            background: #0264d8 !important;
        }
    }
    &.inverted {
        .button_image {
            order: 1;
            margin-right: 0;
            margin-left: 20px;
        }
        .button_text {
            order: 0;
        }
    }

    .previneOverflowText {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-align: left;
        white-space: normal;
        text-overflow: ellipsis;
        line-break: anywhere;
    }
}
.hasHover {
    cursor: pointer;
    &:hover {
        background: #0264d8 !important;
        .button {
            &_image {
                opacity: 1;
            }
            &_text {
                color: #fff;
            }
        }
    }
}

.textCenter {
    justify-content: center;
}
.smallspace {
    padding: 5px 10px;
    .button {
        &_image {
            margin-right: 5px;
        }
        &_content {
            padding: 0;
        }
    }
    &.inverted {
        .button_image {
            margin-left: 5px;
        }
    }
}
</style>
