function loadingController() {

	let total = 0;
	let loaded = 0;
	let status = 'ended';

	function start(qty) {
		if (status === 'ended') {
			total = qty;
			status = 'loading';
		}
		else {
			total += qty;
		}
	}

	function reset() {
		total = 0;
		loaded = 0;
		status = 'ended';
	}

	function add() {

		total++;
	}

	function completeTask() {
		loaded++;
		if (loaded === total) {
			status = 'ended';
			loaded = 0;
			total = 0;
		}
	}

	function getProgress() {
		if (status == 'ended')
			return 100;
		return Math.round(loaded / total * 100);
	}

	function getStatus() {
		return status;
	}

	return {
		start: start,
		add: add,
		completeTask: completeTask,
		getProgress: getProgress,
		getStatus: getStatus,
		reset: reset
	}
}

export default loadingController;
