<template>
    <div class="app">
        <Menu v-if="idCustomer > 0" />
        <MenuAdmin v-if="idCustomer == -1" />
        <Container>
            <Column>
                <Block class="block-all">
                    <!-- Photo -->
                    <LoadingElement v-if="loadInfo" />
                    <div
                        class="productScreen_mainPhoto"
                        v-if="!loadInfo && image"
                    >

                        <img
                            :src="formatImage()"
                            alt=""
                        />
                    </div>
                    <div
                        class="
                                    productScreen_mainPhoto
                                    productScreen_dragDrop
                                "
                        v-if="!loadInfo && !image"
                    >
                        <p class="text">
                            Upload product images - Required*
                        </p>
                        <p class="text-small">Drag and Drop</p>
                        <div>
                            <img :src="
                                            require('@/assets/imgs/icons/tabler_drag-drop.svg')
                                        " />
                        </div>
                    </div>
                    <input
                        v-if="!loadInfo && !image"
                        type="file"
                        accept="image/*"
                        multiple
                        class="inputAll"
                        @change="eventUploadFile($event)"
                    />
                    <!-- End Photo -->
                </Block>
            </Column>
            <Column class="column-data">
                <Block title="Template Info">
                    <LoadingElement v-if="loadInfo && customers.length" />
                    <div
                        class="row"
                        v-if="!loadInfo && customers.length"
                    >
                        <p class="simpleSectionnText">Name:</p>
                        <InputText v-model="name" />
                        <p class="simpleSectionnText">Set As:</p>
                        <Dropdown
                            :list="['public', 'private']"
                            :modelValue="public ? 0 : 1"
                            @update="updatePublic"
                        />
                        <div
                            class="row"
                            v-if="public == 0"
                        >

                            <p class="simpleSectionnText">Client:</p>
                            <DropdownFilter
                                :list="getNamesCustomers()"
                                :modelValue="customer_index"
                                :background="'#3d424e'"
                                @update="updateCustomer"
                                class="dropdownFilter"
                            />
                        </div>
                    </div>
                </Block>
                <Block title="Notes">
                    <LoadingElement v-if="loadInfo" />
                    <InputTextArea
                        v-model="note"
                        v-if="!loadInfo"
                    />
                </Block>
                <Block title="Ambient Type*">
                    <p
                        class="simpleSectionnText"
                        v-if="ambients.length === 0"
                    >
                        No ambients selected
                    </p>
                    <LoadingElement v-if="loadInfo || loadAmbient" />
                    <ul class="listTag listTag-edit">
                        <li
                            v-for="(ambient) in getMyAmbients()"
                            :key="'tagprod_' + ambient.id"
                        >
                            <Button
                                :text="ambient.name"
                                icon="/imgs/icons/trash.svg"
                                :inverted="true"
                                :smallspace="true"
                                @click="removeAmbient(ambient)"
                            />
                        </li>
                    </ul>
                    <div class="
                                        productScreen_productinfo_item_header_title
                                        divCategories row
                                    ">
                        <p>Select Tags</p>
                        <div class="row">
                            <p class="simpleSectionnText">
                                Add Custom Tag:
                            </p>

                            <InputText
                                icon="/imgs/icons/plus.svg"
                                v-model="inputAmbient"
                                @submit="submitInputAmbient"
                                @keyup.enter="submitInputAmbient"
                            />
                        </div>
                    </div>
                    <LoadingElement v-if="loadAmbient" />
                    <ul
                        class="listTag"
                        v-if="!loadAmbient"
                    >
                        <li
                            v-for="(ambient) in filteredAmbients"
                            :key="'tagadd_' + ambient.id"
                        >
                            <Button
                                :text="ambient.name"
                                icon="/imgs/icons/plus.svg"
                                :inverted="true"
                                :smallspace="true"
                                @click="addAmbient(ambient)"
                            />
                        </li>
                    </ul>
                </Block>
                <Block title="Ambient Tags*">
                    <p
                        class="simpleSectionnText"
                        v-if="tags.length === 0"
                    >
                        No tags selected
                    </p>
                    <LoadingElement v-if="loadInfo || loadTag" />
                    <ul
                        class="listTag listTag-edit"
                        v-if="!loadInfo && !loadTag"
                    >
                        <li
                            v-for="(tag, i) in getMyTags()"
                            :key="'tagprod_' + tag.id"
                        >
                            <Button
                                :text="tag.name"
                                icon="/imgs/icons/trash.svg"
                                :inverted="true"
                                :smallspace="true"
                                @click="removeTag(i)"
                            />
                        </li>
                    </ul>
                    <div class="
                                        productScreen_productinfo_item_header_title
                                        divCategories row
                                    ">
                        <p>Select Tags</p>
                        <div class="row">
                            <p class="simpleSectionnText">
                                Add Custom Tag:
                            </p>

                            <InputText
                                icon="/imgs/icons/plus.svg"
                                v-model="inputTag"
                                @submit="submitInputTag"
                                @keyup.enter="submitInputTag"
                            />
                        </div>
                    </div>
                    <LoadingElement v-if="loadTag" />
                    <ul
                        class="listTag"
                        v-if="!loadTag"
                    >
                        <li
                            v-for="(tag) in filteredTags"
                            :key="'tagadd_' + tag.id"
                        >
                            <Button
                                :text="tag.name"
                                icon="/imgs/icons/plus.svg"
                                :inverted="true"
                                :smallspace="true"
                                @click="addTag(tag)"
                            />
                        </li>
                    </ul>
                </Block>
                <ButtonBorderText
                    class="buttonSave"
                    @click="buttonSave()"
                    noicon="true"
                    text="Save"
                />
            </Column>
        </Container>
    </div>
    <PopupAlert
        v-if="popupAlert.show"
        :title="popupAlert.title"
        :subTitle="popupAlert.subTitle"
        :confirm="popupAlert.confirm"
        :cancel="popupAlert.cancel"
        @confirm="emitConfirm"
        @cancel="emitCancel"
    />
</template>

<script>
import Menu from "@/views/common/Menu.vue";
import MenuAdmin from "@/views/common/Menu_Admin.vue";
import Container from "@/components/sections/Container.vue";
import Column from "@/components/sections/Column.vue";
import Block from "@/components/sections/Block.vue";
import Button from "@/components/buttons/Button-icon-text.vue";
import ButtonIcon from "@/components/buttons/Button-icon.vue";
import ButtonPhoto from "@/components/listItem/Square-photo-description-event.vue";
import ButtonBorderIcon from "@/components/buttons/Button-icon-border.vue";
import InputText from "@/components/inputs/InputText.vue";
import InputTextArea from "@/components/inputs/InputTextarea.vue";
import Dropdown from "@/components/dropdowns/Dropdown-rounded.vue";
import http from "@/http";
import store from "@/store";
import LoadingElement from "@/components/LoadingElement.vue";
import PopupAlert from "@/views/common/PopupAlert.vue";
import ButtonBorderText from "@/components/buttons/Button-text-border.vue";
import DropdownFilter from "@/components/dropdowns/Dropdown.vue";

export default {
    components: {
        Menu,
        Container,
        Column,
        Block,
        Button,
        ButtonIcon,
        ButtonPhoto,
        ButtonBorderIcon,
        InputText,
        InputTextArea,
        Dropdown,
        LoadingElement,
        PopupAlert,
        ButtonBorderText,
        DropdownFilter,
        MenuAdmin,
    },
    data() {
        return {
            text: "",
            inputTag: "",
            inputAmbient: "",
            id: 0,
            loadInfo: true,
            loadTag: true,
            loadAmbient: true,
            idCustomer: 0,
            customer_index: 0,
            customers: [],
            ambients: [],
            image: "",
            name: "",
            note: "",
            public: false,
            tags: [],
            catalog: {
                ambients: [],
                tags: [],
            },
            original: {
                ambients: [],
                tags: [],
            },
            popupAlert: {
                show: false,
                title: "New Service",
                subTitle: "Creating a new service...",
                confirm: false,
                cancel: false,
                type: "loading",
            },
            saveStatus: {
                info: false,
                tags: false,
                ambients: false,
                eeror: "",
            },
        };
    },
    methods: {
        async getData() {
            let query = "?id=" + this.id;
            if (this.idCustomer > -1) {
                query += "&id_customer=" + this.idCustomer;
            }
            http.get("/template/get" + query)
                .then((res) => {
                    let template = res.data.template;
                    this.image = template.image_name;
                    this.name = template.name;
                    this.note = template.note;
                    this.public = template.public === 1;
                    this.loadInfo = false;
                    template.tags.forEach((tag) => {
                        this.original.tags.push(tag.id);
                    });
                    template.ambients.forEach((ambient) => {
                        this.original.ambients.push(ambient.id);
                    });
                    template.tags.forEach((tag) => {
                        this.tags.push(tag.id);
                    });
                    template.ambients.forEach((ambient) => {
                        this.ambients.push(ambient.id);
                    });
                })
                .catch((err) => {
                    debugger;
                    console.log(err);
                });
        },
        async getTags() {
            http.get("/template/tag/get_all")
                .then((res) => {
                    this.catalog.tags = res.data.tags;
                    this.loadTag = false;
                })
                .catch((err) => {
                    debugger;
                    console.log(err);
                });
        },
        async getAmbients() {
            http.get("/template/ambient/get_all")
                .then((res) => {
                    this.catalog.ambients = res.data.ambients;
                    this.loadAmbient = false;
                })
                .catch((err) => {
                    debugger;
                    console.log(err);
                });
        },
        async createNewTag() {
            this.popupAlert.show = true;
            this.popupAlert.type = "create";
            this.popupAlert.title = "New Tag";
            this.popupAlert.subTitle = "Creating a new tag...";
            this.popupAlert.confirm = false;
            this.popupAlert.cancel = false;
            let data = {
                name: this.inputTag,
            };
            http.post("/template/tag/add", data)
                .then((res) => {
                    this.popupAlert.subTitle = "Tag created successfully!";
                    this.popupAlert.confirm = true;
                    this.popupAlert.cancel = false;
                    let tag = res.data.tag;
                    this.catalog.tags.push(tag);
                    this.inputTag = "";
                    this.addTag(tag);
                })
                .catch((err) => {
                    debugger;
                    this.popupAlert.subTitle =
                        "Error creating tag: " + err.message;
                    this.popupAlert.confirm = true;
                    this.popupAlert.cancel = false;
                    console.log(err);
                });
        },
        async createNewAmbient() {
            this.popupAlert.show = true;
            this.popupAlert.type = "create";
            this.popupAlert.title = "New Ambient";
            this.popupAlert.subTitle = "Creating a new ambient...";
            this.popupAlert.confirm = false;
            this.popupAlert.cancel = false;
            let data = {
                name: this.inputAmbient,
            };
            http.post("/template/ambient/add", data)
                .then((res) => {
                    this.popupAlert.subTitle = "Ambient created successfully!";
                    this.popupAlert.confirm = true;
                    this.popupAlert.cancel = false;
                    let ambient = res.data.ambient;
                    this.catalog.ambients.push(ambient);
                    this.inputAmbient = "";
                    this.addAmbient(ambient);
                })
                .catch((err) => {
                    debugger;
                    this.popupAlert.subTitle =
                        "Error creating ambient: " + err.message;
                    this.popupAlert.confirm = true;
                    this.popupAlert.cancel = false;
                    console.log(err);
                });
        },
        async getCustomers() {
            http.get("/gets/customers/all/show")
                .then((res) => {
                    this.customers = res.data.customers;
                    this.customer_index = this.customers.findIndex(
                        (customer) => customer.id === this.idCustomer
                    );
                    if (this.customer_index === -1) {
                        this.customer_index = 0;
                    }
                })
                .catch((err) => {
                    debugger;
                    console.log(err);
                });
        },
        getNamesCustomers() {
            let names = [];
            for (let i = 0; i < this.customers.length; i++) {
                names.push(this.customers[i].company);
            }
            return names;
        },
        buttonSave() {
            this.popupAlert.show = true;
            this.popupAlert.type = "loading";
            this.popupAlert.title = "Saving Template";
            this.popupAlert.subTitle = "Saving template...";
            this.popupAlert.confirm = false;
            this.popupAlert.cancel = false;
            this.saveStatus.info = false;
            this.saveStatus.tags = false;
            this.saveStatus.ambients = false;
            this.saveStatus.error = "";
            this.saveInfo();
            this.loopPushTags();
            this.loopPushAmbients();
        },
        checkAndRemovePopupUpdate() {
            if (
                this.saveStatus.info &&
                this.saveStatus.tags &&
                this.saveStatus.ambients
            ) {
                this.popupAlert.show = true;
                this.popupAlert.confirm = true;
                this.popupAlert.cancel = false;
                if (!this.saveStatus.error) {
                    this.popupAlert.title = "Template Saved";
                    this.popupAlert.subTitle = "Template saved successfully!";
                    this.popupAlert.type = "success";
                } else {
                    this.popupAlert.title = "Error Saving Template";
                    this.popupAlert.subTitle = this.saveStatus.error;
                    this.popupAlert.type = "error";
                }
            }
        },
        async loopPushTags() {
            let tags = this.getNewTags();
            console.log(tags, this.tags, this.original.tags);
            debugger;
            for (let i = 0; i < tags.length; i++) {
                await this.pushNewTag(tags[i]);
            }
            this.loopDeleteTags();
        },
        async loopPushAmbients() {
            let ambients = this.getNewAmbients();
            for (let i = 0; i < ambients.length; i++) {
                await this.pushNewAmbient(ambients[i]);
            }
            this.loopDeleteAmbients();
        },
        async loopDeleteTags() {
            let tags = this.getDeletedTags();
            for (let i = 0; i < tags.length; i++) {
                await this.pushDeletedTag(tags[i]);
            }
            this.saveStatus.tags = true;
            this.checkAndRemovePopupUpdate();
        },
        async loopDeleteAmbients() {
            let ambients = this.getDeletedAmbients();
            for (let i = 0; i < ambients.length; i++) {
                await this.pushDeletedAmbient(ambients[i]);
            }
            this.saveStatus.ambients = true;
            this.checkAndRemovePopupUpdate();
        },
        async saveInfo() {
            let data = {
                id: this.id,
                name: this.name,
                note: this.note,
                public: this.public ? 1 : 0,
            };
            if (this.customer_index > -1) {
                data.id_customer = this.customers[this.customer_index].id;
            }
            http.post("/template/edit", data)
                .then((res) => {
                    console.log(res);
                })
                .catch((err) => {
                    debugger;
                    console.log(err);
                    this.saveStatus.error = err.message;
                })
                .finally(() => {
                    this.saveStatus.info = true;
                    this.checkAndRemovePopupUpdate();
                });
        },
        async pushNewTag(id) {
            let data = {
                id_template: this.id,
                id_tag: id,
            };
            http.post("/template/add_tag", data)
                .then((res) => {
                    console.log(res);
                })
                .catch((err) => {
                    debugger;
                    console.log(err);
                    this.saveStatus.error = err.message;
                });
        },
        async pushNewAmbient(id) {
            let data = {
                id_template: this.id,
                id_ambient: id,
            };
            http.post("/template/add_ambient", data)
                .then((res) => {
                    console.log(res);
                })
                .catch((err) => {
                    debugger;
                    console.log(err);
                    this.saveStatus.error = err.message;
                });
        },
        async pushDeletedTag(id) {
            let data = {
                id_template: this.id,
                id_tag: id,
            };
            let str_param = Object.keys(data)
                .map((key) => key + "=" + data[key])
                .join("&");
            http.delete(`/template/remove_tag?${str_param}`, data)
                .then((res) => {
                    console.log(res);
                })
                .catch((err) => {
                    debugger;
                    console.log(err);
                    this.saveStatus.error = err.message;
                });
        },
        async pushDeletedAmbient(id) {
            let data = {
                id_template: this.id,
                id_ambient: id,
            };
            //key=value&key2=value2
            let str_param = Object.keys(data)
                .map((key) => key + "=" + data[key])
                .join("&");
            http.delete(`/template/remove_ambient?${str_param}`)
                .then((res) => {
                    console.log(res);
                })
                .catch((err) => {
                    debugger;
                    console.log(err);
                    this.saveStatus.error = err.message;
                });
        },
        getNewTags() {
            let tags = [];
            for (let i = 0; i < this.tags.length; i++) {
                let filtTag = this.original.tags.find(
                    (tag) => tag === this.tags[i]
                );
                if (!filtTag) {
                    tags.push(this.tags[i]);
                }
            }
            return tags;
        },
        getNewAmbients() {
            let ambients = [];
            for (let i = 0; i < this.ambients.length; i++) {
                let filtAmbient = this.original.ambients.find(
                    (ambient) => ambient === this.ambients[i]
                );
                if (!filtAmbient) {
                    ambients.push(this.ambients[i]);
                }
            }
            return ambients;
        },
        getDeletedTags() {
            let tags = [];
            for (let i = 0; i < this.original.tags.length; i++) {
                let filtTag = this.tags.find(
                    (tag) => tag === this.original.tags[i]
                );
                if (!filtTag) {
                    tags.push(this.original.tags[i]);
                }
            }
            return tags;
        },
        getDeletedAmbients() {
            let ambients = [];
            for (let i = 0; i < this.original.ambients.length; i++) {
                let filtAmbient = this.ambients.find(
                    (ambient) => ambient === this.original.ambients[i]
                );
                if (!filtAmbient) {
                    ambients.push(this.original.ambients[i]);
                }
            }
            return ambients;
        },
        addTag(tag) {
            this.tags.push(tag.id);
        },
        addAmbient(ambient) {
            this.ambients.push(ambient.id);
        },
        submitInputTag() {
            let tags = this.filterTags();
            if (tags.length > 0) {
                this.addTag(tags[0]);
            } else {
                this.createNewTag();
            }
        },
        submitInputAmbient() {
            let ambients = this.filterAmbients();
            if (ambients.length > 0) {
                this.addAmbient(ambients[0]);
            } else {
                this.createNewAmbient();
            }
        },
        removeTag(index) {
            this.tags.splice(index, 1);
        },
        removeAmbient(index) {
            this.ambients.splice(index, 1);
        },
        formatImage() {
            //replace .jpg or another extension to _thumb.jpg
            let file = this.image;
            return `${process.env.VUE_APP_AWS_BUCKET_IMAGES}${file}`;
        },
        eventUploadFile(event) {
            console.log(event);
        },
        getMyTags() {
            let tags = [];
            for (let i = 0; i < this.tags.length; i++) {
                let filtTag = this.catalog.tags.find(
                    (tag) => tag.id === this.tags[i]
                );
                if (filtTag) {
                    tags.push(filtTag);
                }
            }
            return tags;
        },
        getMyAmbients() {
            let ambients = [];
            for (let i = 0; i < this.ambients.length; i++) {
                let filtAmbient = this.catalog.ambients.find(
                    (ambient) => ambient.id === this.ambients[i]
                );
                if (filtAmbient) {
                    ambients.push(filtAmbient);
                }
            }
            return ambients;
        },
        getTagsNotSelected() {
            let tags = [];
            for (let i = 0; i < this.catalog.tags.length; i++) {
                let filtTag = this.tags.find(
                    (tag) => tag === this.catalog.tags[i].id
                );
                if (!filtTag) {
                    tags.push(this.catalog.tags[i]);
                }
            }
            return tags;
        },
        getAmbientsNotSelected() {
            let ambients = [];
            for (let i = 0; i < this.catalog.ambients.length; i++) {
                let filtAmbient = this.ambients.find(
                    (ambient) => ambient === this.catalog.ambients[i].id
                );
                if (!filtAmbient) {
                    ambients.push(this.catalog.ambients[i]);
                }
            }
            return ambients;
        },
        filterTags() {
            let tags = this.getTagsNotSelected();
            let input = this.inputTag.toLowerCase().trim();
            return tags.filter((tag) => {
                return tag.name.toLowerCase().includes(input);
            });
        },
        filterAmbients() {
            let ambients = this.getAmbientsNotSelected();
            let input = this.inputAmbient.toLowerCase().trim();
            return ambients.filter((ambient) => {
                return ambient.name.toLowerCase().includes(input);
            });
        },
        emitConfirm() {
            this.popupAlert.show = false;
        },
        updatePublic(index) {
            this.public = index === 0;
        },
    },
    computed: {
        filteredTags() {
            return this.filterTags();
        },
        filteredAmbients() {
            return this.filterAmbients();
        },
    },
    created() {
        //get id from url
        this.id = this.$route.params.id;
        this.idCustomer = store.state.customer.id;
        this.getData();
        this.getTags();
        this.getAmbients();
        this.getCustomers();
    },
};
</script>

<style lang="scss" scoped>
* {
    box-sizing: border-box;
}

.app {
    display: flex;
    height: 100vh;
}
.row {
    display: flex;
    align-items: center;
    gap: 10px;
}

.column {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
    > * {
        flex: 1;
    }

    .buttonSave {
        flex: initial;
    }
}

.block {
    &:last-child {
        margin-bottom: 0;
    }
}

.productScreen_mainPhoto {
    flex: 1;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 100%;
    margin-bottom: 0px;
    img {
        max-width: 100%;
        max-height: 100%;
    }
    align-items: stretch;
}

.block-all {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    height: 100%;
    min-height: 1px;

    > * {
        &:last-child {
            flex: 1;
            display: flex;
            height: 100%;
            min-height: 1px;
        }
    }
    .block_content {
        background: red;
    }
}

.simpleSectionnText {
    white-space: nowrap;
}

.productScreen_productinfo_item_header_title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .row {
        margin-bottom: 0px;
    }
}

.column-buttons {
    max-width: 200px;
}

.column-data {
    max-width: 710px;

    > * {
        &:first-child {
            flex: inherit;
        }
    }
}

.contentLoad {
    width: 100%;
}
.dropdownFilter {
    &:deep {
        .simpleDropdown {
            &_header {
                border-radius: 50px;
                border: 1px solid #666972;
                &:hover {
                    background: #3d424e !important;
                }
            }
            &_text {
                color: #ffffff;
            }
        }
    }
}
</style>
