import http from '@/http';
import store from '@/store';

const userAccess = {
	_access: {},
	_getAccess: async function (slugs) {
		if (store.state.customer.id === -1 && store.state.user.type === 'ilustraviz') {
			slugs.forEach(slug => {
				this._access[slug] = true;
			});
			return;
		}
		try {
			const response = await http.get(`user/have_access?id_customer=${store.state.customer.id}&slugs=${slugs.join(',')}`);
			Object.keys(response.data.data).forEach((key) => {
				this._access[key] = response.data.data[key];
			});
		} catch (error) {
			console.log(error);
		}
	},
	to: async function (slugs) {
		let result = {};
		let toSearch = [];
		if (typeof slugs === 'string') {
			slugs = [slugs];
		}
		slugs.forEach((slug) => {
			if (typeof this._access[slug] === 'undefined') {
				toSearch.push(slug);
			} else {
				result[slug] = this._access[slug];
			}
		});
		if (toSearch.length > 0) {
			await this._getAccess(toSearch);
			toSearch.forEach((slug) => {
				result[slug] = this._access[slug];
			});
		}
		return result;
	}
};

export default userAccess;
