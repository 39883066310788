<template>
    <div>
        <div class="userBlock">
            <div class="userBlock_photo">
                <img :src="getPhoto()" v-if="newData.photoData || photo" />
                <div v-else class="photo_name">
                    <p>{{ initials(name) }}</p>
                </div>
                <div class="userBlock_photo_edit" v-if="edit && thisUser">
                    <p class="font-button">Change</p>
                    <input
                        type="file"
                        accept="image/png, image/jpeg"
                        @change="changePhoto"
                    />
                </div>
            </div>
            <div class="userBlock_info">
                <div class="row">
                    <div class="userBlock_info_group">
                        <p class="userBlock_info_label font-field">User Name</p>
                        <p
                            class="userBlock_info_value font-common"
                            v-if="!edit || !thisUser"
                        >
                            {{ name }}
                        </p>
                        <Input
                            v-else
                            type="text"
                            placeholder="User Name"
                            v-model="newData.name"
                        />
                    </div>
                    <div class="userBlock_info_edit">
                        <Button
                            v-if="admin || thisUser"
                            text="Edit User"
                            :noicon="true"
                            background="#3d424e"
                            :active="edit"
                            @click="toggleEdit()"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="userBlock_info_group">
                        <p class="userBlock_info_label font-field">Email</p>
                        <p class="userBlock_info_value font-common">
                            {{ email }}
                        </p>
                    </div>
                    <div class="userBlock_info_edit"></div>
                </div>
                <div class="row">
                    <div class="userBlock_info_group">
                        <p class="userBlock_info_label font-field">Phone</p>
                        <p
                            class="userBlock_info_value font-common"
                            v-if="!edit || !thisUser"
                        >
                            {{ phone }}
                        </p>
                        <Input
                            v-else
                            type="tel"
                            placeholder="Phone"
                            v-model="newData.phone"
                        />
                    </div>
                    <div class="userBlock_info_edit"></div>
                </div>
                <div class="row" v-if="edit">
                    <div class="userBlock_info_group">
                        <p class="userBlock_info_label font-field">Password</p>
                        <p class="userBlock_info_value font-common">
                            ****************
                        </p>
                    </div>
                    <div class="userBlock_info_edit">
                        <Button
                            text="Reset Password"
                            :noicon="true"
                            background="#3d424e"
                            class="button"
                            @click="resetPaasword()"
                        />
                    </div>
                </div>
                <div class="row" v-if="admin && roles">
                    <div class="userBlock_info_group">
                        <p class="userBlock_info_label font-field">Role</p>
                        <p class="userBlock_info_value font-common">
                            {{ !edit ? getuserRoleName() : "Change Role" }}
                        </p>
                    </div>
                    <div class="userBlock_info_edit" v-if="edit">
                        <Dropdown
                            :list="formatRolesDropdown()"
                            :modelValue="getIndexRoleById(getRoleIdUser())"
                            background="#3d424e"
                            class="dropdown"
                            textAlign="center"
                            @update="setRoleUser"
                        />
                    </div>
                </div>
                <div class="row" v-if="admin && edit">
                    <div class="userBlock_info_group">
                        <Button
                            v-if="userEnabled"
                            text="Disable Account"
                            :noicon="true"
                            :type="'danger-border'"
                            class="button"
                            @click="disableAccount()"
                        />
                        <Button
                            v-else
                            text="Enable Account"
                            :noicon="true"
                            background="none"
                            class="button"
                            @click="enableAccount()"
                        />
                    </div>
                    <div class="userBlock_info_edit">
                        <Button
                            text="Delete Account"
                            :noicon="true"
                            :type="'danger'"
                            class="button"
                            @click="deleteAccount()"
                        />
                    </div>
                </div>
                <Button
                    text="Save"
                    :noicon="true"
                    class="button-save"
                    background="#1e2127"
                    borderRadius="0px 0px 5px 5px"
                    color="#ffffff"
                    height="50px"
                    v-if="edit"
                    @click="save()"
                />
            </div>
        </div>
        <PopupAlert
            v-if="popupAlert.show"
            :title="popupAlert.title"
            :subTitle="popupAlert.subTitle"
            :confirm="popupAlert.confirm"
            :cancel="popupAlert.cancel"
            @confirm="alertEmitConfirm"
            @cancel="alertEmitCancel"
            :error="popupAlert.type === 'error'"
        />
    </div>
</template>

<script>
import Button from '@/components/buttons/Button-icon-text.vue';
import Input from '@/components/inputs/InputText.vue';
import Dropdown from '@/components/dropdowns/Dropdown.vue';
import http from '../../http';
import PopupAlert from '@/views/common/PopupAlert.vue';

export default {
  components: {
    Button,
    Input,
    Dropdown,
    PopupAlert,
  },
  props: [
    'name',
    'email',
    'photo',
    'phone',
    'role',
    'admin',
    'thisUser',
    'roles',
    'id_customer',
    'id',
    'userEnabled',
  ],
  data() {
    return {
      edit: false,
      newData: {
        name: '',
        phone: '',
        role: '',
        photoData: null,
        photoFile: null,
      },
      popupAlert: {
        show: false,
        title: '',
        subTitle: '',
        confirm: false,
        cancel: false,
        type: '',
      },
    };
  },
  methods: {
    getPhoto() {
      if (!this.edit || !this.newData.photoData) {
        if (this.photo.includes('data')) return this.photo;
        return `${process.env.VUE_APP_AWS_BUCKET_IMAGES}${this.photo}`;
      }
      return this.newData.photoData;
    },
    toggleEdit() {
      this.newData.name = this.name;
      this.newData.phone = this.phone;
      this.newData.role = this.role;
      this.newData.photoData = null;
      this.newData.photoFile = null;
      this.edit = !this.edit;
    },
    updateName(value) {
      this.newData.name = value;
    },
    formatRolesDropdown() {
      if (!this.roles) return [];
      const result = [];
      this.roles.forEach((role) => {
        result.push(role.name);
      });
      return result;
    },
    getIndexRoleById(id) {
      if (!this.roles) return -1;
      let result = -1;
      this.roles.forEach((role, index) => {
        if (role.id === id) result = index;
      });
      return result;
    },
    getRoleIdUser() {
      if (!this.role || this.role == -1) return -1;
      if (!this.newData.role) return this.role.id;
      return this.newData.role.id;
    },
    setRoleUser(value) {
      this.newData.role = this.roles[value];
    },
    getuserRoleName() {
      if (!this.roles) return '';
      if (!this.role) return '';
      return this.role.name;
    },
    changePhoto(event) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        this.newData.photoData = e.target.result;
        this.newData.photoFile = file;
      };
      reader.readAsDataURL(file);
    },
    async save() {
      console.log('saving');
      let results;
      this.popupAlert.show = true;
      this.popupAlert.title = 'Save changes';
      this.popupAlert.subTitle = 'Saving informations';
      this.popupAlert.confirm = false;
      this.popupAlert.cancel = false;
      await this.saveInfo();
      this.popupAlert.subTitle = 'Saving photo';
      results = await this.savePhoto();
      if (!results.success) {
        this.popupAlert.show = true;
        this.popupAlert.title = 'Error';
        this.popupAlert.subTitle = results.message;
        this.popupAlert.confirm = true;
        this.popupAlert.cancel = false;
        this.popupAlert.type = 'error';
        return;
      }
      this.popupAlert.subTitle = 'Saving role';
      await this.saveRole();
      console.log('end save');
      this.popupAlert.confirm = true;
      this.edit = false;
      this.popupAlert.subTitle = 'Informations saved';
    },
    async saveInfo() {
      if (!this.thisUser) return;
      await http
        .put('user/edit', {
          name: this.newData.name,
          phone: this.newData.phone,
        })
        .then((r) => {
          this.$emit('updateMyUser', {
            name: this.newData.name,
            phone: this.newData.phone,
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async savePhoto() {
      const result = {
        success: true,
        message: '',
      };
      if (!this.thisUser) return result;
      if (!this.newData.photoFile) return result;
      const formData = new FormData();
      formData.append('photo', this.newData.photoFile);
      await http
        .post('user/photo', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((r) => {
          result.success = true;
          this.$emit('updateMyUserPhoto', this.newData.photoData);
        })
        .catch((e) => {
          debugger;
          this.newData.photoFile = null;
          this.newData.photoData = null;
          result.success = false;
          if (e.response.data.errors.photo) result.message = e.response.data.errors.photo[0];
          else result.message = e.response.data.message;
          console.log(e);
        });
      return result;
    },
    async saveRole() {
      if (!this.admin) return;
      await http
        .put('user/role', {
          id_role: this.newData.role.id,
          id_customer: this.id_customer,
          id_user: this.id,
        })
        .then((r) => {
          this.$emit('updateUserRole', {
            role: this.newData.role,
            id: this.id,
          });
        })
        .catch((e) => {
          debugger;
          console.log(e);
        });
    },
    async resetPaasword() {
      this.popupAlert.show = true;
      this.popupAlert.title = 'Reset password';
      this.popupAlert.subTitle = 'Requesting password reset';
      this.popupAlert.confirm = false;
      this.popupAlert.cancel = false;
      this.popupAlert.type = '';
      await http
        .post(`/api/auth/forgot-password?email=${this.email}`)
        .then((r) => {})
        .catch((e) => {
          console.error(e);
        });
      this.popupAlert.confirm = true;
      if (this.thisUser) this.popupAlert.subTitle = 'Check your email for password reset link';
      else this.popupAlert.subTitle = 'Password reset link sent to user email';
    },
    disableAccount() {
      this.$emit('disableUser', this.id);
    },
    enableAccount() {
      this.$emit('enableUser', this.id);
    },
    deleteAccount() {
      this.$emit('deleteUser', this.id);
    },
    alertEmitConfirm() {
      this.popupAlert.show = false;
      this.popupAlert.title = '';
      this.popupAlert.subTitle = '';
      this.popupAlert.confirm = false;
      this.popupAlert.cancel = false;
      this.popupAlert.type = '';
    },
    initials(name) {
      const nameSplit = name.split(' ');
      let initials = '';
      if (nameSplit.length > 1) {
        initials = nameSplit[0].charAt(0) + nameSplit[1].charAt(0);
      } else {
        initials = nameSplit[0].charAt(0);
      }
      return initials;
    },
  },
};
</script>

<style lang="scss" scoped>
.userBlock {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    &_photo {
        width: 72px;
        height: 72px;
        border-radius: 50%;
        overflow: hidden;
        border: 3px solid #3d424e;
        margin-right: 20px;
        position: relative;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        &_edit {
            position: absolute;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background: rgba(#3d424e, 0.5);
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            input {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
                cursor: pointer;
            }
            p {
                color: #ffffff;
            }
        }
    }
    &_info {
        flex: 1;
        &_group {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            flex: 1;

            & + & {
                margin-top: 10px;
            }
        }
        &_label {
            color: #ffffff;
        }
        &_value {
            color: #ffffff;
        }
        .row {
            display: flex;
            align-items: center;
            gap: 20px;

            & + .row {
                margin-top: 10px;
            }
        }
        &_edit {
            min-width: 170px;
        }
    }
    &_buttons {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
.dropdown,
.button {
    width: 170px;

    &-save {
        margin-top: 20px;
        width: 100%;
    }
}

.photo_name {
    width: 100%;
    height: 100%;
    background: #7d35ff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-weight: bold;
    color: #fff;
}
</style>
