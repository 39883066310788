<template>
    <div class="app">
        <Menu active="product catalog" />
        <div class="app_content">
            <div class="menuCatalogo">
                <div
                    class="menuCatalogo_group"
                    v-if="
                        permissions['reader-info-access'] ||
                        permissions['editor-info-access']
                    "
                >
                    <h2 class="menuCatalogo_title">Products</h2>
                    <Button
                        :text="'All Products'"
                        @click="selectAllProducts()"
                        :active="allProductsSelected"
                        style="margin-top: 10px"
                    />
                    <ul class="menuCatalogo_itens">
                        <li
                            class="menuCatalogo_item"
                            :class="{ 'menuCatalogo_item-open': item.open }"
                            v-for="(item, i) in filterCategories('product')"
                            :key="item.id"
                        >
                            <div
                                class="menuCatalogo_item_header"
                                @click="toogleCategory(item)"
                            >
                                <p class="menuCatalogo_item_header_title">
                                    {{ item.name }}
                                </p>
                                <div class="menuCatalogo_item_header_icon">
                                    <img
                                        :src="
                                            require('@/assets/imgs/icons/chevron-down.svg')
                                        "
                                        alt=""
                                    />
                                </div>
                            </div>
                            <div class="menuCatalogo_item_content">
                                <ul>
                                    <li
                                        class="menuCatalogo_item_content_item"
                                        v-for="(subitem, j) in item.itens"
                                        :key="'input_' + subitem.id"
                                    >
                                        <Button
                                            :text="subitem.name"
                                            @click="toogleItem(item, j)"
                                            :active="subitem.selected"
                                        />
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>

            </div>
            <div class="content">
                <div class="menu_superior"></div>
                <div class="appInternal">
                    <div class="catalog">
                        <div class="catalog_topMenu">
                            <div class="catalog_filters">
                                <Input
                                    icon="/imgs/icons/search.svg"
                                    :placeholder="placeholders.name"
                                    v-model="filters.name"
                                />
                                <Input
                                    icon="/imgs/icons/search.svg"
                                    :placeholder="placeholders.id"
                                    v-model="filters.id"
                                />
                                <Input
                                    icon="/imgs/icons/search.svg"
                                    placeholder="Collection"
                                    v-if="
                                        placeholders.typeSelected == 'product'
                                    "
                                    v-model="filters.collection"
                                />
                                <DropdownCalendar @update="updateCalendarValue" />
                                <Dropdown
                                    :list="[
                                        'Private',
                                        'Public',
                                        'Public & Private',
                                    ]"
                                    :modelValue="filters.public"
                                    v-if="placeholders.typeSelected == 'scene'"
                                    @update="updatePublicTemplate"
                                />
                                <p
                                    class="font-field"
                                    v-if="
                                        placeholders.typeSelected == 'product'
                                    "
                                >
                                    Automation:
                                </p>
                                <Dropdown
                                    :list="['All', 'Sended', 'Not Sended']"
                                    :modelValue="filters.automation"
                                    v-if="
                                        placeholders.typeSelected == 'product'
                                    "
                                    @update="updateAutomationFilter"
                                />
                            </div>
                            <div class="catalog_scale">
                                <p class="font-field">Thumb Size:</p>
                                <ButtonIcon
                                    icon="/imgs/icons/thumbnail_size-small.svg"
                                    padding="0px"
                                    class="button_scale"
                                    @click="size = 'small'"
                                    :active="size == 'small'"
                                />
                                <ButtonIcon
                                    icon="/imgs/icons/thumbnail_size-medium.svg"
                                    padding="0px"
                                    class="button_scale"
                                    @click="size = 'medium'"
                                    :active="size == 'medium'"
                                />
                                <ButtonIcon
                                    icon="/imgs/icons/thumbnail_size-big.svg"
                                    padding="0px"
                                    class="button_scale"
                                    @click="size = 'big'"
                                    :active="size == 'big'"
                                />
                            </div>
                        </div>
                        <ul
                            class="catalog_list"
                            :style="{
                                gridTemplateColumns: get_size_grid(),
                            }"
                        >
                            <li
                                v-for="prod in filteredProducts"
                                :key="'prod_' + prod.id"
                                :style="{}"
                            >
                                <Item
                                    :photo="
                                        prod.type == 'scene'
                                            ? prod.image.replace(
                                                  '.png',
                                                  '_thumb.jpg'
                                              )
                                            : prod.image
                                    "
                                    :infos="[prod.name, '#' + prod.id]"
                                    :active="prod.selected"
                                    :hasAction="true"
                                    :actionEmit="true"
                                    background="#ffffff"
                                    @action="openZoomImage(prod.image)"
                                    @click="
                                        prod.type == 'product'
                                            ? ''
                                            : openZoomImage(prod.image)
                                    "
                                    :link="
                                        prod.type == 'product'
                                            ? `/product/${prod.id}`
                                            : false
                                    "
                                    :fullAction="prod.type == 'scene'"
                                    :lazyload="true"
                                    :flag="
                                        prod.automation_system == 1
                                            ? 'A'
                                            : false
                                    "
                                    flagColor="#7d35ff"
                                    :style="{
                                        width: '100%',
                                        height: '100%',
                                    }"
                                />
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ZoomImage
        v-if="zoomImage.show"
        :url="zoomImage.image"
        @close="closeZoomImage"
    />
    <PopupAlert
        v-if="popupAlert.show"
        :title="popupAlert.title"
        :subTitle="popupAlert.subTitle"
        :confirm="popupAlert.confirm"
        @confirm="emitConfirm"
    />
</template>

<script>
import axios from "axios";
import Menu from "./common/Menu.vue";
import PopupAlert from "./common/PopupAlert.vue";
import Button from "@/components/buttons/Button-text.vue";
import ButtonIcon from "@/components/buttons/Button-icon.vue";
import Input from "@/components/inputs/Input-icon-line.vue";
import Dropdown from "@/components/dropdowns/Dropdown.vue";
import Item from "@/components/listItem/Square-photo-description-event.vue";
import ItemCustom from "@/components/listItem/Square-photo-description-custom-event.vue";
import ButtonBorder from "@/components/buttons/Button-text-border.vue";
import DropdownCalendar from "@/components/dropdowns/Dropdown-calendar.vue";
import http from "@/http";
import store from "@/store";
import sleep from "@/utils/sleep";
import ZoomImage from "@/components/popups/ZoomImage.vue";
import loadingController from "@/utils/loadingController";

const lController = new loadingController();

export default {
    components: {
        Menu,
        Button,
        Input,
        Dropdown,
        Item,
        ButtonBorder,
        DropdownCalendar,
        PopupAlert,
        ZoomImage,
        ButtonIcon,
        ItemCustom,
    },
    data() {
        return {
            categories: [],
            allProductsSelected: true,
            idClient: 1,
            placeholders: {
                typeSelected: "product",
                idSubSelected: 0,
                name: "SKU",
                id: "ID Model",
            },
            size: "small",
            products: [],
            filters: {
                name: "",
                id: "",
                public: 2,
                automation: 0,
                collection: "",
                startDate: {
                    day: 1,
                    month: 1,
                    year: 2019,
                },
                endDate: {
                    day: 1,
                    month: 1,
                    year: 2019,
                },
            },
            popupAlert: {
                show: false,
                title: "New Service",
                subTitle: "Creating a new service...",
                confirm: false,
            },
            zoomImage: {
                show: false,
                image: "",
            },
            permissions: {
                "reader-info-access": false,
                "editor-info-access": false,
                "reader-template-access": false,
            },
        };
    },
    methods: {
        updateCalendarValue(value) {
            this.filters.startDate.day = value[0].day;
            this.filters.startDate.month = value[0].month;
            this.filters.startDate.year = value[0].year;
            this.filters.endDate.day = value[1].day;
            this.filters.endDate.month = value[1].month;
            this.filters.endDate.year = value[1].year;
        },
        get_size_grid() {
            switch (this.size) {
                case "small":
                    return "repeat(auto-fill, 110px)";
                case "medium":
                    return "repeat(auto-fill, 172px)";
                case "big":
                    return "1fr 1fr 1fr";
                default:
                    return "repeat(auto-fill, 110px)";
            }
        },
        /* eslint-disable no-param-reassign */
        toogleCategory(item) {
            this.categories.forEach((cat, i) => {
                if (item !== cat) cat.open = false;
            });
            item.open = !item.open;
        },
        toogleItem(item, subindex) {
            this.allProductsSelected = false;
            this.placeholders.typeSelected = item.type;
            this.placeholders.idSubSelected = item.itens[subindex].id;
            if (item.type === "scene") {
                this.placeholders.name = "Template Name";
                this.placeholders.id = "ID Template";
            } else {
                this.placeholders.name = "SKU";
                this.placeholders.id = "ID Model";
            }
            this.categories.forEach((cat, i) => {
                cat.itens.forEach((c, j) => {
                    if (item !== cat || j !== subindex) c.selected = false;
                    else c.selected = true;
                });
            });
        },
        selectAllProducts() {
            this.allProductsSelected = true;
            this.placeholders.typeSelected = "product";
            this.placeholders.name = "SKU";
            this.placeholders.id = "ID Model";
            this.categories.forEach((cat, i) => {
                cat.open = false;
                cat.itens.forEach((c, j) => {
                    c.selected = false;
                });
            });
        },
        selectProduct(event, prod) {
            if (
                prod.type === "product" &&
                !event.target.getAttribute("action")
            ) {
                this.$router.push(`/product/${prod.id}`);
                // window.location.href = `/product/${prod.id}`;
            }
        },
        /* eslint-enable no-param-reassign */
        updatePublicTemplate(value) {
            this.filters.public = value;
        },
        updateAutomationFilter(value) {
            this.filters.automation = value;
        },
        getDataTemplatesMenu() {
            http.get("/gets/roomsets/templates/categories/show")
                .then((r) => {
                    if (r.data.success) {
                        const i = this.categories.push({
                            name: "Templates",
                            id: 0,
                            open: false,
                            type: "scene",
                            itens: [
                                {
                                    name: "All",
                                    id: 0,
                                    selected: false,
                                    type: "scene",
                                },
                            ],
                        });
                        r.data.categories.forEach((cat) => {
                            this.categories[i - 1].itens.push({
                                name: cat.category,
                                id: cat.id,
                                type: "scene",
                                selected: false,
                            });
                        });
                    }
                    this.completeTaskLoad();
                    this.getDataCategories();
                })
                .catch((e) => {
                    console.log(e);
                });
        },
        getDataCategories() {
            http.get("/gets/product/categories/show")
                .then((r) => {
                    if (r.data.success) {
                        r.data.categories.forEach((cat) => {
                            const i = this.categories.push({
                                name: cat.category,
                                id: cat.id,
                                open: false,
                                type: "product",
                                itens: [
                                    {
                                        name: "All",
                                        id: 0,
                                        selected: false,
                                        type: "product",
                                    },
                                ],
                            });
                            cat.subcategories.forEach((subcat) => {
                                this.categories[i - 1].itens.push({
                                    name: subcat.subcategory,
                                    id: subcat.id,
                                    type: "product",
                                    selected: false,
                                });
                            });
                        });
                    }
                    this.completeTaskLoad();
                })
                .catch((e) => {
                    console.log(e);
                });
        },
        getDataTemplates() {
            http.get(
                `/gets/roomsets/templates/show?id_customer=${this.idClient}`
            )
                .then((r) => {
                    if (r.data.success) {
                        r.data.templates.forEach((temp) => {
                            this.products.push({
                                id: temp.id,
                                name: temp.name ? temp.name : "",
                                image: temp.image_thumb
                                    ? `${process.env.VUE_APP_AWS_BUCKET_IMAGES}${temp.image_thumb}`
                                    : "",
                                public: temp.public,
                                collection: temp.collection
                                    ? temp.collection
                                    : "",
                                type: "scene",
                                created_at: new Date(temp.created_at),
                                category: [],
                            });
                            const rand = Math.floor(Math.random() * 10);
                            for (let i = 0; i < rand; i += 1) {
                                this.products[
                                    this.products.length - 1
                                ].category.push(Math.floor(Math.random() * 50));
                            }
                        });
                    }
                    this.completeTaskLoad();
                })
                .catch((e) => {
                    console.log(e);
                    this.completeTaskLoad();
                });
        },
        getDataProducts() {
            http.get(`/gets/product/show?id_customer=${this.idClient}`)
                .then((r) => {
                    console.log(r.data);
                    if (r.data.success) {
                        r.data.products.forEach((prod) => {
                            this.products.push({
                                id: prod.id_product,
                                name: prod.sku ? prod.sku : "",
                                image: prod.images[0]
                                    ? `${process.env.VUE_APP_AWS_BUCKET_IMAGES}${prod.images[0]}`
                                    : "",
                                public: 0,
                                collection: prod.collection
                                    ? prod.collection
                                    : "",
                                type: "product",
                                created_at: new Date(prod.created_at),
                                category: [],
                                automation_system: prod.automation_system,
                            });
                            prod.subcategories.forEach((c) => {
                                if (c.trim())
                                    this.products[
                                        this.products.length - 1
                                    ].category.push(c.trim());
                            });
                        });
                    }
                    this.completeTaskLoad();
                })
                .catch((e) => {
                    console.log(e);
                    this.completeTaskLoad();
                });
        },
        /* eslint-disable no-param-reassign */
        submitService() {
            let templates = [];

            templates = this.products.filter((prod) => prod.selected);
            if (templates.length === 0) {
                this.popupAlert.show = true;
                this.popupAlert.title = "Error";
                this.popupAlert.subTitle =
                    "You must select at least one template";
                this.popupAlert.confirm = true;
                return;
            }
            this.popupAlert.title = "New Service";
            this.popupAlert.subTitle = "Creating a new service...";
            this.popupAlert.show = true;
            this.popupAlert.confirm = false;
            console.log(templates.map((temp) => temp.id).join(","));
            http.post("/posts/services/request", {
                id_customer: this.idClient,
                templates: templates.map((temp) => temp.id).join(","),
            })
                .then((r) => {
                    if (r.data.success) {
                        this.popupAlert.title = "Success";
                        this.popupAlert.subTitle =
                            "Service created successfully";
                        this.popupAlert.confirm = true;
                        templates.forEach((temp) => {
                            temp.selected = false;
                        });
                    } else {
                        this.popupAlert.title = "Error";
                        this.popupAlert.subTitle = r.data.message;
                        this.popupAlert.confirm = true;
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        },
        /* eslint-enable no-param-reassign */
        emitConfirm() {
            this.popupAlert.show = false;
            this.popupAlert.confirm = false;
        },
        async completeTaskLoad() {
            lController.completeTask();
            this.popupAlert.subTitle = `Please wait. completed ${lController.getProgress()}%`;
            if (lController.getStatus() === "ended") {
                await sleep(1000);
                this.popupAlert.show = false;
            }
        },
        filterCategories(type) {
            return this.categories.filter((c) => c.type === type);
        },
        openZoomImage(image) {
            this.zoomImage.image = image;
            this.zoomImage.show = true;
        },
        closeZoomImage() {
            this.zoomImage.image = "";
            this.zoomImage.show = false;
        },
        startAtSection() {
            // get param url
            const urlParams = new URLSearchParams(window.location.search);
            const section = urlParams.get("section");
            if (section == "allproducts") {
                this.selectAllProducts();
            }
        },
        hasValidUrlParam() {
            const urlParams = new URLSearchParams(window.location.search);
            const validParans = ["allproducts"];
            for (let i = 0; i < validParans.length; i += 1) {
                if (urlParams.get("section") == validParans[i]) {
                    return true;
                }
            }
            return false;
        },
        async setupAccess() {
            const slugs = [];
            Object.keys(this.permissions).forEach((key) => {
                slugs.push(key);
            });

            const access = await this.$userAccess.to(slugs);
            Object.keys(this.permissions).forEach((key) => {
                this.permissions[key] = access[key];
            });
            if (!this.permissions["reader-template-access"]) {
                this.selectAllProducts();
            }
            // if all access are false, redirect to dashboard
            if (!Object.values(this.permissions).includes(true)) {
                this.$router.push({ name: "Dashboard" });
            }
        },
    },
    computed: {
        filteredProducts() {
            let aux = [];
            let selected = [];
            const startDate = new Date(
                this.filters.startDate.year,
                this.filters.startDate.month - 1,
                this.filters.startDate.day
            );
            const nameLcase = this.filters.name.toLowerCase();
            const idLcase = this.filters.id.toLowerCase();
            const collectionLcase = this.filters.collection.toLowerCase();
            selected = this.products.filter((prod) => prod.selected);
            aux = this.products.filter((prod) => !prod.selected);
            aux = aux.filter((prod) =>
                prod.name.toLowerCase().includes(nameLcase)
            );
            aux = aux.filter((prod) => prod.id.toString().includes(idLcase));
            if (this.placeholders.idSubSelected > 0) {
                aux = aux.filter((p) =>
                    p.category.includes(
                        this.placeholders.idSubSelected.toString()
                    )
                );
            }
            if (this.filters.endDate.day !== null) {
                const endDate = new Date(
                    this.filters.endDate.year,
                    this.filters.endDate.month - 1,
                    this.filters.endDate.day + 1
                );
                aux = aux.filter(
                    (p) => p.created_at >= startDate && p.created_at <= endDate
                );
            } else {
                const endDate = new Date(
                    this.filters.startDate.year,
                    this.filters.startDate.month - 1,
                    this.filters.startDate.day,
                    23,
                    59,
                    59
                );
                aux = aux.filter(
                    (p) => p.created_at >= startDate && p.created_at <= endDate
                );
            }
            if (this.allProductsSelected) {
                aux = aux.filter((p) => p.type === "product");
                aux = aux.filter((prod) =>
                    prod.collection.toLowerCase().includes(collectionLcase)
                );
            } else if (this.placeholders.typeSelected === "scene") {
                aux = aux.filter((prod) => prod.type === "scene");
                if (this.filters.public !== 2)
                    aux = aux.filter((p) => p.public === this.filters.public);
            } else {
                aux = aux.filter((prod) => prod.type === "product");
                aux = aux.filter((prod) =>
                    prod.collection.toLowerCase().includes(collectionLcase)
                );
            }
            if (
                this.placeholders.typeSelected == "product" &&
                this.filters.automation !== 0
            ) {
                aux = aux.filter(
                    (p) =>
                        p.automation_system ===
                        (this.filters.automation == 1 ? 1 : 0)
                );
            }

            return selected.concat(aux).sort((a, b) => (a.id < b.id ? 1 : -1));
        },
        templatesSelected() {
            return this.products.filter((prod) => prod.selected);
        },
    },
    created() {
        this.setupAccess();
        this.popupAlert.title = "Loading Product";
        this.popupAlert.subTitle = "Please wait. completed 0%";
        this.popupAlert.show = true;
        this.idClient = store.state.customer.id;
        lController.start(3);
        this.getDataTemplatesMenu();
        this.getDataProducts();
    },
    mounted() {
        this.startAtSection();
    },
};
</script>

<style lang="scss" scoped>
* {
    margin: 0;
    padding: 0;
    text-decoration: none;
    list-style: none;
}
.app {
    display: flex;
    width: 100vw;
    height: 100vh;
    //overflow-x: hidden;
    &_content {
        background: #141517;
        //width: 100%;
        display: flex;
        align-items: stretch;
        width: calc(100% - 0px);
        overflow-x: hidden;
        .content {
            width: inherit;
            .appInternal {
                padding-top: 20px;
                padding-bottom: 45px;
                padding-left: 45px;
                padding-right: 45px;
                height: calc(100% - 85px);
                display: flex;
                justify-content: space-between;
            }
        }
    }
}
.menu_superior {
    height: 58px;
    min-height: 58px;
    background: #141517;
}
.menuCatalogo {
    background: #1e2127;
    padding: 35px 10px;
    width: fit-content;
    height: 100%;
    transition: 0.5s ease;

    &_group {
        & + & {
            margin-top: 20px;
        }
    }

    * {
        transition: 0.5s ease;
    }
    &_title {
        font-size: 15px;
        font-weight: bold;
        color: #fff;
        padding-bottom: 10px;
        border-bottom: 1px solid #3d424e;
        padding-left: 10px;
    }
    &_itens {
        margin-top: 10px;

        + .menuCatalogo_title {
            margin-top: 20px;
        }
    }
    &_item {
        margin-top: 5px;
        &_header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 9px;
            padding-right: 3px;
            cursor: pointer;
            &_title {
                font-size: 13px;
                color: #fff;
            }
            &_icon {
                width: 20px;
                height: 20px;
                margin-left: 10px;
                display: flex;
                align-items: center;
                position: relative;
                > img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
        .has-itens {
            &::before {
                content: "";
                left: -10px;
                background: #0264d8;
                min-width: 5px;
                height: 5px;
                position: absolute;
                border-radius: 50%;
            }
        }
        &_content {
            display: flex;
            flex-direction: column;
            padding: 0 5px;
            background: #272a30;
            border-radius: 0 0 4px 4px;
            ul {
                opacity: 0;
            }
            &_item {
                height: 0px;
                overflow: hidden;
                font-size: 13px;
                color: #fff;
                transition: 0.5s ease;
            }
        }

        &-open {
            background: #2e3238;
            border-radius: 4px;
            .menuCatalogo {
                &_item {
                    &_header {
                        &_icon {
                            > img {
                                transform: rotate(180deg);
                            }
                        }
                    }
                    &_content {
                        ul {
                            opacity: 1;
                        }
                        &_item {
                            height: 35px;
                            margin-top: 5px;
                            &:last-child {
                                margin-bottom: 5px;
                            }
                        }
                    }
                }
            }
        }

        &:last-child {
            margin-bottom: 5px;
        }
    }
}

.catalog {
    background: #1e2127;
    padding: 40px 26px;
    padding-bottom: 0;
    height: 100%;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-right: 30px;
    position: relative;
    overflow: hidden;
    &_filters {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        > * {
            margin-right: 20px;
            margin-bottom: 20px;
            &:last-child {
                margin-right: 0;
            }
        }

        .font-field {
            color: #fff;
        }
    }
    &_list {
        display: grid;
        grid-template-columns: repeat(auto-fill, 110px);
        grid-gap: 15px;
        justify-content: space-between;
        overflow-y: scroll;
        height: fit-content;
        padding-bottom: 26px;
        &::-webkit-scrollbar {
            display: none;
        }
        > li {
            aspect-ratio: 1 / 1;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &_topMenu {
        display: flex;
        justify-content: space-between;
    }

    &_scale {
        display: flex;
        align-items: center;
        p {
            color: #fff;
            margin-right: 10px;
        }
        > * {
            margin-right: 10px;
            &:last-child {
                margin-right: 0;
            }
        }
    }

    .simpleDropdown {
        border: 1px solid #3d424e;
        border-radius: 10px;
        //padding: 10px 15px;
    }
    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 47px;
        background: linear-gradient(
            180deg,
            rgba(30, 33, 39, 0) 0%,
            rgba(30, 33, 39, 0.8) 100%
        );
    }
}
.catalogTotal {
    &_title {
        font-size: 20px;
        font-weight: bold;
        color: #fff;
        margin-bottom: 15px;
    }
    &_content {
        background: #1e2127;
        border-radius: 20px;
    }
    &_lines {
        padding: 40px 32px;
        //border-bottom: 1px solid #3d424e;
    }
    &_line {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        &:last-child {
            margin-bottom: 0;
        }
        &_icon {
            width: 40px;
            height: 40px;
            background: #3d424e;
            border-radius: 50%;
            display: flex;
            padding: 8px;
            margin-right: 10px;
            > img {
                width: 100%;
                height: 100%;
            }
        }
        &_text {
            font-size: 15px;
            color: #95949b;
        }
    }
    .button {
        padding-top: 25px;
        padding-bottom: 25px;
        color: #505256;
        background: #1e2127;
        font-weight: bold;
        font-size: 15px;
        border-radius: 0 0 20px 20px;
        &:hover {
            background: #0264d8;
            color: #fff;
        }
    }
}

.button_scale {
    width: 30px;
    height: 30px;
}
</style>
