<template>
    <div>
        <div
            class="myViewport"
            @wheel="zoomImage($event)"
            @click="closeAction"
            close="true"
        >
            <img
                class="myContent"
                :src="url"
                alt="image"
                :style="`transform: scale(${zoomImageValue})
                        translate(${moveImageValue.x}px, ${moveImageValue.y}px)`"
                @mousemove="moveImage($event)"
                @mousedown="clickImage($event, true)"
                @mouseup="clickImage($event, false)"
                @mouseleave="clickImage($event, false)"
            />
        </div>
        <div class="actionZoom_controlers">
            <div
                class="actionZoom_controlers_close"
                @click="closeAction"
                close="true"
            >
                <img
                    :src="require('@/assets/imgs/icons/x.svg')"
                    alt=""
                    close="true"
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ["url"],
    data() {
        return {
            zoomImageValue: 1,
            moveImageValue: {
                x: 0,
                y: 0,
            },
            lastPointMouse: {
                x: 0,
                y: 0,
            },
            speedZoom: 0.1,
            enableDrag: false,
        };
    },
    methods: {
        moveImage(event) {
            if (this.enableDrag) {
                const smootness = this.zoomImageValue;
                this.moveImageValue.x +=
                    ((this.lastPointMouse.x - event.clientX) * -1) / smootness;
                this.moveImageValue.y +=
                    ((this.lastPointMouse.y - event.clientY) * -1) / smootness;
            }
            this.lastPointMouse.x = event.clientX;
            this.lastPointMouse.y = event.clientY;
        },
        clickImage(event, enable) {
            event.preventDefault();
            this.enableDrag = enable;
        },
        zoomImage(event) {
            if (event.deltaY > 0) {
                if (this.zoomImageValue - this.speedZoom > 0.1) {
                    this.zoomImageValue -= this.speedZoom;
                    this.moveImageValue.x += this.speedZoom * 2;
                    this.moveImageValue.y += this.speedZoom * 2;
                }
            } else {
                this.zoomImageValue += this.speedZoom;
                this.moveImageValue.x -= this.speedZoom * 2;
                this.moveImageValue.y -= this.speedZoom * 2;
            }
        },
        closeAction(event) {
            if (event.target.getAttribute("close") === "true") {
                this.zoomImageValue = 1;
                this.moveImageValue = {
                    x: 0,
                    y: 0,
                };
                this.$emit("close");
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.myViewport {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background: rgba($color: #141517, $alpha: 0.5);
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 15;
}

.myContent {
    position: relative;
    display: flex;
    align-items: center;
    z-index: 15;
    width: auto;
    height: 100%;
}
.actionZoom_controlers {
    &_close {
        position: fixed;
        top: 15px;
        right: 15px;
        cursor: pointer;
        z-index: 6;
        background: #3d424e;
        padding: 7px;
        border-radius: 50%;
        width: 34px;
        height: 34px;
        transition: 0.5s ease;
        z-index: 99;
        > img {
            width: 20px;
            height: 20px;
        }
        &:hover {
            background: #0264d8;
            box-shadow: 0px 7px 5.32px rgba(0, 109, 255, 0.06),
                0px 16px 17.87px rgba(0, 109, 255, 0.08),
                0px 8px 33.42px rgba(0, 109, 255, 0.12),
                0px 33px 80px rgba(0, 109, 255, 0.16);
        }
    }
}
</style>
