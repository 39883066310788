<template>
    <div class="inputLineIcon">
        <input type="text" :placeholder="placeholder" v-model="message" />
        <span>
            <img :src="require('@/assets' + icon)" alt="" />
        </span>
    </div>
</template>

<script>
import { computed } from 'vue';

export default {
  props: ['icon', 'placeholder', 'modelValue'],
  setup(props, { emit }) {
    const message = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value),
    });

    return {
      message,
    };
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
};
</script>

<style lang="scss" scoped>
* {
    margin: 0;
    padding: 0;
    text-decoration: none;
    list-style: none;
}
.inputLineIcon {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #2e3238;
    width: 100%;
    max-width: 180px;
    span {
        opacity: 0.5;
        transition: 0.5s ease;
        order: 0;
        img {
            width: 20px;
            height: 20px;
        }
    }
    input {
        background: none;
        border: none;
        //border-bottom: 1px solid #2e3238;
        padding: 10px;
        color: #fff;
        order: 1;
        width: 100%;
        &::placeholder {
            color: #95949b;
        }
        &:focus-visible {
            outline: none;
            ~ span {
                opacity: 1;
            }
        }
    }
}
</style>
