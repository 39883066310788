<template>
    <div class="term">
        <div class="WordSection1">
            <p
                class="MsoNormal"
                align="center"
                style="
                    margin-top: 0cm;
                    margin-right: 117.9pt;
                    margin-bottom: 22.9pt;
                    margin-left: 117.9pt;
                    text-align: center;
                    text-indent: 0cm;
                    line-height: 165%;
                "
            >
                <b
                    ><span lang="EN-US"
                        >General Terms and Conditions of Use and Privacy
                        Policy</span
                    ></b
                >
            </p>

            <p
                class="MsoNormal"
                style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 31.95pt;
                    margin-left: -0.25pt;
                "
            >
                <span lang="EN-US"
                    >These General Terms and Conditions for hiring and using the
                    services provided by this platform (hereinafter referred to
                    simply as &#8220;Terms of Use&#8221; or
                    &#8220;Instrument&#8221;) govern the provision of services,
                    as defined below, by <b>ILUSTRAVIZ PRODUCTIONS LTDA.</b>, a
                    limited liability company, headquartered in the City of
                    S&atilde;o Paulo, State of S&atilde;o Paulo, at Rua Pereira
                    Estefano, n&ordm; 114 Conjunto 908, Vila da Sa&uacute;de,
                    CEP 04144-070, registered before the CNPJ/ME under No
                    28.056.484/0001-00, with its articles of incorporation filed
                    with the Board of Trade of the State of S&atilde;o Paulo
                    (&#8220;JUCESP&#8221;) under NIRE 35235043159 (hereinafter
                    referred to as &#8220;<b>Illustraviz</b>&#8221; or
                    &#8220;<b>Contractor</b>&#8221;), to any individual or legal
                    entity (hereinafter referred to as
                    &#8220;<b>User</b>&#8221;).</span
                >
            </p>

            <h1
                style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.85pt;
                    margin-left: 0cm;
                    text-indent: 0cm;
                "
            >
                <span
                    lang="EN-US"
                    style="
                        font-size: 11pt;
                        line-height: 110%;
                        font-family: 'Calibri', sans-serif;
                        font-weight: normal;
                    "
                    >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span
                ><span lang="EN-US"
                    >I-&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; General
                    Provisions</span
                >
            </h1>

            <p class="MsoNormal" style="margin-left: -0.25pt">
                <span lang="EN-US"
                    >1.1 By registering, as well as using our website and
                    services and, therefore, <u>agree to our Terms of Use</u>,
                    Users consent to the collection, use and treatment of their
                    personal data, pursuant to the terms of the Privacy Policy
                    below. Users may revoke their consent at any time, by
                    expressly stating so in this sense, by contacting us through
                    the following e-mail contact@ilustraviz.com.</span
                >
            </p>

            <p class="MsoNormal" style="margin-left: -0.25pt">
                <span lang="EN-US"
                    >1.2 These Terms of Use may be amended at any time by
                    Ilustraviz, and Users must periodically consult them. The
                    version available on the website will always be the current
                    one and will indicate the date of the last change. The
                    continuity of access to the website represents the
                    Users&#8217; agreement with such changes.
                </span>
            </p>

            <p class="MsoNormal" style="margin-left: -0.25pt">
                <span lang="EN-US"
                    >1.3 Access to the website and contracting the services
                    available are expressly prohibited for minors under 18</span
                ><span lang="EN-US" style="color: red"> </span
                ><span lang="EN-US"
                    >years of age. The Contractor will not knowingly collect,
                    store or use personal data from persons under the age of 18.
                </span>
            </p>

            <p class="MsoNormal" style="margin-left: -0.25pt">
                <span lang="EN-US"
                    >1.4 If Users disagree with the provisions established in
                    this instrument, they are not authorized to continue
                    accessing the website and contracting the services made
                    available therein.</span
                >
            </p>

            <p class="MsoNormal" style="margin-left: -0.25pt">
                <span lang="EN-US"
                    >1.5 Ilustraviz reserves the right to refuse service
                    requests at its sole discretion and without the need for
                    justification.
                </span>
            </p>

            <p class="MsoNormal" style="margin-left: -0.25pt">
                <span lang="EN-US"
                    >1.6 Ilustraviz also reserves the right to delete access
                    accounts, refuse and/or interrupt ongoing service orders
                    when violations, fraud, illegal activities or conduct or
                    involving offensive, illegal, illicit or potentially
                    infringing content are identified.</span
                >
            </p>

            <p class="MsoNormal" style="margin-left: -0.25pt">
                <span lang="EN-US"
                    >1.7 Ilustraviz is entitled to promote, at any time and free
                    of any penalty, the unilateral and unmotivated termination
                    of this Agreement, or the discontinuation of the platform
                    and/or services, provided that prior notification is given,
                    with the exception of the rights and obligations established
                    prior to termination, which will be fully complied with by
                    the parties.
                </span>
            </p>

            <p
                class="MsoNormal"
                style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 31.95pt;
                    margin-left: -0.25pt;
                "
            >
                <span lang="EN-US"
                    >1.8 Any notifications and communications between Ilustraviz
                    and Users in relation to rights and obligations constituted
                    by them and regulated by this instrument will always be
                    recorded, either by email or by any other electronic means
                    suitable of such communications.
                </span>
            </p>

            <p
                class="MsoNormal"
                align="left"
                style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 22.95pt;
                    margin-left: 0cm;
                    text-align: left;
                    text-indent: 0cm;
                    line-height: 110%;
                "
            >
                <span
                    lang="EN-US"
                    style="
                        font-size: 11pt;
                        line-height: 110%;
                        font-family: 'Calibri', sans-serif;
                    "
                    >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span
                ><b
                    ><span lang="EN-US"
                        >II-&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Specific
                        Provisions
                    </span></b
                >
            </p>

            <h1
                style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.85pt;
                    margin-left: -0.25pt;
                "
            >
                <span lang="EN-US">2 - Purpose </span>
            </h1>

            <p
                class="MsoNormal"
                style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 30.9pt;
                    margin-left: -0.25pt;
                "
            >
                <span lang="EN-US"
                    >2.1 The purpose of this instrument is the provision of
                    services for the creation, processing, treatment and digital
                    rendering of images by Ilustraviz to Users through the use
                    of a digital platform owned by Ilustraviz.
                </span>
            </p>

            <h1
                style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.85pt;
                    margin-left: -0.25pt;
                "
            >
                <span lang="EN-US">3 -Term of Validity</span>
            </h1>

            <p class="MsoNormal" style="margin-left: -0.25pt">
                <span lang="EN-US"
                    >3.1 These contractual conditions are in force from the date
                    the User starts using the platform, by filling in the
                    registration data, defining the plan option to be contracted
                    and approving the respective payment.
                </span>
            </p>

            <p
                class="MsoNormal"
                style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 30.9pt;
                    margin-left: -0.25pt;
                "
            >
                <span lang="EN-US"
                    >3.2 Its term will depend on the conditions of the
                    contracted plan, ending on the same date as the plan ends
                    and the parties fulfill their respective obligations.</span
                >
            </p>

            <h1
                style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.85pt;
                    margin-left: -0.25pt;
                "
            >
                <span lang="EN-US"
                    >4 - Registration on the Platform, Plans and Step by Step
                </span>
            </h1>

            <p
                class="MsoNormal"
                style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 0cm;
                    margin-left: -0.25pt;
                "
            >
                <span lang="EN-US"
                    >4.1 For the correct use of Ilustraviz&#8217;s platform, it
                    is an essential condition that
                </span>
            </p>

            <p class="MsoNormal" style="margin-left: -0.25pt">
                <span lang="EN-US"
                    >Users: (i) have internet access and log-in the platform
                    using a computer/laptop (it is not feasible to use the
                    platform via <i>smartphone</i> or <i>tablets</i>); (ii)
                    register as Users and create a log-in account in the
                    platform, agreeing with these Terms of Use; (iii) have their
                    registration approved and access released by Ilustraviz upon
                    advance payment of the amount of the contracted plan.</span
                >
            </p>

            <p class="MsoNormal" style="margin-left: -0.25pt">
                <span lang="EN-US"
                    >4.2 Payments will be processed through the use of a
                    specific and specialized platform, called Stripe
                    (<i>www.stripe.com</i>).
                </span>
            </p>

            <p class="MsoNormal" style="margin-left: -0.25pt">
                <span lang="EN-US"
                    >4.3 The services are provided by Ilustraviz based on 3D
                    models, with adherence to pre-defined plans (quarterly,
                    semi-annual or annual) and authorized payments made in
                    advance, fully and exclusively via credit or debit card.
                </span>
            </p>

            <p class="MsoNormal" style="margin-left: -0.25pt">
                <span lang="EN-US"
                    >4.4 The plans differ in relation to the period in which the
                    User can use the services object of this instrument through
                    access to the platform, and the respective quantity of 3D
                    models finalized per month by Ilustraviz.
                </span>
            </p>

            <p class="MsoNormal" style="margin-left: -0.25pt">
                <span lang="EN-US"
                    >4.5 After registration, a simulator will be made available
                    to Users that will help and guide Users, enabling them to
                    choose the best plan in relation to their respective
                    demand.</span
                >
            </p>

            <p class="MsoNormal" style="margin-left: -0.25pt">
                <span lang="EN-US"
                    >4.6 Considering that a certain phase of the provision of
                    services is developed by humans, employees of the company,
                    the number of plans offered in each category for contracting
                    is limited, according to the availability of professionals
                    by Ilustraviz.</span
                >
            </p>

            <p class="MsoNormal" style="margin-left: -0.25pt">
                <span lang="EN-US"
                    >4.7 Upon confirmation of registration and payment, the User
                    will receive a communication informing about the release of
                    access to the platform.
                </span>
            </p>

            <p class="MsoNormal" style="margin-left: -0.25pt">
                <span lang="EN-US"
                    >4.8 When performing the first access, an automatically
                    generated temporary password will be provided, which must be
                    immediately changed by Users themselves for security
                    reasons.
                </span>
            </p>

            <p class="MsoNormal" style="margin-left: -0.25pt">
                <span lang="EN-US"
                    >4.9 Users must then upload the original files to be treated
                    and rendered (3D models), which must meet the specifications
                    that will be provided by the <i>project manager</i>, in case
                    the models sent do not conform thereof.
                </span>
            </p>

            <p class="MsoNormal" style="margin-left: -0.25pt">
                <span lang="EN-US"
                    >4.10 From then on, Users will receive a confirmation email
                    regarding the start of the services with the inclusion of
                    that specific project in the service order and estimated
                    deadline.
                </span>
            </p>

            <p class="MsoNormal" style="margin-left: -0.25pt">
                <span lang="EN-US"
                    >4.11 Users will always be able to check the progress of
                    their projects on the platform and check the deadlines for
                    completion and delivery.</span
                >
            </p>

            <p class="MsoNormal" style="margin-left: -0.25pt">
                <span lang="EN-US"
                    >4.12 After processing, Users will receive an email saying
                    that their 3D model is ready for review and, then, they will
                    be able to express approval using the elements indicated on
                    the platform itself. If desired, Users may request up to
                    three (3) changes per 3D model, free of charge. After the
                    third time, it will be counted as the use of a new 3D model,
                    according to the number of total models contracted by Users.
                </span>
            </p>

            <p class="MsoNormal" style="margin-left: -0.25pt">
                <span lang="EN-US"
                    >4.13 Any request to change the design can only be made and
                    fulfilled as long as it is made before the final approval of
                    the 3D model by Users.
                </span>
            </p>

            <p class="MsoNormal" style="margin-left: -0.25pt">
                <span lang="EN-US"
                    >4.14 After formal and final approval, Users will receive an
                    email containing a link directing directly to the page of
                    the approved 3D model, where all other generated content can
                    be accessed. If Users have sent a project with several
                    models, they will receive a link to the page containing the
                    entire catalog.</span
                >
            </p>

            <p
                class="MsoNormal"
                style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 30.9pt;
                    margin-left: -0.25pt;
                "
            >
                <span lang="EN-US"
                    >4.15 Upon receipt of the access link, the User is
                    authorized to carry out the <i>downloads</i> of finished
                    models directly from the platform.
                </span>
            </p>

            <h1
                style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.85pt;
                    margin-left: -0.25pt;
                "
            >
                <span lang="EN-US">5 - Ilustraviz&#8217; s Obligations</span>
            </h1>

            <p class="MsoNormal" style="margin-left: -0.25pt">
                <span lang="EN-US"
                    >5.1 Provide quality technical and operational
                    infrastructure, as well as ensure the proper functioning of
                    the system, in order to enable Users to access the platform,
                    pursuant to the terms established in this instrument. Any
                    errors and system unavailability will be corrected in the
                    shortest possible period of time.
                </span>
            </p>

            <p class="MsoNormal" style="margin-left: -0.25pt">
                <span lang="EN-US"
                    >5.2 Make any changes to 3D models that may be requested by
                    Users, respecting the established limits.
                </span>
            </p>

            <p class="MsoNormal" style="margin-left: -0.25pt">
                <span lang="EN-US"
                    >5.3 Provide technical support to Users, within the limits
                    of the provisions of this instrument, through the following
                    e-mail: contact@ilustraviz.com from Monday to Friday, from
                    9:00 am to 6:00 pm (GMT -3), with a deadline up to two (2)
                    business days to respond to Users&#8217; request.
                </span>
            </p>

            <p class="MsoNormal" style="margin-left: -0.25pt">
                <span lang="EN-US"
                    >5.4 Promote, within the limits of its technical and
                    operational capacity, continuous improvements that bring
                    benefits to Users in the use of the platform.
                </span>
            </p>

            <p
                class="MsoNormal"
                style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 30.9pt;
                    margin-left: -0.25pt;
                "
            >
                <span lang="EN-US"
                    >5.5 Ilustraviz is not responsible for losses and damages
                    resulting from the misuse of the services and/or the
                    platform by Users.
                </span>
            </p>

            <h1
                style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.85pt;
                    margin-left: -0.25pt;
                "
            >
                <span lang="EN-US">6 - Users&#8217; Obligations</span>
            </h1>

            <p class="MsoNormal" style="margin-left: -0.25pt">
                <span lang="EN-US"
                    >6.1 Pay the amounts related to the contracted plan.
                </span>
            </p>

            <p class="MsoNormal" style="margin-left: -0.25pt">
                <span lang="EN-US"
                    >6.2 Do not allow their platform access data to be shared or
                    used by third parties. Cases of breach of access to accounts
                    due to negligence regarding the security of registration
                    data committed by Users will be their sole
                    responsibility.</span
                >
            </p>

            <p
                class="MsoNormal"
                style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 30.9pt;
                    margin-left: -0.25pt;
                "
            >
                <span lang="EN-US"
                    >6.3 Provide truthful, accurate, current and complete
                    information about itself, whether an individual or legal
                    entity, as well as keep all registration data and any other
                    information provided to the Contractor up to date, being
                    responsible and exempting Ilustraviz for damages caused due
                    to the supply of false, untrue or inaccurate information.
                </span>
            </p>

            <h1
                style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.85pt;
                    margin-left: -0.25pt;
                "
            >
                <span lang="EN-US">7 - Price and Payment Method</span>
            </h1>

            <p class="MsoNormal" style="margin-left: -0.25pt">
                <span lang="EN-US"
                    >7.1 For the provision of the services described in this
                    instrument, Users will pay Ilustraviz the amount for
                    contracting a plan, which may be quarterly, semiannually or
                    annually, whose characteristics will be informed to Users at
                    the time of contracting.
                </span>
            </p>

            <p class="MsoNormal" style="margin-left: -0.25pt">
                <span lang="EN-US"
                    >7.2 The amounts related to the respective plans are
                    indicated with maximum visibility and clarity when accessing
                    the steps for contracting.</span
                >
            </p>

            <p class="MsoNormal" style="margin-left: -0.25pt">
                <span lang="EN-US"
                    >7.3 Authorization to charge for contracting a plan will be
                    given at the time of contracting, irrevocably and
                    irreversibly, therefore in advance of the final delivery of
                    the contracted service and refers to the amount charged for
                    using the platform in the respective plan period of validity
                    and the number of models registered by Users when choosing
                    the plan.
                </span>
            </p>

            <p class="MsoNormal" style="margin-left: -0.25pt">
                <span lang="EN-US"
                    >7.4 In the event of the choice to make payments in
                    installments by credit card, Users hereby authorize monthly
                    charges to be made on their invoice, corresponding to the
                    amounts due.
                </span>
            </p>

            <p class="MsoNormal" style="margin-left: -0.25pt">
                <span lang="EN-US"
                    >7.5 During the term of validity of a contracted plan, the
                    price contracted by the User will not change. However,
                    Ilustraviz reserves the right to change the values of the
                    services, for new hires, whenever necessary, and in
                    accordance with market rules.
                </span>
            </p>

            <p class="MsoNormal" style="margin-left: -0.25pt">
                <span lang="EN-US"
                    >7.6 The amount charged in installments, for collection
                    purposes, constitutes an indivisible whole, so that partial
                    payments and/or returns will not be accepted. Therefore, if
                    the payment for the contracted plan value for any reason is
                    not full, Users will be subject to the consequences of
                    default.
                </span>
            </p>

            <p class="MsoNormal" style="margin-left: -0.25pt">
                <span lang="EN-US"
                    >7.7 In the event of default, failure to pay the outstanding
                    amounts will result in a late payment fine of two percent
                    (2%) on the outstanding balance, plus late payment interest
                    of one percent (1%) per month, calculated
                    <i>pro rata die</i> until the date of actual payment,
                    without prejudice to the interruption of the provision of
                    services.
                </span>
            </p>

            <p class="MsoNormal" style="margin-left: -0.25pt">
                <span lang="EN-US"
                    >7.8 If, for any reason, there is an event of default by the
                    User is configured, Ilustraviz may block their access,
                    without prior notice, until payments are settled. The
                    continuation of Users&#8217; access permission in the event
                    of payment of the amounts in arrears will not be considered
                    novation, but an act of mere tolerance, not generating any
                    right to Users.</span
                >
            </p>

            <p class="MsoNormal" style="margin-left: -0.25pt">
                <span lang="EN-US"
                    >7.9 In the event of non-remedied default within a period of
                    up to ninety (90) days, Ilustraviz may definitively delete
                    the User's account, interrupting and canceling all work
                    orders in progress, without the right to any refunds to
                    Users.
                </span>
            </p>

            <p
                class="MsoNormal"
                style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 30.9pt;
                    margin-left: -0.25pt;
                "
            >
                <span lang="EN-US"
                    >7.10 If Ilustraviz does not definitively delete the User's
                    account, it will be possible to request reactivation, upon
                    payment of all outstanding amounts, including penalties,
                    through an express request sent to
                    contact@ilustraviz.com.</span
                >
            </p>

            <h1
                style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.85pt;
                    margin-left: -0.25pt;
                "
            >
                <span lang="EN-US">8 - Cancellation and Refund Policy</span>
            </h1>

            <p class="MsoNormal" style="margin-left: -0.25pt">
                <span lang="EN-US"
                    >8.1 Users may request eventual termination of this
                    agreement and/or cancellation of service order(s) not yet
                    initiated by sending an e-mail to contact@ilustraviz.com, in
                    which case the following rules apply:</span
                >
            </p>

            <p class="MsoNormal" style="margin-left: 35.9pt">
                <span lang="EN-US"
                    >8.1.1 Users may exercise their right of withdrawal in
                    relation to the contracting of a plan, pursuant to the terms
                    of article 49 of the Consumer Protection Code, within a
                    period of seven (7) days from the date of release of access
                    to the platform of Ilustraviz, which will take place after
                    payment approval, by sending an e-mail to
                    contact@ilustraviz.com, in which case the amounts
                    paid/authorized shall be fully refunded.
                </span>
            </p>

            <p class="MsoNormal" style="margin-left: 35.9pt">
                <span lang="EN-US"
                    >8.1.2 If Users choose to request the cancellation of any
                    service order, they can only do so provided that the
                    services related to that order have not been
                    initiated;</span
                >
            </p>

            <p class="MsoNormal" style="margin-left: 35.9pt">
                <span lang="EN-US"
                    >8.1.3 If Users choose to request the cancellation of the
                    contracting of any plan in progress, or even after the
                    period of 7 days indicated above in 8.1.1, they may do so in
                    relation to the number of 3D models contracted and whose
                    service have not been initiated and/or are in progress. In
                    this case, Ilustraviz will refund 75% of the amount of the
                    contracted plan calculated proportionally in relation to the
                    number of 3D models on which no services have yet been
                    requested.
                </span>
            </p>

            <p class="MsoNormal" style="margin-left: 35.9pt">
                <span lang="EN-US"
                    >8.1.4 A work order is considered initiated when there is a
                    change of its <i>status</i> to &#8220;Producing&#8221;.
                </span>
            </p>

            <p class="MsoNormal" style="margin-left: -0.25pt">
                <span lang="EN-US"
                    >8.2 Subject to the above, in case of cancellation by
                    sending a request by e-mail to contact@ilustraviz.com, all
                    Users accesses will be blocked and the files already
                    existing in the User's access account will be stored for a
                    maximum period of ninety (90) days. After this period, all
                    data will be permanently deleted with no possibility of
                    recovery.
                </span>
            </p>

            <p class="MsoNormal" style="margin-left: -0.25pt">
                <span lang="EN-US"
                    >8.3 Users may export copies of data and files already made
                    available in their access account within the period referred
                    to in the previous item. Copies will be provided in the
                    following formats (Vignette: jpg, png, tiff. Silo: jpg, png,
                    tiff. 360: jpg, png, tiff. Video: .mp4, .mov. Viewer 3D
                    Logo: png, jpg. Viewer 3D: glb. AR: fbx, .obj, blend,.glb,
                    .usdz), leaving Ilustraviz not obliged to import any data
                    into another system.
                </span>
            </p>

            <p
                class="MsoNormal"
                style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 30.9pt;
                    margin-left: -0.25pt;
                "
            >
                <span lang="EN-US"
                    >8.4 In case of cancellation due to default by Users, and
                    consequent blocking of the respective platform&#8217;s
                    access account, the files already made available will be
                    saved for ninety (90) days, counted from the communication
                    by email sent to the User so that, within that period, Users
                    settle the payment, or chooses to do so in the
                    &#8220;maintenance&#8221; format, intended only for the
                    storage of files. After this period, the files will be
                    permanently deleted from the system.
                </span>
            </p>

            <h1
                style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.85pt;
                    margin-left: -0.25pt;
                "
            >
                <span lang="EN-US">9 - Intellectual Property Rights</span>
            </h1>

            <p class="MsoNormal" style="margin-left: -0.25pt">
                <span lang="EN-US"
                    >9.1 The parties acknowledge that all rights related to
                    intellectual or copyright protection that fall under or
                    relate to the <i>software</i> used by Ilustraviz in the
                    provision of services belong solely and exclusively to
                    Ilustraviz. Thus, the use by Users of the properties made
                    available by the platform does not transfer to them, nor
                    does it grant, in any capacity, any right, nor does it grant
                    to them any authorization in relation to such technology,
                    which is for the exclusive use of Ilustraviz.</span
                >
            </p>

            <p class="MsoNormal" style="margin-left: -0.25pt">
                <span lang="EN-US"
                    >9.2 Any and all content and material originally made
                    available by Ilustraviz itself on its website is considered
                    to be its property, or for use as exclusively authorized by
                    Ilustraviz. Unless expressly permitted, no external agent
                    may use, modify, copy, reproduce, republish, make available,
                    transmit or distribute such content in any way.
                </span>
            </p>

            <p class="MsoNormal" style="margin-left: -0.25pt">
                <span lang="EN-US"
                    >9.3 Users represent and warranty that they have all rights
                    to use the content or materials made available by them on
                    Ilustraviz&#8217;s platform.
                </span>
            </p>

            <p class="MsoNormal" style="margin-left: -0.25pt">
                <span lang="EN-US"
                    >9.4 Any damage, expense, loss, infringement or litigation
                    related to the misuse by Users of content whose rights
                    belong to third parties will be the sole and exclusive
                    responsibility of Users, which undertake to keep Ilustraviz
                    completely free of challenges.</span
                >
            </p>

            <p class="MsoNormal" style="margin-left: -0.25pt">
                <span lang="EN-US"
                    >9.5 Ilustraviz, in turn, respects the intellectual property
                    rights of Users and will only use them to provide the
                    contracted services. The respective copyrights will remain
                    with the User/author. Ilustraviz will not use work or
                    intellectual property for purposes other than those
                    pre-established in this instrument, or on other platforms in
                    addition to its website, nor will it resell or grant its use
                    to third parties under any circumstance.
                </span>
            </p>

            <p class="MsoNormal" style="margin-left: -0.25pt">
                <span lang="EN-US"
                    >9.6 The rights over items/images that will make up the
                    background of the 3D models, technically known as
                    &quot;vignettes&quot;, which may be freely used, both by
                    Ilustraviz and by any of the Users, in light of the fact
                    that over them do not apply the protection of rights or
                    exclusive use rights of any nature.</span
                >
            </p>

            <p class="MsoNormal" style="margin-left: -0.25pt">
                <span lang="EN-US"
                    >9.7 Users agree, however, that all images made available to
                    Ilustraviz to be treated and edited pursuant to the terms of
                    this provision of services may be part of Ilustraviz's
                    portfolio, which will maintain rights of use over the
                    respective contents exclusively for advertising purposes and
                    dissemination of the work carried out. It should be noted
                    that this provision only applies to the results of the
                    projects, so that Ilustraviz will not disclose, under any
                    circumstance, files made available by its customers in the
                    manner in which they were originally received.
                </span>
            </p>

            <p
                class="MsoNormal"
                style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 30.9pt;
                    margin-left: -0.25pt;
                "
            >
                <span lang="EN-US"
                    >9.8 It is the exclusive responsibility of Users to obtain
                    copies and keep in their personal files the content of their
                    account on our website, in relation to the services
                    contracted and already provided. Ilustraviz will keep the
                    images in its database for a determined period, only while
                    the contracted plans are in force, and disclaims any
                    obligation in this regard.
                </span>
            </p>

            <h1
                style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.85pt;
                    margin-left: -0.25pt;
                "
            >
                <span lang="EN-US">10 - Confidentiality</span>
            </h1>

            <p
                class="MsoNormal"
                style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 30.9pt;
                    margin-left: -0.25pt;
                "
            >
                <span lang="EN-US"
                    >10.1 The parties mutually undertake to keep the information
                    exchanged in this provision of services in secrecy and
                    confidentiality, using them exclusively within the limits of
                    the provisions of this instrument and in relation to the
                    contracted services.</span
                >
            </p>

            <h1
                style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.85pt;
                    margin-left: -0.25pt;
                "
            >
                <span lang="EN-US">11 - Final Provisions</span>
            </h1>

            <p class="MsoNormal" style="margin-left: -0.25pt">
                <span lang="EN-US"
                    >11.1 Ilustraviz is authorized to monitor, at any time, the
                    use of the platform by Users, in order to guarantee its
                    correct use, in accordance with the terms and conditions
                    established in this instrument, as well as being authorized
                    to access, upon request, the account of the User, in order
                    to investigate and solve eventual failures or instabilities
                    in the system.
                </span>
            </p>

            <p class="MsoNormal" style="margin-left: -0.25pt">
                <span lang="EN-US"
                    >11.2 Users represent and warrant that the person who
                    provided the information and electronically accepted the
                    contractual conditions had powers to legally represent
                    Users&#8217; legal entity, as well as to enter into this
                    Agreement.
                </span>
            </p>

            <h1
                style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.85pt;
                    margin-left: -0.25pt;
                "
            >
                <span lang="EN-US"
                    >12 - Applicable Laws and Regulations and Jurisdiction</span
                >
            </h1>

            <p class="MsoNormal" style="margin-left: -0.25pt">
                <span lang="EN-US"
                    >12.1 This instrument is governed by Brazilian Law.</span
                >
            </p>

            <p class="MsoNormal" style="margin-left: -0.25pt">
                <span lang="EN-US"
                    >12.2 The District Court of the City of S&atilde;o Paulo is
                    hereby elected to resolve any doubts arising from the
                    interpretation or execution of this instrument, with the
                    waiver of any other, however privileged it may be.</span
                >
            </p>

            <p class="MsoNormal" style="margin-left: -0.25pt">
                <span lang="EN-US"
                    >12.3 Ilustraviz&#8217;s website and platform are maintained
                    and managed in Brazil. By using them, Users consent to the
                    transmission and export of personal information to Brazil,
                    as well as its use and storage, as specified in this
                    instrument.
                </span>
            </p>

            <p class="MsoNormal" style="margin-left: -0.25pt">
                <span lang="EN-US"
                    >12.4 In case of doubts or questions related to these terms
                    of use, please contact Illustravitz through the following
                    e-mail address: contact@ilustraviz.com.<br
                        clear="all"
                        style="page-break-before: always"
                    />
                </span>
            </p>

            <p
                class="MsoNormal"
                align="left"
                style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 30.6pt;
                    margin-left: -0.25pt;
                    text-align: left;
                    line-height: 110%;
                "
            >
                <b><span lang="EN-US">Privacy Policy </span></b>
            </p>

            <p
                class="MsoNormal"
                align="left"
                style="
                    margin-left: -0.25pt;
                    text-align: left;
                    line-height: 110%;
                "
            >
                <b><span lang="EN-US">General Provisions </span></b>
            </p>

            <h1
                style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.85pt;
                    margin-left: -0.25pt;
                "
            >
                <span lang="EN-US">1 - Data Protection </span>
            </h1>

            <p class="MsoNormal" style="margin-left: -0.25pt">
                <span lang="EN-US"
                    >1.1 Considering that the provision of Ilustraviz services
                    may involve the sharing of personal data (as defined in Law
                    n&ordm; 13.709/18 - &#8220;LGPD&#8221;), hereinafter
                    &#8220;Personal Data&#8221;, the Parties undertake, in
                    relation to any transaction that uses Personal Data
                    (&#8220;Treatment&#8221;) in the context of this agreement,
                    to:
                </span>
            </p>

            <p class="MsoNormal" style="margin-left: 35.9pt">
                <span lang="EN-US" style="line-height: 107%"
                    >a)<span style="font: 7pt 'Times New Roman'"
                        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                ><span lang="EN-US"
                    >Process Personal Data in accordance with the LGPD and other
                    applicable Data Protection laws and regulations in Brazil
                    (collectively referred to as &#8220;Data Protection Laws and
                    Regulations&#8221;), including those that come into force
                    after this contracting, ensuring, in particular, that all
                    Treatment is duly justified in one of the legal bases
                    established by the LGPD.
                </span>
            </p>

            <p class="MsoNormal" style="margin-left: 35.9pt">
                <span lang="EN-US" style="line-height: 107%"
                    >b)<span style="font: 7pt 'Times New Roman'"
                        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                ><span lang="EN-US"
                    >Process only the Personal Data necessary to perform the
                    provisions of this instrument, and only for the purpose of
                    performing the contracted services, except in cases where
                    the Processing is necessary for the fulfillment of legal or
                    regulatory obligations to which the Parties are subject.
                </span>
            </p>

            <p class="MsoNormal" style="margin-left: 36.5pt">
                <span lang="EN-US" style="line-height: 107%"
                    >1.2<span style="font: 7pt 'Times New Roman'"
                        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                ><span lang="EN-US"
                    >Ilustraviz ensures that the Processing of Personal Data,
                    carried out in the context of the provision of the
                    contracted services, will be restricted to its Employees
                    responsible for the Treatment, as well as that such
                    Employees will receive training regarding the principles of
                    data protection and the laws involving such treatment and
                    will be aware of Ilustraviz's obligations.
                </span>
            </p>

            <p class="MsoNormal" style="margin-left: 36.5pt">
                <span lang="EN-US" style="line-height: 107%"
                    >1.3<span style="font: 7pt 'Times New Roman'"
                        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                ><span lang="EN-US"
                    >If Ilustraviz identifies the occurrence or suspicion of a
                    security incident that may cause relevant damage to Users,
                    in accordance with Data Protection Laws and Regulations, it
                    will notify Users in writing immediately and without undue
                    delay. The notification must contain sufficient information
                    (at a minimum, description of what happened, nature of the
                    Personal Data, the people involved, date, cause, possible
                    impacts on Personal Data Subjects, mitigation actions taken,
                    and next steps) so that the other party can adopt the
                    measures and requirements imposed by the Data Protection
                    Laws and Regulations.</span
                >
            </p>

            <p class="MsoNormal" style="margin-left: 36.5pt">
                <span lang="EN-US" style="line-height: 107%"
                    >1.4<span style="font: 7pt 'Times New Roman'"
                        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                ><span lang="EN-US"
                    >When any data processing activity within the scope of this
                    instrument is carried out through a third-party Operator (as
                    defined in the LGPD), Ilustraviz warrants that the Operator
                    will have a level of protection of personal data at least
                    equivalent to that provided herein.</span
                >
            </p>

            <p class="MsoNormal" style="margin-left: 36.5pt">
                <span lang="EN-US" style="line-height: 107%"
                    >1.5<span style="font: 7pt 'Times New Roman'"
                        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                ><span lang="EN-US"
                    >Ilustraviz, upon termination of the relationship, will
                    interrupt the processing of Personal Data and, upon written
                    request from Users, will promote the elimination of Personal
                    Data, as well as all copies that may exist, except when the
                    maintenance of Personal Data (i) it is necessary to comply
                    with a legal or regulatory obligation; or (ii) is supported
                    by another legal basis that allows its maintenance.
                </span>
            </p>

            <p
                class="MsoNormal"
                style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 30.9pt;
                    margin-left: 36.5pt;
                "
            >
                <span lang="EN-US" style="line-height: 107%"
                    >1.6<span style="font: 7pt 'Times New Roman'"
                        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                ><span lang="EN-US"
                    >The provisions of this policy do not generate any type of
                    joint liability for any penalties related to the treatment
                    activities carried out in the context of the services
                    contracted herein, and Ilustraviz shall be liable only up to
                    the limit of its activities.
                </span>
            </p>

            <h1
                style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.85pt;
                    margin-left: -0.25pt;
                "
            >
                <span lang="EN-US">2 - How we collect your information</span>
            </h1>

            <p class="MsoNormal" style="margin-left: -0.25pt">
                <span lang="EN-US"
                    >2.1 The data collected are those provided by Users
                    themselves through the use of the <i>website</i> and related
                    pages, but the platform may also collect anonymous data,
                    which will not identify the individual.
                </span>
            </p>

            <p class="MsoNormal" style="margin-left: -0.25pt">
                <span lang="EN-US"
                    >2.2 Anonymous information and data, that is, those that do
                    not individualize the person, may be used and stored
                    indefinitely, at Ilustraviz's discretion, as well as shared
                    with third parties, business partners and customers.
                </span>
            </p>

            <p
                class="MsoNormal"
                style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 30.9pt;
                    margin-left: -0.25pt;
                "
            >
                <span lang="EN-US"
                    >2.3 Ilustraviz uses technologies and platform from
                    third-party partners to enable the recognition and
                    processing of Users data, in order to improve its services.
                    In these cases, information about the User's activity on the
                    Ilustraviz platform will be collected, so that
                    advertisements can be personalized.</span
                >
            </p>

            <h1
                style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.85pt;
                    margin-left: -0.25pt;
                "
            >
                3 - Collected Data
            </h1>

            <p
                class="MsoNormal"
                style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 10.05pt;
                    margin-left: -0.25pt;
                "
            >
                3.1 - Data Collected:
            </p>

            <p
                class="MsoNormal"
                style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 0.1pt;
                    margin-left: 36pt;
                    text-indent: -18pt;
                "
            >
                <span style="line-height: 107%"
                    >&#8226;<span style="font: 7pt 'Times New Roman'"
                        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                >Name
            </p>

            <p
                class="MsoNormal"
                style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 0.05pt;
                    margin-left: 36pt;
                    text-indent: -18pt;
                "
            >
                <span style="line-height: 107%"
                    >&#8226;<span style="font: 7pt 'Times New Roman'"
                        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                >CPF and RG
            </p>

            <p
                class="MsoNormal"
                style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 0.1pt;
                    margin-left: 36pt;
                    text-indent: -18pt;
                "
            >
                <span style="line-height: 107%"
                    >&#8226;<span style="font: 7pt 'Times New Roman'"
                        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                >Electronic address (E-mail)
            </p>

            <p
                class="MsoNormal"
                style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 0.1pt;
                    margin-left: 36pt;
                    text-indent: -18pt;
                "
            >
                <span style="line-height: 107%"
                    >&#8226;<span style="font: 7pt 'Times New Roman'"
                        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                >Address
            </p>

            <p
                class="MsoNormal"
                style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 0.1pt;
                    margin-left: 36pt;
                    text-indent: -18pt;
                "
            >
                <span style="line-height: 107%"
                    >&#8226;<span style="font: 7pt 'Times New Roman'"
                        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                >Phone number
            </p>

            <p
                class="MsoNormal"
                style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 0.1pt;
                    margin-left: 36pt;
                    text-indent: -18pt;
                "
            >
                <span style="line-height: 107%"
                    >&#8226;<span style="font: 7pt 'Times New Roman'"
                        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                >Payment data
            </p>

            <p
                class="MsoNormal"
                style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 0.05pt;
                    margin-left: 36pt;
                    text-indent: -18pt;
                "
            >
                <span style="line-height: 107%"
                    >&#8226;<span style="font: 7pt 'Times New Roman'"
                        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                >IP address
            </p>

            <p
                class="MsoNormal"
                style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 0.05pt;
                    margin-left: 36pt;
                    text-indent: -18pt;
                "
            >
                <span lang="EN-US" style="line-height: 107%"
                    >&#8226;<span style="font: 7pt 'Times New Roman'"
                        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                ><span lang="EN-US"
                    >Information about the Users' browser and operating system
                </span>
            </p>

            <p
                class="MsoNormal"
                style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 6.05pt;
                    margin-left: 36pt;
                    text-indent: -18pt;
                "
            >
                <span style="line-height: 107%"
                    >&#8226;<span style="font: 7pt 'Times New Roman'"
                        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                >Links accessed and pages visited
            </p>

            <p
                class="MsoNormal"
                style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 30.9pt;
                    margin-left: -0.25pt;
                "
            >
                <span lang="EN-US"
                    >3.2 With the exception of cases in which such information
                    is flagged, Users' personal data will not be shared with
                    third parties, unless it is necessary to resolve any demand
                    of Users themselves or as a result of legal or regulatory
                    determination.
                </span>
            </p>

            <h1
                style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.85pt;
                    margin-left: -0.25pt;
                "
            >
                <span lang="EN-US">4 - How we use your information </span>
            </h1>

            <p class="MsoNormal" style="margin-left: -0.25pt">
                <span lang="EN-US"
                    >4.1 Ilustraviz may use personal data to identify Users and
                    their interactions in their <i>website</i>, in order to
                    operate, improve and customize its platform and promotional
                    activities, adapting it to the profile of each User.
                </span>
            </p>

            <p class="MsoNormal" style="margin-left: -0.25pt">
                <span lang="EN-US"
                    >4.2 Furthermore, Ilustraviz may use personal data to
                    resolve Users&#8217; demands, send updates on the services
                    provided, administrative notifications and promotional
                    materials, such as marketing campaigns, authorize
                    transactions and process payments, generate service orders,
                    receipts and refunds. Finally, it will use such data for the
                    purposes of its own interest in the development of its
                    services, as well as for third parties with a legitimate
                    interest, such as authorities. Ilustraviz reserves the right
                    to access and disclose personal information when complying
                    with legal or regulatory obligations, within the limits of
                    what is requested, as well as to investigate suspected
                    fraud, harassment, violations and other illegal activities
                    or in violation of the own rules of its <i>website</i>.
                </span>
            </p>

            <p
                class="MsoNormal"
                style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 30.9pt;
                    margin-left: -0.25pt;
                "
            >
                <span lang="EN-US"
                    >4.3 Ilustraviz will also use personal information to send
                    communications to Users via email and telephone, for
                    example, or other means authorized by Users. By providing an
                    electronic address, Users agree to receive communications
                    via e-mail from Ilustraviz.</span
                >
            </p>

            <h1
                style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.85pt;
                    margin-left: -0.25pt;
                "
            >
                <span lang="EN-US"
                    >5 - Information shared with third parties
                </span>
            </h1>

            <p class="MsoNormal" style="margin-left: -0.25pt">
                <span lang="EN-US"
                    >5.1 The sharing of personal data with third parties will be
                    carried out to the extent necessary. However, Ilustraviz
                    reserves the right to authorize third-party partners to
                    process Users&#8217; personal data in order to make its
                    services available (website maintenance, revisions, updates,
                    etc.), considering that such persons will do so in
                    accordance with this Privacy Policy, keeping such data
                    confidential and ensuring compliance with the resulting
                    legal duties.&nbsp;
                </span>
            </p>

            <p
                class="MsoNormal"
                style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 30.9pt;
                    margin-left: -0.25pt;
                "
            >
                <span lang="EN-US"
                    >5.2 The names, addresses and contact details of such
                    authorized persons will be made available to Users upon
                    request.
                </span>
            </p>

            <h1
                style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.85pt;
                    margin-left: -0.25pt;
                "
            >
                <span lang="EN-US">6 - Payment Data</span>
            </h1>

            <p
                class="MsoNormal"
                style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 30.9pt;
                    margin-left: -0.25pt;
                "
            >
                <span lang="EN-US"
                    >In relation to payment data, such information will be
                    processed by a third-party payment platform, which will be
                    responsible for the security of the information. Payments
                    will be made in advance, through the User's choice of a
                    plan.
                </span>
            </p>

            <h1
                style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.85pt;
                    margin-left: -0.25pt;
                "
            >
                <span lang="EN-US">7 - How long the information is stored</span>
            </h1>

            <p class="MsoNormal" style="margin-left: -0.25pt">
                <span lang="EN-US"
                    >7.1 Personal information is stored for the period strictly
                    necessary, depending on the reason for which it is being
                    stored, and Ilustraviz's right and duty to keep it in the
                    following cases: to comply with a legal determination or of
                    a public body, to defend Ilustraviz's interests, to prevent
                    fraud, carry out research and studies in research bodies, in
                    the regular exercise of rights in judicial, administrative
                    or arbitration proceedings and for credit protection.&nbsp;
                </span>
            </p>

            <p class="MsoNormal" style="margin-left: -0.25pt">
                <span lang="EN-US"
                    >7.2 In addition, for marketing purposes, the storage period
                    is three (3) years from the date of the Users&#8217; last
                    activity on Ilustraviz&#8217;s platform.&nbsp;
                </span>
            </p>

            <p
                class="MsoNormal"
                style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 30.9pt;
                    margin-left: -0.25pt;
                "
            >
                <span lang="EN-US"
                    >7.3 Except for these cases, the contractual relationship or
                    the Users&#8217; consent regarding the storage of their data
                    will be maintained, and Users may revoke it at any time,
                    communicating in writing their will communicating in writing
                    their will through e-mail to be sent to
                    contact@ilustraviz.com.</span
                >
            </p>

            <h1
                style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.85pt;
                    margin-left: -0.25pt;
                "
            >
                <span lang="EN-US"
                    >8 - How we protect your personal information
                </span>
            </h1>

            <p class="MsoNormal" style="margin-left: -0.25pt">
                <span lang="EN-US"
                    >8.1 Ilustraviz undertakes to take necessary and appropriate
                    care to ensure that Personal Data is protected. To this end,
                    Ilustraviz uses secure servers to store such information, as
                    well as intrusion alert services to prevent unauthorized
                    access, in addition to ensuring the identity of all people
                    with authorized access.
                </span>
            </p>

            <p class="MsoNormal" style="margin-left: -0.25pt">
                <span lang="EN-US"
                    >8.2 However, even if security measures are adopted, it is
                    known that the transmission of information via the internet
                    is not 100% secure, so that the incommunicability of all
                    information and unauthorized invasions cannot be guaranteed.
                    By using the platform, Users declare to recognize the
                    existence of such risks, so that Ilustraviz will not be held
                    responsible for any damages borne by Users when Ilustraviz's
                    negligence or non-compliance with the necessary security
                    measures is not characterized.
                </span>
            </p>

            <p
                class="MsoNormal"
                style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 30.9pt;
                    margin-left: -0.25pt;
                "
            >
                <span lang="EN-US"
                    >8.3 It is the sole and exclusive responsibility of Users to
                    maintain the privacy of their password to access the
                    website, where their personal information will be
                    registered.
                </span>
            </p>

            <h1
                style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.85pt;
                    margin-left: -0.25pt;
                "
            >
                <span lang="EN-US">9 - Cookies and similar technologies </span>
            </h1>

            <p class="MsoNormal" style="margin-left: -0.25pt">
                <span lang="EN-US"
                    >9.1 Ilustraviz may use cookies and other similar
                    technologies to collect information when accessing its
                    platform and interactions on its page. The purposes can be,
                    but are not limited to: tracking browsing patterns,
                    measuring reach and effectiveness of adds, customizing the
                    platform and facilitating the verification process.</span
                >
            </p>

            <p
                class="MsoNormal"
                style="margin-left: -0.25pt; line-height: 163%"
            >
                <span lang="EN-US"
                    >9.2 Most browsers will allow Users to reject Cookies and
                    delete all information already collected from their
                    computers, if they choose to do so. In these cases, it will
                    be up to Users to reset their own browser's capabilities and
                    it is possible that some functions of Ilustraviz&#8217;s
                    platform become unavailable.
                    <b
                        >10 - Your rights in relation to your personal
                        information</b
                    ></span
                >
            </p>

            <p class="MsoNormal" style="margin-left: -0.25pt">
                <span lang="EN-US"
                    >10.1 Users may, at any time, request access, alteration,
                    correction or deletion of their data, and Ilustraviz may
                    store specific data in specific cases for the necessary
                    period, pursuant to the terms of applicable laws and
                    regulations.</span
                >
            </p>

            <p class="MsoNormal" style="margin-left: -0.25pt">
                <span lang="EN-US"
                    >10.2 Once Users&#8217; data have been changed, they will be
                    submitted to a new profile adequacy assessment as per
                    Ilustraviz&#8217;s rules and may or may not have their
                    rights of access to the platform discontinued.
                </span>
            </p>

            <p
                class="MsoNormal"
                style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 30.9pt;
                    margin-left: -0.25pt;
                "
            >
                <span lang="EN-US"
                    >10.3 The electronic contact address in case Users wish to
                    request access, alteration or deletion of their data is
                    contact@ilustraviz.com. Likewise, if Users no longer wish to
                    receive communications from Ilustraviz via e-mail or
                    telephone, they may request this by contacting us through
                    the same channel.&nbsp;
                </span>
            </p>

            <h1
                style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.85pt;
                    margin-left: -0.25pt;
                "
            >
                <span lang="EN-US">11 - External links </span>
            </h1>

            <p class="MsoNormal" style="margin-left: -0.25pt">
                <span lang="EN-US"
                    >11.1 Ilustraviz&#8217;s website may contain <i>links</i> to
                    other websites, which may be controlled by third parties,
                    such as the platform that stores the files and the platform
                    that operates the payment system for contracting the plans.
                    Ilustraviz is not responsible for the content displayed in
                    external links, as well as for the privacy policy applied
                    thereto. External sites will have their own policies and
                    procedures for handling personal data, and it is up to Users
                    to check such terms before using the respective platforms.
                </span>
            </p>

            <p class="MsoNormal" style="margin-left: -0.25pt">
                <span lang="EN-US"
                    >11.2 The provision of external links on Ilustraviz&#8217;s
                    platform does not imply consent or approval of third-party
                    websites.</span
                >
            </p>
        </div>
    </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.term {
    padding: 20px;
    background: #2e3238;
    > * {
        margin: 0 auto;
        max-width: 800px;
    }
}
* {
    font: revert;
    color: #ffffff;
    line-height: 1.2em;
}

/* Font Definitions */
@font-face {
    font-family: "Cambria Math";
    panose-1: 2 4 5 3 5 4 6 3 2 4;
}
@font-face {
    font-family: Calibri;
    panose-1: 2 15 5 2 2 2 4 3 2 4;
}
@font-face {
    font-family: "Segoe UI Symbol";
    panose-1: 2 11 5 2 4 2 4 2 2 3;
}
/* Style Definitions */
p.MsoNormal,
li.MsoNormal,
div.MsoNormal {
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 7.85pt;
    margin-left: 0.5pt;
    text-align: justify;
    text-justify: inter-ideograph;
    text-indent: -0.5pt;
    line-height: 107%;
    font-size: 12pt;
    font-family: "Arial", sans-serif;
    //color: black;
}
h1 {
    mso-style-link: "T\00EDtulo 1 Char";
    margin-top: 0cm;
    margin-right: 117.9pt;
    margin-bottom: 7.85pt;
    margin-left: 118.4pt;
    text-indent: -0.5pt;
    line-height: 110%;
    page-break-after: avoid;
    font-size: 12pt;
    font-family: "Arial", sans-serif;
    //color: black;
}
span.Ttulo1Char {
    mso-style-name: "T\00EDtulo 1 Char";
    mso-style-link: "T\00EDtulo 1";
    font-family: "Arial", sans-serif;
    color: black;
    font-weight: bold;
}
.MsoChpDefault {
    font-family: "Calibri", sans-serif;
}
.MsoPapDefault {
    margin-bottom: 8pt;
    line-height: 107%;
}
@page WordSection1 {
    size: 595pt 841pt;
    margin: 73.35pt 84.75pt 70.5pt 3cm;
}
div.WordSection1 {
    page: WordSection1;
}
/* List Definitions */
ol {
    margin-bottom: 0cm;
}
ul {
    margin-bottom: 0cm;
}
</style>
