<template>
    <div
        class="simpleDropdown"
        :class="{ showList: open }"
    >
        <div
            class="simpleDropdown_header"
            :class="{ hasHover: background ? 'hover' : '' }"
            :style="{ background: background ? background : '' }"
        >
            <p
                class="simpleDropdown_text font-button"
                v-if="!open"
                @click="setOpenClose(true)"
            >
                {{ list[modelValue] }}
            </p>
            <input
                v-else
                class="simpleDropdown_text font-button"
                :style="{ textAlign: textAlign ? textAlign : 'left' }"
                v-model="filter"
                placeholder="Search..."
                id="search_menu"
            />
            <button
                class="simpleDropdown_icon"
                @click="setOpenClose(!open)"
            >
                <img
                    :src="
                        require('@/assets/imgs/icons/chevron_down_filled.svg')
                    "
                    alt=""
                />
            </button>
        </div>
        <div class="simpleDropdown_list">
            <ul :style="{ maxHeight: maxHeight ? maxHeight : '155px' }">
                <li
                    v-for="(item, i) in filterList"
                    :key="'dropdown_' + item + '_' + i"
                >
                    <Button
                        :text="item"
                        @click="select(item)"
                        :active="modelValue == i"
                        :previneOverflowText="true"
                    />
                </li>
            </ul>
        </div>
        <div
            class="simpleDropdown_close"
            v-if="open"
            @click="open = false"
        ></div>
    </div>
</template>

<script>
import Button from "@/components/buttons/Button-text.vue";

export default {
    props: ["list", "modelValue", "textAlign", "maxHeight", "background"],
    data() {
        return {
            open: false,
            filter: "",
        };
    },
    components: {
        Button,
    },
    methods: {
        select(index) {
            this.filter = index;
            let item = this.list.findIndex((item) => item == index);
            this.$emit("update", item);
            this.open = false;
        },
        setOpenClose(value) {
            this.open = value;
            if (value) {
                this.filter = "";
                setTimeout(() => {
                    document.getElementById("search_menu").focus();
                }, 100);
            }
        },
    },
    computed: {
        filterList() {
            const filtered = this.list.filter((item) =>
                item.toLowerCase().includes(this.filter.toLowerCase().trim())
            );
            return filtered;
        },
    },
};
</script>

<style lang="scss" scoped>
* {
    margin: 0;
    padding: 0;
    text-decoration: none;
    list-style: none;
}
.simpleDropdown {
    position: relative;
    min-width: 165px;

    &_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 15px;
        cursor: pointer;
        border-radius: 5px;
        z-index: 11;
        position: relative;
    }
    &_text {
        color: #95949b;
        margin-right: 5px;
        flex: 1;
        width: 100%;
    }
    &_icon {
        width: 10px;
        background: none;
        border: none;
        cursor: pointer;
        display: flex;
        align-items: center;
    }
    &_list {
        position: absolute;
        top: calc(100% + 10px);
        left: 0;
        width: fit-content;
        background: #1e2127;
        border-radius: 10px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        padding-top: 5px;
        z-index: 11;
        display: none;
        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            display: flex;
            flex-direction: column;
            max-height: 155px;
            overflow-y: scroll;
            width: fit-content;
            &::-webkit-scrollbar {
                display: none;
            }
            li {
                margin-bottom: 5px;
                margin-left: 5px;
                margin-right: 5px;
            }
        }
        .fullHeight {
            max-height: none;
        }
    }
}

.hasHover {
    transition: 0.5s ease;
    .simpleDropdown {
        &_text {
            transition: 0.5s ease;
        }
    }
    &:hover {
        background: #0264d8 !important;
        .simpleDropdown {
            &_text {
                color: #fff;
            }
        }
    }
}

.showList {
    .simpleDropdown_list {
        display: block;
    }
}
.simpleDropdown_close {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;

    //display: none;
}
</style>
