<template>
    <div
        class="simpleDropdown"
        :class="{ showList: open }"
    >
        <div
            class="simpleDropdown_header"
            @click="open = !open"
        >
            <p class="simpleDropdown_text">
                {{ sliceMonthName(nameMonth(calendarSelected.monthStart, 1)) }}
                {{ calendarSelected.dayStart }}
                {{ calendarSelected.dayEnd != null ? "-" : "" }}
                {{
                    calendarSelected.monthEnd != null
                        ? sliceMonthName(
                              nameMonth(calendarSelected.monthEnd, 1)
                          )
                        : ""
                }}
                {{ calendarSelected.dayEnd }}
            </p>
            <button class="simpleDropdown_icon">
                <img
                    :src="require('@/assets/imgs/icons/chevron-down.svg')"
                    alt=""
                />
            </button>
        </div>
        <div
            class="calendar"
            :class="[direction]"
        >
            <div class="calendar_filter">
                <ul>
                    <li class="calendar_filter_button">
                        <Button
                            text="All Time"
                            @click="preselectFilter('alltime')"
                            :active="calendarSelected.preset == 'alltime'"
                        />
                    </li>
                    <li class="calendar_filter_button">
                        <Button
                            text="This week"
                            @click="preselectFilter('week')"
                            :active="calendarSelected.preset == 'week'"
                        />
                    </li>
                    <li class="calendar_filter_button">
                        <Button
                            text="Last week"
                            @click="preselectFilter('last-week')"
                            :active="calendarSelected.preset == 'last-week'"
                        />
                    </li>
                    <li class="calendar_filter_button">
                        <Button
                            text="Last two weeks"
                            @click="preselectFilter('last-two-week')"
                            :active="calendarSelected.preset == 'last-two-week'"
                        />
                    </li>
                    <li class="calendar_filter_button">
                        <Button
                            text="This month"
                            @click="preselectFilter('month')"
                            :active="calendarSelected.preset == 'month'"
                        />
                    </li>
                    <li class="calendar_filter_button">
                        <Button
                            text="Last month"
                            @click="preselectFilter('last-month')"
                            :active="calendarSelected.preset == 'last-month'"
                        />
                    </li>
                    <li class="calendar_filter_button">
                        <Button
                            text="This year"
                            @click="preselectFilter('year')"
                            :active="calendarSelected.preset == 'year'"
                        />
                    </li>
                    <li class="calendar_filter_button">
                        <Button
                            text="Last year"
                            @click="preselectFilter('last-year')"
                            :active="calendarSelected.preset == 'last-year'"
                        />
                    </li>
                </ul>
            </div>
            <div class="calendar_calendars">
                <div
                    class="calendar_calendars_group"
                    v-for="cal in 2"
                    :key="'cal_' + cal"
                >
                    <div class="calendar_calendars_header">
                        <button
                            class="calendar_calendars_header_icon"
                            :class="{ showOffArrow: cal == 2 }"
                            @click="arrowMonth(-1)"
                        >
                            <img
                                :src="
                                    require('@/assets/imgs/icons/chevron-left.svg')
                                "
                                alt=""
                            />
                        </button>
                        <p class="calendar_calendars_header_month">
                            {{ nameMonth(calendar.month, cal) }}
                            {{
                                cal == 2 && calendar.month + 1 > 12
                                    ? calendar.year + 1
                                    : calendar.year
                            }}
                        </p>
                        <button
                            class="calendar_calendars_header_icon"
                            :class="{ showOffArrow: cal == 1 }"
                            @click="arrowMonth(1)"
                        >
                            <img
                                :src="
                                    require('@/assets/imgs/icons/chevron-right.svg')
                                "
                                alt=""
                            />
                        </button>
                    </div>
                    <div class="calendar_calendars_days">
                        <ul>
                            <li class="week">Mo</li>
                            <li class="week">Tu</li>
                            <li class="week">We</li>
                            <li class="week">Th</li>
                            <li class="week">Fr</li>
                            <li class="week">Sa</li>
                            <li class="week">Su</li>
                            <li
                                v-for="d in 6 * 7"
                                :key="'calendar1_d' + d"
                                @click="
                                    !isDayOff(d, cal)
                                        ? selectDay(
                                              d -
                                                  getStartDayWeek(
                                                      calendar.month,
                                                      calendar.year,
                                                      cal
                                                  ),
                                              calendar.month,
                                              calendar.year,
                                              cal
                                          )
                                        : null
                                "
                                :class="{
                                    'active-minnor':
                                        !isDayOff(d, cal) &&
                                        isActiveMinnor(d, cal),
                                    'active-major':
                                        !isDayOff(d, cal) &&
                                        isActiveMajor(d, cal),
                                    'active-middle':
                                        !isDayOff(d, cal) &&
                                        isActiveMiddle(d, cal),
                                    'active-off': isDayOff(d, cal),
                                }"
                            >
                                {{
                                    getDayNumber(
                                        d -
                                            getStartDayWeek(
                                                calendar.month,
                                                calendar.year,
                                                cal
                                            ),
                                        cal
                                    )
                                }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="simpleDropdown_close"
            v-if="open"
            @click="open = false"
        ></div>
    </div>
</template>

<script>
import Button from "@/components/buttons/Button-text.vue";

export default {
    props: ["direction"],
    data() {
        return {
            open: false,
            calendar: {
                day: 1,
                month: 1,
                year: 2022,
            },
            calendarSelected: {
                dayStart: 1,
                monthStart: 1,
                yearStart: 2022,
                dayEnd: 1,
                monthEnd: 1,
                yearEnd: 2022,
                clickType: 0,
                preset: "week",
            },
        };
    },
    components: {
        Button,
    },
    methods: {
        emitEvent() {
            const start = {
                day: this.calendarSelected.dayStart,
                month: this.calendarSelected.monthStart,
                year: this.calendarSelected.yearStart,
            };
            const end = {
                day: this.calendarSelected.dayEnd,
                month: this.calendarSelected.monthEnd,
                year: this.calendarSelected.yearEnd,
            };
            this.$emit("update", [start, end]);
        },
        getDayNumber(day, cal) {
            const month =
                cal === 1 ? this.calendar.month : this.calendar.month + 1;
            const auxYear = this.calendar.year;
            const aux = new Date(auxYear, month - 1, 1);
            aux.setDate(aux.getDate() + day - 1);
            return aux.getDate();
        },
        getStartDayWeek(month, year, cal = 1) {
            const auxMonth = cal === 1 ? month : month + 1;
            const auxYear = year;
            const aux = new Date(auxYear, auxMonth - 1, 1);
            return aux.getDay();
        },
        getLastDayMonth(month, year) {
            const aux = new Date(year, month, 0);
            return aux.getDate();
        },
        selectDay(day, month, year, cal) {
            const auxMonth = cal === 1 ? month : month + 1;
            const auxYear = year;
            this.calendarSelected.preset = "";
            if (day < 0) return;
            if (day > this.getLastDayMonth(auxMonth, auxYear)) return;
            if (this.calendarSelected.clickType === 0) {
                this.calendarSelected.dayStart = day;
                this.calendarSelected.monthStart = auxMonth;
                this.calendarSelected.yearStart = auxYear;
                this.calendarSelected.dayEnd = null;
                this.calendarSelected.monthEnd = null;
                this.calendarSelected.yearEnd = null;
                this.calendarSelected.clickType = 1;
            } else {
                const startDate = new Date(
                    this.calendarSelected.yearStart,
                    this.calendarSelected.monthStart - 1,
                    this.calendarSelected.dayStart
                );
                const endDate = new Date(auxYear, auxMonth - 1, day);
                if (endDate.getTime() === startDate.getTime()) return;
                if (endDate < startDate) {
                    this.calendarSelected.dayEnd =
                        this.calendarSelected.dayStart;
                    this.calendarSelected.monthEnd =
                        this.calendarSelected.monthStart;
                    this.calendarSelected.yearEnd =
                        this.calendarSelected.yearStart;
                    this.calendarSelected.dayStart = day;
                    this.calendarSelected.monthStart = auxMonth;
                    this.calendarSelected.yearStart = auxYear;
                } else {
                    this.calendarSelected.dayEnd = day;
                    this.calendarSelected.monthEnd = auxMonth;
                    this.calendarSelected.yearEnd = auxYear;
                }
                this.calendarSelected.clickType = 0;
            }
            this.emitEvent();
        },
        isActiveMinnor(d, cal) {
            const month =
                cal === 1 ? this.calendar.month : this.calendar.month + 1;
            const auxYear = this.calendar.year;
            const aux = new Date(
                auxYear,
                month - 1,
                d - this.getStartDayWeek(month, auxYear)
            );
            const startDate = new Date(
                this.calendarSelected.yearStart,
                this.calendarSelected.monthStart - 1,
                this.calendarSelected.dayStart
            );
            return aux.getTime() === startDate.getTime();
        },
        isActiveMajor(d, cal) {
            const month =
                cal === 1 ? this.calendar.month : this.calendar.month + 1;
            const auxYear = this.calendar.year;
            const aux = new Date(
                auxYear,
                month - 1,
                d - this.getStartDayWeek(month, auxYear)
            );
            const endDate = new Date(
                this.calendarSelected.yearEnd,
                this.calendarSelected.monthEnd - 1,
                this.calendarSelected.dayEnd
            );
            return aux.getTime() === endDate.getTime();
        },
        isActiveMiddle(d, cal) {
            const month =
                cal === 1 ? this.calendar.month : this.calendar.month + 1;
            const auxYear = this.calendar.year;
            if (this.calendarSelected.dayEnd == null) return false;
            const day = d - this.getStartDayWeek(month, auxYear);
            const dayStart = new Date(
                this.calendarSelected.yearStart,
                this.calendarSelected.monthStart - 1,
                this.calendarSelected.dayStart
            );
            const dayEnd = new Date(
                this.calendarSelected.yearEnd,
                this.calendarSelected.monthEnd - 1,
                this.calendarSelected.dayEnd
            );
            const thisDay = new Date(auxYear, month - 1, day);
            return thisDay > dayStart && thisDay < dayEnd;
        },
        isDayOff(d, cal) {
            const month =
                cal === 1 ? this.calendar.month : this.calendar.month + 1;
            const auxYear = this.calendar.year;
            return (
                d - this.getStartDayWeek(month, auxYear) <= 0 ||
                d - this.getStartDayWeek(month, auxYear) >
                    this.getLastDayMonth(month, auxYear)
            );
        },
        nameMonth(month, cal) {
            let aux = month;
            if (cal === 2) aux += 1;
            switch (aux) {
                case 1:
                    return "January";
                case 2:
                    return "February";
                case 3:
                    return "March";
                case 4:
                    return "April";
                case 5:
                    return "May";
                case 6:
                    return "June";
                case 7:
                    return "July";
                case 8:
                    return "August";
                case 9:
                    return "September";
                case 10:
                    return "October";
                case 11:
                    return "November";
                case 12:
                    return "December";
                default:
                    return "January";
            }
        },
        sliceMonthName(month) {
            return month.slice(0, 3);
        },
        arrowMonth(dir) {
            const date = new Date(
                this.calendar.year,
                this.calendar.month - 1,
                1
            );
            if (dir === 1) {
                date.setMonth(date.getMonth() + 1);
            } else {
                date.setMonth(date.getMonth() - 1);
            }
            this.calendar.month = date.getMonth() + 1;
            this.calendar.year = date.getFullYear();
        },
        preselectFilter(period) {
            const today = new Date();
            this.calendarSelected.clickType = 0;
            this.calendarSelected.preset = period;
            if (period === "week") {
                const start = new Date(
                    today.getFullYear(),
                    today.getMonth(),
                    today.getDate() - today.getDay()
                );
                const end = new Date(
                    today.getFullYear(),
                    today.getMonth(),
                    today.getDate() - today.getDay() + 6
                );
                this.calendarSelected.dayStart = start.getDate();
                this.calendarSelected.monthStart = start.getMonth() + 1;
                this.calendarSelected.yearStart = start.getFullYear();
                this.calendarSelected.dayEnd = end.getDate();
                this.calendarSelected.monthEnd = end.getMonth() + 1;
                this.calendarSelected.yearEnd = end.getFullYear();
            } else if (period === "last-week") {
                const start = new Date(
                    today.getFullYear(),
                    today.getMonth(),
                    today.getDate() - today.getDay() - 7
                );
                const end = new Date(
                    today.getFullYear(),
                    today.getMonth(),
                    today.getDate() - today.getDay()
                );
                this.calendarSelected.dayStart = start.getDate();
                this.calendarSelected.monthStart = start.getMonth() + 1;
                this.calendarSelected.yearStart = start.getFullYear();
                this.calendarSelected.dayEnd = end.getDate();
                this.calendarSelected.monthEnd = end.getMonth() + 1;
                this.calendarSelected.yearEnd = end.getFullYear();
            } else if (period === "last-two-week") {
                const start = new Date(
                    today.getFullYear(),
                    today.getMonth(),
                    today.getDate() - today.getDay() - 14
                );
                const end = new Date(
                    today.getFullYear(),
                    today.getMonth(),
                    today.getDate() - today.getDay()
                );
                this.calendarSelected.dayStart = start.getDate();
                this.calendarSelected.monthStart = start.getMonth() + 1;
                this.calendarSelected.yearStart = start.getFullYear();
                this.calendarSelected.dayEnd = end.getDate();
                this.calendarSelected.monthEnd = end.getMonth() + 1;
                this.calendarSelected.yearEnd = end.getFullYear();
            } else if (period === "month") {
                const start = new Date(
                    today.getFullYear(),
                    today.getMonth(),
                    1
                );
                const end = new Date(
                    today.getFullYear(),
                    today.getMonth(),
                    this.getLastDayMonth(
                        today.getMonth() + 1,
                        today.getFullYear()
                    )
                );
                this.calendarSelected.dayStart = start.getDate();
                this.calendarSelected.monthStart = start.getMonth() + 1;
                this.calendarSelected.yearStart = start.getFullYear();
                this.calendarSelected.dayEnd = end.getDate();
                this.calendarSelected.monthEnd = end.getMonth() + 1;
                this.calendarSelected.yearEnd = end.getFullYear();
            } else if (period === "last-month") {
                const start = new Date(
                    today.getFullYear(),
                    today.getMonth() - 1,
                    1
                );
                const end = new Date(today.getFullYear(), today.getMonth(), 0);
                this.calendarSelected.dayStart = start.getDate();
                this.calendarSelected.monthStart = start.getMonth() + 1;
                this.calendarSelected.yearStart = start.getFullYear();
                this.calendarSelected.dayEnd = end.getDate();
                this.calendarSelected.monthEnd = end.getMonth() + 1;
                this.calendarSelected.yearEnd = end.getFullYear();
            } else if (period === "year") {
                const start = new Date(today.getFullYear(), 0, 1);
                const end = new Date(today.getFullYear(), 11, 31);
                this.calendarSelected.dayStart = start.getDate();
                this.calendarSelected.monthStart = start.getMonth() + 1;
                this.calendarSelected.yearStart = start.getFullYear();
                this.calendarSelected.dayEnd = end.getDate();
                this.calendarSelected.monthEnd = end.getMonth() + 1;
                this.calendarSelected.yearEnd = end.getFullYear();
            } else if (period === "last-year") {
                const start = new Date(today.getFullYear() - 1, 0, 1);
                const end = new Date(today.getFullYear(), 0, 1 - 1);
                this.calendarSelected.dayStart = start.getDate();
                this.calendarSelected.monthStart = start.getMonth() + 1;
                this.calendarSelected.yearStart = start.getFullYear();
                this.calendarSelected.dayEnd = end.getDate();
                this.calendarSelected.monthEnd = end.getMonth() + 1;
                this.calendarSelected.yearEnd = end.getFullYear();
            } else if (period === "alltime") {
                const end = new Date();
                this.calendarSelected.dayStart = 1;
                this.calendarSelected.monthStart = 1;
                this.calendarSelected.yearStart = 2000;
                this.calendarSelected.dayEnd = end.getDate();
                this.calendarSelected.monthEnd = end.getMonth() + 1;
                this.calendarSelected.yearEnd = end.getFullYear();
            }
            this.emitEvent();
        },
    },
    computed: {},
    created() {
        const today = new Date();
        this.preselectFilter("alltime");
    },
};
</script>

<style lang="scss" scoped>
* {
    margin: 0;
    padding: 0;
    text-decoration: none;
    list-style: none;
}
.simpleDropdown {
    position: relative;
    min-width: 165px;
    &_header {
        padding: 10px 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
    }
    &_text {
        color: #fff;
        font-size: 15px;
        margin-right: 5px;
    }
    &_icon {
        width: 20px;
        height: 20px;
        background: none;
        border: none;
        cursor: pointer;
    }
}

.showList {
    .calendar {
        display: flex;
    }
}
.calendar {
    position: absolute;
    top: calc(100% + 20px);
    right: 0;
    background: #2c3038;
    border: 1px solid #141517;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    z-index: 11;
    display: none;

    &.right {
        right: -420px;

        &::before {
            left: 135px;
            right: auto;
        }
    }
    &_filter {
        padding-top: 10px;
        padding-bottom: 10px;
        border-right: 1px solid #505256;
        background: #2c3038;
        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            display: flex;
            flex-direction: column;
            overflow-y: scroll;
            width: fit-content;
            &::-webkit-scrollbar {
                display: none;
            }
        }
    }
    &_calendars {
        background: #2c3038;
        display: flex;
        padding: 10px 20px;
        border-radius: 0 10px 10px 0;
        &_header {
            display: flex;
            align-items: center;
            &_icon {
                width: 26px;
                height: 26px;
                min-width: 26px;
                background: none;
                border: none;
                cursor: pointer;
            }
            &_month {
                color: #fff;
                font-size: 11px;
                font-weight: bold;
                white-space: nowrap;
                margin: 0 auto;
            }
        }
        &_days {
            ul {
                display: grid;
                grid-template-columns: repeat(7, 26px);
                li {
                    text-align: center;
                    width: 26px;
                    height: 26px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 11px;
                    color: #b3b3b3;
                    border-radius: 5px;
                    transition: 0.5s ease;
                    &:hover {
                        background: #0092ff;
                        color: #fff;
                        cursor: pointer;
                    }
                }
                .week {
                    color: #fff;
                    font-weight: bold;
                    &:hover {
                        background: none;
                        cursor: default;
                    }
                }
                .active {
                    &-minnor {
                        background: #0092ff;
                        color: #fff;
                        border-radius: 5px 0 0 5px;
                    }
                    &-major {
                        background: #0092ff;
                        color: #fff;
                        border-radius: 0 5px 5px 0;
                    }
                    &-middle {
                        background: #3d424e;
                        border-radius: 0px;
                    }
                    &-off {
                        color: #505256;
                        &:hover {
                            background: none;
                            color: #505256;
                            cursor: default;
                        }
                    }
                }
            }
        }
        &_group {
            & + & {
                margin-left: 20px;
            }
        }
    }
    &::before {
        content: "";
        position: absolute;
        top: -9px;
        right: 18px;
        width: 18px;
        height: 20px;
        background: #2c3038;
        z-index: -1;
        transform: rotateZ(45deg);
        border: 1px solid #141517;
    }
}
.showOffArrow {
    cursor: default;
    opacity: 0;
}

.simpleDropdown_close {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
}
</style>
