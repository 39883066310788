<template>
    <div class="app">
        <Menu active="add templates" />
        <Container>
            <Column class="column-buttons">
                <Block
                    title="New Templates List"
                    class="block-all"
                >
                    <div class="list_buttons">
                        <div class="">
                            <ul class="menuCatalogo_itens">
                                <li class="menuCatalogo_item">
                                    <Button
                                        class="buttonAdd"
                                        icon="/imgs/icons/plus.svg"
                                        background="#141517"
                                        @click="addTemplate()"
                                    />
                                </li>

                                <li
                                    class="menuCatalogo_item"
                                    v-for="(template, i) in templates"
                                    :key="'prod_button' + i"
                                >
                                    <Button
                                        :noicon="true"
                                        :text="getTemplateName(i)"
                                        align="left"
                                        :active="templateSelected === i"
                                        @click="selectProduct(i)"
                                        :previneOverflowText="true"
                                        class="buttonProd"
                                        :class="{ buttonError: template.error }"
                                    />
                                    <ButtonIcon
                                        class="buttonIcon"
                                        icon="/imgs/icons/trash.svg"
                                        @click="deleteTemplate(i)"
                                    />
                                </li>
                                <li class="menuCatalogo_item">
                                    <Button
                                        class="buttonAdd"
                                        icon="/imgs/icons/plus.svg"
                                        background="#141517"
                                        @click="addTemplate()"
                                    />
                                </li>
                            </ul>
                        </div>
                        <div class="menuCatalogo_bottom">
                            <Button
                                :noicon="true"
                                background="#141517"
                                text="SEND"
                                @click="eventButtonSend"
                                class="buttonSend"
                            />
                        </div>
                    </div>
                </Block>
            </Column>
            <Column>
                <Block class="block-all">
                    <!-- Photo -->
                    <div
                        class="productScreen_mainPhoto"
                        v-if="templates[templateSelected].image"
                    >

                        <img :src="templates[templateSelected].image" />
                    </div>
                    <div
                        class="
                                    productScreen_mainPhoto
                                    productScreen_dragDrop
                                "
                        v-else
                    >
                        <p class="text">
                            Upload product images - Required*
                        </p>
                        <p class="text-small">Drag and Drop</p>
                        <div>
                            <img :src="
                                            require('@/assets/imgs/icons/tabler_drag-drop.svg')
                                        " />
                        </div>
                    </div>
                    <input
                        type="file"
                        accept="image/*"
                        multiple
                        class="inputAll"
                        @change="eventUploadFile($event)"
                    />
                    <!-- End Photo -->
                </Block>
            </Column>
            <Column class="column-data">
                <Block title="Template Info">
                    <div class="row">
                        <p class="simpleSectionnText">Name:</p>
                        <InputText v-model="templates[templateSelected].name" />
                        <p class="simpleSectionnText">Set As:</p>
                        <Dropdown
                            :list="['public', 'private']"
                            :modelValue="templates[templateSelected].public"
                            @update="updatePrivatePublic"
                        />
                        <div
                            class="row"
                            v-if="templates[templateSelected].public"
                        >

                            <p class="simpleSectionnText">Client:</p>
                            <DropdownFilter
                                :list="getNamesCustomers()"
                                :modelValue="templates[templateSelected].customer"
                                :background="'#3d424e'"
                                @update="updateCustomer"
                                class="dropdownFilter"
                            />
                        </div>
                    </div>
                </Block>
                <Block title="Notes">
                    <InputTextArea v-model="templates[templateSelected].note" />
                </Block>
                <Block title="Ambient Type*">
                    <p
                        class="simpleSectionnText"
                        v-if="templates[templateSelected].ambients.length === 0"
                    >
                        No ambients selected
                    </p>
                    <ul class="listTag listTag-edit">
                        <li
                            v-for="(ambient) in getTemplateAmbients()"
                            :key="'tagprod_' + ambient.id"
                        >
                            <Button
                                :text="ambient.name"
                                icon="/imgs/icons/trash.svg"
                                :inverted="true"
                                :smallspace="true"
                                @click="removeAmbient(ambient)"
                            />
                        </li>
                    </ul>
                    <div class="
                                        productScreen_productinfo_item_header_title
                                        divCategories row
                                    ">
                        <p>Select Ambient</p>
                        <div class="row">
                            <p class="simpleSectionnText">
                                Add Custom Ambient:
                            </p>

                            <InputText
                                icon="/imgs/icons/plus.svg"
                                v-model="inputAmbient"
                                @submit="submitInputAmbient"
                                @keyup.enter="submitInputAmbient"
                            />
                        </div>
                    </div>
                    <LoadingElement v-if="loadAmbient" />
                    <ul
                        class="listTag"
                        v-if="!loadAmbient"
                    >
                        <li
                            v-for="(ambient) in filteredAmbients"
                            :key="'tagadd_' + ambient.id"
                        >
                            <Button
                                :text="ambient.name"
                                icon="/imgs/icons/plus.svg"
                                :inverted="true"
                                :smallspace="true"
                                @click="addAmbient(ambient)"
                            />
                        </li>
                    </ul>
                </Block>
                <Block title="Ambient Tags*">
                    <p
                        class="simpleSectionnText"
                        v-if="templates[templateSelected].tags.length === 0"
                    >
                        No tags selected
                    </p>
                    <ul class="listTag listTag-edit">
                        <li
                            v-for="(tag) in getTemplateTags()"
                            :key="'tagprod_' + tag.id"
                        >
                            <Button
                                :text="tag.name"
                                icon="/imgs/icons/trash.svg"
                                :inverted="true"
                                :smallspace="true"
                                @click="removeTag(i)"
                            />
                        </li>
                    </ul>
                    <div class="
                                        productScreen_productinfo_item_header_title
                                        divCategories row
                                    ">
                        <p>Select Tags</p>
                        <div class="row">
                            <p class="simpleSectionnText">
                                Add Custom Tag:
                            </p>

                            <InputText
                                icon="/imgs/icons/plus.svg"
                                v-model="inputTag"
                                @submit="submitInputTag"
                                @keyup.enter="submitInputTag"
                            />
                        </div>
                    </div>
                    <LoadingElement v-if="loadTags" />
                    <ul class="listTag">
                        <li
                            v-for="(tag) in filteredTags"
                            :key="'tagadd_' + tag.id"
                        >
                            <Button
                                :text="tag.name"
                                icon="/imgs/icons/plus.svg"
                                :inverted="true"
                                :smallspace="true"
                                @click="addTag(tag)"
                            />
                        </li>
                    </ul>
                </Block>
            </Column>
        </Container>
    </div>
    <PopupAlert
        v-if="popupAlert.show"
        :title="popupAlert.title"
        :subTitle="popupAlert.subTitle"
        :confirm="popupAlert.confirm"
        :cancel="popupAlert.cancel"
        @confirm="emitConfirm"
        @cancel="emitCancel"
    />
</template>

<script>
import Menu from "@/views/common/Menu_Admin.vue";
import Container from "@/components/sections/Container.vue";
import Column from "@/components/sections/Column.vue";
import Block from "@/components/sections/Block.vue";
import Button from "@/components/buttons/Button-icon-text.vue";
import ButtonIcon from "@/components/buttons/Button-icon.vue";
import ButtonPhoto from "@/components/listItem/Square-photo-description-event.vue";
import ButtonBorderIcon from "@/components/buttons/Button-icon-border.vue";
import InputText from "@/components/inputs/InputText.vue";
import InputTextArea from "@/components/inputs/InputTextarea.vue";
import Dropdown from "@/components/dropdowns/Dropdown-rounded.vue";
import DropdownFilter from "@/components/dropdowns/Dropdown.vue";
import LoadingElement from "@/components/LoadingElement.vue";
import http from "@/http";
import store from "@/store";
import PopupAlert from "@/views/common/PopupAlert.vue";

export default {
    components: {
        Menu,
        Container,
        Column,
        Block,
        Button,
        ButtonIcon,
        ButtonPhoto,
        ButtonBorderIcon,
        InputText,
        InputTextArea,
        Dropdown,
        LoadingElement,
        PopupAlert,
        DropdownFilter,
    },
    data() {
        return {
            text: "Add",
            templates: [],
            templateSelected: null,
            ambients: [],
            tags: [],
            loadAmbient: true,
            loadTags: true,
            inputAmbient: "",
            inputTag: "",
            customers: [],
            popupAlert: {
                show: false,
                title: "New Service",
                subTitle: "Creating a new service...",
                confirm: false,
                cancel: false,
                type: "loading",
            },
        };
    },
    methods: {
        addTemplate() {
            this.templates.push({
                name: "",
                public: 0,
                customer: 1,
                note: "",
                tags: [],
                ambients: [],
                file: null,
                image: null,
                error: false,
            });
            this.templateSelected = this.templates.length - 1;
        },
        deleteTemplate(index) {
            this.templates.splice(index, 1);
            this.templateSelected = 0;
            if (this.templates.length === 0) {
                this.addTemplate();
            }
        },
        selectProduct(index) {
            this.templateSelected = index;
            this.templates[this.templateSelected].error = false;
        },
        getTemplateName(index) {
            let name = this.templates[index].name.trim();
            if (name === "") {
                return "Template " + (index + 1);
            }
            return name;
        },
        eventUploadFile(event) {
            let file = event.target.files[0];
            let permitedExtensions = ["image/jpeg", "image/png"];
            if (file && permitedExtensions.includes(file.type)) {
                this.templates[this.templateSelected].file = file;
                this.templates[this.templateSelected].image =
                    URL.createObjectURL(file);
            }
        },
        updatePrivatePublic(value) {
            this.templates[this.templateSelected].public = value;
        },
        async getAmbients() {
            http.get("/template/ambient/get_all")
                .then((res) => {
                    this.ambients = res.data.ambients;
                    this.loadAmbient = false;
                })
                .catch((err) => {
                    debugger;
                    console.log(err);
                });
        },
        async getTags() {
            http.get("/template/tag/get_all")
                .then((res) => {
                    this.tags = res.data.tags;
                    this.loadTags = false;
                })
                .catch((err) => {
                    debugger;
                    console.log(err);
                });
        },
        async getCustomers() {
            http.get("/gets/customers/all/show")
                .then((res) => {
                    this.customers = res.data.customers;
                })
                .catch((err) => {
                    debugger;
                    console.log(err);
                });
        },
        getTemplateAmbients() {
            let ambients = [];
            this.templates[this.templateSelected].ambients.forEach((id) => {
                let ambient = this.ambients.find((a) => a.id === id);
                if (ambient) {
                    ambients.push(ambient);
                }
            });
            return ambients;
        },
        getTemplateTags() {
            let tags = [];
            this.templates[this.templateSelected].tags.forEach((id) => {
                let tag = this.tags.find((t) => t.id === id);
                if (tag) {
                    tags.push(tag);
                }
            });
            return tags;
        },
        submitInputAmbient() {
            let ambients = this.filterAmbients();
            if (ambients.length > 0) {
                this.addAmbient(ambients[0]);
            } else {
                this.createNewAmbient();
            }
        },
        submitInputTag() {
            let tags = this.filterTags();
            if (tags.length > 0) {
                this.addTag(tags[0]);
            } else {
                this.createNewTag();
            }
        },
        addAmbient(ambient) {
            this.templates[this.templateSelected].ambients.push(ambient.id);
        },
        addTag(tag) {
            this.templates[this.templateSelected].tags.push(tag.id);
        },
        removeAmbient(index) {
            this.templates[this.templateSelected].ambients.splice(index, 1);
        },
        removeTag(index) {
            this.templates[this.templateSelected].tags.splice(index, 1);
        },
        async createNewAmbient() {
            this.popupAlert.show = true;
            this.popupAlert.type = "create";
            this.popupAlert.title = "New Ambient";
            this.popupAlert.subTitle = "Creating a new ambient...";
            this.popupAlert.confirm = false;
            this.popupAlert.cancel = false;
            let data = {
                name: this.inputAmbient,
            };
            http.post("/template/ambient/add", data)
                .then((res) => {
                    this.popupAlert.subTitle = "Ambient created successfully!";
                    this.popupAlert.confirm = true;
                    this.popupAlert.cancel = false;
                    let ambient = res.data.ambient;
                    this.ambients.push(ambient);
                    this.inputAmbient = "";
                    this.addAmbient(ambient);
                })
                .catch((err) => {
                    debugger;
                    this.popupAlert.subTitle =
                        "Error creating ambient: " + err.message;
                    this.popupAlert.confirm = true;
                    this.popupAlert.cancel = false;
                    console.log(err);
                });
        },
        async createNewTag() {
            this.popupAlert.show = true;
            this.popupAlert.type = "create";
            this.popupAlert.title = "New Tag";
            this.popupAlert.subTitle = "Creating a new tag...";
            this.popupAlert.confirm = false;
            this.popupAlert.cancel = false;
            let data = {
                name: this.inputTag,
            };
            http.post("/template/tag/add", data)
                .then((res) => {
                    this.popupAlert.subTitle = "Tag created successfully!";
                    this.popupAlert.confirm = true;
                    this.popupAlert.cancel = false;
                    let tag = res.data.tag;
                    this.tags.push(tag);
                    this.inputTag = "";
                    this.addTag(tag);
                })
                .catch((err) => {
                    debugger;
                    this.popupAlert.subTitle =
                        "Error creating tag: " + err.message;
                    this.popupAlert.confirm = true;
                    this.popupAlert.cancel = false;
                    console.log(err);
                });
        },
        async postNewTemplate(template) {
            const formData = new FormData();
            let templatePublic = template.public === 0 ? 1 : 0;
            debugger;
            formData.append("public", templatePublic);
            formData.append("image", template.file);
            formData.append("name", template.name);
            formData.append("note", template.note);
            if (!templatePublic) {
                formData.append(
                    "id_customer",
                    this.customers[template.customer].id
                );
            }
            await http
                .post("/template/add", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((res) => {
                    template.id = res.data.template.id;
                })
                .catch((err) => {
                    debugger;
                    console.log(err);
                });
        },
        async postAmbientTemplate(template_id, ambient_id) {
            await http
                .post("/template/add_ambient", {
                    id_template: template_id,
                    id_ambient: ambient_id,
                })
                .then((res) => {
                    console.log(res);
                })
                .catch((err) => {
                    debugger;
                    console.log(err);
                });
        },
        async postTagTemplate(template_id, tag_id) {
            await http
                .post("/template/add_tag", {
                    id_template: template_id,
                    id_tag: tag_id,
                })
                .then((res) => {
                    console.log(res);
                })
                .catch((err) => {
                    debugger;
                    console.log(err);
                });
        },
        filterAmbients() {
            let ambients = this.ambients.filter((ambient) => {
                return (
                    ambient.name
                        .toLowerCase()
                        .indexOf(this.inputAmbient.toLowerCase()) !== -1
                );
            });
            ambients = ambients.filter((ambient) => {
                return !this.templates[this.templateSelected].ambients.includes(
                    ambient.id
                );
            });
            return ambients;
        },
        filterTags() {
            let tags = this.tags.filter((tag) => {
                return (
                    tag.name
                        .toLowerCase()
                        .indexOf(this.inputTag.toLowerCase()) !== -1
                );
            });
            tags = tags.filter((tag) => {
                return !this.templates[this.templateSelected].tags.includes(
                    tag.id
                );
            });
            return tags;
        },
        emitConfirm() {
            if (this.popupAlert.type === "end") {
                this.popupAlert.show = false;
                this.$router.push("/admin/catalog_template");
            }
            this.popupAlert.show = false;
        },
        getNamesCustomers() {
            return this.customers.map((c) => c.company);
        },
        updateCustomer(value) {
            this.templates[this.templateSelected].customer = value;
        },
        async eventButtonSend() {
            if (!this.checkSend()) {
                return;
            }
            console.log("Send");
            this.popupAlert.title = "Send Templates";
            this.popupAlert.subTitle = "Sending templates...";
            this.popupAlert.confirm = false;
            this.popupAlert.cancel = false;
            this.popupAlert.show = true;
            for (let i = 0; i < this.templates.length; i++) {
                let template = this.templates[i];
                this.popupAlert.subTitle = `Sending template ${this.getTemplateName(
                    i
                )}...`;
                await this.postNewTemplate(template);
                for (let j = 0; j < template.ambients.length; j++) {
                    await this.postAmbientTemplate(
                        template.id,
                        template.ambients[j]
                    );
                }
                for (let j = 0; j < template.tags.length; j++) {
                    await this.postTagTemplate(template.id, template.tags[j]);
                }
            }
            this.popupAlert.subTitle = "Templates sent successfully!";
            this.popupAlert.confirm = true;
            this.popupAlert.cancel = false;
            this.popupAlert.type = "end";
        },
        checkSend() {
            let error = false;
            this.templates.forEach((template) => (template.error = false));
            this.templates.forEach((template) => {
                if (template.file === null) {
                    template.error = true;
                    error = true;
                    let name = this.getTemplateName(
                        this.templates.indexOf(template)
                    );
                    this.popupAlert.subTitle = `Template ${name} has no image`;
                }
                if (template.ambients.length === 0) {
                    template.error = true;
                    error = true;
                    let name = this.getTemplateName(
                        this.templates.indexOf(template)
                    );
                    this.popupAlert.subTitle = `Template ${name} has no ambients`;
                }
                if (error) {
                    this.popupAlert.show = true;
                    this.popupAlert.title = "Error";
                    this.popupAlert.confirm = true;
                    this.popupAlert.cancel = false;
                    return;
                }
            });
            return !error;
        },
    },
    computed: {
        filteredAmbients() {
            return this.filterAmbients();
        },
        filteredTags() {
            return this.filterTags();
        },
    },
    created() {
        this.addTemplate();
        this.getAmbients();
        this.getTags();
        this.getCustomers();
    },
};
</script>

<style lang="scss" scoped>
.app {
    display: flex;
    height: 100vh;
}

.menuCatalogo {
    &_itens {
        margin-top: 10px;

        + .menuCatalogo_title {
            margin-top: 20px;
        }
    }

    &_item {
        margin-top: 5px;
        display: flex;
        align-items: stretch;

        .button {
            min-width: auto;
        }

        .buttonProd {
            flex: 1;
        }
        .buttonIcon {
            margin-left: 10px;
        }

        .buttonAdd {
            width: 100%;
        }

        .buttonError {
            border: 1px solid #ed1802;
        }

        &_header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 9px;
            padding-right: 3px;
            cursor: pointer;

            &_title {
                font-size: 13px;
                color: #fff;
            }

            &_icon {
                width: 20px;
                height: 20px;
                margin-left: 10px;
                display: flex;
                align-items: center;
                position: relative;

                > img {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .has-itens {
            &::before {
                content: "";
                left: -10px;
                background: #0264d8;
                min-width: 5px;
                height: 5px;
                position: absolute;
                border-radius: 50%;
            }
        }

        &_content {
            display: flex;
            flex-direction: column;
            padding: 0 5px;
            background: #272a30;
            border-radius: 0 0 4px 4px;

            ul {
                opacity: 0;
            }

            &_item {
                height: 0px;
                overflow: hidden;
                font-size: 13px;
                color: #fff;
                transition: 0.5s ease;
            }
        }

        &-open {
            background: #2e3238;
            border-radius: 4px;

            .menuCatalogo {
                &_item {
                    &_header {
                        &_icon {
                            > img {
                                transform: rotate(180deg);
                            }
                        }
                    }

                    &_content {
                        ul {
                            opacity: 1;
                        }

                        &_item {
                            height: 35px;
                            margin-top: 5px;

                            &:last-child {
                                margin-bottom: 5px;
                            }
                        }
                    }
                }
            }
        }

        .button {
            //max-width: 160px;
        }

        .button_text {
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-align: left;
            white-space: normal;
        }

        &:last-child {
            margin-bottom: 5px;
        }
    }
}
.row {
    display: flex;
    align-items: center;
    gap: 10px;
}

.column {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
    > * {
        flex: 1;
    }
}

.block {
    &:last-child {
        margin-bottom: 0;
    }
}

.list_buttons {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    gap: 10px;
    height: 100%;
}

.productScreen_mainPhoto {
    flex: 1;
    height: 100%;
}

.block-all {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    > * {
        &:last-child {
            flex: 1;
        }
    }
    .block_content {
        background: red;
    }
}

.simpleSectionnText {
    white-space: nowrap;
}

.productScreen_productinfo_item_header_title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .row {
        margin-bottom: 0px;
    }
}

.column-buttons {
    max-width: 200px;
}

.column-data {
    max-width: 710px;
}

.buttonSend {
    width: 100%;
    min-width: inherit;
}
.dropdownFilter {
    &:deep {
        .simpleDropdown {
            &_header {
                border-radius: 50px;
                border: 1px solid #666972;
                &:hover {
                    background: #3d424e !important;
                }
            }
            &_text {
                color: #ffffff;
            }
        }
    }
}
.simpleDropdown_header {
    border-radius: 50px;
}
</style>
