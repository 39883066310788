<template>
    <div
        class="productScreen_section"
        v-if="customizations_images.length > 0"
    >
        <div class="productScreen_section_line">
            <p class="productScreen_titleSection">360 Product View</p>
        </div>
        <Block
            title="Download Options"
            class="downloadOptions"
        >
            <div>
                <ul class="retailer_list">
                    <li
                        class="row"
                        v-for="item in getListRetailers()"
                        :key="'retailer_'+item.id"
                    >
                        <Toggle
                            :value="downloadRetailer[item.key].active"
                            @click="downloadRetailer[item.key].active = !downloadRetailer[item.key].active"
                        />
                        <p class="font-field">{{ item.name }}</p>
                        <InputText
                            v-if="item.note !== false"
                            placeholder="Note"
                            v-model="downloadRetailer[item.key].note"
                        />
                    </li>
                </ul>
                <ButtonBorderText
                    class="button_save"
                    @click="buttonRequestDownloadRetailer()"
                    noicon="true"
                    text="Send to email"
                    v-if="showButtonDownloadRetailer()"
                />
            </div>
        </Block>
        <div class="productScreen_servicesList">
            <ButtonBorderIcon
                class="button_left buttonScroll"
                icon="/imgs/icons/chevrons-left.svg"
                @click="scrollElement('scrollAutomationSystem', 'left')"
                v-if="scrollAutomationSystemAux.scrollX > 0"
            />
            <ul
                @scroll="verifyScrollAutomationSystem"
                id="scrollAutomationSystem"
            >
                <li
                    class="productScreen_servicesList_item"
                    v-for="(sv, i) in customizations"
                    :key="'sv_as_' + sv"
                >
                    <ButtonItemService
                        v-if="i === 0"
                        title="New preset"
                        subtitle=""
                        icon="/imgs/icons/plus.svg"
                        :active="selected == 0 && typeSelected == 'automation'"
                        @click="selectItem(i, 'automation')"
                    />
                    <ButtonItemServicePhoto
                        v-if="i > 0"
                        :photos="[
                            customizations_images[0]
                                ? customizations_images[0].reflection
                                : '',
                            customizations_images[0]
                                ? customizations_images[0].shadow
                                : '',
                            customizations_images[0]
                                ? customizations_images[0].beauty
                                : '',
                        ]"
                        :opacities="[
                            customizations[i].hasReflection
                                ? customizations[i].reflections.replace(
                                      '%',
                                      ''
                                  ) / 100
                                : 0,
                            customizations[i].hasShadow
                                ? customizations[i].dropShadow.replace(
                                      '%',
                                      ''
                                  ) / 100
                                : 0,
                            1,
                        ]"
                        :background="customizations[i].background"
                        :active="i == selected && typeSelected == 'automation'"
                        :alignCenter="true"
                        flag="A"
                        flagColor="#7d35ff"
                        :hasAction="true"
                        :actionEmit="true"
                        :actionIcon="'/imgs/icons/trash.svg'"
                        @click="selectItem(i, 'automation')"
                        @action="deleteItem(i, 'automation')"
                        crossorigin="anonymous"
                    />
                </li>
            </ul>
            <ButtonBorderIcon
                class="button_right buttonScroll"
                icon="/imgs/icons/chevrons-right.svg"
                @click="scrollElement('scrollAutomationSystem', 'right')"
                v-if="
                    scrollAutomationSystemAux.scrollX < 2 &&
                    scrollAutomationSystemAux.scrollX > -1
                "
            />
        </div>
        <div class="productScreen_section_grid">
            <div class="backgroundColorSelection backgroundColorSelection-block">
                <div class="backgroundColorSelection_header">
                    <p>iFrame Code (24 frames)</p>
                </div>
                <div class="backgroundColorSelection_content">
                    <p
                        class="text-likeInput cursor-pointer"
                        @click="copyTextIframeAutomation(24)"
                        v-if="selected > 0 && typeSelected == 'automation'"
                    >
                        &lt;iframe src=&quot;{{
                            server
                        }}/iframe/360?id_product={{
                            id
                        }}&amp;id_customization={{
                            customizations[selected].id
                        }}&quot;
                        title=&quot;ilustranext&quot;&gt;&lt;/iframe&gt;
                    </p>
                    <p
                        class="text-likeInput"
                        v-else
                    >Select Preset</p>
                </div>
            </div>
            <div class="backgroundColorSelection backgroundColorSelection-block">
                <div class="backgroundColorSelection_header">
                    <p>iFrame Code (48 frames)</p>
                </div>
                <div class="backgroundColorSelection_content">
                    <p
                        class="text-likeInput cursor-pointer"
                        @click="copyTextIframeAutomation(48)"
                        v-if="selected > 0 && typeSelected == 'automation'"
                    >
                        &lt;iframe src=&quot;{{
                            server
                        }}/iframe/360?id_product={{
                            id
                        }}&amp;id_customization={{
                            customizations[selected].id
                        }}&quot;
                        title=&quot;ilustranext&quot;&gt;&lt;/iframe&gt;
                    </p>
                    <p
                        class="text-likeInput"
                        v-else
                    >Select Preset</p>
                </div>
            </div>
        </div>
        <div class="backgroundColorSelection">
            <div class="backgroundColorSelection_header">
                <p>Background Color</p>
            </div>
            <div class="
                    backgroundColorSelection_content
                    backgroundColorSelection_content-flexList
                ">
                <ul>
                    <li
                        class="backgroundColorSelection_color"
                        :class="{
                            none: color == 'none',
                            active:
                                typeSelected == 'automation' &&
                                color == customizations[selected].background,
                        }"
                        v-for="color in backgroundsColors"
                        :key="'color_' + color"
                        :style="{ backgroundColor: color }"
                        @click="selectBackgroundColor(color)"
                    ></li>
                </ul>
                <ButtonIcon
                    text="More Colors"
                    :noicon="true"
                    background="#3d424e"
                    :active="colorPickerOn"
                    @click="colorPickerOn = !colorPickerOn"
                />
            </div>
            <Chrome
                v-model="colorpicker"
                class="colorPicker"
                v-if="colorPickerOn"
            />
        </div>
        <div class="productScreen_section_line">
            <p class="productScreen_titleSection marginTop">Product Settings</p>
        </div>
        <div class="">
            <div class="backgroundColorSelection backgroundColorSelection-block">
                <div class="backgroundColorSelection_header">
                    <p>Product Transform</p>
                </div>
                <div class="backgroundColorSelection_content">
                    <div class="backgroundColorSelection_content_slider">
                        <p class="label">Scale</p>
                        <Slider
                            @update="updateImageScale"
                            :myValue="customizations[selected].image_scale"
                            :min="-1"
                            :max="1"
                            :step="0.01"
                            key="sliderShadowSilo"
                            :enabled="typeSelected == 'automation'"
                        />
                        <span
                            class="inputSlider"
                            v-if="typeSelected == 'automation'"
                        >
                            <input
                                type="text"
                                v-model="customizations[selected].image_scale"
                                @change="
                                    formatScale(
                                        customizations[selected],
                                        'image_scale',
                                        0
                                    )
                                "
                            />
                        </span>
                        <p
                            class="fakeInput"
                            type="text"
                            v-else
                        >
                            {{ customizations[selected].image_scale }}
                        </p>
                    </div>
                    <div class="backgroundColorSelection_content_slider">
                        <p class="label">Horizontal Offset</p>
                        <Slider
                            @update="updateImageOffsetX"
                            :myValue="customizations[selected].image_offset_x"
                            :min="-1"
                            :max="1"
                            :step="0.01"
                            key="sliderShadowSilo"
                            :enabled="typeSelected == 'automation'"
                        />
                        <span
                            class="inputSlider"
                            v-if="typeSelected == 'automation'"
                        >
                            <input
                                type="text"
                                v-model="
                                    customizations[selected].image_offset_x
                                "
                                @change="
                                    formatScale(
                                        customizations[selected],
                                        'image_offset_x'
                                    )
                                "
                            />
                        </span>
                        <p
                            class="fakeInput"
                            type="text"
                            v-else
                        >
                            {{ customizations[selected].image_offset_x }}
                        </p>
                    </div>
                    <div class="backgroundColorSelection_content_slider">
                        <p class="label">Vertical Offset</p>
                        <Slider
                            @update="updateImageOffsetY"
                            :myValue="customizations[selected].image_offset_y"
                            :min="-1"
                            :max="1"
                            :step="0.01"
                            key="sliderShadowSilo"
                            :enabled="
                                customizations[selected].hasShadow &&
                                typeSelected == 'automation'
                            "
                        />
                        <span
                            class="inputSlider"
                            v-if="typeSelected == 'automation'"
                        >
                            <input
                                type="text"
                                v-model="
                                    customizations[selected].image_offset_y
                                "
                                @change="
                                    formatScale(
                                        customizations[selected],
                                        'image_offset_y'
                                    )
                                "
                            />
                        </span>
                        <p
                            class="fakeInput"
                            type="text"
                            v-else
                        >
                            {{ customizations[selected].image_offset_y }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="productScreen_section_grid">
            <div class="backgroundColorSelection backgroundColorSelection-block">
                <div class="backgroundColorSelection_header">
                    <p>Drop Shadow</p>
                </div>
                <div class="backgroundColorSelection_content">
                    <div class="backgroundColorSelection_content_buttons">
                        <ButtonIcon
                            text="With Shadow"
                            :active="
                                typeSelected == 'automation' &&
                                customizations[selected].hasShadow
                            "
                            :noicon="true"
                            @click="updateHasShadow(true)"
                        />
                        <ButtonIcon
                            text="Without Shadow"
                            :active="
                                typeSelected == 'automation' &&
                                !customizations[selected].hasShadow
                            "
                            :noicon="true"
                            @click="updateHasShadow(false)"
                        />
                    </div>
                    <div class="backgroundColorSelection_content_slider">
                        <Slider
                            @update="updateShadow"
                            :myValue="
                                typeSelected == 'automation'
                                    ? customizations[selected].dropShadow
                                    : 0
                            "
                            key="sliderShadowSilo"
                            :enabled="
                                typeSelected == 'automation' &&
                                customizations[selected].hasShadow
                            "
                        />
                        <span
                            class="inputSlider"
                            v-if="typeSelected == 'automation'"
                        >
                            <input
                                type="text"
                                v-model="customizations[selected].dropShadow"
                                @change="
                                    formatPercent(
                                        customizations[selected],
                                        'dropShadow'
                                    )
                                "
                            />
                        </span>
                        <p
                            type="text"
                            v-else
                        >
                            {{
                                typeSelected == "automation"
                                    ? customizations[selected].dropShadow
                                    : 0
                            }}%
                        </p>
                    </div>
                </div>
            </div>
            <div class="backgroundColorSelection backgroundColorSelection-block">
                <div class="backgroundColorSelection_header">
                    <p>Reflection</p>
                </div>
                <div class="backgroundColorSelection_content">
                    <div class="backgroundColorSelection_content_buttons">
                        <ButtonIcon
                            text="With Reflection"
                            :active="
                                typeSelected == 'automation' &&
                                customizations[selected].hasReflection
                            "
                            :noicon="true"
                            @click="updateHasReflections(true)"
                        />
                        <ButtonIcon
                            text="Without Reflection"
                            :active="
                                typeSelected == 'automation' &&
                                !customizations[selected].hasReflection
                            "
                            :noicon="true"
                            @click="updateHasReflections(false)"
                        />
                    </div>
                    <div class="backgroundColorSelection_content_slider">
                        <Slider
                            @update="updateReflection"
                            :myValue="
                                typeSelected == 'automation'
                                    ? customizations[selected].reflections
                                    : 0
                            "
                            :enabled="
                                typeSelected == 'automation' &&
                                customizations[selected].hasReflection
                            "
                            key="sliderRefrectiosSilo"
                        />
                        <span
                            class="inputSlider"
                            v-if="typeSelected == 'automation'"
                        >
                            <input
                                type="text"
                                v-model="customizations[selected].reflections"
                                @change="
                                    formatPercent(
                                        customizations[selected],
                                        'reflections'
                                    )
                                "
                            />
                        </span>
                        <p
                            type="text"
                            v-else
                        >
                            {{
                                typeSelected == "automation"
                                    ? customizations[selected].reflections
                                    : 0
                            }}%
                        </p>
                    </div>
                </div>
            </div>
            <div class="backgroundColorSelection backgroundColorSelection-block">
                <div class="backgroundColorSelection_header">
                    <p>Saturation</p>
                </div>
                <div class="backgroundColorSelection_content">
                    <div class="backgroundColorSelection_content_slider">
                        <Slider
                            @update="updateSaturation"
                            :myValue="customizations[selected].saturation"
                            max="200"
                            key="sliderShadowSilo"
                            :enabled="typeSelected == 'automation'"
                        />
                        <span
                            class="inputSlider"
                            v-if="typeSelected == 'automation'"
                        >
                            <input
                                type="text"
                                v-model="customizations[selected].saturation"
                                @change="
                                    formatPercent(
                                        customizations[selected],
                                        'saturation'
                                    )
                                "
                            />
                        </span>
                        <p
                            type="text"
                            v-else
                        >
                            {{ customizations[selected].saturation }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="backgroundColorSelection backgroundColorSelection-block">
                <div class="backgroundColorSelection_header">
                    <p>Brightness</p>
                </div>
                <div class="backgroundColorSelection_content">
                    <div class="backgroundColorSelection_content_slider">
                        <Slider
                            @update="updateBrightness"
                            :myValue="customizations[selected].brightness"
                            max="200"
                            key="sliderShadowSilo"
                            :enabled="typeSelected == 'automation'"
                        />
                        <span
                            class="inputSlider"
                            v-if="typeSelected == 'automation'"
                        >
                            <input
                                type="text"
                                v-model="customizations[selected].brightness"
                                @change="
                                    formatPercent(
                                        customizations[selected],
                                        'brightness'
                                    )
                                "
                            />
                        </span>
                        <p
                            type="text"
                            v-else
                        >
                            {{ customizations[selected].brightness }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="productScreen_section noBorderBotton">
        <div class="productScreen_section_line">
            <p class="productScreen_titleSection">Images Uploaded</p>
            <ButtonIcon
                text="Download All Images"
                icon="/imgs/icons/download.svg"
                @click="downloadUploadImages"
                inverted="true"
                class="buttonSection"
            />
        </div>
        <div class="productScreen_servicesList">
            <ButtonBorderIcon
                class="button_left buttonScroll"
                icon="/imgs/icons/chevrons-left.svg"
                @click="scrollElement('scrollImagemUploaded', 'left')"
                v-if="scrollImageUploadedAux.scrollX > 0"
            />
            <ul
                @scroll="verifyScrollImagemUploaded"
                id="scrollImagemUploaded"
            >
                <li
                    class="productScreen_servicesList_item"
                    v-if="permissions['editor-360-access']"
                >
                    <ButtonItemService
                        title="Upload"
                        subtitle=""
                        icon="/imgs/icons/alert.svg"
                        @click="openModalUpload"
                    />
                </li>
                <li
                    class="productScreen_servicesList_item"
                    v-for="(imagesUploadedSilo, i) in uploadImages"
                    :key="'imagesUploadedSilo_' + i"
                >
                    <ButtonItemServicePhoto
                        :photo="imagesUploadedSilo.photo[0]"
                        :active="i == selected && typeSelected == 'manual'"
                        :alignCenter="true"
                        @click="selectItem(i, 'manual')"
                        crossorigin="anonymous"
                    />
                </li>
            </ul>
            <ButtonBorderIcon
                class="button_right buttonScroll"
                icon="/imgs/icons/chevrons-right.svg"
                @click="scrollElement('scrollImagemUploaded', 'right')"
                v-if="
                    scrollImageUploadedAux.scrollX < 2 &&
                    scrollImageUploadedAux.scrollX > -1
                "
            />
        </div>

        <div class="backgroundColorSelection backgroundColorSelection-block">
            <div class="backgroundColorSelection_header">
                <p>iFrame Code (24 frames)</p>
            </div>
            <div class="backgroundColorSelection_content">
                <p
                    class="text-likeInput cursor-pointer"
                    @click="copyTextIframeUploaded()"
                    v-if="typeSelected != 'automation'"
                >
                    &lt;iframe src=&quot;{{ server }}/iframe/360?id_product={{
                        id
                    }}&amp;id_customization={{
                        uploadImages[selected].id
                    }}&amp;uploadimage=1&quot;
                    title=&quot;ilustranext&quot;&gt;&lt;/iframe&gt;
                </p>
                <p
                    class="text-likeInput"
                    v-else
                >Select Uploaded group</p>
            </div>
        </div>
    </div>
    <ButtonBorderText
        class="button_save"
        @click="saveAutomation()"
        v-if="
            edited &&
            typeSelected == 'automation' &&
            permissions['editor-360-access']
        "
        noicon="true"
        text="Save"
    />
    <PopupAlert
        v-if="popupAlert.show"
        :title="popupAlert.title"
        :subTitle="popupAlert.subTitle"
        :confirm="popupAlert.confirm"
        :cancel="popupAlert.cancel"
        @confirm="emitConfirm"
        @cancel="emitCancel"
    />
    <PopupUpload
        v-if="modalUpload.show"
        :title="'Upload Image'"
        :subTitle="'Drag and drop your image here'"
        :confirm="true"
        :cancel="true"
        @confirm="emitConfirmUpload"
        @cancel="emitCancelUpload"
    />
</template>

<script>
import { Chrome } from "@ckpack/vue-color";
import ButtonIcon from "@/components/buttons/Button-icon-text.vue";
import ButtonBorderIcon from "@/components/buttons/Button-icon-border.vue";
import ButtonBorderText from "@/components/buttons/Button-text-border.vue";
import ButtonItemService from "@/components/listItem/Icon-title-subtitle.vue";
import ButtonItemServicePhoto from "@/components/listItem/Square-photo-description-event.vue";
import Slider from "@/components/inputs/Slider.vue";
import PopupAlert from "../common/PopupAlert.vue";
import Block from "@/components/sections/Block.vue";
import Toggle from "@/components/inputs/Toggle.vue";
import InputText from "@/components/inputs/InputText.vue";
import PopupUpload from "../common/PopupUpload.vue";
import http from "@/http";
import preloadImages from "@/utils/preloadImages";
import loadingController from "@/utils/loadingController";
import sleep from "@/utils/sleep";

const lController = new loadingController();

export default {
    components: {
        ButtonIcon,
        ButtonBorderIcon,
        ButtonBorderText,
        ButtonItemService,
        ButtonItemServicePhoto,
        Slider,
        PopupAlert,
        PopupUpload,
        Chrome,
        Block,
        Toggle,
        InputText,
    },
    props: ["id", "customer", "uploadImages", "photo"],
    data() {
        return {
            selected: 0,
            edited: false,
            requestedService: false,
            typeSelected: "automation",
            colorpicker: "#ffffff",
            colorPickerOn: false,
            server: process.env.VUE_APP_URL_API,
            backgroundsColors: [
                "none",
                "#ffffff",
                "#bababa",
                "#414141",
                "#000000",
                "#b4cf66",
                "#ffec5c",
                "#0897b4",
                "#f3d3b5",
                "#f0941f",
                "#ef6024",
                "#6df2ff",
                "#872626",
                "#efd806",
                "#c4eef2",
                "#a67458",
            ],
            customizations_images: [],
            customizations: [
                {
                    background: "#ffffff",
                    hasShadow: true,
                    dropShadow: "35%",
                    hasReflection: true,
                    reflections: "10%",
                    saturation: "100%",
                    brightness: "100%",
                    image_scale: 0,
                    image_offset_x: 0,
                    image_offset_y: 0,
                    id: 0,
                },
            ],
            scrollImageUploadedAux: {
                scrollX: 0,
            },
            scrollAutomationSystemAux: {
                scrollX: 0,
            },
            popupAlert: {
                show: false,
                title: "Automation System",
                subTitle: "Saving...",
                confirm: false,
                cancel: false,
                type: "save",
            },
            modalUpload: {
                show: false,
            },
            permissions: {
                "editor-360-access": false,
                "send-products-for-automation": false,
            },
            downloadRetailer: {},
        };
    },
    methods: {
        getDataAutomation() {
            // $route.params.id
            http.get(
                `/gets/product/automation/360/config/show?id_product=${this.id}&id_customer=${this.customer}`
            )
                .then((r) => {
                    if (r.data.success) {
                        r.data.product_config_360.forEach((item) => {
                            this.customizations.push({
                                id: item.id,
                                images: [
                                    {
                                        beauty: "/beauty.png",
                                        shadow: "/shadow.png",
                                        reflection: "/reflections.png",
                                    },
                                ],
                                background: item.background,
                                hasShadow: item.hasShadow === "1",
                                dropShadow: `${item.shadow}%`,
                                hasReflection: item.hasReflection === "1",
                                reflections: `${item.reflection}%`,
                                saturation: `${item.saturation}%`,
                                brightness: `${item.brightness}%`,
                                image_scale: item.image_scale,
                                image_offset_x: item.image_offset_x,
                                image_offset_y: item.image_offset_y,
                            });
                        });
                    }
                    this.completeTaskLoad();
                })
                .catch((r) => {
                    console.log("Error:");
                    console.log(r);
                });
        },
        getDownloadRetailer() {
            http.get(`/gets/retailer/list`)
                .then((r) => {
                    if (r.data.success) {
                        r.data.data.forEach((item) => {
                            let key = item.retailer
                                .toLowerCase()
                                .replace(" ", "_");
                            this.downloadRetailer[key] = {
                                active: false,
                                note:
                                    typeof item.file_name == "string" &&
                                    item.file_name.includes("<note>")
                                        ? ""
                                        : false,
                                id: item.id,
                                name: item.retailer,
                            };
                        });
                    }
                    this.downloadRetailer.fourK = {
                        active: false,
                        id: 0,
                        name: "4K Images",
                        note: false,
                    };
                })
                .catch((r) => {
                    console.log("Error:");
                    console.log(r);
                });
        },
        getListRetailers() {
            let list = [];
            for (const key in this.downloadRetailer) {
                list.push({
                    id: this.downloadRetailer[key].id,
                    active: this.downloadRetailer[key].active,
                    note: this.downloadRetailer[key].note,
                    name: this.downloadRetailer[key].name,
                    key: key,
                });
            }
            return list;
        },
        getImagesSilo() {
            // $route.params.id
            http.get(
                `/gets/product/automation/360/images/show?id_product=${this.id}&id_customer=${this.customer}`
            )
                .then((r) => {
                    if (r.data.success) {
                        const imagesToPreload = [];
                        r.data.images_automation.forEach((image) => {
                            this.customizations_images.push({
                                beauty: `${
                                    process.env.VUE_APP_AWS_BUCKET_IMAGES
                                }${image.beauty.replace(
                                    ".png",
                                    "_thumb.png?type=siloimage"
                                )}`,
                                shadow: `${
                                    process.env.VUE_APP_AWS_BUCKET_IMAGES
                                }${image.shadow.replace(".png", "_thumb.png")}`,
                                reflection: `${
                                    process.env.VUE_APP_AWS_BUCKET_IMAGES
                                }${image.reflection.replace(
                                    ".png",
                                    "_thumb.png"
                                )}`,
                            });
                            imagesToPreload.push(
                                `${
                                    process.env.VUE_APP_AWS_BUCKET_IMAGES
                                }${image.beauty.replace(
                                    ".png",
                                    "_thumb.png?type=siloimage"
                                )}`
                            );
                            imagesToPreload.push(
                                `${
                                    process.env.VUE_APP_AWS_BUCKET_IMAGES
                                }${image.shadow.replace(".png", "_thumb.png")}`
                            );
                            imagesToPreload.push(
                                `${
                                    process.env.VUE_APP_AWS_BUCKET_IMAGES
                                }${image.reflection.replace(
                                    ".png",
                                    "_thumb.png"
                                )}`
                            );
                        });
                        preloadImages(imagesToPreload);
                        this.emitUpdateSilo();
                        this.completeTaskLoad();
                    }
                })
                .catch((r) => {
                    console.log("Error:");
                    console.log(r);
                });
        },
        postDataAutomation() {
            http.post("/posts/product/automation/360/config/store", {
                id_product: this.id,
                id_customer: this.customer,
                background: this.customizations[this.selected].background,
                hasShadow: this.customizations[this.selected].hasShadow ? 1 : 0,
                shadow: this.customizations[this.selected].dropShadow
                    .toString()
                    .replace("%", ""),
                hasReflection: this.customizations[this.selected].hasReflection
                    ? 1
                    : 0,
                reflection: this.customizations[this.selected].reflections
                    .toString()
                    .replace("%", ""),
                saturation: this.customizations[this.selected].saturation
                    .toString()
                    .replace("%", ""),
                brightness: this.customizations[this.selected].brightness
                    .toString()
                    .replace("%", ""),
                image_scale: this.customizations[this.selected].image_scale,
                image_offset_x:
                    this.customizations[this.selected].image_offset_x,
                image_offset_y:
                    this.customizations[this.selected].image_offset_y,
            })
                .then((r) => {
                    if (r.data.success) {
                        this.popupAlert.subTitle = "Saved!";
                        this.popupAlert.confirm = true;
                        this.selected =
                            this.customizations.push({
                                id: r.data.id_product_config,
                                images: [
                                    {
                                        beauty: "/beauty.png",
                                        shadow: "/shadow.png",
                                        reflection: "/reflections.png",
                                    },
                                ],
                                background:
                                    this.customizations[this.selected]
                                        .background,
                                hasShadow:
                                    this.customizations[this.selected]
                                        .hasShadow,
                                dropShadow:
                                    this.customizations[this.selected]
                                        .dropShadow,
                                hasReflection:
                                    this.customizations[this.selected]
                                        .hasReflection,
                                reflections:
                                    this.customizations[this.selected]
                                        .reflections,
                                saturation:
                                    this.customizations[this.selected]
                                        .saturation,
                                brightness:
                                    this.customizations[this.selected]
                                        .brightness,
                                image_scale:
                                    this.customizations[this.selected]
                                        .image_scale,
                                image_offset_x:
                                    this.customizations[this.selected]
                                        .image_offset_x,
                                image_offset_y:
                                    this.customizations[this.selected]
                                        .image_offset_y,
                            }) - 1;
                        this.customizations[this.selected].id =
                            r.data.id_product_config;
                        this.edited = false;
                    } else {
                        this.popupAlert.subTitle = `Error: ${r.data.message}`;
                        this.popupAlert.confirm = true;
                    }
                })
                .catch((r) => {
                    console.log("Error:");
                    console.log(r);
                });
        },
        async postImageUpload(file, id) {
            const response = {
                success: false,
                message: "",
                path: "",
            };
            // const filesSend = [];
            // filesSend.push(file);
            const formData = new FormData();
            formData.append("file_image", file);
            formData.append("id_product", this.id);
            formData.append("id_customer", this.customer);
            formData.append("id_folder", id);
            await http
                .post("/posts/product/images-uploaded/360/store", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((r) => {
                    response.success = r.data.success;
                    response.message = r.data.message;
                    if (r.data.success) {
                        response.path = r.data.path_image;
                        // this.uploadImages.push({
                        //  photo: `${process.env.VUE_APP_AWS_BUCKET_IMAGES}${r.data.path_image}`,
                        // });
                    }
                })
                .catch((r) => {
                    console.log("Error:");
                    console.log(r);
                    response.success = false;
                    response.message = r;
                });
            return response;
        },
        sendTailorRequest() {
            if (!this.requestedService) {
                http.post("/posts/email/request/service/tailor/send", {
                    id_product: this.id,
                    id_customer: this.customer,
                    page: "360 Image",
                })
                    .then((r) => {
                        if (r.data.success) {
                            this.popupAlert.subTitle =
                                "Request sent! We will get in touch with you soon";
                            this.popupAlert.title = "Tailor Request";
                            this.popupAlert.confirm = true;
                            this.popupAlert.type = "request";
                            this.popupAlert.show = true;
                            const data = new Date();
                            this.requestedService = true;
                            localStorage.setItem(
                                "360_request",
                                `${data.getFullYear()}-${
                                    data.getMonth() + 1
                                }-${data.getDate()}`
                            );
                        } else {
                            this.popupAlert.subTitle = `Error: ${r.data.message}`;
                            this.popupAlert.confirm = true;
                        }
                    })
                    .catch((r) => {
                        console.log("Error:");
                        console.log(r);
                    });
            }
        },
        defineTailorRequest() {
            const value = localStorage.getItem("360_request");
            if (value != null) {
                const data = new Date(value);
                const now = new Date();
                const dCompare = new Date(
                    now.getFullYear(),
                    now.getMonth(),
                    now.getDate()
                );
                if (data >= dCompare) {
                    this.requestedService = true;
                }
            }
            this.completeTaskLoad();
        },
        updateDataAutomation() {
            http.put("/puts/product/automation/360/config/edit", {
                id_product: this.id,
                id_customer: this.customer,
                id_product_config: this.customizations[this.selected].id,
                background: this.customizations[this.selected].background,
                hasShadow: this.customizations[this.selected].hasShadow ? 1 : 0,
                shadow: this.customizations[this.selected].dropShadow
                    .toString()
                    .replace("%", ""),
                hasReflection: this.customizations[this.selected].hasReflection
                    ? 1
                    : 0,
                reflection: this.customizations[this.selected].reflections
                    .toString()
                    .replace("%", ""),
                saturation: this.customizations[this.selected].saturation
                    .toString()
                    .replace("%", ""),
                brightness: this.customizations[this.selected].brightness
                    .toString()
                    .replace("%", ""),
                image_scale: this.customizations[this.selected].image_scale,
                image_offset_x:
                    this.customizations[this.selected].image_offset_x,
                image_offset_y:
                    this.customizations[this.selected].image_offset_y,
            })
                .then((r) => {
                    if (r.data.success) {
                        this.popupAlert.subTitle = "Saved!";
                        this.popupAlert.confirm = true;
                        this.customizations[this.selected].id =
                            r.data.id_product_config;
                        this.edited = false;
                    } else {
                        this.popupAlert.subTitle = `Error: ${r.data.message}`;
                        this.popupAlert.confirm = true;
                    }
                })
                .catch((r) => {
                    console.log("Error:");
                    console.log(r);
                    this.popupAlert.subTitle = `Error: ${r.message}`;
                    this.popupAlert.confirm = true;
                });
        },
        deleteDataAutomation(i) {
            this.popupAlert.subTitle = "Deleting...";
            this.popupAlert.confirm = false;
            this.popupAlert.cancel = false;
            http.delete(
                `${
                    process.env.VUE_APP_URL_API
                }/deletes/product/automation/360/config/delete?id_product_config=${
                    this.customizations[this.selected].id
                }&id_product=${this.id}&id_customer=${this.customer}`
            )
                .then((r) => {
                    this.popupAlert.confirm = true;
                    this.popupAlert.cancel = false;
                    this.popupAlert.type = "confirm";
                    if (r.data.success) {
                        this.popupAlert.subTitle = "Deleted!";
                        this.customizations.splice(i, 1);
                        this.selected = 0;
                    } else {
                        this.popupAlert.subTitle = `Error: ${r.data.message}`;
                    }
                })
                .catch((r) => {
                    console.log("Error:");
                    console.log(r);
                    if (r.response.data.message)
                        this.popupAlert.subTitle = `Error: ${r.response.data.message}`;
                    else this.popupAlert.subTitle = `Error: ${r.message}`;
                    this.popupAlert.confirm = true;
                });
        },
        saveAutomation() {
            if (this.customizations[this.selected].id === 0) {
                this.popupAlert.title = "Automation System";
                this.popupAlert.subTitle = "Creating...";
                this.popupAlert.show = true;
                this.popupAlert.type = "save";
                this.popupAlert.confirm = false;
                this.popupAlert.cancel = false;
                this.postDataAutomation();
            } else {
                this.popupAlert.title = "Automation System";
                this.popupAlert.subTitle = "Updating...";
                this.popupAlert.show = true;
                this.popupAlert.type = "save";
                this.popupAlert.confirm = false;
                this.popupAlert.cancel = false;
                this.updateDataAutomation();
            }
        },
        downloadZipUploadedImages(zipName) {
            http.get(
                `/gets/product/download/zip/images-uploaded?zip_name=${zipName}`
            )
                .then((r) => {
                    if (r.data.success) {
                        window.open(r.data.url, "_blank");
                    } else {
                        this.popupAlert.subTitle = `Error: ${r.data.message}`;
                        this.popupAlert.confirm = true;
                    }
                })
                .catch((r) => {
                    console.log("Error:");
                    console.log(r);
                });
        },
        downloadUploadImages() {
            this.popupAlert.title = "Download Images";
            this.popupAlert.subTitle = "Preparing images do download...";
            this.popupAlert.show = true;
            this.popupAlert.type = "download";
            this.popupAlert.confirm = false;
            this.popupAlert.cancel = false;
            http.get(
                `/gets/product/zip/360/images-uploaded?id_product=${
                    this.id
                }&id_customer=${this.customer}&id_image=${
                    this.uploadImages[this.selected].id
                }`
            )
                .then((r) => {
                    if (r.data.success) {
                        window.open(
                            `${process.env.VUE_APP_URL_API}/gets/product/download/zip/images-uploaded?name_zip=${r.data.name_zip}`,
                            "_blank"
                        );
                        this.popupAlert.subTitle = "Downloaded!";
                        this.popupAlert.show = false;
                    } else {
                        this.popupAlert.subTitle = `Error: ${r.data.message}`;
                        this.popupAlert.confirm = true;
                        this.popupAlert.cancel = false;
                        this.popupAlert.type = "confirm";
                        this.popupAlert.show = true;
                    }
                })
                .catch((r) => {
                    console.log("Error:");
                    console.log(r);
                    debugger;
                    this.popupAlert.subTitle = `Error: ${r.message}`;
                    this.popupAlert.confirm = true;
                    this.popupAlert.cancel = false;
                    this.popupAlert.type = "confirm";
                });
        },
        verifyScrollImagemUploaded() {
            const element = document.getElementById("scrollImagemUploaded");
            const scrollX = element.scrollLeft;
            const sizeScroll = element.scrollWidth - element.offsetWidth;
            if (scrollX === 0 && scrollX === sizeScroll)
                this.scrollImageUploadedAux.scrollX = -1;
            else if (scrollX === 0) this.scrollImageUploadedAux.scrollX = 0;
            else if (scrollX === sizeScroll)
                this.scrollImageUploadedAux.scrollX = 2;
            else this.scrollImageUploadedAux.scrollX = 1;
        },
        verifyScrollAutomationSystem() {
            const element = document.getElementById("scrollAutomationSystem");
            const scrollX = element.scrollLeft;
            const sizeScroll = element.scrollWidth - element.offsetWidth;
            if (scrollX === 0 && scrollX === sizeScroll)
                this.scrollAutomationSystemAux.scrollX = -1;
            else if (scrollX === 0) this.scrollAutomationSystemAux.scrollX = 0;
            else if (scrollX === sizeScroll)
                this.scrollAutomationSystemAux.scrollX = 2;
            else this.scrollAutomationSystemAux.scrollX = 1;
        },
        /* eslint-disable no-param-reassign */
        scrollElement(id, direction) {
            const element = document.getElementById(id);
            if (direction === "left") {
                element.scrollBy({
                    left: -100,
                    behavior: "smooth",
                });
            } else {
                element.scrollBy({
                    left: 100,
                    behavior: "smooth",
                });
            }
        },
        /* eslint-enable no-param-reassign */
        selectItem(i, type) {
            this.selected = i;
            this.typeSelected = type;
            this.emitUpdateSilo();
        },
        selectBackgroundColor(color) {
            if (this.typeSelected === "automation") {
                this.customizations[this.selected].background = color;
                this.edited = true;
                this.emitUpdateSilo();
            }
        },
        emitUpdateSilo() {
            if (this.typeSelected === "automation") {
                this.$emit("update", {
                    background: this.customizations[this.selected].background,
                    hasShadow: this.customizations[this.selected].hasShadow,
                    dropShadow: this.customizations[this.selected].dropShadow
                        .toString()
                        .replace("%", ""),
                    hasReflection:
                        this.customizations[this.selected].hasReflection,
                    reflection: this.customizations[this.selected].reflections
                        .toString()
                        .replace("%", ""),
                    saturation: this.customizations[this.selected].saturation,
                    brightness: this.customizations[this.selected].brightness,
                    image_scale: this.customizations[this.selected].image_scale,
                    image_offset_x:
                        this.customizations[this.selected].image_offset_x,
                    image_offset_y:
                        this.customizations[this.selected].image_offset_y,
                    images: this.customizations_images,
                    id: this.customizations[this.selected].id,
                });
                // this.$emit('update', this.customizations[this.selected]);
            } else {
                console.log(this.uploadImages[this.selected].photo);
                const photos = [];
                this.uploadImages[this.selected].photo.forEach((p) => {
                    photos.push({
                        beauty: p,
                        shadow: p,
                        reflection: p,
                    });
                });
                this.$emit("update", {
                    images: photos,
                    background: "#ffffff",
                    hasShadow: false,
                    dropShadow: 0,
                    hasReflection: false,
                    reflections: 0,
                });
            }
        },
        formatScale(obj, key, min = -1, max = 1) {
            const aux = parseInt(obj[key]);
            if (aux > max) obj[key] = max;
            if (aux < min) obj[key] = min;
            this.emitUpdateSilo();
        },
        updateKeyPropertie(value, key) {
            if (this.typeSelected === "automation") {
                this.edited = true;
                this.customizations[this.selected][key] = `${value}`;
                this.emitUpdateSilo();
            }
        },
        updateImageScale(value) {
            this.updateKeyPropertie(value, "image_scale");
        },
        updateImageOffsetX(value) {
            this.updateKeyPropertie(value, "image_offset_x");
        },
        updateImageOffsetY(value) {
            this.updateKeyPropertie(value, "image_offset_y");
        },
        updateShadow(value) {
            if (this.typeSelected === "automation") {
                this.edited = true;
                this.customizations[this.selected].dropShadow = `${value}%`;
                this.emitUpdateSilo();
            }
        },
        updateSaturation(value) {
            if (this.typeSelected === "automation") {
                this.edited = true;
                this.customizations[this.selected].saturation = `${value}%`;
                this.emitUpdateSilo();
            }
        },
        updateBrightness(value) {
            if (this.typeSelected === "automation") {
                this.edited = true;
                this.customizations[this.selected].brightness = `${value}%`;
                this.emitUpdateSilo();
            }
        },
        updateReflection(value) {
            if (this.typeSelected === "automation") {
                this.edited = true;
                this.customizations[this.selected].reflections = `${value}%`;
                this.emitUpdateSilo();
            }
        },
        updateHasShadow(value) {
            if (this.typeSelected === "automation") {
                this.edited = true;
                this.customizations[this.selected].hasShadow = value;
                this.emitUpdateSilo();
            }
        },
        updateHasReflections(value) {
            if (this.typeSelected === "automation") {
                this.edited = true;
                this.customizations[this.selected].hasReflection = value;
                this.emitUpdateSilo();
            }
        },
        deleteItem(index, type) {
            if (type === "automation") {
                this.popupAlert.show = true;
                this.popupAlert.title = "Delete Automation";
                this.popupAlert.subTitle =
                    "Are you sure you want to delete this automation?";
                this.popupAlert.confirm = true;
                this.popupAlert.cancel = true;
                this.popupAlert.type = "deleteAutomation";
            } else {
                // Todo: delete image
            }
        },
        openModalUpload() {
            this.modalUpload.show = true;
        },
        emitConfirm() {
            if (this.popupAlert.type === "deleteAutomation") {
                this.deleteDataAutomation(this.selected);
            } else if (this.popupAlert.type === "save") {
                this.popupAlert.confirm = false;
                this.popupAlert.show = false;
            } else {
                this.popupAlert.confirm = false;
                this.popupAlert.show = false;
            }
        },
        emitCancel() {
            this.popupAlert.show = false;
        },
        /* eslint-disable */
        async getIdNewImageUploaded() {
            const response = {
                success: false,
                message: "",
            };
            await http
                .get(
                    `/gets/product/images_uploaded/360/newsection?id_product=${this.id}&id_customer=${this.customer}`
                )
                .then((r) => {
                    if (r.data.success) {
                        response.success = true;
                        response.message = r.data.new_id;
                    } else {
                        response.message = r.data.message;
                    }
                })
                .catch((r) => {
                    response.message = r.message;
                });
            console.log(response);
            return response;
        },
        async emitConfirmUpload(files) {
            this.modalUpload.show = false;
            this.popupAlert.show = true;
            this.popupAlert.title = "Uploading...";
            this.popupAlert.subTitle =
                "Creating new image group, please wait...";
            this.popupAlert.confirm = false;
            this.popupAlert.cancel = false;
            this.popupAlert.subTitle = "";

            const response = await this.getIdNewImageUploaded();
            //debugger;
            if (!response.success) {
                this.popupAlert.title = "Error";
                this.popupAlert.subTitle = response.message;
                this.popupAlert.confirm = true;
                this.popupAlert.cancel = false;
                return;
            }

            for (let i = 0; i < files.length; i += 1) {
                this.popupAlert.subTitle = `Uploading ${i + 1} of ${
                    files.length
                } images`;
                const result = await this.postImageUpload(
                    files[i],
                    response.message
                );
                if (!result.success) {
                    this.popupAlert.title = "Error";
                    this.popupAlert.subTitle = result.message;
                    this.popupAlert.confirm = true;
                    this.popupAlert.cancel = false;
                    this.popupAlert.type = "error";
                    return;
                }
                if (i == 0) {
                    this.$emit(
                        "updateImageUpload",
                        `${process.env.VUE_APP_AWS_BUCKET_IMAGES}${result.path}`
                    );
                }
            }
            this.popupAlert.subTitle = "Upload complete";
            this.popupAlert.confirm = true;
            this.popupAlert.type = "upload";

            /* eslint-enable */
        },
        emitCancelUpload() {
            this.modalUpload.show = false;
        },
        downloadAutomationImages() {
            this.$emit("downloadAutomationBatch");
            // const images = this.customizations_images;
            // const zip = new JSZip();
            // const img = zip.folder('images');
            // for (let i = 0; i < images.length; i += 1) {
            //  img.file(`beauty_${i}.png`, images[i].beauty, { base64: true });
            //  img.file(`shadow_${i}.png`, images[i].shadow, { base64: true });
            //  img.file(`reflection_${i}.png`, images[i].reflection, { base64: true });
            // }
            // zip.generateAsync({ type: 'blob' }).then((content) => {
            //  saveAs(content, 'images.zip');
            // });
        },
        async completeTaskLoad() {
            lController.completeTask();
            this.popupAlert.subTitle = `Please wait. completed ${lController.getProgress()}%`;
            if (lController.getStatus() === "ended") {
                await sleep(1000);
                this.popupAlert.show = false;
            }
        },
        copyTextIframeAutomation(frames) {
            navigator.clipboard.writeText(
                `<iframe src="${this.server}/iframe/360?id_product=${
                    this.id
                }&id_customization=${
                    this.customizations[this.selected].id
                }&frames=${frames}" title="ilustranext"></iframe>`
            );
            this.popupAlert.title = "Copied";
            this.popupAlert.subTitle =
                "The text has been copied to the clipboard";
            this.popupAlert.confirm = true;
            this.popupAlert.cancel = false;
            this.popupAlert.show = true;
        },
        copyTextIframeUploaded() {
            navigator.clipboard.writeText(
                `<iframe src="${this.server}/iframe/360?id_product=${
                    this.id
                }&id_customization=${
                    this.customizations[this.selected].id
                }&uploadimage=1" title="ilustranext"></iframe>`
            );
            this.popupAlert.title = "Copied";
            this.popupAlert.subTitle =
                "The text has been copied to the clipboard";
            this.popupAlert.confirm = true;
            this.popupAlert.cancel = false;
            this.popupAlert.show = true;
        },
        formatPercent(obj, key) {
            console.log(obj, key);
            if (obj[key].includes("%")) {
                this.emitUpdateSilo();
                return;
            }
            obj[key] = `${obj[key]}%`;
            this.emitUpdateSilo();
        },
        async setupAccess() {
            const slugs = [];
            Object.keys(this.permissions).forEach((key) => {
                slugs.push(key);
            });

            const access = await this.$userAccess.to(slugs);
            Object.keys(this.permissions).forEach((key) => {
                this.permissions[key] = access[key];
            });
        },
        checkDataDownloadRetailer() {
            let result = {
                valid: true,
                message: "",
            };
            if (!this.selected) {
                result = {
                    valid: false,
                    message: "Please select or create a preset.",
                };
            }
            Object.keys(this.downloadRetailer).forEach((key) => {
                if (this.downloadRetailer[key].active) {
                    debugger;
                    if (
                        this.downloadRetailer[key].note !== false &&
                        this.downloadRetailer[key].note == ""
                    ) {
                        result = {
                            valid: false,
                            message: `Please fill input for ${key}`,
                        };
                    }
                }
            });
            return result;
        },
        async buttonRequestDownloadRetailer() {
            const check = this.checkDataDownloadRetailer();
            if (!check.valid) {
                this.popupAlert.title = "Error";
                this.popupAlert.subTitle = check.message;
                this.popupAlert.confirm = true;
                this.popupAlert.cancel = false;
                this.popupAlert.type = "error";
                this.popupAlert.show = true;
                return;
            }
            const toSend = Object.keys(this.downloadRetailer).filter(
                (key) => this.downloadRetailer[key].active
            );
            this.popupAlert.title = "Requesting Download Automation";
            this.popupAlert.subTitle = "Please wait.";
            this.popupAlert.confirm = false;
            this.popupAlert.cancel = false;
            this.popupAlert.show = true;
            for (let i = 0; i < toSend.length; i++) {
                await this.postQueueDownload(
                    this.downloadRetailer[toSend[i]].id,
                    this.downloadRetailer[toSend[i]].note
                );
            }
            this.popupAlert.subTitle =
                "Download Automation Requested, you'll receive an email when it's ready.";
            this.popupAlert.confirm = true;
            this.popupAlert.cancel = false;
            this.popupAlert.type = "info";
        },
        async postQueueDownload(idRetailer, note = null) {
            const dataRequest = {
                id_product: this.id,
                id_customer: this.customer,
                id_product_config: this.customizations[this.selected].id,
            };
            if (idRetailer) {
                dataRequest.retailer = idRetailer;
            }
            if (note) {
                dataRequest.note = note;
            }
            debugger;
            await http
                .post("/posts/product/automation/enqueue/download", dataRequest)
                .then((r) => {
                    console.log(r);
                })
                .catch((r) => {
                    console.log("Error:");
                    console.log(r);
                    /* eslint-disable */
                    debugger;
                    /* eslint-enable */
                });
        },
        setRetaiilerDownload(key, value) {
            this.downloadRetailer[key].active = value;
        },
        setRetaiilerDownloadAmazon(value) {
            this.setRetaiilerDownload("amazon", value);
        },
        setRetaiilerDownloadLowes(value) {
            this.setRetaiilerDownload("lowes", value);
        },
        setRetaiilerDownloadFourK(value) {
            this.setRetaiilerDownload("fourK", value);
        },
        setRetaiilerDownload(id, value) {
            debugger;
            this.downloadRetailer[id].active = value;
        },
        showButtonDownloadRetailer() {
            let toShow = false;
            Object.keys(this.downloadRetailer).forEach((key) => {
                if (this.downloadRetailer[key].active) {
                    toShow = true;
                }
            });
            return toShow;
        },
    },
    watch: {
        colorpicker(value) {
            if (this.typeSelected === "automation") {
                this.selectBackgroundColor(
                    value.hex ? value.hex : value,
                    false
                );
            }
        },
    },
    mounted() {
        this.setupAccess();
        this.popupAlert.title = "Loading Product";
        this.popupAlert.show = true;
        lController.start(3);
        this.defineTailorRequest();
        this.getImagesSilo();
        this.getDataAutomation();
        window.addEventListener("resize", () => {
            this.verifyScrollImagemUploaded();
            this.verifyScrollAutomationSystem();
        });
        this.getDownloadRetailer();
        // this.emitUpdateSilo();
    },
};
</script>

<style lang="scss" scoped>
.productScreen_servicesList {
    background: #2e3238;
    .buttonScroll {
        background: #2e3238;
    }
    .button {
        &_left {
            border-right-color: #141517;
        }
        &_right {
            border-left-color: #141517;
        }
    }
}

.backgroundColorSelection {
    background: #2e3238;
    border-radius: 5px;
    margin-top: 10px;
    position: relative;
    .backgroundColorSelection_header {
        padding: 11px 20px;
        p {
            color: #fff;
            font-size: 15px;
            font-weight: bold;
            margin: 0;
        }
        border-bottom: 1px solid #373c45;
    }
    .backgroundColorSelection_content {
        padding: 14px 20px;

        ul {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            justify-content: space-between;
            li {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                cursor: pointer;
                border: 2px solid rgba(#0092ff, 0);
                transition: 0.5s ease;
                &:hover {
                    border: 2px solid rgba(#0092ff, 1);
                }
            }
            .none {
                background: url("/background-colors-none.png");
            }
            .active {
                border: 2px solid rgba(#0092ff, 1);
            }
        }
    }

    .colorPicker {
        position: absolute;
        top: 90px;
        right: 20px;
        z-index: 9;
    }
    &_content {
        &_buttons {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            @media screen and (max-width: 1020px) {
                justify-content: center;
                .button {
                    width: 100%;
                    margin-bottom: 10px;
                }
            }
            .button {
                background: #3d424e;
                &:hover {
                    background: #0092ff;
                }
            }
        }
        .active {
            background: #0092ff;
        }
        &_slider {
            display: flex;
            align-items: center;
            margin-top: 10px;

            .fakeInput {
                background: #3d424e;
                border: none;
                border-radius: 5px;
                width: 72px;
                font-size: 15px;
                color: #ffffff;
                text-align: center;
                padding: 6px;
                margin: 0;
                margin-left: 10px;
            }

            .label {
                color: #fff;
                font-family: Fira Sans;
                font-size: 15px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                white-space: nowrap;
                margin-right: 10px;
                min-width: 120px;
            }

            .inputSlider {
                position: relative;
                > input {
                    background: #3d424e;
                    border: none;
                    border-radius: 5px;
                    width: 72px;
                    font-size: 15px;
                    color: #ffffff;
                    text-align: center;
                    padding: 6px;
                    margin: 0;
                    margin-left: 10px;
                }

                &::before {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 50%;
                    right: 5px;
                    transform: translateY(-50%);
                    font-size: 15px;
                    color: #ffffff;
                }
            }
        }

        &-flexList {
            display: flex;
            align-items: center;
            justify-content: space-between;
            > ul {
                flex: 1;
                margin-right: 20px;
            }
            .button {
                min-width: auto;
            }
        }
    }
    &-block {
        width: 100%;
    }
}

.text-likeInput {
    background: #3d424e;
    border: none;
    border-radius: 5px;
    font-size: 15px;
    color: #ffffff;
    padding: 6px;
    margin: 0;
    white-space: nowrap;
    overflow-x: scroll;

    &::-webkit-scrollbar {
        display: none;
    }
}

.cursor-pointer {
    cursor: pointer;
}

.retailer_list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;

    p {
        color: #fff;
    }

    .row {
        display: flex;
        align-items: center;
        gap: 5px;
    }
}

.block {
    margin-bottom: 10px;
    .button_save {
        margin-top: 10px;
        margin-bottom: -15px;
    }
}

.downloadOptions {
    padding-bottom: 0;
}
</style>
