<template>
    <div class="block">
        <div class="block_header">
            <slot name="header"></slot>
            <p class="font-title" v-if="!customHeader">{{ title }}</p>
        </div>
        <div class="block_content">
            <slot></slot>
        </div>
    </div>
</template>

<script>
import InputText from '@/components/inputs/InputText.vue';

export default {
  props: ['title', 'titleIsInput', 'modelValue', 'customHeader'],
  components: {
    InputText,
  },
};
</script>

<style lang="scss" scoped>
.block {
    background: #2e3238;
    border-radius: 5px;
    margin-top: 10px;
    position: relative;
    &_header {
        padding: 10px;
        p {
            color: #fff;
            margin: 0;
        }
        border-bottom: 1px solid #373c45;
    }
    &_content {
        padding: 14px 20px;
    }
    &_input {
        max-width: 250px;
    }

    .row {
        display: flex;
        align-items: center;
        > * + * {
            margin-left: 12px;
        }
    }
    .listImages {
        &-90 {
            .photoItem {
                width: 90px;
                height: 90px;
            }
        }
    }
}
</style>
