<template>
    <div
        class="input-wrapper"
        :style="{ 'border-color': inputFocused ? 'white' : '' }"
    >
        <Avatar v-if="avatar" :url="avatar.url" />
        <input
            :placeholder="config ? config.placeholder : ''"
            :style="{
                width: !avatar && !isAPassword() ? '100% !important' : '',
            }"
            :type="verifyType()"
            :value="modelValue"
            :disabled="Disabled()"
            @blur="inputOnFocus(false)"
            @focus="inputOnFocus(true)"
            @input="updateValue"
            class="input"
        />
        <input
            :style="{
                'background-image':
                    isAPassword() && showPassword()
                        ? iconHidePassword
                        : iconShowPassword,
            }"
            type="button"
            v-if="isAPassword()"
            @click="togglePassword"
        />
    </div>
</template>

<script>
import Avatar from '@/components/styleguide/Avatar.vue';

export default {
  name: 'TextInput',
  components: {
    Avatar,
  },
  data() {
    return {
      inputFocused: false,
      iconShowPassword: `url(${require('../assets/svg/eye-off.svg')})`,
      iconHidePassword: `url(${require('../assets/svg/eye.svg')})`,
    };
  },
  props: {
    modelValue: String,
    config: {
      type: Object,
      inputType: String,
      placeholder: String,
      showPassword: Boolean,
    },
    avatar: {
      type: Object,
      url: String,
      name: String,
      alt: String,
    },
  },
  methods: {
    inputOnFocus(focused) {
      this.inputFocused = focused;
    },
    updateValue(event) {
      this.$emit('update:modelValue', event.target.value);
    },
    isAPassword() {
      return this.config && this.config.inputType === 'password';
    },
    showPassword() {
      return this.config.showPassword;
    },
    togglePassword() {
      this.$emit('showHidePassword');
    },
    verifyType() {
      let type = 'text';

      if (this.isAPassword()) type = 'password';

      if (this.config && this.showPassword()) type = 'text';

      if (this.config) {
        switch (this.config.inputType) {
        case 'email':
        case 'file':
          type = this.config.inputType;
          break;
        }
      }

      return type;
    },
    Disabled() {
      let disable = false;

      if (this.config) {
        if (this.config.disable) disable = true;
      }

      return disable;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/layout/Mixins.scss";
$white: white;

.input {
    &-wrapper {
        align-items: center;
        background: #3d424e;
        border-radius: 20px;
        border: 1px solid #c4c4c44d;
        column-gap: 10px;
        display: flex;
        min-height: 40px;
        //min-width: 304px;
        padding: 3px;
        position: relative;
        transition: 0.3s all linear;
        width: 100%;
    }

    @include component-text($white);
    height: 100%;
    padding: 0 10px;
    width: calc(100% - (34px + 10px));
}
input[type="file"] {
    text-indent: -999em;
}
input[type="button"] {
    display: block;
    cursor: pointer;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    transition: 0.5s ease;
    background-position: center;

    &:hover {
        background-color: $color_blue_1;
    }
}
</style>
