<template>
    <router-view />
</template>

<script>
export default {};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400&display=swap");
@import "@/layout/Mixins.scss";
@import "@/layout/FontStyle.scss";
.app,
#app {
    margin: 0;
    padding: 0;
    box-sizing: border-box !important;
    font-family: Fira Sans;
}

body {
    background-color: #141517;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin-bottom: 10px;
}

img {
    width: 100%;
    height: 100%;
    border-radius: inherit;
}
* {
    box-sizing: border-box;
}
</style>
