<template>
    <div class="contentLoad">
        <div
            class="barLoad"
            v-for="i in qty"
            :key="'loadbar_'+i"
            :style="{ width: `${Math.floor(Math.random() * 100) + 1}%` }"
        ></div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            qty: 0,
        };
    },
    created() {
        this.qty = Math.floor(Math.random() * 10) + 1;
    },
};
</script>

<style lang="scss" scoped>
.contentLoad {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.barLoad {
    width: 100%;
    height: 20px;
    background: #141517;
    animation: loading 2s ease infinite;
    border-radius: 5px;
}

@keyframes loading {
    0% {
        background: #141517;
    }
    50% {
        background: #2e3238;
    }
    100% {
        background: #141517;
    }
}
</style>
