<template>
    <div
        class="productScreen_section"
        v-if="permissions['send-products-for-automation']"
    >
        <div class="productScreen_section_line">
            <p class="productScreen_titleSection">
                Need a tailor made service?
            </p>
        </div>
        <ButtonIcon
            text="Click here to be contacted"
            :icon="
                requestedService
                    ? '/imgs/icons/check.svg'
                    : '/imgs/icons/add-items.svg'
            "
            inverted="true"
            class="buttonSection"
            @click="sendTailorRequest()"
            :active="requestedService"
        />
    </div>
    <div class="productScreen_section" v-if="customizations_images.length > 0">
        <div class="productScreen_section_line">
            <p class="productScreen_titleSection">Automation System</p>
            <ButtonIcon
                text="Download Preset Selected"
                icon="/imgs/icons/download.svg"
                @click="downloadAutomationImages"
                inverted="true"
                class="buttonSection"
            />
        </div>
        <div class="productScreen_servicesList">
            <ButtonBorderIcon
                class="button_left buttonScroll"
                icon="/imgs/icons/chevrons-left.svg"
                @click="scrollElement('scrollAutomationSystem', 'left')"
                v-if="scrollAutomationSystemAux.scrollX > 0"
            />
            <ul
                @scroll="verifyScrollAutomationSystem"
                id="scrollAutomationSystem"
            >
                <li
                    class="productScreen_servicesList_item"
                    v-for="(sv, i) in customizations"
                    :key="'sv_vi_' + sv"
                >
                    <ButtonItemService
                        v-if="i === 0"
                        title="New preset"
                        subtitle=""
                        icon="/imgs/icons/plus.svg"
                        :active="selected == 0 && typeSelected == 'automation'"
                        @click="selectItem(i, 'automation')"
                    />
                    <ButtonItemServicePhoto
                        v-if="i > 0"
                        :photos="[
                            customizations[i].background,
                            customizations_images[0]
                                ? customizations_images[0].reflection
                                : '',
                            customizations_images[0]
                                ? customizations_images[0].shadow
                                : '',
                            customizations_images[0]
                                ? customizations_images[0].beauty
                                : '',
                        ]"
                        :active="i == selected && typeSelected == 'automation'"
                        :alignCenter="true"
                        flag="A"
                        flagColor="#7d35ff"
                        :hasAction="true"
                        :actionEmit="true"
                        :opacities="[
                            1,
                            customizations[i].hasReflection
                                ? customizations[i].reflections.replace(
                                      '%',
                                      ''
                                  ) / 100
                                : 0,
                            customizations[i].hasShadow
                                ? customizations[i].dropShadow.replace(
                                      '%',
                                      ''
                                  ) / 100
                                : 0,
                            1,
                        ]"
                        :actionIcon="'/imgs/icons/trash.svg'"
                        @click="selectItem(i, 'automation')"
                        @action="deleteItem(i, 'automation')"
                        crossorigin="anonymous"
                    />
                </li>
            </ul>
            <ButtonBorderIcon
                class="button_right buttonScroll"
                icon="/imgs/icons/chevrons-right.svg"
                @click="scrollElement('scrollAutomationSystem', 'right')"
                v-if="
                    scrollAutomationSystemAux.scrollX < 2 &&
                    scrollAutomationSystemAux.scrollX > -1
                "
            />
        </div>
        <div class="productScreen_section_line">
            <p class="productScreen_titleSection marginTop">
                Background Settings
            </p>
        </div>
        <div class="backgroundColorSelection">
            <div class="backgroundColorSelection_header">
                <p>Background</p>
            </div>
            <div class="backgroundColorSelection_container">
                <ButtonBorderIcon
                    class="button_left"
                    icon="/imgs/icons/chevrons-left.svg"
                    @click="scrollElement('scrollBackgroundsVignette', 'left')"
                    v-if="scrollBackgroundsVignetteAux.scrollX > 0"
                />
                <ul
                    @scroll="verifyScrollBackgroundVignette"
                    class="backgroundColorSelection_images"
                    id="scrollBackgroundsVignette"
                >
                    <li
                        class="productScreen_servicesList_item"
                        v-for="(bg, i) in backgroundsColors"
                        :key="'bg_as_' + i"
                    >
                        <ButtonItemServicePhoto
                            :photo="bg.replace('.jpg', '_thumb.jpg')"
                            :active="
                                typeSelected == 'automation' &&
                                bg == customizations[selected].background
                            "
                            :alignCenter="true"
                            @click="selectBackgroundColor(bg)"
                            crossorigin="anonymous"
                        />
                    </li>
                </ul>
                <ButtonBorderIcon
                    class="button_right"
                    icon="/imgs/icons/chevrons-right.svg"
                    @click="scrollElement('scrollBackgroundsVignette', 'right')"
                    v-if="
                        scrollBackgroundsVignetteAux.scrollX < 2 &&
                        scrollBackgroundsVignetteAux.scrollX > -1
                    "
                />
            </div>
        </div>
        <div class="">
            <div
                class="backgroundColorSelection backgroundColorSelection-block"
            >
                <div class="backgroundColorSelection_header">
                    <p>Background Transform</p>
                </div>
                <div class="backgroundColorSelection_content">
                    <div class="backgroundColorSelection_content_slider">
                        <p class="label">Scale</p>
                        <Slider
                            @update="updateBgScale"
                            :myValue="customizations[selected].bg_scale"
                            :min="-1"
                            :max="1"
                            :step="0.01"
                            key="sliderShadowSilo"
                            :enabled="typeSelected == 'automation'"
                        />
                        <span
                            class="inputSlider"
                            v-if="typeSelected == 'automation'"
                        >
                            <input
                                type="text"
                                v-model="customizations[selected].bg_scale"
                                @change="
                                    formatScale(
                                        customizations[selected],
                                        'bg_scale'
                                    )
                                "
                            />
                        </span>
                        <p class="fakeInput" type="text" v-else>
                            {{ customizations[selected].bg_scale }}
                        </p>
                    </div>
                    <div class="backgroundColorSelection_content_slider">
                        <p class="label">Horizontal Offset</p>
                        <Slider
                            @update="updateBgOffsetX"
                            :myValue="customizations[selected].bg_offset_x"
                            :min="-1"
                            :max="1"
                            :step="0.01"
                            key="sliderShadowSilo"
                            :enabled="typeSelected == 'automation'"
                        />
                        <span
                            class="inputSlider"
                            v-if="typeSelected == 'automation'"
                        >
                            <input
                                type="text"
                                v-model="customizations[selected].bg_offset_x"
                                @change="
                                    formatScale(
                                        customizations[selected],
                                        'bg_offset_x'
                                    )
                                "
                            />
                        </span>
                        <p class="fakeInput" type="text" v-else>
                            {{ customizations[selected].bg_offset_x }}
                        </p>
                    </div>
                    <div class="backgroundColorSelection_content_slider">
                        <p class="label">Vertical Offset</p>
                        <Slider
                            @update="updateBgOffsetY"
                            :myValue="customizations[selected].bg_offset_y"
                            :min="-1"
                            :max="1"
                            :step="0.01"
                            key="sliderShadowSilo"
                            :enabled="typeSelected == 'automation'"
                        />
                        <span
                            class="inputSlider"
                            v-if="typeSelected == 'automation'"
                        >
                            <input
                                type="text"
                                v-model="customizations[selected].bg_offset_y"
                                @change="
                                    formatScale(
                                        customizations[selected],
                                        'bg_offset_y'
                                    )
                                "
                            />
                        </span>
                        <p class="fakeInput" type="text" v-else>
                            {{ customizations[selected].bg_offset_y }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="productScreen_section_line">
            <p class="productScreen_titleSection marginTop">Product Settings</p>
        </div>
        <div class="">
            <div
                class="backgroundColorSelection backgroundColorSelection-block"
            >
                <div class="backgroundColorSelection_header">
                    <p>Product Transform</p>
                </div>
                <div class="backgroundColorSelection_content">
                    <div class="backgroundColorSelection_content_slider">
                        <p class="label">Scale</p>
                        <Slider
                            @update="updateImageScale"
                            :myValue="customizations[selected].image_scale"
                            :min="-1"
                            :max="1"
                            :step="0.01"
                            key="sliderShadowSilo"
                            :enabled="typeSelected == 'automation'"
                        />
                        <span
                            class="inputSlider"
                            v-if="typeSelected == 'automation'"
                        >
                            <input
                                type="text"
                                v-model="customizations[selected].image_scale"
                                @change="
                                    formatScale(
                                        customizations[selected],
                                        'image_scale',
                                        0
                                    )
                                "
                            />
                        </span>
                        <p class="fakeInput" type="text" v-else>
                            {{ customizations[selected].image_scale }}
                        </p>
                    </div>
                    <div class="backgroundColorSelection_content_slider">
                        <p class="label">Horizontal Offset</p>
                        <Slider
                            @update="updateImageOffsetX"
                            :myValue="customizations[selected].image_offset_x"
                            :min="-1"
                            :max="1"
                            :step="0.01"
                            key="sliderShadowSilo"
                            :enabled="typeSelected == 'automation'"
                        />
                        <span
                            class="inputSlider"
                            v-if="typeSelected == 'automation'"
                        >
                            <input
                                type="text"
                                v-model="
                                    customizations[selected].image_offset_x
                                "
                                @change="
                                    formatScale(
                                        customizations[selected],
                                        'image_offset_x'
                                    )
                                "
                            />
                        </span>
                        <p class="fakeInput" type="text" v-else>
                            {{ customizations[selected].image_offset_x }}
                        </p>
                    </div>
                    <div class="backgroundColorSelection_content_slider">
                        <p class="label">Vertical Offset</p>
                        <Slider
                            @update="updateImageOffsetY"
                            :myValue="customizations[selected].image_offset_y"
                            :min="-1"
                            :max="1"
                            :step="0.01"
                            key="sliderShadowSilo"
                            :enabled="
                                customizations[selected].hasShadow &&
                                typeSelected == 'automation'
                            "
                        />
                        <span
                            class="inputSlider"
                            v-if="typeSelected == 'automation'"
                        >
                            <input
                                type="text"
                                v-model="
                                    customizations[selected].image_offset_y
                                "
                                @change="
                                    formatScale(
                                        customizations[selected],
                                        'image_offset_y'
                                    )
                                "
                            />
                        </span>
                        <p class="fakeInput" type="text" v-else>
                            {{ customizations[selected].image_offset_y }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="productScreen_section_grid">
            <div
                class="backgroundColorSelection backgroundColorSelection-block"
            >
                <div class="backgroundColorSelection_header">
                    <p>Drop Shadow</p>
                </div>
                <div class="backgroundColorSelection_content">
                    <div class="backgroundColorSelection_content_buttons">
                        <ButtonIcon
                            text="With Shadow"
                            :active="
                                customizations[selected].hasShadow &&
                                typeSelected == 'automation'
                            "
                            :noicon="true"
                            @click="updateHasShadow(true)"
                        />
                        <ButtonIcon
                            text="Without Shadow"
                            :active="
                                !customizations[selected].hasShadow &&
                                typeSelected == 'automation'
                            "
                            :noicon="true"
                            @click="updateHasShadow(false)"
                        />
                    </div>
                    <div class="backgroundColorSelection_content_slider">
                        <Slider
                            @update="updateShadow"
                            :myValue="customizations[selected].dropShadow"
                            key="sliderShadowSilo"
                            :enabled="
                                customizations[selected].hasShadow &&
                                typeSelected == 'automation'
                            "
                        />
                        <span
                            class="inputSlider"
                            v-if="typeSelected == 'automation'"
                        >
                            <input
                                type="text"
                                v-model="customizations[selected].dropShadow"
                                @change="
                                    formatPercent(
                                        customizations[selected],
                                        'dropShadow'
                                    )
                                "
                            />
                        </span>
                        <p class="fakeInput" type="text" v-else>
                            {{ customizations[selected].dropShadow }}%
                        </p>
                    </div>
                </div>
            </div>
            <div
                class="backgroundColorSelection backgroundColorSelection-block"
            >
                <div class="backgroundColorSelection_header">
                    <p>Reflection</p>
                </div>
                <div class="backgroundColorSelection_content">
                    <div class="backgroundColorSelection_content_buttons">
                        <ButtonIcon
                            text="With Reflection"
                            :active="
                                customizations[selected].hasReflection &&
                                typeSelected == 'automation'
                            "
                            :noicon="true"
                            @click="updateHasReflections(true)"
                        />
                        <ButtonIcon
                            text="Without Reflection"
                            :active="
                                !customizations[selected].hasReflection &&
                                typeSelected == 'automation'
                            "
                            :noicon="true"
                            @click="updateHasReflections(false)"
                        />
                    </div>
                    <div class="backgroundColorSelection_content_slider">
                        <Slider
                            @update="updateReflection"
                            :myValue="customizations[selected].reflections"
                            :enabled="
                                customizations[selected].hasReflection &&
                                typeSelected == 'automation'
                            "
                            key="sliderRefrectiosSilo"
                        />
                        <span
                            class="inputSlider"
                            v-if="typeSelected == 'automation'"
                        >
                            <input
                                type="text"
                                v-model="customizations[selected].reflections"
                                @change="
                                    formatPercent(
                                        customizations[selected],
                                        'reflections'
                                    )
                                "
                            />
                        </span>
                        <p class="fakeInput" type="text" v-else>
                            {{ customizations[selected].reflections }}%
                        </p>
                    </div>
                </div>
            </div>
            <div
                class="backgroundColorSelection backgroundColorSelection-block"
            >
                <div class="backgroundColorSelection_header">
                    <p>Saturation</p>
                </div>
                <div class="backgroundColorSelection_content">
                    <div class="backgroundColorSelection_content_slider">
                        <Slider
                            @update="updateSaturation"
                            :myValue="customizations[selected].saturation"
                            max="200"
                            key="sliderShadowSilo"
                            :enabled="typeSelected == 'automation'"
                        />
                        <span
                            class="inputSlider"
                            v-if="typeSelected == 'automation'"
                        >
                            <input
                                type="text"
                                v-model="customizations[selected].saturation"
                                @change="
                                    formatPercent(
                                        customizations[selected],
                                        'saturation'
                                    )
                                "
                            />
                        </span>
                        <p class="fakeInput" type="text" v-else>
                            {{ customizations[selected].saturation }}
                        </p>
                    </div>
                </div>
            </div>
            <div
                class="backgroundColorSelection backgroundColorSelection-block"
            >
                <div class="backgroundColorSelection_header">
                    <p>Brightness</p>
                </div>
                <div class="backgroundColorSelection_content">
                    <div class="backgroundColorSelection_content_slider">
                        <Slider
                            @update="updateBrightness"
                            :myValue="customizations[selected].brightness"
                            max="200"
                            key="sliderShadowSilo"
                            :enabled="typeSelected == 'automation'"
                        />
                        <span
                            class="inputSlider"
                            v-if="typeSelected == 'automation'"
                        >
                            <input
                                type="text"
                                v-model="customizations[selected].brightness"
                                @change="
                                    formatPercent(
                                        customizations[selected],
                                        'brightness'
                                    )
                                "
                            />
                        </span>
                        <p class="fakeInput" type="text" v-else>
                            {{ customizations[selected].brightness }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="productScreen_section">
        <div class="productScreen_section_line">
            <p class="productScreen_titleSection">Images Uploaded</p>
            <ButtonIcon
                text="Download All Images"
                icon="/imgs/icons/download.svg"
                @click="downloadUploadImages"
                inverted="true"
                class="buttonSection"
            />
        </div>
        <div class="productScreen_servicesList">
            <ButtonBorderIcon
                class="button_left buttonScroll"
                icon="/imgs/icons/chevrons-left.svg"
                @click="scrollElement('scrollImagemUploaded', 'left')"
                v-if="scrollImageUploadedAux.scrollX > 0"
            />
            <ul @scroll="verifyScrollImagemUploaded" id="scrollImagemUploaded">
                <li
                    class="productScreen_servicesList_item"
                    v-if="permissions['editor-vignette-access']"
                >
                    <ButtonItemService
                        title="Upload"
                        subtitle=""
                        icon="/imgs/icons/alert.svg"
                        @click="openModalUpload"
                    />
                </li>
                <li
                    class="productScreen_servicesList_item"
                    v-for="(imagesUploadedSilo, i) in uploadImages"
                    :key="'imagesUploadedSilo_' + i"
                >
                    <ButtonItemServicePhoto
                        :photo="imagesUploadedSilo.photo"
                        :active="i == selected && typeSelected == 'manual'"
                        :alignCenter="true"
                        @click="selectItem(i, 'manual')"
                    />
                </li>
            </ul>
            <ButtonBorderIcon
                class="button_right buttonScroll"
                icon="/imgs/icons/chevrons-right.svg"
                @click="scrollElement('scrollImagemUploaded', 'right')"
                v-if="
                    scrollImageUploadedAux.scrollX < 2 &&
                    scrollImageUploadedAux.scrollX > -1
                "
            />
        </div>
    </div>
    <ButtonBorderText
        class="button_save"
        @click="saveAutomation()"
        v-if="
            edited &&
            typeSelected == 'automation' &&
            permissions['editor-vignette-access']
        "
        noicon="true"
        text="Save"
    />
    <PopupAlert
        v-if="popupAlert.show"
        :title="popupAlert.title"
        :subTitle="popupAlert.subTitle"
        :confirm="popupAlert.confirm"
        :cancel="popupAlert.cancel"
        @confirm="emitConfirm"
        @cancel="emitCancel"
    />
    <PopupUpload
        v-if="modalUpload.show"
        :title="'Upload Image'"
        :subTitle="'Drag and drop your image here'"
        :confirm="true"
        :cancel="true"
        @confirm="emitConfirmUpload"
        @cancel="emitCancelUpload"
    />
</template>

<script>
import ButtonIcon from '@/components/buttons/Button-icon-text.vue';
import ButtonBorderIcon from '@/components/buttons/Button-icon-border.vue';
import ButtonBorderText from '@/components/buttons/Button-text-border.vue';
import ButtonItemService from '@/components/listItem/Icon-title-subtitle.vue';
import ButtonItemServicePhoto from '@/components/listItem/Square-photo-description-event.vue';
import Slider from '@/components/inputs/Slider.vue';
import http from '@/http';
import preloadImages from '@/utils/preloadImages';
import PopupAlert from '../common/PopupAlert.vue';
import sleep from '@/utils/sleep';
import loadingController from '@/utils/loadingController';
import PopupUpload from '../common/PopupUpload.vue';

const lController = new loadingController();

export default {
  components: {
    ButtonIcon,
    ButtonBorderIcon,
    ButtonItemService,
    ButtonItemServicePhoto,
    ButtonBorderText,
    Slider,
    PopupAlert,
    PopupUpload,
  },
  props: ['id', 'customer', 'uploadImages', 'photo'],
  data() {
    return {
      selected: 0,
      edited: false,
      requestedService: false,
      typeSelected: 'automation',
      scrollBackgroundsVignetteAux: {
        scrollX: 0,
      },
      backgroundsColors: [],
      customizations_images: [],
      customizations: [
        {
          background: `${process.env.VUE_APP_AWS_BUCKET_IMAGES}/roomsets/template_aut/TEMP1.jpg`,
          hasShadow: true,
          dropShadow: '35%',
          hasReflection: true,
          reflections: '10%',
          saturation: '100%',
          brightness: '100%',
          bg_scale: 0,
          bg_offset_x: 0,
          bg_offset_y: 0,
          image_scale: 0,
          image_offset_x: 0,
          image_offset_y: 0,
          id: 0,
        },
      ],
      scrollImageUploadedAux: {
        scrollX: 0,
      },
      scrollAutomationSystemAux: {
        scrollX: 0,
      },
      popupAlert: {
        show: false,
        title: 'Automation System',
        subTitle: 'Saving...',
        confirm: false,
        cancel: false,
        type: 'save',
      },
      modalUpload: {
        show: false,
      },
      permissions: {
        'editor-vignette-access': false,
        'send-products-for-automation': false,
      },
    };
  },
  methods: {
    getDataAutomation() {
      // $route.params.id
      http
        .get(
          `/gets/product/automation/vignette/config/show?id_product=${this.id}&id_customer=${this.customer}`,
        )
        .then((r) => {
          if (r.data.success) {
            console.log(r.data);
            r.data.product_config_vignette.forEach((item) => {
              this.customizations.push({
                id: item.id,
                images: [
                  {
                    beauty: '/beauty.png',
                    shadow: '/shadow.png',
                    reflection: '/reflections.png',
                  },
                ],
                background: process.env.VUE_APP_AWS_BUCKET_IMAGES + item.background,
                hasShadow: item.hasShadow === '1',
                dropShadow: `${item.shadow}%`,
                hasReflection: item.hasReflection === '1',
                reflections: `${item.reflection}%`,
                saturation: `${item.saturation}%`,
                brightness: `${item.brightness}%`,
                bg_scale: item.bg_scale,
                bg_offset_x: item.bg_offset_x,
                bg_offset_y: item.bg_offset_y,
                image_scale: item.image_scale,
                image_offset_x: item.image_offset_x,
                image_offset_y: item.image_offset_y,
              });
            });
          }
        })
        .catch((r) => {
          console.log('Error:');
          console.log(r);
        })
        .finally(() => {
          this.completeTaskLoad();
        });
    },
    getImagesVignette() {
      // $route.params.id
      http
        .get(
          `/gets/product/automation/vignette/images/show?id_product=${this.id}&id_customer=${this.customer}`,
        )
        .then((r) => {
          console.log('Images:');
          console.log(r);
          if (r.data.success) {
            const imagesToPreload = [];
            r.data.images_automation.forEach((image) => {
              this.customizations_images.push({
                beauty: `${process.env.VUE_APP_AWS_BUCKET_IMAGES}${image.beauty.replace(
                  '.png',
                  '_thumb.png',
                )}`,
                shadow: `${process.env.VUE_APP_AWS_BUCKET_IMAGES}${image.shadow.replace(
                  '.png',
                  '_thumb.png',
                )}`,
                reflection: `${process.env.VUE_APP_AWS_BUCKET_IMAGES}${image.reflection.replace(
                  '.png',
                  '_thumb.png',
                )}`,
              });
              imagesToPreload.push(
                `${process.env.VUE_APP_AWS_BUCKET_IMAGES}${image.beauty.replace(
                  '.png',
                  '_thumb.png',
                )}`,
              );
              imagesToPreload.push(
                `${process.env.VUE_APP_AWS_BUCKET_IMAGES}${image.shadow.replace(
                  '.png',
                  '_thumb.png',
                )}`,
              );
              imagesToPreload.push(
                `${process.env.VUE_APP_AWS_BUCKET_IMAGES}${image.reflection.replace(
                  '.png',
                  '_thumb.png',
                )}`,
              );
            });
            preloadImages(imagesToPreload);
            this.emitUpdateSilo();
          }
        })
        .catch((r) => {
          console.log('Error:');
          console.log(r);
        })
        .finally(() => {
          this.completeTaskLoad();
        });
    },
    getBackgroundsColors() {
      http
        .get('/gets/product/automation/vignette/backgrounds/show')
        .then((r) => {
          console.log('Backgrounds:');
          console.log(r);
          if (r.data.success) {
            r.data.backgrounds.forEach((background) => {
              this.backgroundsColors.push(`${process.env.VUE_APP_AWS_BUCKET_IMAGES}${background}`);
            });
            this.customizations[0].background = this.backgroundsColors[0];
            // this.backgroundsColors = `${process.env.VUE_APP_AWS_BUCKET_IMAGES}${r.data.backgrounds}`;
          }
          setTimeout(() => {
            this.verifyScrollBackgroundVignette();
          }, 1000);
        })
        .catch((r) => {
          console.log('Error:');
          console.log(r);
        })
        .finally(() => {
          this.completeTaskLoad();
        });
    },
    sendTailorRequest() {
      if (!this.requestedService) {
        this.popupAlert.title = 'Tailor Request';
        this.popupAlert.subTitle = 'Requesting...';
        this.popupAlert.confirm = false;
        this.popupAlert.type = 'request';
        this.popupAlert.show = true;

        http
          .post('/posts/email/request/service/tailor/send', {
            id_product: this.id,
            id_customer: this.customer,
            page: 'Vignette',
          })
          .then((r) => {
            if (r.data.success) {
              this.popupAlert.subTitle = 'Request sent! We will get in touch with you soon';
              this.popupAlert.confirm = true;
              this.popupAlert.show = true;
              const data = new Date();
              this.requestedService = true;
              localStorage.setItem(
                'vignette_request',
                `${data.getFullYear()}-${data.getMonth() + 1}-${data.getDate()}`,
              );
            } else {
              this.popupAlert.subTitle = `Error: ${r.data.message}`;
              this.popupAlert.confirm = true;
            }
          })
          .catch((r) => {
            console.log('Error:');
            console.log(r);
          });
      }
    },
    defineTailorRequest() {
      const value = localStorage.getItem('vignette_request');
      if (value != null) {
        const data = new Date(value);
        const now = new Date();
        const dCompare = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        if (data >= dCompare) {
          this.requestedService = true;
        }
      }
    },
    verifyScrollBackgroundVignette() {
      const element = document.getElementById('scrollBackgroundsVignette');
      if (!element) return;
      const scrollX = element.scrollLeft;
      const sizeScroll = element.scrollWidth - element.offsetWidth;
      if (scrollX === 0 && scrollX === sizeScroll) this.scrollBackgroundsVignetteAux.scrollX = -1;
      else if (scrollX === 0) this.scrollBackgroundsVignetteAux.scrollX = 0;
      else if (scrollX === sizeScroll) this.scrollBackgroundsVignetteAux.scrollX = 2;
      else this.scrollBackgroundsVignetteAux.scrollX = 1;
    },
    verifyScrollImagemUploaded() {
      const element = document.getElementById('scrollImagemUploaded');
      const scrollX = element.scrollLeft;
      const sizeScroll = element.scrollWidth - element.offsetWidth;
      if (scrollX === 0 && scrollX === sizeScroll) this.scrollImageUploadedAux.scrollX = -1;
      else if (scrollX === 0) this.scrollImageUploadedAux.scrollX = 0;
      else if (scrollX === sizeScroll) this.scrollImageUploadedAux.scrollX = 2;
      else this.scrollImageUploadedAux.scrollX = 1;
    },
    verifyScrollAutomationSystem() {
      const element = document.getElementById('scrollAutomationSystem');
      if (!element) return;
      const scrollX = element.scrollLeft;
      const sizeScroll = element.scrollWidth - element.offsetWidth;
      if (scrollX === 0 && scrollX === sizeScroll) this.scrollAutomationSystemAux.scrollX = -1;
      else if (scrollX === 0) this.scrollAutomationSystemAux.scrollX = 0;
      else if (scrollX === sizeScroll) this.scrollAutomationSystemAux.scrollX = 2;
      else this.scrollAutomationSystemAux.scrollX = 1;
    },
    /* eslint-disable no-param-reassign */
    scrollElement(id, direction) {
      const element = document.getElementById(id);
      if (direction === 'left') {
        element.scrollBy({
          left: -100,
          behavior: 'smooth',
        });
      } else {
        element.scrollBy({
          left: 100,
          behavior: 'smooth',
        });
      }
    },
    /* eslint-enable no-param-reassign */
    selectItem(i, type) {
      this.selected = i;
      this.typeSelected = type;
      this.emitUpdateSilo();
    },
    selectBackgroundColor(color) {
      if (this.typeSelected === 'automation') {
        this.customizations[this.selected].background = color;
        this.edited = true;
        this.emitUpdateSilo();
      }
    },
    emitUpdateSilo() {
      if (this.typeSelected === 'automation') {
        this.$emit('update', {
          background: this.customizations[this.selected].background,
          hasShadow: this.customizations[this.selected].hasShadow,
          dropShadow: this.customizations[this.selected].dropShadow.toString().replace('%', ''),
          hasReflection: this.customizations[this.selected].hasReflection,
          reflection: this.customizations[this.selected].reflections.toString().replace('%', ''),
          saturation: this.customizations[this.selected].saturation,
          brightness: this.customizations[this.selected].brightness,
          bg_scale: this.customizations[this.selected].bg_scale,
          bg_offset_x: this.customizations[this.selected].bg_offset_x,
          bg_offset_y: this.customizations[this.selected].bg_offset_y,
          image_scale: this.customizations[this.selected].image_scale,
          image_offset_x: this.customizations[this.selected].image_offset_x,
          image_offset_y: this.customizations[this.selected].image_offset_y,
          images: this.customizations_images,
        });
        // this.$emit('update', this.customizations[this.selected]);
      } else {
        console.log(this.uploadImages[this.selected].photo);
        this.$emit('update', {
          images: [
            {
              beauty: this.uploadImages[this.selected].photo,
              shadow: this.uploadImages[this.selected].photo,
              reflection: this.uploadImages[this.selected].photo,
            },
          ],
          background: '#ffffff',
          hasShadow: false,
          dropShadow: 0,
          hasReflection: false,
          reflections: 0,
        });
      }
    },
    updateKeyPropertie(value, key) {
      if (this.typeSelected === 'automation') {
        this.edited = true;
        this.customizations[this.selected][key] = `${value}`;
        this.emitUpdateSilo();
      }
    },
    updateBgScale(value) {
      this.updateKeyPropertie(value, 'bg_scale');
    },
    updateBgOffsetX(value) {
      this.updateKeyPropertie(value, 'bg_offset_x');
    },
    updateBgOffsetY(value) {
      this.updateKeyPropertie(value, 'bg_offset_y');
    },
    updateImageScale(value) {
      this.updateKeyPropertie(value, 'image_scale');
    },
    updateImageOffsetX(value) {
      this.updateKeyPropertie(value, 'image_offset_x');
    },
    updateImageOffsetY(value) {
      this.updateKeyPropertie(value, 'image_offset_y');
    },
    updateShadow(value) {
      if (this.typeSelected === 'automation') {
        this.edited = true;
        this.customizations[this.selected].dropShadow = `${value}%`;
        this.emitUpdateSilo();
      }
    },
    updateSaturation(value) {
      if (this.typeSelected === 'automation') {
        this.edited = true;
        this.customizations[this.selected].saturation = `${value}%`;
        this.emitUpdateSilo();
      }
    },
    updateBrightness(value) {
      if (this.typeSelected === 'automation') {
        this.edited = true;
        this.customizations[this.selected].brightness = `${value}%`;
        this.emitUpdateSilo();
      }
    },
    updateReflection(value) {
      if (this.typeSelected === 'automation') {
        this.edited = true;
        this.customizations[this.selected].reflections = `${value}%`;
        this.emitUpdateSilo();
      }
    },
    updateHasShadow(value) {
      if (this.typeSelected === 'automation') {
        this.edited = true;
        this.customizations[this.selected].hasShadow = value;
        this.emitUpdateSilo();
      }
    },
    updateHasReflections(value) {
      if (this.typeSelected === 'automation') {
        this.edited = true;
        this.customizations[this.selected].hasReflection = value;
        this.emitUpdateSilo();
      }
    },
    saveAutomation() {
      if (this.customizations[this.selected].id === 0) {
        this.popupAlert.title = 'Automation System';
        this.popupAlert.subTitle = 'Creating...';
        this.popupAlert.show = true;
        this.popupAlert.type = 'save';
        this.popupAlert.confirm = false;
        this.popupAlert.cancel = false;
        this.postDataAutomation();
      } else {
        this.popupAlert.title = 'Automation System';
        this.popupAlert.subTitle = 'Updating...';
        this.popupAlert.show = true;
        this.popupAlert.type = 'save';
        this.popupAlert.confirm = false;
        this.popupAlert.cancel = false;
        this.updateDataAutomation();
      }
    },
    postDataAutomation() {
      http
        .post('/posts/product/automation/vignette/config/store', {
          id_product: this.id,
          id_customer: this.customer,
          background: this.customizations[this.selected].background.replace(
            process.env.VUE_APP_AWS_BUCKET_IMAGES,
            '',
          ),
          hasShadow: this.customizations[this.selected].hasShadow ? 1 : 0,
          shadow: this.customizations[this.selected].dropShadow.toString().replace('%', ''),
          hasReflection: this.customizations[this.selected].hasReflection ? 1 : 0,
          reflection: this.customizations[this.selected].reflections.toString().replace('%', ''),
          saturation: this.customizations[this.selected].saturation.toString().replace('%', ''),
          brightness: this.customizations[this.selected].brightness.toString().replace('%', ''),
          bg_scale: this.customizations[this.selected].bg_scale,
          bg_offset_x: this.customizations[this.selected].bg_offset_x,
          bg_offset_y: this.customizations[this.selected].bg_offset_y,
          image_scale: this.customizations[this.selected].image_scale,
          image_offset_x: this.customizations[this.selected].image_offset_x,
          image_offset_y: this.customizations[this.selected].image_offset_y,
        })
        .then((r) => {
          if (r.data.success) {
            this.popupAlert.subTitle = 'Saved!';
            this.popupAlert.confirm = true;
            this.selected = this.customizations.push({
              id: r.data.id_product_config,
              images: [
                {
                  beauty: '/beauty.png',
                  shadow: '/shadow.png',
                  reflection: '/reflections.png',
                },
              ],
              background: this.customizations[this.selected].background,
              hasShadow: this.customizations[this.selected].hasShadow,
              dropShadow: this.customizations[this.selected].dropShadow,
              hasReflection: this.customizations[this.selected].hasReflection,
              reflections: this.customizations[this.selected].reflections,
              saturation: this.customizations[this.selected].saturation,
              brightness: this.customizations[this.selected].brightness,
              bg_scale: this.customizations[this.selected].bg_scale,
              bg_offset_x: this.customizations[this.selected].bg_offset_x,
              bg_offset_y: this.customizations[this.selected].bg_offset_y,
              image_scale: this.customizations[this.selected].image_scale,
              image_offset_x: this.customizations[this.selected].image_offset_x,
              image_offset_y: this.customizations[this.selected].image_offset_y,
            }) - 1;
            this.customizations[this.selected].id = r.data.id_product_config;
            this.edited = false;
          } else {
            console.log(r.data);
            this.popupAlert.subTitle = `Error: ${r.data.message}`;
            this.popupAlert.confirm = true;
            this.popupAlert.type = 'error';
          }
        })
        .catch((r) => {
          this.popupAlert.subTitle = `Error: ${r.message}`;
          this.popupAlert.confirm = true;
          this.popupAlert.type = 'error';
          console.log('Error:');
          debugger;
          console.log(r.message);
        });
    },
    updateDataAutomation() {
      http
        .put('/puts/product/automation/vignette/config/edit', {
          id_product: this.id,
          id_customer: this.customer,
          id_product_config: this.customizations[this.selected].id,
          background: this.customizations[this.selected].background.replace(
            process.env.VUE_APP_AWS_BUCKET_IMAGES,
            '',
          ),
          hasShadow: this.customizations[this.selected].hasShadow ? 1 : 0,
          shadow: this.customizations[this.selected].dropShadow.toString().replace('%', ''),
          hasReflection: this.customizations[this.selected].hasReflection ? 1 : 0,
          reflection: this.customizations[this.selected].reflections.toString().replace('%', ''),
          saturation: this.customizations[this.selected].saturation.toString().replace('%', ''),
          brightness: this.customizations[this.selected].brightness.toString().replace('%', ''),
          bg_scale: this.customizations[this.selected].bg_scale,
          bg_offset_x: this.customizations[this.selected].bg_offset_x,
          bg_offset_y: this.customizations[this.selected].bg_offset_y,
          image_scale: this.customizations[this.selected].image_scale,
          image_offset_x: this.customizations[this.selected].image_offset_x,
          image_offset_y: this.customizations[this.selected].image_offset_y,
        })
        .then((r) => {
          if (r.data.success) {
            this.popupAlert.subTitle = 'Saved!';
            this.popupAlert.confirm = true;
            this.customizations[this.selected].id = r.data.id_product_config;
            this.edited = false;
          } else {
            this.popupAlert.subTitle = `Error: ${r.data.message}`;
            this.popupAlert.confirm = true;
          }
        })
        .catch((r) => {
          console.log('Error:');
          console.log(r);
          if (r.response.data.message) this.popupAlert.subTitle = `Error: ${r.response.data.message}`;
          else this.popupAlert.subTitle = `Error: ${r.message}`;
          this.popupAlert.confirm = true;
          this.popupAlert.type = 'error';
        });
    },
    deleteDataAutomation(i) {
      this.popupAlert.subTitle = 'Deleting...';
      this.popupAlert.confirm = false;
      this.popupAlert.cancel = false;
      http
        .delete(
          `${
            process.env.VUE_APP_URL_API
          }/deletes/product/automation/vignette/config/delete?id_product_config=${
            this.customizations[this.selected].id
          }&id_product=${this.id}&id_customer=${this.customer}`,
        )
        .then((r) => {
          this.popupAlert.confirm = true;
          this.popupAlert.cancel = false;
          this.popupAlert.type = 'confirm';
          if (r.data.success) {
            this.popupAlert.subTitle = 'Deleted!';
            this.customizations.splice(i, 1);
            this.selected = 0;
          } else {
            this.popupAlert.subTitle = `Error: ${r.data.message}`;
          }
        })
        .catch((r) => {
          console.log('Error:');
          console.log(r.data);
          if (r.response.data.message) this.popupAlert.subTitle = `Error: ${r.response.data.message}`;
          else this.popupAlert.subTitle = `Error: ${r.message}`;
          this.popupAlert.confirm = true;
          this.popupAlert.type = 'error';
        });
    },
    emitConfirm() {
      if (this.popupAlert.type === 'deleteAutomation') {
        this.deleteDataAutomation(this.selected);
      } else if (this.popupAlert.type === 'save') {
        this.popupAlert.confirm = false;
        this.popupAlert.show = false;
      } else {
        this.popupAlert.confirm = false;
        this.popupAlert.show = false;
      }
    },
    emitCancel() {
      this.popupAlert.show = false;
    },
    downloadAutomationImages() {
      this.$emit('downloadAutomationBatch');
    },
    /* eslint-disable */
    async emitConfirmUpload(files) {
      this.modalUpload.show = false;
      this.popupAlert.show = true;
      this.popupAlert.title = 'Uploadind...';
      this.popupAlert.confirm = false;
      this.popupAlert.cancel = false;
      this.popupAlert.subTitle = '';

      for (let i = 0; i < files.length; i += 1) {
        this.popupAlert.subTitle = `Uploading ${i + 1} of ${files.length} images`;
        const result = await this.postImageUpload(files[i]);
        if (!result.success) {
          this.popupAlert.title = 'Error';
          this.popupAlert.subTitle = result.message;
          this.popupAlert.confirm = true;
          this.popupAlert.cancel = false;
          this.popupAlert.type = 'error';
          return;
        }
      }
      this.popupAlert.subTitle = 'Upload complete';
      this.popupAlert.confirm = true;
      this.popupAlert.type = 'upload';

      /* eslint-enable */
    },
    async postImageUpload(file) {
      const response = {
        success: false,
        message: '',
      };
      // const filesSend = [];
      // filesSend.push(file);
      const formData = new FormData();
      formData.append('file_image', file);
      formData.append('id_product', this.id);
      formData.append('id_customer', this.customer);
      await http
        .post('/posts/product/images-uploaded/vignette/store', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((r) => {
          console.log(r);
          response.success = r.data.success;
          response.message = r.data.message;
          if (r.data.success) {
            this.$emit(
              'updateImageUpload',
              `${process.env.VUE_APP_AWS_BUCKET_IMAGES}${r.data.path_image}`,
            );
            // this.uploadImages.push({
            //  photo: `${process.env.VUE_APP_AWS_BUCKET_IMAGES}${r.data.path_image}`,
            // });
          }
        })
        .catch((r) => {
          console.log('Error:');
          debugger;
          console.log(r);
          response.success = false;
          response.message = r;
        });
      return response;
    },
    emitCancelUpload() {
      this.modalUpload.show = false;
    },
    async completeTaskLoad() {
      lController.completeTask();
      this.popupAlert.subTitle = `Please wait. completed ${lController.getProgress()}%`;
      if (lController.getStatus() === 'ended') {
        await sleep(1000);
        this.popupAlert.show = false;
      }
    },
    deleteItem(index, type) {
      if (type === 'automation') {
        this.popupAlert.show = true;
        this.popupAlert.title = 'Delete Automation';
        this.popupAlert.subTitle = 'Are you sure you want to delete this automation?';
        this.popupAlert.confirm = true;
        this.popupAlert.cancel = true;
        this.popupAlert.type = 'deleteAutomation';
      } else {
        // Todo: delete image
      }
    },
    openModalUpload() {
      this.modalUpload.show = true;
    },
    downloadUploadImages() {
      this.popupAlert.title = 'Download Images';
      this.popupAlert.subTitle = 'Preparing images do download...';
      this.popupAlert.show = true;
      this.popupAlert.type = 'download';
      this.popupAlert.confirm = false;
      this.popupAlert.cancel = false;
      http
        .get(
          `/gets/product/zip/vignette/images-uploaded?id_product=${this.id}&id_customer=${this.customer}`,
        )
        .then((r) => {
          console.log(r);
          if (r.data.success) {
            window.open(
              `${process.env.VUE_APP_URL_API}/gets/product/download/zip/images-uploaded?name_zip=${r.data.name_zip}`,
              '_blank',
            );
            this.popupAlert.subTitle = 'Downloaded!';
            this.popupAlert.show = false;
          } else {
            this.popupAlert.subTitle = `Error: ${r.data.message}`;
            this.popupAlert.confirm = true;
            this.popupAlert.cancel = false;
            this.popupAlert.type = 'confirm';
            this.popupAlert.show = true;
          }
        })
        .catch((r) => {
          console.log('Error:');
          debugger;
          console.log(r);
          this.popupAlert.subTitle = `Error: ${r.message} - ${r.response.data.message}`;
          this.popupAlert.confirm = true;
          this.popupAlert.cancel = false;
          this.popupAlert.type = 'confirm';
        });
    },
    formatPercent(obj, key) {
      console.log(obj, key);
      if (obj[key].includes('%')) {
        this.emitUpdateSilo();
        return;
      }
      obj[key] = `${obj[key]}%`;
      this.emitUpdateSilo();
    },
    formatScale(obj, key, min = -1, max = 1) {
      const aux = parseInt(obj[key]);
      if (aux > max) obj[key] = max;
      if (aux < min) obj[key] = min;
      this.emitUpdateSilo();
    },
    async setupAccess() {
      const slugs = [];
      Object.keys(this.permissions).forEach((key) => {
        slugs.push(key);
      });

      const access = await this.$userAccess.to(slugs);
      Object.keys(this.permissions).forEach((key) => {
        this.permissions[key] = access[key];
      });
    },
  },
  mounted() {
    this.setupAccess();
    this.popupAlert.title = 'Loading Product';
    this.popupAlert.subTitle = 'Please wait. completed 0%';
    this.popupAlert.show = true;
    lController.start(3);
    this.defineTailorRequest();
    this.getImagesVignette();
    this.getBackgroundsColors();
    this.verifyScrollImagemUploaded();
    this.verifyScrollAutomationSystem();
    this.verifyScrollBackgroundVignette();
    this.getDataAutomation();
    window.addEventListener('resize', () => {
      this.verifyScrollImagemUploaded();
      this.verifyScrollAutomationSystem();
      this.verifyScrollBackgroundVignette();
    });
    // this.emitUpdateSilo();
  },
};
</script>

<style lang="scss" scoped>
.productScreen_servicesList {
    background: #2e3238;
    .buttonScroll {
        background: #2e3238;
    }
    .button {
        &_left {
            border-right-color: #141517;
        }
        &_right {
            border-left-color: #141517;
        }
    }
}

.backgroundColorSelection {
    background: #2e3238;
    border-radius: 5px;
    margin-top: 10px;
    &_container {
        position: relative;
        .button {
            position: absolute;
            z-index: 1;
            height: 100%;
            top: 0;
            display: flex;
            align-items: center;
            background: #2e3238;
            border-top: none;
            &_left {
                left: 0;
                border-radius: 10px 0 0 10px;
                border-right: 1px solid #373c45;
            }
            &_right {
                right: 0;
                border-radius: 0 10px 10px 0;
                border-left: 1px solid #373c45;
            }
            &:hover {
                background: #0264d8;
            }
        }
    }
    .backgroundColorSelection_header {
        padding: 11px 20px;
        p {
            color: #fff;
            font-size: 15px;
            font-weight: bold;
            margin: 0;
        }
        border-bottom: 1px solid #373c45;
    }
    .backgroundColorSelection_content {
        padding: 14px 20px;
        * {
            user-select: none;
        }

        ul {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            justify-content: space-between;
            li {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                cursor: pointer;
                border: 2px solid rgba(#0092ff, 0);
                transition: 0.5s ease;
                &:hover {
                    border: 2px solid rgba(#0092ff, 1);
                }
            }
            .none {
                background: url("/background-colors-none.png");
            }
            .active {
                border: 2px solid rgba(#0092ff, 1);
            }
        }
    }
    &_content {
        &_buttons {
            display: flex;
            justify-content: space-between;
            .button {
                background: #3d424e;
                &:hover {
                    background: #0092ff;
                }
            }
        }
        .active {
            background: #0092ff;
        }
        &_slider {
            display: flex;
            align-items: center;
            margin-top: 10px;
            * {
                user-select: none;
            }

            .fakeInput {
                background: #3d424e;
                border: none;
                border-radius: 5px;
                width: 72px;
                font-size: 15px;
                color: #ffffff;
                text-align: center;
                padding: 6px;
                margin: 0;
                margin-left: 10px;
            }

            .label {
                color: #fff;
                font-family: Fira Sans;
                font-size: 15px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                white-space: nowrap;
                margin-right: 10px;
                min-width: 120px;
            }

            .inputSlider {
                position: relative;
                > input {
                    background: #3d424e;
                    border: none;
                    border-radius: 5px;
                    width: 72px;
                    font-size: 15px;
                    color: #ffffff;
                    text-align: center;
                    padding: 6px;
                    margin: 0;
                    margin-left: 10px;
                }

                &::before {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 50%;
                    right: 5px;
                    transform: translateY(-50%);
                    font-size: 15px;
                    color: #ffffff;
                }
            }
        }
    }
    &-block {
        width: 100%;
    }
    &_images {
        padding-top: 10px;
        padding-bottom: 10px;
        display: flex;
        overflow-x: auto;

        &::-webkit-scrollbar {
            display: none;
        }

        .productScreen_servicesList_item {
            &:last-child {
                margin-right: 12px;
            }
        }
    }
}
</style>
