<template>
    <div class="linePhotoInfo">
        <router-link :to="link">
            <div class="linePhotoInfo_left">
                <div class="linePhotoInfo_photo">
                    <img :src="photo" alt="" />
                </div>
                <div class="linePhotoInfo_infos">
                    <p
                        class="linePhotoInfo_text"
                        v-for="info in itens"
                        :key="'itemLinePhoto_' + info"
                    >
                        <span>{{ info.key }}</span
                        >{{ info.value }}
                    </p>
                </div>
            </div>
            <div class="linePhotoInfo_icon">
                <img
                    :src="require('@/assets/imgs/icons/chevron-right.svg')"
                    alt=""
                />
            </div>
        </router-link>
    </div>
</template>

<script>
export default {
  props: ['photo', 'itens', 'link'],
};
</script>

<style lang="scss" scoped>
* {
    margin: 0;
    padding: 0;
    text-decoration: none;
    list-style: none;
}
.linePhotoInfo {
    padding: 10px;
    align-items: center;
    justify-content: space-between;
    background: #272a30;
    border-radius: 5px;
    > a {
        display: flex;
        align-items: center;
    }
    &_left {
        display: flex;
        align-items: center;
        flex: 1;
    }
    &_photo {
        width: 80px;
        height: 80px;
        border-radius: 5px;
        margin-right: 25px;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &_infos {
        display: flex;
        flex: 1;
    }
    &_text {
        color: #fff;
        font-size: 15px;
        margin-right: 25px;
        span {
            font-weight: bold;
        }
        &:first-child {
            width: 70%;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
        &:nth-child(2) {
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
        &:last-child {
            margin-right: 0;
        }
    }
    &_icon {
        width: 24px;
        height: 24px;
        margin-left: 10px;
        cursor: pointer;
        img {
            width: 100%;
            height: 100%;
        }
    }
}
</style>
