<template>
    <div class="User">
        <main class="content">
            <div class="profile-info">
                <div
                    class="profile-info__photo"
                    :class="{ 'replace-img': !user.photo }"
                >
                    <img :src="user.photo" alt="Profile picture" />
                </div>

                <div class="user-info">
                    <div class="row">
                        <h3
                            class="user-info__title"
                            :class="{ replace: !user.name }"
                        >
                            {{ user.name }}
                        </h3>
                        <p
                            class="user-info__text"
                            :class="{ replace: !user.office }"
                        >
                            {{ user.office }}
                        </p>
                    </div>
                </div>

                <div class="social-media" v-if="false">
                    <h2 class="social-media__title">Social</h2>

                    <div class="social-media__grid">
                        <div class="social-media__grid__icon">
                            <img
                                src="https://via.placeholder.com/20x20/00ffff"
                                alt=""
                            />
                        </div>

                        <div class="social-media__grid__icon">
                            <img
                                src="https://via.placeholder.com/20x20/ff6347"
                                alt=""
                            />
                        </div>

                        <div class="social-media__grid__icon">
                            <img
                                src="https://via.placeholder.com/20x20/0000ff"
                                alt=""
                            />
                        </div>
                    </div>
                </div>

                <div class="personal-info">
                    <div class="row">
                        <h3 class="personal-info__title">Email</h3>
                        <p
                            class="personal-info__text"
                            :class="{ replace: !user.name }"
                        >
                            {{ user.email }}
                        </p>
                    </div>
                    <div class="row">
                        <h3 class="personal-info__title">Discord</h3>
                        <p
                            class="personal-info__text"
                            :class="{ replace: !user.discord }"
                        >
                            {{ user.discord }}
                        </p>
                    </div>
                    <div class="row">
                        <h3 class="personal-info__title">Nickname</h3>
                        <p
                            class="personal-info__text"
                            :class="{ replace: !user.nickname }"
                        >
                            {{ user.nickname }}
                        </p>
                    </div>
                    <div class="row">
                        <h3 class="personal-info__title">Team</h3>
                        <p
                            class="personal-info__text"
                            :class="{ replace: !user.teams }"
                            v-for="userTeam of user.teams"
                            :key="userTeam"
                        >
                            {{ userTeam.team }}
                        </p>
                    </div>
                    <div class="row">
                        <h3 class="personal-info__title">Phone</h3>
                        <p
                            class="personal-info__text"
                            :class="{ replace: !user.phone }"
                        >
                            {{ user.phone }}
                        </p>
                    </div>
                    <div class="row">
                        <h3 class="personal-info__title">Country</h3>
                        <p
                            class="personal-info__text"
                            :class="{ replace: !user.country }"
                        >
                            {{ user.country }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="achievements">
                <h1 class="achievements__title">Achievements</h1>
                <div class="achievements__grid" v-if="user.loaded">
                    <achievementCards
                        v-for="(achievement, i) in userAchievements"
                        :key="i"
                        :url="
                            'https://ilustranext.com/img/ui/svg/achievements/' +
                            achievement.image
                        "
                        :name="achievement.name"
                        :alt="achievement.description"
                    />
                </div>
            </div>
        </main>
    </div>
</template>

<script>
import axios from 'axios';
import achievementCards from '@/components/AchievementCard.vue';

export default {
  name: 'User',
  components: {
    achievementCards,
  },
  data() {
    return {
      user: {},
      achievements: [],
      userAchievements: [],
      personalInfo: ['Email', 'Discord', 'Nickname', 'Team', 'Phone', 'Country'],
    };
  },
  methods: {
    async getHttpUser() {
      const baseUrl = process.env.VUE_APP_URL_API;
      const user = this.$route.params.id;
      const apiUrl = `/gets/user/${user}`;
      await axios
        .get(`${baseUrl}${apiUrl}?access_token=${this.getAccessToken()}&id_user=${user}`)
        .then((r) => {
          if (r.data.success === undefined) {
            const {
              nickname, name, email, phone, country, discord, id, teams,
            } = r.data;

            this.user = {
              name,
              email,
              phone,
              country,
              discord,
              id,
              nickname,
              teams,
              office: r.data.function_at_ilustraviz,
              photo: `https://systemiv-images.s3.us-east-2.amazonaws.com/users/ilustraviz/${user}.png`,
              loaded: true,
            };
          } else {
            this.redirectToLogin();
          }
        })
        .catch((err) => {
          window.console.error(err);
        });
    },
    async getHttpAchievements() {
      const baseUrl = process.env.VUE_APP_URL_API;
      const user = this.$route.params.id;
      const apiUrl = '/gets/achievements';

      await axios
        .get(`${baseUrl}${apiUrl}?access_token=${this.getAccessToken()}&id_user=${user}`)
        .then((r) => {
          if (r.data.success === undefined) {
            this.achievements = r.data;
            this.getHttpUserAchievements();
          }
        })
        .catch((err) => {
          window.console.error(err);
        });
    },
    async getHttpUserAchievements() {
      const baseUrl = process.env.VUE_APP_URL_API;
      const user = this.$route.params.id;
      const apiUrl = `/gets/achievements/user/${user}/show`;

      await axios
        .get(`${baseUrl}${apiUrl}?access_token=${this.getAccessToken()}&id_user=${user}`)
        .then((r) => {
          if (r.data.success === undefined) {
            this.setUserAchievements(r.data);
          }
        })
        .catch((err) => {
          window.console.error(err);
        });
    },
    getAccessToken() {
      let token = window.document.cookie.split('=')[1];
      if (!token) {
        this.updateCookies();
      } else {
        token = token.replace("'", '');
        token = token.replace('"', '');
      }
      return token;
    },
    async clearAccessToken() {
      const clear = () => this.$router.replace({ ...this.$router.currentRoute, query: {} });

      await clear();
    },
    async updateCookies() {
      const paramToken = this.$router.currentRoute.value.query.access_token;
      const storageToken = this.getAccessToken();

      if (paramToken !== storageToken) {
        await this.clearAccessToken();
        window.document.cookie = `access_token=${paramToken}`;
      }
    },
    setUserAchievements(userAchievements) {
      const arr = [];
      userAchievements.forEach((el) => arr.push(el.id_achievement));
      this.userAchievements = this.achievements.filter((el) => arr.includes(el.id));
      return arr;
    },
    redirectToLogin() {
      const url = process.env.VUE_APP_URL_REDIRECT;
      window.open(url, '_self');
    },
  },
  created() {
    this.getHttpUser();
    this.getHttpAchievements();
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
@import "@/layout/Mixins.scss";

.User {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.content {
    width: 90%;
    height: 90%;
    background: linear-gradient(120.23deg, #272a2f 10.38%, #1e2127 56.66%);
    border-radius: 20px;
    display: flex;
    align-items: stretch;
}

.profile-info {
    min-width: 220px;
    border-right: 1px solid rgba(196, 196, 196, 0.3);

    &__photo {
        $size: 200px;

        margin: 10px auto 20px;
        width: $size;
        height: $size;
        border-radius: 50%;

        img {
            object-fit: cover;
            object-position: center;
        }
    }
}

.user-info {
    padding: 0 10px;

    &__title {
        @include column-title(#fff);
    }

    &__text {
        //@include component-title(#fff);
    }
}

.personal-info {
    padding: 10px;
    border-top: 1px solid rgba(196, 196, 196, 0.3);

    &__title {
        @include column-title(#fff);
    }

    &__text {
        @include info-text(#fff);
    }

    .row {
        margin-bottom: 20px;
    }
}

.social-media {
    padding: 10px;
    border-top: 1px solid rgba(196, 196, 196, 0.3);

    &__title {
        //@include component-title(#fff);
    }

    &__grid {
        display: flex;
        column-gap: 25px;

        &__icon {
            $size: 20px;
            width: $size;
            height: $size;
        }
    }
}

.achievements {
    width: 100%;

    &__title {
        padding: 10px;
        padding-bottom: 0;
        @include main-title(#fff);
        margin: 0;
    }

    &__grid {
        display: grid;
        row-gap: 10px;
        width: 100%;
        overflow: auto;
        grid-template-columns: repeat(auto-fill, minmax(236px, 1fr));
        grid-template-rows: 322px;
        height: calc(100% - 34px);
        padding: 10px;

        &::-webkit-scrollbar {
            visibility: hidden;
        }
    }
}

.replace {
    width: 100%;
    height: 100%;
    max-height: 200px;
    background: linear-gradient(-45deg, #0092ff, #14456c, #1e2127, #000000);
    background-size: 200% 200%;
    animation: gradient 5s ease infinite;
    color: transparent;

    &-img {
        background: linear-gradient(-45deg, #0092ff, #14456c, #1e2127, #000000);
        background-size: 400% 400%;
        animation: gradient 5s ease infinite;

        img {
            opacity: 0;
        }
    }
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}
</style>
