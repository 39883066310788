<template>
    <transition>
        <section class="buttons">
            <slot> </slot>
        </section>
    </transition>
</template>

<script>
export default {
  name: 'SectionButtons',
};
</script>

<style lang="scss" scoped>
.buttons {
    display: flex;
    width: 100%;
    min-height: 70px;
    border-top: 1px solid grey;
    border-radius: 0 0 20px 20px;
    cursor: pointer;
}
</style>
