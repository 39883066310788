<template>
    <div class="login">
        <div class="login__background">
            <img
                v-for="i in 10"
                :key="'background_' + i"
                :class="{ off: number_img != i }"
                :src="require('../assets/backgrounds/bkl' + i + '.jpg')"
            />
        </div>
        <div class="wrapper">
            <div class="logo">
                <img src="../assets/ilustraviz_logo.png" />
            </div>
            <Box class="box-login box-login--success" v-if="login_error.sended">
                <div class="box-login__form">
                    <Typography class="box-login__title" is="h2" variant="title"
                        >Account Login</Typography
                    >
                    <div class="textSendedEmail">
                        <p>An email was sent to you. Verify your email.</p>
                    </div>
                </div>
                <SectionButtons v-if="!user.loading">
                    <button
                        :key="'loginerrorButton'"
                        class="box-login__button box-login__button--success"
                        @click="backToLogin()"
                    >
                        Back to Login
                    </button>
                </SectionButtons>
            </Box>
            <Box
                v-else
                class="box-login"
                :class="{ 'box-login--error': login_error.text }"
            >
                <div
                    class="box-login__form"
                    v-if="!user.loading && !login_error.forgot_password"
                >
                    <Typography class="box-login__title" is="h2" variant="title"
                        >Account Login</Typography
                    >
                    <Typography
                        class="box-login__label"
                        is="label"
                        variant="subtitle"
                        >Email
                        <TextInput v-model="user.email" />
                    </Typography>
                    <Typography
                        class="box-login__label"
                        is="label"
                        variant="subtitle"
                        >Password
                        <TextInput
                            v-model="user.password"
                            :config="{
                                inputType: 'password',
                                showPassword: showPassword,
                            }"
                            @keyup.enter="Login()"
                            @showHidePassword="showPassword = !showPassword"
                        />
                    </Typography>
                </div>
                <div
                    class="box-login__form"
                    v-if="!user.loading && login_error.forgot_password"
                >
                    <Typography class="box-login__title" is="h2" variant="title"
                        >Account Login</Typography
                    >
                    <Typography
                        class="box-login__label"
                        is="label"
                        variant="subtitle"
                        >Email to receive the reset password link
                        <TextInput
                            v-model="user.email"
                            @keyup.enter="ResetPassword()"
                        />
                    </Typography>
                </div>
                <div v-if="user.loading" class="box-login box-loading">
                    <div class="box-login__loading">
                        <div class="icon_loading">
                            <img
                                :src="
                                    require('@/assets/imgs/icons/loading.png')
                                "
                                alt=""
                            />
                        </div>
                        <p class="loadingText">Loading...</p>
                    </div>
                </div>
                <SectionButtons v-if="!user.loading">
                    <button
                        :key="'loginButton'"
                        v-if="!login_error.text && !login_error.forgot_password"
                        class="box-login__button"
                        @click="Register()"
                    >
                        Sign Up
                    </button>
                    <button
                        :key="'registerButton'"
                        v-if="!login_error.text && !login_error.forgot_password"
                        class="box-login__button"
                        @click="Login()"
                    >
                        Sign In
                    </button>
                    <button
                        :key="'resetButton'"
                        v-if="!login_error.text && login_error.forgot_password"
                        class="box-login__button box-login__button--send"
                        @click="ResetPassword()"
                    >
                        Send
                    </button>
                    <button
                        :key="'loginerrorButton'"
                        v-if="login_error.text"
                        class="box-login__button box-login__button--error"
                    >
                        {{ login_error.text }}
                    </button>
                </SectionButtons>
            </Box>
            <button
                type="button"
                class="forgotPassword"
                v-if="!login_error.sended"
                @click="
                    login_error.forgot_password = !login_error.forgot_password
                "
            >
                {{
                    login_error.forgot_password
                        ? "Back to login"
                        : "Reset your password!"
                }}
            </button>
        </div>
    </div>
</template>

<script>
import http from '../http';
import store from '../store';
import TextInput from '@/components/TextInput.vue';
import SectionButtons from '@/components/SectionButtons.vue';
import Typography from '@/components/styleguide/Typography.vue';
import Box from '@/components/styleguide/Box.vue';

export default {
  name: 'Login',
  components: {
    TextInput,
    Typography,
    Box,
    SectionButtons,
  },
  data() {
    return {
      login_error: {
        forgot_password: false,
        sended: false,
        text: '',
      },
      user: {
        email: '',
        password: '',
        loading: false,
      },
      number_img: Math.floor(Math.random() * 10) + 1,
      button_show_password: {
        active_hover: false,
        show_password_svg: false,
      },
      showPassword: false,
    };
  },
  methods: {
    changeImg() {
      setTimeout(() => {
        this.number_img = Math.floor(Math.random() * 10) + 1;
        this.changeImg();
      }, 10000);
    },
    async Login() {
      let result = {
        success: false,
        message: '',
      };

      if (this.user.email && this.user.password) {
        if (!this.user.email.includes('@')) {
          this.login_error.text = 'Invalid email';
          setTimeout(() => {
            this.login_error.text = '';
          }, 2000);
          return;
        }
        this.user.loading = true;

        // login-user;
        await http
          .post('/api/auth/login', {
            email: this.user.email.toLocaleLowerCase(),
            password: this.user.password,
          })
          .then((r) => {
            console.log(r);
            result = r.data;
            this.$cookies.set('user_token', r.data.token.split('|')[1], '60d');
            // go to page with js
            window.location.href = '/dashboard';
          })
          .catch((e) => {
            console.error(e);
            this.user.loading = false;
            this.login_error.text = e.response.data.message;
            setTimeout(() => {
              this.login_error.text = '';
            }, 2000);
          });
      } else {
        this.login_error.text = 'Invalid email or password';
        setTimeout(() => {
          this.login_error.text = '';
        }, 2000);
      }
    },
    async ResetPassword() {
      if (this.login_error.text) return;

      if (!this.user.email || !this.user.email.includes('@')) {
        this.login_error.text = 'Invalid email format';
        setTimeout(() => {
          this.login_error.text = '';
        }, 2000);
      }
      this.user.loading = true;
      http
        .post(`/api/auth/forgot-password?email=${this.user.email}`)
        .then((r) => {
          this.user.loading = false;
          this.login_error.sended = true;
          // setTimeout(() => {
          //  this.login_error.sended = false;
          //  this.login_error.forgot_password = false;
          // }, 2000);
        })
        .catch((e) => {
          console.error(e);
          this.login_error.text = e.response.data.message;
          setTimeout(() => {
            this.login_error.text = '';
          }, 2000);
        });
    },
    Register() {
      this.$router.push('/register/customer');
    },
    backToLogin() {
      this.login_error.forgot_password = false;
      this.login_error.sended = false;
    },
  },
  mounted() {
    this.changeImg();
  },
  created() {
    if (this.$cookies.get('user_token')) {
      this.$router.push('/dashboard/');
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/layout/Mixins.scss";

.login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    //width: 100%;
    //margin: 0 auto;
    //max-width: 1100px;
    padding-left: 100px;
    position: relative;
    background: linear-gradient(90deg, #141517 40%, rgba(20, 21, 23, 0) 100%);

    &__background {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: absolute;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: absolute;
            top: 0;
            right: 0;
            transition: opacity 1s ease;
            opacity: 1;
        }
        .off {
            opacity: 0;
        }
    }

    @media screen and (max-width: 650px) {
        padding-left: 0;
        align-items: center;
        padding: 0 20px;
    }
}

.wrapper {
    //margin-left: 110px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 450px;
    width: 100%;
    row-gap: 20px;
}

.logo {
    max-width: 300px;
    width: 100%;
    @media screen and (max-width: 4600px) {
        width: calc(100% - 80px);
    }
}

.box-login {
    width: 100%;
    max-width: 450px;
    height: 320px;
    max-height: 320px;

    &__form {
        width: 100%;
        padding: 20px;
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        flex: 1;
    }

    &--error {
        transition: 0.1s ease;
        border: 3px solid #ed1802;
        box-shadow: 0px 7px 5.32px rgba(255, 24, 0, 0.06),
            0px 16px 17.87px rgba(255, 24, 0, 0.08),
            0px 8px 33.42px rgba(255, 24, 0, 0.12),
            0px 33px 80px rgba(255, 24, 0, 0.16);
    }

    &--success {
        transition: 0.1s ease;
        border: 3px solid #0fc621;
        box-shadow: 0px 7px 5.32px rgba(#0fc621, 0.06),
            0px 16px 17.87px rgba(#0fc621, 0.08),
            0px 8px 33.42px rgba(#0fc621, 0.12),
            0px 33px 80px rgba(#0fc621, 0.16);
    }

    &__label {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        position: relative;
        flex: 1;
        display: flex;
        justify-content: center;
    }

    &__button {
        @include column-title(white);
        display: block;
        width: 100%;
        transition: 0.3s ease;
        border-radius: 0px 0px 20px 20px;
        min-height: 70px;

        &--send {
            border-radius: 0px 0px 20px 20px !important;
        }

        &:hover {
            background: $color_blue_1;
            box-shadow: 0px 7px 5.32px rgba(0, 109, 255, 0.06),
                0px 16px 17.87px rgba(0, 109, 255, 0.08),
                0px 8px 33.42px rgba(0, 109, 255, 0.12),
                0px 33px 80px rgba(0, 109, 255, 0.16);
        }

        &--error {
            cursor: not-allowed;
            &:hover {
                background: #ed1802;
                box-shadow: 0px 7px 5.32px rgba(255, 24, 0, 0.06),
                    0px 16px 17.87px rgba(255, 24, 0, 0.08),
                    0px 8px 33.42px rgba(255, 24, 0, 0.12),
                    0px 33px 80px rgba(255, 24, 0, 0.16);
            }
        }
        &--success {
            cursor: pointer;
            border-radius: 0px 0px 10px 10px;
            &:hover {
                background: #0fc621;
                box-shadow: 0px 7px 5.32px rgba(#0fc621, 0.06),
                    0px 16px 17.87px rgba(#0fc621, 0.08),
                    0px 8px 33.42px rgba(#0fc621, 0.12),
                    0px 33px 80px rgba(#0fc621, 0.16);
            }
        }

        &:first-child {
            border-radius: 0px 0px 0px 20px;
        }
        &:last-child {
            border-radius: 0px 0px 20px 0px;
        }
    }
    &__loading {
        .icon_loading {
            width: 50px;
            height: 50px;
            img {
                animation: k_loading 1s infinite linear;
            }
        }
    }

    &__loader {
        width: 70px;
        animation: loading 3s linear infinite;
    }
}
.box-loading {
    display: flex;
    justify-content: center;
    align-items: center;
}

.forgotPassword {
    font-family: "Fira Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #95949b;
    transition: 0.5s ease;
    &:hover {
        color: #ffffff;
    }
}
.loadingText {
    font-family: "Fira Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #95949b;
    margin-top: 15px;
}

@keyframes k_loading {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(-360deg);
    }
}
.textSendedEmail {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
        font-family: "Fira Sans";
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 18px;
        color: white;
        text-align: center;
        margin-bottom: 24px;
    }
}
</style>
