<template>
    <div class="simpleDropdown" :class="{ showList: open }">
        <div class="simpleDropdown_header" @click="open = !open">
            <p class="simpleDropdown_text">{{ list[modelValue] }}</p>
            <button class="simpleDropdown_icon">
                <img
                    :src="require('@/assets/imgs/icons/chevron-down.svg')"
                    alt=""
                />
            </button>
        </div>
        <div class="simpleDropdown_list">
            <ul :class="{ fullHeight: fullHeight }">
                <li
                    v-for="(item, i) in list"
                    :key="'dropdown_' + item + '_' + i"
                >
                    <Button
                        v-if="item != ''"
                        :text="item"
                        @click="select(i)"
                        :active="modelValue == i"
                    />
                    <div v-else class="separator">
                        <div></div>
                    </div>
                </li>
            </ul>
        </div>
        <div
            class="simpleDropdown_close"
            v-if="open"
            @click="open = false"
        ></div>
    </div>
</template>

<script>
import Button from '@/components/buttons/Button-text.vue';

export default {
  props: ['list', 'modelValue', 'fullHeight'],
  data() {
    return {
      open: false,
    };
  },
  components: {
    Button,
  },
  methods: {
    select(index) {
      this.$emit('update', index);
      this.open = false;
    },
  },
};
</script>

<style lang="scss" scoped>
* {
    margin: 0;
    padding: 0;
    text-decoration: none;
    list-style: none;
}
.simpleDropdown {
    position: relative;
    background: #3d424e;
    border: 1px solid #666972;
    border-radius: 50px;
    //width: 100%;
    padding: 8px 10px;
    &_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &_text {
        color: #fff;
        font-size: 15px;
        margin-right: 5px;
    }
    &_icon {
        width: 20px;
        height: 20px;
        background: none;
        border: none;
        cursor: pointer;
    }
    &_list {
        position: absolute;
        top: calc(100% + 10px);
        left: 0;
        width: fit-content;
        background: #272a30;
        border-radius: 10px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        padding-top: 5px;
        z-index: 11;
        display: none;
        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            display: flex;
            flex-direction: column;
            max-height: 155px;
            overflow-y: scroll;
            width: fit-content;
            &::-webkit-scrollbar {
                display: none;
            }
            li {
                margin-bottom: 5px;
                margin-left: 5px;
                margin-right: 5px;
            }
        }
        .fullHeight {
            max-height: none;
        }
    }
}

.showList {
    .simpleDropdown_list {
        display: block;
    }
}

.separator {
    margin-left: 5px;
    margin-right: 5px;
    background: #3d424e;
    width: calc(100% - 10px);
    height: 1px;
}

.simpleDropdown_close {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
}
</style>
