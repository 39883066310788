<template>
    <div
        class="itemIconInfo"
        :class="{
            active: active,
            deactived: deactived,
            backgroundLight: backgroundLight,
        }"
    >
        <div class="itemIconInfo_icon" v-if="icon">
            <img :src="require('@/assets' + icon)" alt="" />
        </div>
        <div class="itemIconInfo_title">
            <span>{{ title }}</span>
        </div>
        <div class="itemIconInfo_subtitle">
            <span>{{ subtitle }}</span>
        </div>
        <div
            class="itemIconInfo_flag"
            v-if="flag"
            :style="{
                background: flagColor,
            }"
        >
            {{ flag }}
        </div>
    </div>
</template>

<script>
export default {
  props: [
    'icon',
    'title',
    'subtitle',
    'active',
    'deactived',
    'flag',
    'flagColor',
    'backgroundLight',
  ],
};
</script>

<style lang='scss' scoped>
.itemIconInfo {
    background: #1e2127;
    width: 90px;
    height: 90px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.5s ease;
    position: relative;
    overflow: hidden;
    &_icon {
        width: 28px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        & img {
            width: 100%;
            height: 100%;
        }
    }
    &_title {
        font-size: 12px;
        color: #fff;
        text-align: center;
        margin-top: 6px;
    }
    &_subtitle {
        font-size: 8px;
        color: #fff;
        text-align: center;
        margin-top: 2px;
    }
    &:hover {
        background: #0092ff;
    }

    &_flag {
        position: absolute;
        top: 0px;
        left: 5px;
        z-index: 1;
        opacity: 1;
        transition: 0.5s ease;
        //background: #0264d8;
        color: #fff;
        font-size: 12px;
        font-weight: 600;
        padding: 5px;
        border-radius: 0px 0px 2px 2px;
    }
}
.active {
    background: #0092ff;
}

.deactived {
    opacity: 0.3;
    &:hover {
        //border: 1px solid rgba($color: #1e2127, $alpha: 0);
        cursor: default;
        background: #1e2127 !important;
        &::after {
            //background: none;
        }
    }
}

.backgroundLight {
    background: #3d424e;
}
</style>
