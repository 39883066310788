<template>
  <div class="avatar">
    <img class="avatar__img" :src="url" :alt="name" />
  </div>
</template>

<script>
export default {
  name: 'Avatar',
  props: ['url', 'name'],
};
</script>

<style lang="scss" scoped>
  .avatar {
    $size: 34px;
    width: $size;
    height: $size;
    border-radius: 50%;

    &__img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: inherit;
    }
  }

</style>>
