<template>
    <div class="app">
        <Menu
            active="settings"
            :userName="user.name"
            :userPhoto="user.photoData"
            @getCompanies="setCompanies"
        />
        <div class="menu">
            <ButtonMenu
                text="User Management"
                icon="/imgs/icons/users.svg"
                :active="true"
            />
        </div>
        <div class="content">
            <div class="content_block">
                <div class="users">
                    <Block title="My account">
                        <div>
                            <UserBlock
                                :name="user.name"
                                :email="user.email"
                                :photo="user.photo ? user.photo : ''"
                                :phone="user.phone"
                                :thisUser="true"
                                @updateMyUser="updateMyUser"
                                @updateMyUserPhoto="updateMyUserPhoto"
                            />
                        </div>
                    </Block>
                    <Block
                        :customHeader="true"
                        v-if="permissions['manage-roles']"
                    >
                        <template v-slot:header>
                            <div class="managerUser_bar">
                                <p class="font-title">Manage Accounts</p>
                                <div class="managerUser_bar_search">
                                    <InputText
                                        placeholder="Search"
                                        class="block_input"
                                        icon="/imgs/icons/search.svg"
                                        v-model="inputFilterUser"
                                    />
                                    <ButtonIcon
                                        v-if="permissions['manage-users']"
                                        icon="/imgs/icons/InviteUser2.svg"
                                        background="#3d424e"
                                        :rounded="true"
                                        padding="8px 8px 8px 9px"
                                        @click="popupAddUser = true"
                                    />
                                </div>
                            </div>
                        </template>
                        <div v-if="users.length">
                            <UserBlock
                                v-for="user in listUsers"
                                :key="'u_' + user.id"
                                :admin="permissions['manage-users']"
                                class="users_item"
                                :name="user.name"
                                :email="user.email"
                                :photo="
                                    user.urlImage ? user.urlImage : user.photo
                                "
                                :phone="user.phone"
                                :role="user.role ? user.role : -1"
                                :roles="roles"
                                :thisUser="false"
                                :id_customer="idClient"
                                :id="user.id"
                                :userEnabled="user.enabled"
                                @updateUserRole="updateUserRole"
                                @disableUser="openDisableId"
                                @enableUser="enableUser"
                                @deleteUser="openDeleteId"
                            />
                        </div>
                        <div v-else class="noUsers">
                            <p class="font-field">Empty List</p>
                            <p class="font-common">No other users</p>
                        </div>
                    </Block>
                </div>
                <div class="roles">
                    <Block
                        title="Roles and Permissions"
                        class="roles_block"
                        v-if="permissions['manage-roles']"
                    >
                        <div class="roles_content">
                            <div class="roles_list">
                                <div class="roles_list_header">
                                    <p class="font-title">Roles</p>
                                    <ButtonIcon
                                        icon="/imgs/icons/plus-circle.svg"
                                        :width="40"
                                        :height="40"
                                        :rounded="true"
                                        padding="0px"
                                        background="none"
                                        opacity="1"
                                        @click="addRole"
                                    />
                                </div>
                                <div class="roles_list_buttons">
                                    <ButtonMenu
                                        v-for="(r, i) in roles"
                                        :key="'role_' + r.id"
                                        :text="r.name"
                                        :noicon="true"
                                        :active="roleActive == i"
                                        :background="
                                            roleActive == i ? false : '#3d424e'
                                        "
                                        @click="setActiveRole(i)"
                                    />
                                </div>
                            </div>
                            <div class="roles_permission" v-if="roles.length">
                                <div class="roles_permission_header">
                                    <p class="font-title">
                                        Permissions -
                                        {{ roles[roleActive].name }}
                                    </p>
                                    <ButtonMenu
                                        text="Delete Role"
                                        noicon="true"
                                        type="danger"
                                        v-if="roleActive != 0"
                                        @click="deleteRole"
                                    />
                                </div>
                                <div
                                    class="roles_permission_nameinput"
                                    v-if="roleActive != 0"
                                >
                                    <p class="font-field">Role Name</p>
                                    <InputText
                                        placeholder="Admin"
                                        v-model="roles[roleActive].name"
                                    />
                                </div>
                                <div class="roles_permission_list">
                                    <div
                                        class="roles_permission_item"
                                        v-for="p in roles[0].privileges"
                                        :key="'permission_' + p"
                                    >
                                        <div class="row">
                                            <p
                                                class="
                                                    font-field
                                                    roles_permission_list_name
                                                "
                                            >
                                                {{ p.name }}
                                            </p>
                                            <Toggle
                                                :value="getRolePermited(p.id)"
                                                v-if="roleActive != 0"
                                                @click="
                                                    updateRolePermited(p.id)
                                                "
                                            />
                                        </div>
                                        <p
                                            class="
                                                font-common
                                                roles_permission_list_description
                                            "
                                        >
                                            {{ p.description }}
                                        </p>
                                    </div>
                                    <ButtonMenu
                                        text="Save"
                                        :noicon="true"
                                        class="button-save"
                                        background="#1e2127"
                                        borderRadius="0px 0px 5px 5px"
                                        color="#ffffff"
                                        height="50px"
                                        @click="saveRole"
                                        v-if="roleActive != 0"
                                    />
                                </div>
                            </div>
                        </div>
                    </Block>
                    <Block :customHeader="true" v-else>
                        <template v-slot:header>
                            <div class="managerUser_bar">
                                <p class="font-title">Manage Accounts</p>
                                <div class="managerUser_bar_search">
                                    <InputText
                                        placeholder="Search"
                                        class="block_input"
                                        icon="/imgs/icons/search.svg"
                                        v-model="inputFilterUser"
                                    />
                                    <ButtonIcon
                                        v-if="permissions['manage-users']"
                                        icon="/imgs/icons/InviteUser2.svg"
                                        background="#3d424e"
                                        :rounded="true"
                                        padding="8px 8px 8px 9px"
                                        @click="popupAddUser = true"
                                    />
                                </div>
                            </div>
                        </template>
                        <div v-if="users.length">
                            <UserBlock
                                v-for="user in listUsers"
                                :key="'u_' + user.id"
                                :admin="permissions['manage-users']"
                                class="users_item"
                                :name="user.name"
                                :email="user.email"
                                :photo="
                                    user.urlImage ? user.urlImage : user.photo
                                "
                                :phone="user.phone"
                                :role="user.role ? user.role : -1"
                                :roles="roles"
                                :thisUser="false"
                                :id_customer="idClient"
                                :id="user.id"
                                @updateUserRole="updateUserRole"
                                @disableUser="openDisableId"
                                @enableUser="enableUser"
                                @deleteUser="openDeleteId"
                            />
                        </div>
                        <div v-else class="noUsers">
                            <p class="font-field">Empty List</p>
                            <p class="font-common">No other users</p>
                        </div>
                    </Block>
                </div>
            </div>
        </div>
    </div>
    <PopupAlert
        v-if="popupAddUser"
        title="Register User"
        subTitle="An email will be sent to the registered user to create the password."
        :confirm="true"
        :cancel="true"
        @cancel="alertEmitCancelAddUser"
        @confirm="addUserEvent"
    >
        <ul class="popupList">
            <li>
                <p class="font-field title">Company</p>
                <Dropdown
                    :list="getNameCompanies()"
                    :modelValue="getIndexCompanie()"
                    background="#3d424e"
                    class="dropdown"
                    @update="changeCompanyFull"
                />
            </li>
            <li>
                <div class="group">
                    <p class="font-field title">Email*</p>
                    <InputText
                        placeholder="Email"
                        class="input"
                        v-model="addUser.email"
                    />
                </div>
                <div class="group">
                    <p class="font-field title">User Name</p>
                    <InputText
                        placeholder="Name"
                        class="input"
                        v-model="addUser.name"
                    />
                </div>
            </li>
            <li>
                <div class="group">
                    <p class="font-field title">Role</p>
                    <p class="font-common subtitle">Change Role</p>
                </div>
                <Dropdown
                    :list="getRolesNames()"
                    :modelValue="addUser.role"
                    background="#3d424e"
                    class="dropdown"
                    @update="changeRoleAddUser"
                />
            </li>
        </ul>
    </PopupAlert>
    <PopupAlert
        v-if="popupDelete"
        title="Delete Account"
        subTitle="The user account will be deleted. Confirm using your user password to proceed."
        :confirm="true"
        :cancel="true"
        @cancel="alertEmitCancelDelete"
        @confirm="deleteUser"
    >
        <ul class="popupList">
            <li>
                <p class="font-field title">Delete from Company</p>
                <Dropdown
                    :list="getCompaniesToDisableDelete()"
                    :modelValue="getindexCompanieToDisableDelete()"
                    background="#3d424e"
                    class="dropdown"
                    @update="changeCompany"
                />
            </li>
            <li>
                <div class="group">
                    <p class="font-field title">Password</p>
                    <InputText
                        placeholder="******"
                        class="input"
                        type="password"
                        v-model="passwordDelete"
                    />
                </div>
            </li>
        </ul>
    </PopupAlert>
    <PopupAlert
        v-if="popupDisable"
        title="Disable Account?"
        subTitle="The user's account will not be deleted from the selected company, but the user will not be able to access the company whose account was deactivated."
        :confirm="true"
        :cancel="true"
        @cancel="alertEmitCancelDisable"
        @confirm="disableUser"
    >
        <ul class="popupList">
            <li>
                <p class="font-field title">Disable in the Company</p>
                <Dropdown
                    :list="getCompaniesToDisableDelete()"
                    :modelValue="getindexCompanieToDisableDelete()"
                    background="#3d424e"
                    class="dropdown"
                    @update="changeCompany"
                />
            </li>
        </ul>
    </PopupAlert>
    <PopupAlert
        v-if="popupAlert.show"
        :title="popupAlert.title"
        :subTitle="popupAlert.subTitle"
        :confirm="popupAlert.confirm"
        :cancel="popupAlert.cancel"
        @confirm="alertEmitConfirm"
        @cancel="alertEmitCancel"
        :error="popupAlert.type === 'error'"
    />
</template>

<script>
import Menu from './common/Menu.vue';
import ButtonMenu from '@/components/buttons/Button-icon-text.vue';
import Block from '@/components/sections/Block.vue';
import UserBlock from '@/components/user/UserBlock.vue';
import InputText from '@/components/inputs/InputText.vue';
import ButtonIcon from '@/components/buttons/Button-icon.vue';
import Toggle from '@/components/inputs/Toggle.vue';
import PopupAlert from './common/PopupAlert.vue';
import Dropdown from '@/components/dropdowns/Dropdown.vue';
import http from '@/http';
import store from '@/store';
import loadingController from '@/utils/loadingController';
import sleep from '@/utils/sleep';

const lController = new loadingController();

export default {
  components: {
    Menu,
    ButtonMenu,
    Block,
    UserBlock,
    InputText,
    ButtonIcon,
    Toggle,
    PopupAlert,
    Dropdown,
  },
  data() {
    return {
      user: {
        name: '',
        photo: '',
        email: '',
        photoData: '',
      },
      users: [],
      permissions: {
        'manage-roles': false,
        'manage-users': false,
      },
      idClient: 1,
      companies: [],
      roles: [],
      roleActive: 0,
      disableId: 0,
      popupDisable: false,
      popupDelete: false,
      popupAddUser: false,
      passwordDelete: '',
      inputFilterUser: '',
      addUser: {
        email: '',
        name: '',
        role: 0,
      },
      popupAlert: {
        show: false,
        title: '',
        subTitle: '',
        confirm: false,
        cancel: false,
        type: '',
      },
    };
  },
  methods: {
    async getUsers() {
      await http
        .get(`users/company?id_customer=${this.idClient}`)
        .then((res) => {
          console.log(this.users);
          res.data.users.forEach((user) => {
            const i = this.users.push(user);
            if (this.users[i - 1].photo == null) this.users[i - 1].photo = store.state.customer.photo;
          });
        })
        .catch((err) => {
          console.log(err);
        });
      this.completeTaskLoad();
    },
    async getRoles() {
      await http
        .get(`customer/roles?id_customer=${this.idClient}`)
        .then((res) => {
          this.roles = res.data.roles;
          console.log(this.roles);
        })
        .catch((err) => {
          console.log(err);
        });
      this.completeTaskLoad();
    },
    async getAccess() {
      const slugs = [];
      // get keys my object
      Object.keys(this.permissions).forEach((key) => {
        slugs.push(key);
      });
      await http
        .get(`user/have_access?id_customer=${this.idClient}&slugs=${slugs.join(',')}`)
        .then((res) => {
          Object.keys(res.data.data).forEach((key) => {
            this.permissions[key] = res.data.data[key];
          });
        })
        .catch((err) => {
          console.log(err);
        });
      this.completeTaskLoad();
    },
    updateMyUser(data) {
      this.user.name = data.name;
      this.user.phone = data.phone;

      store.state.user.name = data.name;
      store.state.user.phone = data.phone;
    },
    updateMyUserPhoto(data) {
      this.user.photoData = data;
      this.user.photo = data;
    },
    updateUserRole(data) {
      const user = this.users.find((user) => user.id === data.id);
      user.role = data.role;
    },
    setActiveRole(role) {
      this.roleActive = role;
    },
    getRolePermited(role) {
      const privilege = this.roles[this.roleActive].privileges.find(
        (privilege) => privilege.id === role,
      );
      if (privilege) return privilege.permited;
      const adminPermited = this.roles[0].privileges.find((privilege) => privilege.id === role);
      this.roles[this.roleActive].privileges.push({
        id: role,
        permited: false,
        slug: adminPermited.slug,
      });
      return false;
    },
    updateRolePermited(role) {
      if (this.roleActive === 0) return;
      const privilege = this.roles[this.roleActive].privileges.find(
        (privilege) => privilege.id === role,
      );
      privilege.permited = !privilege.permited;
    },
    addRole() {
      this.roles.push({
        id: -1,
        name: 'Role Name*',
        privileges: [],
      });
    },
    deleteRole() {
      const role = this.roleActive;
      this.popupAlert.show = true;
      this.popupAlert.title = 'Delete Role';
      this.popupAlert.subTitle = 'Deleting role...';
      this.popupAlert.cancel = false;
      this.popupAlert.confirm = false;
      http
        .delete(`customer/role/delete?id_customer=${this.idClient}&id_role=${this.roles[role].id}`)
        .then((res) => {
          this.roleActive = 0;
          this.roles.splice(role, 1);
          this.popupAlert.subTitle = 'Role deleted';
          this.popupAlert.confirm = true;
        })
        .catch((err) => {
          debugger;
          this.popupAlert.title = 'Error';
          this.popupAlert.subTitle = err.response.data.message;
          this.popupAlert.confirm = true;
          console.log(err);
        });
    },
    async saveRole() {
      const role = this.roles[this.roleActive];
      let result = true;
      this.popupAlert.show = true;
      this.popupAlert.title = 'Save Role';
      this.popupAlert.cancel = false;
      this.popupAlert.confirm = false;
      if (role.id === -1) {
        this.popupAlert.subTitle = 'Creating role...';
        result = await this.createRole();
        if (!result) return;
      } else {
        this.popupAlert.subTitle = 'Updating role...';
        result = await this.updateNameRole();
        if (!result) return;
      }
      result = await this.updateRole();
      if (!result) return;
      this.popupAlert.subTitle = 'Role updated';
      this.popupAlert.confirm = true;
    },
    async createRole() {
      const role = this.roles[this.roleActive];
      let success = true;
      const data = {
        id_customer: this.idClient,
        name: role.name,
      };
      await http
        .post('customer/role/create', data)
        .then((res) => {
          debugger;
          role.id = res.data.role;
        })
        .catch((err) => {
          debugger;
          success = false;
          console.log(err);
          this.popupAlert.show = true;
          this.popupAlert.title = 'Error';
          this.popupAlert.subTitle = err.response.data.message;
          this.popupAlert.cancel = false;
          this.popupAlert.confirm = true;
        });
      return success;
    },
    async updateRole() {
      const role = this.roles[this.roleActive];
      const privileges = {};
      let success = true;
      role.privileges.forEach((privilege) => {
        privileges[privilege.slug] = privilege.permited;
      });
      const data = {
        id_role: role.id,
        id_customer: this.idClient,
        name: role.name,
        permissions: JSON.stringify(privileges),
      };
      await http
        .put('customer/role/edit_permissions', data)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          debugger;
          success = false;
          console.log(err);
          this.popupAlert.show = true;
          this.popupAlert.title = 'Error';
          this.popupAlert.subTitle = err.response.data.message;
          this.popupAlert.cancel = false;
          this.popupAlert.confirm = true;
        });
      return success;
    },
    async updateNameRole() {
      const role = this.roles[this.roleActive];
      let success = true;
      const data = {
        id_role: role.id,
        id_customer: this.idClient,
        name: role.name,
      };
      await http
        .put('customer/role/edit_name', data)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          debugger;
          success = false;
          console.log(err);
          this.popupAlert.show = true;
          this.popupAlert.title = 'Error';
          this.popupAlert.subTitle = err.response.data.message;
          this.popupAlert.cancel = false;
          this.popupAlert.confirm = true;
        });
      return success;
    },
    async disableUser() {
      this.alertEmitCancelDisable();
      this.popupAlert.show = true;
      this.popupAlert.title = 'Disable User';
      this.popupAlert.subTitle = 'Disabling user...';
      this.popupAlert.cancel = false;
      this.popupAlert.confirm = false;
      const data = {
        id_user: this.disableId,
        id_customer: this.idClient,
      };
      await http
        .put('user/deactivate', data)
        .then((res) => {
          this.popupAlert.subTitle = 'User disabled';
          this.popupAlert.confirm = true;
          this.users.find((user) => user.id === this.disableId).enabled = false;
        })
        .catch((err) => {
          debugger;
          this.popupAlert.title = 'Error';
          this.popupAlert.subTitle = err.response.data.message;
          this.popupAlert.confirm = true;
          console.log(err);
        });
    },
    async addUserEvent() {
      this.alertEmitCancelAddUser();
      this.popupAlert.show = true;
      this.popupAlert.title = 'Add User';
      this.popupAlert.subTitle = 'Adding user...';
      this.popupAlert.cancel = false;
      this.popupAlert.confirm = false;
      const data = {
        id_customer: this.idClient,
        name: this.addUser.name,
        email: this.addUser.email,
        id_role: this.roles[this.addUser.role].id,
      };
      debugger;
      await http
        .post('user/inside', data)
        .then((res) => {
          debugger;
          this.users.push({
            id: res.data.id,
            name: data.name,
            email: data.email,
            phone: '',
            urlImage: null,
            photo: store.state.customer.photo,
            enabled: true,
            role: {
              id: this.roles[this.addUser.role].id,
              name: this.roles[this.addUser.role].name,
            },
            sameCompany: [this.companies.find((company) => company.id === this.idClient)],
          });
          this.popupAlert.subTitle = 'User added';
          this.popupAlert.confirm = true;
        })
        .catch((err) => {
          debugger;
          this.popupAlert.title = 'Error';
          this.popupAlert.subTitle = err.response.data.message;
          this.popupAlert.confirm = true;
          console.log(err);
        });
    },
    async deleteUser() {
      this.alertEmitCancelDelete();
      this.popupAlert.show = true;
      this.popupAlert.title = 'Delete User';
      this.popupAlert.subTitle = 'Deleting user...';
      this.popupAlert.cancel = false;
      this.popupAlert.confirm = false;
      const data = {
        id_user: this.disableId,
        id_customer: this.idClient,
        password: this.passwordDelete,
      };
      await http
        .delete('user/delete', { data })
        .then((res) => {
          this.popupAlert.subTitle = 'User deleted';
          this.popupAlert.confirm = true;
          this.users = this.users.filter((user) => user.id !== this.disableId);
        })
        .catch((err) => {
          debugger;
          this.popupAlert.title = 'Error';
          this.popupAlert.subTitle = err.response.data.message;
          this.popupAlert.confirm = true;
          console.log(err);
        });
    },
    async enableUser(id) {
      this.popupAlert.show = true;
      this.popupAlert.title = 'Enable User';
      this.popupAlert.subTitle = 'Enabling user...';
      this.popupAlert.cancel = false;
      this.popupAlert.confirm = false;
      const data = {
        id_user: id,
        id_customer: this.idClient,
      };
      await http
        .put('user/activate', data)
        .then((res) => {
          this.popupAlert.subTitle = 'User enabled';
          this.popupAlert.confirm = true;
          this.users.find((user) => user.id === id).enabled = true;
        })
        .catch((err) => {
          debugger;
          this.popupAlert.title = 'Error';
          this.popupAlert.subTitle = err.response.data.message;
          this.popupAlert.confirm = true;
          console.log(err);
        });
    },
    alertEmitConfirm() {
      this.popupAlert.show = false;
      this.popupAlert.title = '';
      this.popupAlert.subTitle = '';
      this.popupAlert.cancel = false;
      this.popupAlert.confirm = false;
    },
    openDisableId(id) {
      console.log(id);
      this.disableId = id;
      this.popupDisable = true;
    },
    openDeleteId(id) {
      console.log(id);
      this.disableId = id;
      this.popupDelete = true;
    },
    getCompaniesToDisableDelete() {
      const companies = [];
      const user = this.users.find((user) => user.id === this.disableId);
      if (!user) return companies;
      user.sameCompany.forEach((company) => {
        companies.push(company.name);
      });
      return companies;
    },
    getindexCompanieToDisableDelete() {
      const user = this.users.find((user) => user.id === this.disableId);
      if (!user) return -1;
      return user.sameCompany.findIndex((company) => company.id === this.idClient);
    },
    alertEmitCancelDisable() {
      this.popupDisable = false;
    },
    alertEmitCancelDelete() {
      this.popupDelete = false;
    },
    alertEmitCancelAddUser() {
      this.popupAddUser = false;
    },
    changeCompany(index) {
      const user = this.users.find((user) => user.id === this.disableId);
      localStorage.setItem('customer', user.sameCompany[index].id);
      window.location.reload();
    },
    changeCompanyFull(index) {
      localStorage.setItem('customer', this.companies[index].id);
      window.location.reload();
    },
    filterUsers() {
      return this.users.filter(
        (user) => user.name.toLowerCase().includes(this.inputFilterUser.toLowerCase())
          || user.email.toLowerCase().includes(this.inputFilterUser.toLowerCase()),
      );
    },
    setCompanies(companies) {
      this.companies = companies;
    },
    getNameCompanies() {
      const companies = [];
      this.companies.forEach((company) => {
        companies.push(company.name);
      });
      return companies;
    },
    getIndexCompanie() {
      return this.companies.findIndex((company) => company.id === this.idClient);
    },
    getRolesNames() {
      const roles = [];
      this.roles.forEach((role) => {
        roles.push(role.name);
      });
      return roles;
    },
    changeRoleAddUser(index) {
      this.addUser.role = index;
    },
    async completeTaskLoad() {
      lController.completeTask();
      this.popupAlert.subTitle = `Please wait. completed ${lController.getProgress()}%`;
      if (lController.getStatus() === 'ended') {
        await sleep(1000);
        this.popupAlert.show = false;
      }
    },
  },
  computed: {
    listUsers() {
      return this.filterUsers();
    },
  },
  created() {
    this.idClient = store.state.customer.id;
    this.user = store.state.user;
    if (!this.user.photo) this.user.photo = `/users/customers/${store.state.customer.id}.png`;
  },
  async mounted() {
    lController.reset();
    lController.start(3);
    this.popupAlert.show = true;
    this.popupAlert.title = 'Loading';
    this.popupAlert.subTitle = 'Please wait. completed 0%';
    this.popupAlert.confirm = false;
    this.popupAlert.cancel = false;
    this.popupAlert.type = 'loading';
    this.getAccess();
    this.getUsers();
    this.getRoles();
  },
};
</script>

<style lang="scss" scoped>
.menu {
    background: #1e2127;
    padding: 20px 5px;
}
.app {
    display: grid;
    grid-template-columns: minmax(1px, auto) minmax(1px, auto) 1fr;
}
.content {
    padding: 20px;
    display: flex;
    justify-content: stretch;
    align-items: stretch;
    &_block {
        background: #1e2127;
        padding: 10px;
        border-radius: 10px;
        width: 100%;
        margin-bottom: 0px;
        display: grid;
        grid-template-columns: 575px 1fr;
        grid-gap: 10px;
        max-height: calc(100vh - 40px);
        > * {
            background: #272a30;
            padding: 10px;
            border-radius: 10px;
            overflow-y: auto;

            &::-webkit-scrollbar {
                display: none;
            }
        }
    }
}
.users {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    > * {
        &:last-child {
            flex: 1;
        }
    }
    &_item {
        & + & {
            padding-top: 20px;
            margin-top: 20px;
            border-top: 1px solid #3d424e;
        }
    }
}

.managerUser {
    &_bar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        p {
            color: #fff;
            margin: 0;
        }

        &_search {
            display: flex;
            align-items: center;
            > * + * {
                margin-left: 10px;
                height: 30px;
                width: 30px;
            }
        }
    }
}

.roles {
    height: 100%;
    > * {
        height: 100%;
    }
    &_block {
    }
    &_content {
        display: flex;
        align-items: stretch;
        justify-content: stretch;
        gap: 20px;
        height: 100%;
    }
    &_list {
        max-width: 200px;
        max-height: 100%;
        overflow-y: auto;
        padding-bottom: 20px;

        &::-webkit-scrollbar {
            display: none;
        }

        &_header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 20px;
            p {
                color: #ffffff;
            }
        }
        &_buttons {
            display: flex;
            flex-direction: column;
            gap: 10px;
            margin-top: 10px;
        }
    }
    &_permission {
        flex: 1;
        overflow-y: auto;
        max-height: 100%;
        padding-bottom: 20px;

        &::-webkit-scrollbar {
            display: none;
        }

        &_header {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            margin-bottom: 20px;
            p {
                color: #ffffff;
            }
        }
        &_nameinput {
            display: flex;
            flex-direction: column;
            margin-bottom: 20px;
            p {
                color: #ffffff;
            }
        }
        &_list {
            .row {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            &_name {
                color: #ffffff;
            }
            &_description {
                color: #95949b;
            }

            .button-save {
                margin-top: 20px;
                width: 100%;
                position: sticky;
                bottom: -20px;
            }
        }

        &_item {
            & + & {
                margin-top: 10px;
                padding-top: 10px;
                border-top: 1px solid #3d424e;
            }
        }
    }
}
.popupList {
    padding-top: 10px;
    margin-top: 10px;
    border-top: 1px solid #3d424e;
    width: 100%;
    li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
        .title {
            color: #ffffff;
        }
        .subtitle {
            color: #ffffff;
        }
        .input {
            width: 100%;
        }
        .group {
            flex: 1;
        }
        & + li {
            margin-top: 10px;
            padding-top: 10px;
            border-top: 1px solid #3d424e;
        }
    }
}

.block {
    margin-top: 0px;
    margin-bottom: 0px !important;

    & + & {
        margin-top: 10px;
    }
}
</style>

<style lang="scss">
.roles {
    &_block {
        .block_content {
            height: calc(100% - 37px);
            padding-bottom: 0;
        }
    }
}
.noUsers {
    p {
        color: #ffffff;
        text-align: center;
    }
}
</style>
