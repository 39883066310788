<template>
  <component
    :is="is"
    class="typography"
    :class="{
      title: isTitle(),
      subtitle: isSubtitle(),
      detail: isDetail(),
    }"
  >
    <slot></slot>
  </component>
</template>

<script>
export default {
  name: 'Typography',
  props: {
    is: {
      type: String,
      required: true,
    },
    variant: {
      type: String,
    },
  },
  methods: {
    isTitle() {
      return (this.variant && this.variant.toLowerCase() === 'title');
    },
    isSubtitle() {
      return (this.variant && this.variant.toLowerCase() === 'subtitle');
    },
    isDetail() {
      return (this.variant && this.variant.toLowerCase() === 'detail');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/layout/Mixins.scss";

.typography {
  @include component-text(white);

  &.title {
    @include main-title(white);
  }

  &.subtitle {
    @include column-title(white);
  }

  &.detail {
    @include info-text(#95949B);
  }
}
</style>
