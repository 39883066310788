<template>
    <div class="productScreen_header">
        <div class="productScreen_header_infos">
            <p class="productScreen_sku">
                {{ collection ? collection + " Collection" : "" }} ({{
                    sku ? "SKU: " + sku : ""
                }}
                #{{ id }})
            </p>
        </div>
        <Button
            v-if="permissions['editor-info-access'] && !edit"
            class="productScreen_header_editButton"
            text="Delete Product"
            :noicon="true"
            :inverted="true"
            type="danger"
            @click="showPopupDeleteInfo()"
        />
        <Button
            v-if="permissions['editor-info-access'] && !edit"
            class="productScreen_header_editButton"
            text="Edit"
            :active="edit"
            icon="/imgs/icons/edit_noBorder.svg"
            :inverted="true"
            :background="'#3d424e'"
            @click="toggleEdit()"
        />
    </div>
    <div
        class="productScreen_header"
        v-if="!edit"
    >
        <p
            class="productScreen_header_flag"
            v-if="automation"
        >A</p>
        <div class="productScreen_header_infos">
            <h1 class="productScreen_name">{{ name ? name : sku }}</h1>
        </div>
    </div>
    <Block
        class="blockSection"
        title="Product Identification - Name or SKU Required"
        v-if="edit"
    >
        <div class="infrow productIdentification_line">
            <div class="infrow">
                <p class="simpleSectionnText">Name:</p>
                <InputText v-model="name" />
            </div>
            <div class="infrow">
                <p class="simpleSectionnText">SKU:</p>
                <InputText v-model="sku" />
            </div>
            <div class="infrow">
                <p class="simpleSectionnText">Collection:</p>
                <InputText v-model="collection" />
            </div>
        </div>
    </Block>
    <Block
        class="blockSection"
        title="Automation System"
        v-if="edit && permissions['send-products-for-automation']"
    >
        <div class="infrow">
            <p class="simpleSectionnText">Send to Automation:</p>
            <Toggle
                :value="automation"
                @update="setToggleAutomation"
            />
        </div>
    </Block>
    <Block
        class="blockSection"
        title="Technical files and reference images"
    >
        <div
            class="infdragDropFiles"
            :class="{ infdragDropFiles_edit: edit }"
        >
            <div
                class="infdragDropFiles_listFiles"
                v-if="files.length"
            >
                <ul>
                    <li
                        class="infdragDropFiles_listFiles_item"
                        v-for="(file, i) in files"
                        :key="'files_' + i"
                    >
                        <Button
                            class="infdragDropFiles_listFiles_item_file"
                            :noicon="true"
                            :text="file.name"
                            align="left"
                            background="#3d424e"
                            :disabled="true"
                            :previneOverflowText="true"
                        />
                        <ButtonIcon
                            v-if="!edit"
                            class="buttonIcon"
                            icon="/imgs/icons/download2.svg"
                            background="#3d424e"
                            @click="downloadFileProduct(file.url)"
                        />
                        <ButtonIcon
                            v-if="edit"
                            class="buttonIcon"
                            icon="/imgs/icons/trash.svg"
                            background="#3d424e"
                            @click="removeFileProduct(i)"
                        />
                    </li>
                </ul>
            </div>
            <div
                class="infdragDropFiles_info"
                v-if="edit"
            >
                <p class="text">Upload Files</p>
                <p class="text-small">Drag and Drop</p>
                <div>
                    <img :src="
                            require('@/assets/imgs/icons/tabler_drag-drop.svg')
                        " />
                </div>
            </div>
            <input
                type="file"
                multiple
                class="inputAll"
                @change="eventUploadFile($event)"
            />
        </div>
    </Block>
    <Block
        class="blockSection"
        :title="
            'Technical Specifications' + (automation ? ' - Units Required' : '')
        "
    >
        <div class="row inftechnical_content inftechnical">
            <div class="row inftechnical_content">
                <div class="inftechnical_imageMeasure">
                    <img
                        :src="require('@/assets/imgs/measureImage.svg')"
                        alt=""
                    />
                </div>
                <ul class="inftechnical_measures">
                    <li class="infrow inftechnical_measures_measure">
                        <p class="simpleSectionnText">Units:</p>
                        <Dropdown
                            v-if="edit"
                            class="inftechnical_measures_input"
                            :list="measures"
                            :modelValue="measureSelected"
                            @update="setMeasureSelected"
                            :fullHeight="true"
                        />
                        <p
                            v-else
                            class="inftextThin"
                        >{{ measure.unity }}</p>
                    </li>
                    <li class="infrow inftechnical_measures_measure">
                        <p class="simpleSectionnText">Height:</p>
                        <InputText
                            v-if="edit"
                            class="inftechnical_measures_input"
                            placeholder="15” 3’/10"
                            v-model="measure.height"
                        />
                        <p
                            v-else
                            class="inftextThin"
                        >{{ measure.height }}</p>
                    </li>
                    <li class="infrow inftechnical_measures_measure">
                        <p class="simpleSectionnText">Width:</p>
                        <InputText
                            v-if="edit"
                            class="inftechnical_measures_input"
                            placeholder="15” 3’/10"
                            v-model="measure.width"
                        />
                        <p
                            v-else
                            class="inftextThin"
                        >{{ measure.width }}</p>
                    </li>
                    <li class="infrow inftechnical_measures_measure">
                        <p class="simpleSectionnText">Depth:</p>
                        <InputText
                            v-if="edit"
                            class="inftechnical_measures_input"
                            placeholder="15” 3’/10"
                            v-model="measure.depth"
                        />
                        <p
                            v-else
                            class="inftextThin"
                        >{{ measure.depth }}</p>
                    </li>
                </ul>
            </div>
            <div class="inftechnical_content inftechnical_infos">
                <div class="infrow">
                    <p class="simpleSectionnText">Finish:</p>
                    <InputText
                        v-if="edit"
                        placeholder="Matte Black, Satin"
                        v-model="finish"
                    />
                    <p
                        v-else
                        class="inftextThin"
                    >{{ finish }}</p>
                </div>
                <div>
                    <p class="
                            simpleSectionnText
                            inftechnical_infos_labelInformations
                        ">
                        More informations:
                    </p>
                    <InputTextArea
                        v-if="edit"
                        placeholder="Some more details to inform about technical aspects of the product"
                        v-model="moreInformations"
                    />
                    <p
                        v-else
                        class="inftextThin inputAreaText"
                    >
                        {{ moreInformations }}
                    </p>
                </div>
            </div>
        </div>
    </Block>
    <Block
        class="blockSection"
        title="Categories"
    >
        <div class="">
            <ul
                class="listTag"
                :class="{ 'listTag-edit': edit }"
            >
                <li
                    v-for="(tag, i) in category.tags"
                    :key="'tag_' + tag.id"
                >
                    <Button
                        :text="tag.name"
                        :noicon="!edit"
                        icon="/imgs/icons/trash.svg"
                        :inverted="true"
                        @click="removeCategory(i)"
                        :smallspace="true"
                    />
                </li>
            </ul>
        </div>
        <div
            class="productScreen_productinfo_item_header_title divCategories"
            v-if="edit"
        >
            <p v-show="edit">Add a Categorie</p>
        </div>
        <div
            class=""
            v-if="edit"
        >
            <ul class="listTag listTag-add">
                <li
                    v-for="tag in filterAddCategories"
                    :key="'tagnew_' + tag.id"
                >
                    <Button
                        :text="tag.name"
                        :noicon="!edit"
                        icon="/imgs/icons/plus.svg"
                        :inverted="true"
                        :smallspace="true"
                        @click="addCategory(tag)"
                    />
                </li>
            </ul>
        </div>
    </Block>
    <Block
        class="blockSection"
        title="Tags"
    >
        <div class="">
            <ul
                class="listTag"
                :class="{ 'listTag-edit': edit }"
            >
                <li
                    v-for="(tag, i) in tags"
                    :key="'tag_' + tag.id"
                >
                    <Button
                        :text="tag.name"
                        :noicon="!edit"
                        icon="/imgs/icons/trash.svg"
                        :inverted="true"
                        @click="removeTag(i)"
                        :smallspace="true"
                    />
                </li>
            </ul>
        </div>
        <div
            class="
                productScreen_productinfo_item_header_title
                divCategories
                infrow
                inflineInputTag
            "
            v-if="edit"
        >
            <p>Select Tags</p>
            <div class="addTags">
                <p class="simpleSectionnText">Add Custom Tag:</p>

                <InputText
                    icon="/imgs/icons/plus.svg"
                    v-model="customTagInput"
                    @keyup.enter="addCustomTag"
                    @submit="addCustomTag"
                />
            </div>
        </div>
        <div
            class=""
            v-if="edit"
        >
            <ul class="listTag listTag-add">
                <li
                    v-for="tag in filterAddTags"
                    :key="'tagnew_' + tag.id"
                >
                    <Button
                        :text="tag.name"
                        :noicon="!edit"
                        icon="/imgs/icons/plus.svg"
                        :inverted="true"
                        :smallspace="true"
                        @click="addTag(tag)"
                    />
                </li>
            </ul>
        </div>
    </Block>
    <Block
        :customHeader="edit"
        :title="desc.name"
        v-for="(desc, i) in infos"
        :key="'desc_' + i"
        class="blockSection"
    >
        <template
            v-slot:header
            v-if="edit"
        >
            <div class="row header_descriptions">
                <InputText
                    placeholder="Matte Black, Satin"
                    v-model="desc.name"
                    class="descriptions_input"
                />
                <ButtonIcon
                    class="buttonIcon"
                    icon="/imgs/icons/trash.svg"
                    background="none"
                    @click="removeDescription(i)"
                />
            </div>
        </template>
        <InputTextArea
            placeholder=""
            v-model="desc.description"
            v-if="edit"
        />
        <p
            v-else
            class="info_description_text"
        >{{ desc.description }}</p>
    </Block>
    <Button
        class="buttonAdd buttonAdd-screenRight"
        icon="/imgs/icons/plus.svg"
        background="#141517"
        @click="addDescription"
        v-if="edit"
    />
    <ButtonBorderText
        class="button_save"
        @click="saveEvent()"
        v-if="edit"
        noicon="true"
        text="Save"
    />
    <PopupAlert
        v-if="popupAlert.show"
        :title="popupAlert.title"
        :subTitle="popupAlert.subTitle"
        :confirm="popupAlert.confirm"
        :cancel="popupAlert.cancel"
        @confirm="emitConfirm"
        @cancel="emitCancel"
    />
</template>

<script>
import axios from "axios";
import ButtonIcon from "@/components/buttons/Button-icon.vue";
import http from "@/http";
import Block from "@/components/sections/Block.vue";
import Button from "@/components/buttons/Button-icon-text.vue";
import InputText from "@/components/inputs/InputText.vue";
import Toggle from "@/components/inputs/Toggle.vue";
import Dropdown from "@/components/dropdowns/Dropdown-rounded.vue";
import InputTextArea from "@/components/inputs/InputTextarea.vue";
import downloadFile from "@/utils/downloadFile.js";
import ButtonBorderText from "@/components/buttons/Button-text-border.vue";
import PopupAlert from "../common/PopupAlert.vue";

export default {
    components: {
        ButtonIcon,
        Block,
        Button,
        InputText,
        Toggle,
        Dropdown,
        InputTextArea,
        ButtonBorderText,
        PopupAlert,
    },
    props: ["id", "id_customer"],
    data() {
        return {
            allCategories: [],
            category: {
                open: false,
                edit: false,
                tags: [],
            },
            edit: false,
            name: "",
            sku: "",
            collection: "",
            automation: false,
            cancelable_autsys: true,
            measure: {
                height: "",
                width: "",
                depth: "",
                unity: "",
            },
            finish: "",
            files: [],
            allTags: [],
            tags: [],
            infos: [],
            moreInformations:
                "Some more details to inform about technical aspects of the product",
            measures: ["in", "ft", "", "mm", "cm", "m"],
            customTagInput: "",
            popupAlert: {
                show: false,
                title: "Automation System",
                subTitle: "Saving...",
                confirm: false,
                cancel: false,
                type: "save",
            },
            toUpdates: {
                categories: {
                    add: [],
                    remove: [],
                },
                tags: {
                    add: [],
                    remove: [],
                },
                infos: {
                    remove: [],
                },
                files: {
                    add: [],
                    remove: [],
                },
            },
            permissions: {
                "editor-info-access": false,
                "send-products-for-automation": false,
            },
        };
    },
    methods: {
        getData() {
            http.get(
                `/gets/product?id_product=${this.id}&id_customer=${this.id_customer}`
            )
                .then((r) => {
                    if (r.data.success) {
                        this.setDataProduct(r.data.product);
                    } else {
                        console.log("Error:");
                    }
                })
                .catch((r) => {
                    console.log("Error:");
                    debugger;
                    console.log(r.response);
                    // this.$router.push('/catalog');
                });
        },
        setDataProduct(dataHttp) {
            this.collection = dataHttp.collection;
            this.name = dataHttp.name;
            this.sku = dataHttp.sku;
            this.measure.height = dataHttp.height
                ? dataHttp.height
                : dataHttp.pro_height;
            this.measure.width = dataHttp.width
                ? dataHttp.width
                : dataHttp.pro_width;
            this.measure.depth = dataHttp.depth
                ? dataHttp.depth
                : dataHttp.pro_depth;
            this.measure.unity = dataHttp.measure; //*
            this.finish = dataHttp.finish;
            this.moreInformations = dataHttp.note; //*
            this.automation = dataHttp.automation_system == 1; //*
            this.cancelable_autsys = dataHttp.cancelable_as == 1; //*
            if (
                dataHttp.categories &&
                typeof dataHttp.categories === "object"
            ) {
                dataHttp.categories.forEach((cat) => {
                    this.addCategory(
                        {
                            name: cat.subcategory,
                            id: cat.id_subcategory,
                        },
                        false
                    );
                });
            }
        },
        getTags() {
            http.get(`/gets/product/tag/all?id_product=${this.id}`)
                .then((r) => {
                    if (r.data.success) {
                        r.data.tags.forEach((tag) => {
                            this.tags.push({
                                id: tag.id,
                                name: tag.name,
                            });
                        });
                    } else {
                        console.log("Error:");
                    }
                })
                .catch((r) => {
                    console.log("Error:");
                    // debugger;
                    console.log(r.response);
                    this.$router.push("/catalog");
                });
        },
        getFiles() {
            http.get(`/gets/product/assets/show?id_product=${this.id}`)
                .then((r) => {
                    if (r.data.success) {
                        r.data.files.forEach((file) => {
                            this.files.push({
                                name: file.split("/").pop(),
                                url: file,
                            });
                        });
                    } else {
                        console.log("Error:");
                    }
                })
                .catch((r) => {
                    console.log("Error:");
                    debugger;
                    console.log(r.response);
                    // this.$router.push('/catalog');
                });
        },
        getDataInfos() {
            http.get(
                `/gets/product/description/all/show?id_product=${this.id}&id_customer=${this.id_customer}`
            )
                .then((r) => {
                    if (r.data.success) {
                        r.data.descriptions.forEach((description) => {
                            this.infos.push({
                                name: description.title,
                                description: description.notes,
                                id: description.id,
                            });
                        });
                    } else {
                        console.log(r);
                    }
                    // this.completeTaskLoad();
                })
                .catch((e) => {
                    console.log(e);
                });
        },
        getDataCategories() {
            http.get("/gets/product/categories/show")
                .then((r) => {
                    if (r.data.success) {
                        r.data.categories.forEach((cat) => {
                            cat.subcategories.forEach((subcat) => {
                                this.allCategories.push({
                                    name: subcat.subcategory,
                                    id: subcat.id,
                                });
                            });
                        });
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        },
        getDataTags() {
            http.get(`/gets/tags/show?id_customer=${this.id_customer}`)
                .then((r) => {
                    if (r.data.success) {
                        console.log(r.data);
                        r.data.tags.forEach((tag) => {
                            this.allTags.push({
                                name: tag.name,
                                id: tag.id,
                            });
                        });
                    } else {
                        console.log(r.data);
                    }
                })
                .catch((e) => {
                    debugger;
                    console.log(e);
                });
        },
        downloadFileProduct(url) {
            const path = `${process.env.VUE_APP_AWS_BUCKET_IMAGES}/${url}`;
            const fileName = url.split("/").pop();
            downloadFile(path, fileName);
        },
        toggleEdit() {
            this.edit = !this.edit;
            this.$emit("editStatus", this.edit);
        },
        /* eslint-disable no-param-reassign */
        heightTextArea(event, info) {
            info.height = 0;
            info.height = event.target.scrollHeight;
        },
        startSetAllTextAreasHeight() {
            this.infos.forEach((info, i) => {
                info.height = 0;
                const textarea = document.getElementById(`textarea_${i}`);
                textarea.style.height = "";
                info.height = textarea.scrollHeight;
            });
        },
        editOpenCloseInfo(info, value) {
            info.open = true;
            info.edit = value;
            setTimeout(() => {
                this.startSetAllTextAreasHeight();
            }, 500);
        },
        updateDbInfo(title, value, info) {
            if (title.trim() && value.trim()) {
                if (info.id === 0) {
                    http.post("/posts/product/description/store", {
                        id_product: this.id,
                        id_customer: this.customer,
                        title: title.trim(),
                        notes: value.trim(),
                    })
                        .then((r) => {
                            if (r.data.success) {
                                info.id = r.data.id_product_description;
                                console.log(r.data);
                            } else {
                                console.log(r.data);
                                console.log({
                                    id_product: this.id,
                                    id_customer: this.customer,
                                    title: title.trim(),
                                    notes: value.trim(),
                                });
                            }
                        })
                        .catch((r) => {
                            console.log(r);
                        });
                } else {
                    http.put("/puts/product/description/edit", {
                        id_product_description: info.id,
                        id_product: this.id,
                        id_customer: this.customer,
                        title,
                        notes: value,
                    }).then((r) => {
                        console.log(r);
                    });
                }
            } else {
                console.log(
                    title.trim() && value.trim(),
                    title.trim(),
                    value.trim()
                );
            }
        },
        deleteInfo(index, info) {
            this.$emit("deleteInfo", index);
            http.delete(
                `/deletes/product/description/delete?id_product_description=${info.id}&id_product=${this.id}&id_customer=${this.customer}`
            )
                .then((r) => {
                    console.log(r.data);
                })
                .catch((e) => {
                    console.log(e);
                });
        },
        /* eslint-enable no-param-reassign */
        openCloseCategory(value) {
            this.category.open = true;
            this.category.edit = value;
        },
        addCategory(tag, updateBd = true) {
            if (!tag.name || !tag.id) return;
            this.category.tags.push({
                name: tag.name,
                id: tag.id,
            });
            if (updateBd) {
                if (this.toUpdates.categories.remove.includes(tag.id)) {
                    this.toUpdates.categories.remove.splice(
                        this.toUpdates.categories.remove.indexOf(tag.id),
                        1
                    );
                } else {
                    this.toUpdates.categories.add.push(tag.id);
                }
            }
        },
        addinfo() {
            this.$emit("addinfo");
        },
        removeCategory(index) {
            if (this.edit) {
                const removed = this.category.tags.splice(index, 1);
                console.log(`Removed: ${removed}`);
                if (this.toUpdates.categories.add.includes(removed[0].id)) {
                    this.toUpdates.categories.add.splice(
                        this.toUpdates.categories.add.indexOf(removed[0].id),
                        1
                    );
                } else {
                    this.toUpdates.categories.remove.push(removed[0].id);
                }
            }
        },
        removeTag(index) {
            if (this.edit) {
                const removed = this.tags.splice(index, 1);
                console.log(`Removed: ${removed}`);
                if (this.toUpdates.tags.add.includes(removed[0].id)) {
                    this.toUpdates.tags.add.splice(
                        this.toUpdates.tags.add.indexOf(removed[0].id),
                        1
                    );
                } else {
                    this.toUpdates.tags.remove.push(removed[0].id);
                }
            }
        },
        addTag(tag, updateBd = true) {
            if (this.tags.find((t) => t.id === tag.id)) return;
            this.tags.push(tag);
            if (updateBd) {
                if (this.toUpdates.tags.remove.includes(tag.id)) {
                    this.toUpdates.tags.remove.splice(
                        this.toUpdates.tags.remove.indexOf(tag.id),
                        1
                    );
                } else {
                    this.toUpdates.tags.add.push(tag.id);
                }
            }
        },
        changeTextArea(event, info) {
            this.updateDbInfo(info.title, event.target.value.trim(), info);
        },
        changeTitle(event, info) {
            this.updateDbInfo(event.target.value.trim(), info.value, info);
        },
        addDescription() {
            this.infos.push({
                name: "",
                description: "",
                id: 0,
            });
        },
        setToggleAutomation() {
            if (this.cancelable_autsys) this.automation = !this.automation;
        },
        setMeasureSelected(value) {
            this.measure.unity = this.measures[value];
        },
        removeDescription(index) {
            const { id } = this.infos[index];
            this.infos.splice(index, 1);

            if (id) {
                this.toUpdates.infos.remove.push(id);
            }
        },
        async updateInfos() {
            let success = true;
            this.popupAlert.show = true;
            this.popupAlert.title = "Update Product";
            this.popupAlert.subTitle = "Updating product informations...";
            this.popupAlert.confirm = false;
            this.popupAlert.cancel = false;
            this.popupAlert.type = "info";

            await http
                .put("/puts/product/edit", {
                    id_product: this.id,
                    name: this.name,
                    sku: this.sku,
                    collection: this.collection,
                    automation_system: this.automation ? 1 : 0,
                    height: this.measure.height,
                    width: this.measure.width,
                    depth: this.measure.depth,
                    finish: this.finish,
                    note: this.moreInformations,
                    measure: this.measure.unity,
                })
                .then((r) => {
                    console.log(r.data);
                    if (r.data.success) {
                        this.setDataProduct(r.data.product);
                    } else {
                        success = false;
                        this.popupAlert.title = "Error";
                        this.popupAlert.subTitle = `Error updating product informations: ${r.data.message}`;
                    }
                })
                .catch((e) => {
                    console.log(e);
                    success = false;
                    this.popupAlert.title = "Error";
                    this.popupAlert.subTitle = `Error updating product informations: ${e}`;
                });

            if (!success) {
                this.popupAlert.type = "error";
                this.popupAlert.show = true;
                this.popupAlert.confirm = true;
                this.popupAlert.cancel = false;
            }
            return success;
        },
        async updateCategoryAdd(id) {
            let success = true;
            this.popupAlert.show = true;
            this.popupAlert.title = "Update Product";
            this.popupAlert.subTitle = "Adding category...";
            this.popupAlert.confirm = false;
            this.popupAlert.cancel = false;
            this.popupAlert.type = "info";

            await http
                .post("/posts/product/subcategories/store", {
                    id_product: this.id,
                    id_customer: this.id_customer,
                    id_product_subcategory: id,
                })
                .then((r) => {
                    console.log(r.data);
                    if (!r.data.success) {
                        success = false;
                        this.popupAlert.title = "Error";
                        this.popupAlert.subTitle = `Error adding category: ${r.data.message}`;
                    }
                })
                .catch((e) => {
                    console.log(e);
                    success = false;
                    this.popupAlert.title = "Error";
                    this.popupAlert.subTitle = `Error adding category: ${e}, ${e.response.data.message}`;
                });

            if (!success) {
                this.popupAlert.type = "error";
                this.popupAlert.show = true;
                this.popupAlert.confirm = true;
                this.popupAlert.cancel = false;
            }
            return success;
        },
        async updateTagAdd(id) {
            let success = true;
            this.popupAlert.show = true;
            this.popupAlert.title = "Update Product";
            this.popupAlert.subTitle = "Adding tag...";
            this.popupAlert.confirm = false;
            this.popupAlert.cancel = false;
            this.popupAlert.type = "info";

            await http
                .post("/posts/product/tag/store", {
                    id_product: this.id,
                    id_tag: id,
                })
                .then((r) => {
                    console.log(r.data);
                    if (!r.data.success) {
                        success = false;
                        this.popupAlert.title = "Error";
                        this.popupAlert.subTitle = `Error adding tag: ${r.data.message}`;
                    }
                })
                .catch((e) => {
                    console.log(e);
                    success = false;
                    debugger;
                    this.popupAlert.title = "Error";
                    this.popupAlert.subTitle = `Error adding tag: ${e}, ${e.response.data.message}`;
                });

            if (!success) {
                this.popupAlert.type = "error";
                this.popupAlert.show = true;
                this.popupAlert.confirm = true;
                this.popupAlert.cancel = false;
            }
            return success;
        },
        async updateInfoAdd(info) {
            let success = true;
            this.popupAlert.show = true;
            this.popupAlert.title = "Update Product";
            this.popupAlert.subTitle = "Adding product information...";
            this.popupAlert.confirm = false;
            this.popupAlert.cancel = false;
            this.popupAlert.type = "info";
            await http
                .post("/posts/product/description/store", {
                    id_product: this.id,
                    id_customer: this.id_customer,
                    title: info.name.trim(),
                    notes: info.description.trim(),
                })
                .then((r) => {
                    console.log(r.data);
                    if (!r.data.success) {
                        success = false;
                        info.id = r.data.id_product_description;
                        this.popupAlert.title = "Error";
                        this.popupAlert.subTitle = `Error adding product information: ${r.data.message}`;
                    }
                })
                .catch((e) => {
                    console.log(e);
                    success = false;
                    this.popupAlert.title = "Error";
                    this.popupAlert.subTitle = `Error adding product information: ${e}, ${e.response.data.message}`;
                });

            if (!success) {
                this.popupAlert.type = "error";
                this.popupAlert.show = true;
                this.popupAlert.confirm = true;
                this.popupAlert.cancel = false;
            }
            return success;
        },
        async updateInfoPut(info) {
            let success = true;
            this.popupAlert.show = true;
            this.popupAlert.title = "Update Product";
            this.popupAlert.subTitle = "Updating product information...";
            this.popupAlert.confirm = false;
            this.popupAlert.cancel = false;
            this.popupAlert.type = "info";
            await http
                .put("/puts/product/description/edit", {
                    id_product_description: info.id,
                    id_product: this.id,
                    id_customer: this.id_customer,
                    title: info.name.trim(),
                    notes: info.description.trim(),
                })
                .then((r) => {
                    console.log(r.data);
                    if (!r.data.success) {
                        success = false;
                        this.popupAlert.title = "Error";
                        this.popupAlert.subTitle = `Error updating product information: ${r.data.message}`;
                    }
                })
                .catch((e) => {
                    console.log(e);
                    success = false;
                    this.popupAlert.title = "Error";
                    this.popupAlert.subTitle = `Error updating product information: ${e}, ${e.response.data.message}`;
                });

            if (!success) {
                this.popupAlert.type = "error";
                this.popupAlert.show = true;
                this.popupAlert.confirm = true;
                this.popupAlert.cancel = false;
            }
            return success;
        },
        async updateInfoRemove(id) {
            const success = true;
            this.popupAlert.show = true;
            this.popupAlert.title = "Update Product";
            this.popupAlert.subTitle = "Deleting product information...";
            this.popupAlert.confirm = false;
            this.popupAlert.cancel = false;
            this.popupAlert.type = "info";
            await http
                .delete(
                    `/deletes/product/description/delete?id_product_description=${id}&id_product=${this.id}&id_customer=${this.id_customer}`
                )
                .then((r) => {
                    console.log(r.data);
                    if (!r.data.success) {
                        this.popupAlert.title = "Error";
                        this.popupAlert.subTitle = `Error deleting product information: ${r.data.message}`;
                        this.popupAlert.type = "error";
                        this.popupAlert.show = true;
                        this.popupAlert.confirm = true;
                        this.popupAlert.cancel = false;
                    }
                })
                .catch((e) => {
                    console.log(e);
                    this.popupAlert.title = "Error";
                    this.popupAlert.subTitle = `Error deleting product information: ${e}, ${e.response.data.message}`;
                    this.popupAlert.type = "error";
                    this.popupAlert.show = true;
                    this.popupAlert.confirm = true;
                    this.popupAlert.cancel = false;
                });
            if (!success) {
                this.popupAlert.type = "error";
                this.popupAlert.show = true;
                this.popupAlert.confirm = true;
                this.popupAlert.cancel = false;
            }
            return success;
        },
        async updateCategoryRemove(id) {
            let success = true;
            this.popupAlert.show = true;
            this.popupAlert.title = "Update Product";
            this.popupAlert.subTitle = "Removing category...";
            this.popupAlert.confirm = false;
            this.popupAlert.cancel = false;
            this.popupAlert.type = "info";

            await http
                .delete(
                    `/deletes/product/subcategories/delete?id_product=${this.id}&id_customer=${this.id_customer}&id_product_subcategory=${id}`
                )
                .then((r) => {
                    console.log(r.data);
                    if (!r.data.success) {
                        success = false;
                        this.popupAlert.title = "Error";
                        this.popupAlert.subTitle = `Error removing category: ${r.data.message}`;
                    }
                })
                .catch((e) => {
                    console.log(e);
                    success = false;
                    debugger;
                    this.popupAlert.title = "Error";
                    this.popupAlert.subTitle = `Error removing category: ${e}`;
                });

            if (!success) {
                this.popupAlert.type = "error";
                this.popupAlert.show = true;
                this.popupAlert.confirm = true;
                this.popupAlert.cancel = false;
            }
            return success;
        },
        async updateTagRemove(id) {
            let success = true;
            this.popupAlert.show = true;
            this.popupAlert.title = "Update Product";
            this.popupAlert.subTitle = "Removing tag...";
            this.popupAlert.confirm = false;
            this.popupAlert.cancel = false;
            this.popupAlert.type = "info";

            await http
                .delete(
                    `/deletes/product/tag/delete?id_product=${this.id}&id_tag=${id}`
                )
                .then((r) => {
                    console.log(r.data);
                    if (!r.data.success) {
                        success = false;
                        this.popupAlert.title = "Error";
                        this.popupAlert.subTitle = `Error removing tag: ${r.data.message}`;
                    }
                })
                .catch((e) => {
                    console.log(e);
                    success = false;
                    debugger;
                    this.popupAlert.title = "Error";
                    this.popupAlert.subTitle = `Error removing tag: ${e}`;
                });

            if (!success) {
                this.popupAlert.type = "error";
                this.popupAlert.show = true;
                this.popupAlert.confirm = true;
                this.popupAlert.cancel = false;
            }
            return success;
        },
        checkSendProducts() {
            if (this.automation) {
                if (
                    !this.measure.width ||
                    !this.measure.height ||
                    !this.measure.depth
                ) {
                    return {
                        status: false,
                        message:
                            "You need to add the dimensions to the product",
                    };
                }
            }
            return {
                status: true,
                message: "",
            };
        },
        async saveEvent() {
            let result = this.checkSendProducts();
            if (!result.status) {
                this.popupAlert.title = "Error";
                this.popupAlert.subTitle = result.message;
                this.popupAlert.type = "error";
                this.popupAlert.show = true;
                this.popupAlert.confirm = true;
                this.popupAlert.cancel = false;
                return false;
            }
            result = await this.updateInfos();
            if (!result) {
                return false;
            }
            // Categorties
            for (let i = 0; i < this.toUpdates.categories.add.length; i += 1) {
                result = await this.updateCategoryAdd(
                    this.toUpdates.categories.add[i]
                );
                if (!result) {
                    return false;
                }
            }
            for (
                let i = 0;
                i < this.toUpdates.categories.remove.length;
                i += 1
            ) {
                result = await this.updateCategoryRemove(
                    this.toUpdates.categories.remove[i]
                );
                if (!result) {
                    return false;
                }
            }
            // tags
            for (let i = 0; i < this.toUpdates.tags.add.length; i += 1) {
                result = await this.updateTagAdd(this.toUpdates.tags.add[i]);
                if (!result) {
                    return false;
                }
            }
            for (let i = 0; i < this.toUpdates.tags.remove.length; i += 1) {
                result = await this.updateTagRemove(
                    this.toUpdates.tags.remove[i]
                );
                if (!result) {
                    return false;
                }
            }
            // infos
            for (let i = 0; i < this.infos.length; i += 1) {
                if (this.infos[i].id === 0) {
                    result = await this.updateInfoAdd(this.infos[i]);
                    if (!result) {
                        return false;
                    }
                } else {
                    result = await this.updateInfoPut(this.infos[i]);
                    if (!result) {
                        return false;
                    }
                }
            }
            for (let i = 0; i < this.toUpdates.infos.remove.length; i += 1) {
                result = await this.updateInfoRemove(
                    this.toUpdates.infos.remove[i]
                );
                if (!result) {
                    return false;
                }
            }
            for (let i = 0; i < this.toUpdates.files.add.length; i += 1) {
                await this.storeFileProduct(this.toUpdates.files.add[i].file);
            }
            for (let i = 0; i < this.toUpdates.files.remove.length; i += 1) {
                await this.deleteFileProduct(this.toUpdates.files.remove[i]);
            }
            this.popupAlert.show = false;
            this.resetUpdates();
            this.$emit("updateImages");
            this.toggleEdit();
        },
        eventUploadFile(event) {
            const files = [];
            const images = [];
            const supportedFiles = [
                "txt",
                "pdf",
                "doc",
                "docx",
                "xls",
                "xlsx",
                "ppt",
                "pptx",
                "csv",
                "dwg",
            ];
            const supportedImages = ["png", "jpg", "jpeg", "webp"];
            for (let i = 0; i < event.target.files.length; i += 1) {
                let extensionFile = event.target.files[i].name.split(".");
                extensionFile =
                    extensionFile[extensionFile.length - 1].toLowerCase();
                if (supportedFiles.includes(extensionFile)) {
                    files.push(event.target.files[i]);
                } else if (supportedImages.includes(extensionFile)) {
                    images.push(event.target.files[i]);
                }
            }
            this.processFileUploadInput(files);
            this.$emit("addUploadImages", images);
            event.target.value = "";
        },
        processFileUploadInput(files) {
            const supportedFiles = [
                "txt",
                "pdf",
                "doc",
                "docx",
                "xls",
                "xlsx",
                "ppt",
                "pptx",
                "csv",
                "dwg",
            ];
            for (let i = 0; i < files.length; i += 1) {
                let extensionFile = files[i].name.split(".");
                extensionFile =
                    extensionFile[extensionFile.length - 1].toLowerCase();
                if (supportedFiles.includes(extensionFile)) {
                    const file = files[i];
                    this.files.push({
                        name: file.name,
                        file,
                    });
                    this.toUpdates.files.add.push({
                        name: file.name,
                        file,
                    });
                }
            }
        },
        removeFileProduct(index) {
            const fileName = this.files[index].name;
            this.files.splice(index, 1);
            const toUpdate = this.toUpdates.files.add.find(
                (f) => f.name === fileName
            );
            if (toUpdate) {
                this.toUpdates.files.add.splice(
                    this.toUpdates.files.add.indexOf(toUpdate),
                    1
                );
            } else {
                this.toUpdates.files.remove.push(fileName);
            }
        },
        async storeFileProduct(file) {
            this.popupAlert.subTitle = "Adding file to product";
            this.popupAlert.confirm = false;
            this.popupAlert.cancel = false;
            this.popupAlert.type = "";
            this.popupAlert.show = true;
            // send file to server
            const formData = new FormData();
            formData.append("id_product", this.id);
            formData.append("file", file);
            await http
                .post("/posts/product/asset/store", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((r) => {
                    console.log(r.data);
                })
                .catch((e) => {
                    console.log(e);
                    debugger;
                });
        },
        async deleteFileProduct(file) {
            this.popupAlert.subTitle = "Removing file from product";
            this.popupAlert.confirm = false;
            this.popupAlert.cancel = false;
            this.popupAlert.type = "";
            this.popupAlert.show = true;
            await http
                .delete(
                    `/deletes/product/asset/delete?id_product=${this.id}&file=${file}`
                )
                .then((r) => {
                    console.log(r.data);
                })
                .catch((e) => {
                    console.log(e);
                    debugger;
                });
        },
        async addCustomTag() {
            const value = this.customTagInput.split(",");
            for (let i = 0; i < value.length; i++) {
                const tagName = value[i].trim();
                let tag;
                if (tagName === "") continue;
                tag = this.allTags.find(
                    (tag) => tag.name.toLowerCase() === tagName.toLowerCase()
                );
                if (tag) {
                    this.addTag(tag);
                } else {
                    const tag = {
                        name: tagName,
                        id: 0,
                    };
                    await this.createTag(tag);
                }
            }
            this.customTagInput = "";
        },
        async createTag(tag) {
            this.popupAlert.title = "Creating tag";
            this.popupAlert.subTitle = `Creating tag ${tag.name}`;
            this.popupAlert.confirm = false;
            this.popupAlert.cancel = false;
            this.popupAlert.type = "";
            this.popupAlert.show = true;
            await http
                .post("/posts/tag/store", {
                    id_customer: this.id_customer,
                    name: tag.name,
                })
                .then((r) => {
                    console.log(r);
                    if (r.data.success) {
                        tag.id = r.data.tag.id;
                        this.allTags.push(tag);
                        this.addTag(tag);
                        this.popupAlert.show = false;
                    } else {
                        this.popupAlert.title = "Error";
                        this.popupAlert.subTitle = `Error create tag ${tag.name}: ${r.data.message}`;
                        this.popupAlert.confirm = true;
                        this.popupAlert.cancel = false;
                        this.popupAlert.type = "error";
                        this.popupAlert.show = true;
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        },
        async deleteProduct() {
            this.popupAlert.show = true;
            this.popupAlert.title = "Delete Product";
            this.popupAlert.subTitle = "Deleting product...";
            this.popupAlert.confirm = false;
            this.popupAlert.cancel = false;
            this.popupAlert.type = "info";
            await http
                .delete(
                    `/deletes/product/squedule?id_product=${this.id}&id_customer=${this.id_customer}`
                )
                .then((r) => {
                    console.log(r.data);
                    if (r.data.success) {
                        this.popupAlert.title = "Success";
                        this.popupAlert.subTitle = "Product deleted";
                        this.popupAlert.confirm = true;
                        this.popupAlert.cancel = false;
                        this.popupAlert.type = "delete_ok";
                    } else {
                        this.popupAlert.title = "Error";
                        this.popupAlert.subTitle = `Error deleting product: ${r.data.message}`;
                        this.popupAlert.confirm = true;
                        this.popupAlert.cancel = false;
                        this.popupAlert.type = "error";
                    }
                })
                .catch((e) => {
                    console.log(e);
                    this.popupAlert.title = "Error";
                    this.popupAlert.subTitle = `Error deleting product: ${e}`;
                    this.popupAlert.confirm = true;
                    this.popupAlert.cancel = false;
                    this.popupAlert.type = "error";
                });
        },
        emitConfirm() {
            if (this.popupAlert.type === "delete") this.deleteProduct();
            else if (this.popupAlert.type === "delete_ok")
                this.$router.push("/catalog");
            else this.popupAlert.show = false;
        },
        emitCancel() {
            this.popupAlert.show = false;
        },
        resetUpdates() {
            this.toUpdates = {
                categories: {
                    add: [],
                    remove: [],
                },
                tags: {
                    add: [],
                    remove: [],
                },
                infos: {
                    remove: [],
                },
                files: {
                    add: [],
                    remove: [],
                },
            };
        },
        async setupAccess() {
            const slugs = [];
            Object.keys(this.permissions).forEach((key) => {
                slugs.push(key);
            });

            const access = await this.$userAccess.to(slugs);
            Object.keys(this.permissions).forEach((key) => {
                this.permissions[key] = access[key];
            });
        },
        showPopupDeleteInfo(info) {
            this.popupAlert.show = true;
            this.popupAlert.title = "Delete Product";
            this.popupAlert.subTitle = `Are you sure you want to delete this product?`;
            this.popupAlert.confirm = true;
            this.popupAlert.cancel = true;
            this.popupAlert.type = "delete";
            this.popupAlert.info = info;
        },
    },
    computed: {
        filterAddCategories() {
            return this.allCategories.filter(
                (cat) => !this.category.tags.find((my) => my.id === cat.id)
            );
        },
        filterAddTags() {
            return this.allTags.filter(
                (tag) => !this.tags.find((my) => my.id === tag.id)
            );
        },
        measureSelected() {
            return this.measures.indexOf(this.measure.unity);
        },
    },
    mounted() {
        this.setupAccess();
        this.getDataCategories();
        this.getData();
        this.getFiles();
        this.getDataTags();
        this.getTags();
        this.getDataInfos();
    },
};
</script>

<style lang="scss" >
p {
    margin: 0;
}
.listTag {
    display: flex;
    flex-wrap: wrap;
    margin-left: -5px;
    margin-right: -5px;
    .button {
        min-width: auto;
        background: #3d424e;
        cursor: default;
        &:hover {
            background: #3d424e;
        }
    }
    > li {
        margin: 5px;
    }
    &-edit {
        .button {
            cursor: pointer;
            &:hover {
                background: #ed1802 !important;
            }
        }
    }
    &-add {
        .button {
            cursor: pointer;
            &_image {
                //padding-left: 5px;
            }
            &:hover {
                background: #0264d8;
            }
        }
    }
}
.divCategories {
    border-top: 1px solid #3d424e;
    padding-top: 10px;
    margin-top: 10px;
    padding-bottom: 10px;
}
.inEdit {
    background: #1e2127 !important;
    border-radius: 10px;
    padding: 10px;
}

.productScreen {
    &_header {
        display: flex;
        align-items: flex-start;
        margin-bottom: 10px;
        gap: 10px;

        &_flag {
            font-weight: 700;
            font-size: 20px;
            line-height: 24px;
            display: flex;
            align-items: center;
            text-align: center;
            color: #ffffff;
            padding: 9px;
            background: #7d35ff;
            border-radius: 5px 0 0 5px;
            height: 100%;
            margin-right: 10px;
        }

        &_editButton {
            min-width: auto !important;
            width: fit-content;
        }

        &_infos {
            flex: 1;
        }

        .productScreen_name {
            margin-bottom: 0;
        }
    }

    .button_text {
        padding-top: 1px;
    }

    .infrow {
        display: flex;
        align-items: center;

        > * + * {
            margin-left: 10px;
        }
    }
    .addTags {
        .inputContainer {
        }
    }
}

.infdragDropFiles {
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: relative;

    &_listFiles {
        z-index: 1;
        width: 100%;

        > ul {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 10px;
        }

        &_item {
            display: flex;

            &_file {
                width: 100%;
            }

            > * + * {
                margin-left: 10px;
            }

            .button {
                min-width: auto;
            }
        }
    }

    &_info {
        display: flex;
        flex-direction: column;
        align-items: center;

        .text-small {
            margin-bottom: 10px;
        }
    }

    &_edit {
        background: #141517;
        padding: 20px;
        > * {
            width: 50%;
        }

        .infdragDropFiles {
            &_listFiles {
                > ul {
                    grid-template-columns: 1fr;
                }
            }
        }
    }
}

.blockSection {
    margin-bottom: 10px;
}

.inputAll {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
}

.text {
    font-family: "Fira Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    color: #fff;
    margin-bottom: 10px;

    &-small {
        font-family: "Fira Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        color: #fff;
    }
}

.inftechnical {
    justify-content: space-between;

    > * {
        flex: 1;
    }

    > * + * {
        margin-left: 30px;
    }

    &_imageMeasure {
        width: 170px;
        min-width: 170px;
        height: 170px;

        > img {
            width: 100%;
            height: 100%;
        }
    }

    &_content {
        display: flex;
        align-items: stretch;
    }

    &_measures {
        display: flex;
        flex-direction: column;
        align-items: space-between;
        justify-content: space-between;
        margin-left: 30px;

        &_measure {
            justify-content: space-between;
        }

        &_input {
            width: 110px !important;
        }
        .inftextThin {
            min-width: 110px;
        }
    }

    &_infos {
        display: flex;
        flex-direction: column;
        align-items: space-between;
        justify-content: space-between;

        &_labelInformations {
            margin-bottom: 10px;
        }
        .inputAreaText {
            min-height: 80px;
        }
    }
    .inftextThin {
        width: 100%;
    }
}

.inftextThin {
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    color: #ffffff;
}

.inflineInputTag {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > * {
        flex: 1;
    }

    .addTags {
        align-items: center;
        display: flex;
        p {
            white-space: nowrap;
            margin-right: 10px;
        }
        .inputContainer {
            width: 100%;
        }
    }
}

.info {
    &_description {
        &_text {
            font-style: normal;
            font-weight: 300;
            font-size: 12px;
            line-height: 18px;
            color: #ffffff;
        }
    }
}

.block {
    &:last-child {
        margin-bottom: 20px;
    }
}
</style>

<style lang="scss" scoped>
.buttonAdd-screenRight {
    margin-bottom: 20px;
}
.header_descriptions {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .buttonIcon {
        &:hover {
            background: none !important;
        }
    }
    .inputContainer {
        max-width: 250px;
    }
}
</style>
