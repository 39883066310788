import { createApp } from 'vue';
import VueTilt from 'vue-tilt.js';
import App from './App.vue';
import router from './router';
import store from './store';
import userAccess from './utils/userAccess';
//import VueTheMask from 'vue-the-mask';
//import VueMask from 'v-mask';

const VueApp = createApp(App);
VueApp.use(store);
VueApp.use(router);
VueApp.use(VueTilt);
VueApp.config.globalProperties.$userAccess = userAccess;
//VueApp.use(VueMask);
//VueApp.use(VueTheMask);
VueApp.use(require('vue-cookies'));
VueApp.mount('#app');
